(ee.views.language = function language() {
    //Private variables
    var $screen, _pagination_, _selectedLanguage_, _isAdmin_ = false,
    //Private Methods
    _validLanguage = function (data, languageid) {
        if (!data.locale || !data.name) {
            html.modal.info('Filed "Name" and "Locale" is required', ee.t.error);
            return false;
        }
        var lang = ee.data.languages.get('locale', data.locale);
        if (lang && lang.languageid != languageid) {
            html.modal.info('Language with locale "' + data.locale + '" already exists', 'Error: Can not save a language');
            return false;
        }
        lang = ee.data.languages.get('name', data.name);
        if (lang && lang.languageid != languageid) {
            html.modal.info('Language with name "' + data.name + '" already exists', 'Error: Can not save a language');
            return false;
        }
        return true;
    },
    _getCurrentTextType = function () {
        return $screen.languagetextstabs.find('li.active').data('texttypename');
    },
    _getSearchData = function () {
        return {
            filter: ($screen.languagetextsfilter) ? $screen.languagetextsfilter.val() : '',
            filter: ($screen.searchlangtextcriteria) ? $screen.searchlangtextcriteria.val() : '',
            filter: ($screen.searchinputlangtext) ? $screen.searchinputlangtext.val() : '',
            page: _pagination_.getCurrentPage()
        }
    },
    _renderStatistics = function (data) {
        $screen.statistics.html(html.get('LanguageTextsStatistics', data, true));
    },
    _languageUsersAdmin = function () {

        if (!ee.data.languagesUsers) {
            ee.data.languagesUsers = new dataModel.LanguagesUsersData();
            ee.data.languagesUsers.listsAll(function () { _languageUsersAdmin(); });
            return;
        }

        var $content = html.get('AdminLanguageUsers', { list: ee.data.languagesUsers.getAll(), languages: ee.data.languages.getAll() }, true),
            modal = html.modal.create($content, { title: "Account Access", width: '800px' });
        /*--- Events---*/
        //Add user
        $content.addlanguageuser.click(function () {
            var data = $content.addlanguageuserform.elasticCollectForm();
            data.languageid = parseInt(data.languageid, 10);
            if (ee.tools.isValidEmail(data.email) === false || $.isNumeric(data.languageid) === false || data.languageid <= 0) {
                html.modal.info("Email or Language is not valid", "Error");
                return;
            }
            ee.data.languagesUsers.addlanguageuser(data.languageid, data.email, function () {
                html.modal.closeAll();
                _languageUsersAdmin();
            });
        });
        //Change status
        $content.languageuserslist.on('click', '.action-access', function () {
            var $this = $(this), access = $this.text().trim(), newAccess;
            newAccess = (access === 'Enabled') ? "Paused" : "Enabled";
            ee.data.languagesUsers.changeStatus($this.closest('tr').data('languageid'), $this.data('user'), newAccess, function () {
                if (newAccess === "Enabled") {
                    $this.attr("class", 'label label-primary action-access');
                } else {
                    $this.attr("class", 'label label-default action-access');
                }
                $this.text(newAccess);
            });
        });
        //Disabled/Remove user
        $content.languageuserslist.on('mouseup', '.action-disabled', function () {
            var $this = $(this), languageid = $this.closest('tr').data('languageid'), email = $this.data('email');
            html.modal.confirm(email + ": are you sure you want to delete this user from the language editor?", "Remove user", function () {
                ee.data.languagesUsers.changeStatus(languageid, email, 'disabled', function () {
                    var users = new Collection.Data(ee.data.languagesUsers.get('languageid', languageid).users);
                    users.remove('email', email);
                    if (users.getLength() === 0) {
                        ee.data.languagesUsers.remove('languageid', languageid);
                        $this.closest('tr').remove();
                    } else {
                        $this.closest('li').remove();
                    }
                });
            });
        });
    },
    _languageCreateAdmin = function () {
        var $content = html.get('AdminModalCreateLanguage', {}, true),
            modal = html.modal.create($content, {
                title: 'Create new language',
                buttons: [{
                    name: 'save',
                    css: 'btn_primary',
                    callback: function () {
                        var data = $content.elasticCollectForm();
                        if (_validLanguage(data)) {
                            ee.data.languages.create(data, function () {
                                ee.data.languages = false;
                                modal.close();
                                ee.view.show();
                            });
                        }
                    }
                }]
            });

    },
    _languageEditAdmin = function (language) {
        var $content = html.get('AdminLanguageEditModal', language, true);
        $content.languagestatus.val(language.status);
        var modal = html.modal.create($content, {
            title: ee.t.edit + ' ' + ee.t.language,
            buttons: [{
                name: ee.t.save,
                css: 'btn_primary btn_lg',
                callback: function () {
                    var data = $content.elasticCollectForm();
                    if (_validLanguage(data, language.languageid)) {
                        modal.close();
                        ee.data.languages.edit(language, data, function () {
                            _pagination_.refresh();
                        });
                    }
                }
            }]
        });
    },
    _languageRemoveAdmin = function (language) {
        html.modal.confirm('<span style="font-size:15px;">Are you sure you want to delete the <br/><b>' + language.name + '</b> language? <br/><u>The operation is not reversible</u></span>', 'WARRING!<br/>Remove language', function () {
            ee.data.languages.deleteByLocale(language.locale, function () {
                _pagination_.updateData(ee.data.languages.getAll());
            });
        });
    },
    _textImportAdmin = function () {
        var $content = html.get('AdminLanguageImportTextModal', { languages: ee.data.languages.getAll() }, true),
           modal = html.modal.create($content, {
               title: 'Add new text',
               size: 'emodal-lg',
               buttons: [{
                   name: 'save',
                   css: 'btn_primary',
                   callback: function () {
                       var fileObj = $content.languagefile[0].files[0],
                           textType = _getCurrentTextType();
                       if (!fileObj || textType < 0 || !_selectedLanguage_) {
                           console.log('error');
                           return;
                       }
                       _selectedLanguage_.languagetexts[textType].imports(_selectedLanguage_.locale, fileObj, function () {
                           modal.close();
                           delete _selectedLanguage_.languagetexts[textType];
                           _selectedLanguage_.publishedversion++;
                           if ($screen.publishedversion) {
                               $screen.publishedversion.html('v.' + _selectedLanguage_.publishedversion);
                           }
                           _renderTextTabsContent(textType);
                       });
                   }
               }]
           });
    },
    _textRemoveAdmin = function (keysToDelete) {
        if (keysToDelete.length <= 0) return;
        html.modal.confirm('<span style="font-size:15px;">The selected keys will be removed from all translations:<br/><b>' + keysToDelete.join('</b><br/><b>') + '</b><br/><br/><u>The operation is not reversible </u></span>', 'WARRING!<br/>Remove translations', function () {
            var textType = _getCurrentTextType(), languagetexts = _selectedLanguage_.languagetexts[textType];
            languagetexts.removeByKeys(_selectedLanguage_.languageid, keysToDelete, function () {
                _selectedLanguage_.languagetexts = false;
                _renderTextTabsContent(textType, _getSearchData());
            });
        });
    },
    _textSave = function () {
        var texts = {}, textType = _getCurrentTextType();
        $screen.tabcontent.find('textarea:not([readonly])').each(function () {
            texts[this.dataset.keyname.toLowerCase()] = this.value;
        });
        if (Object.keys(texts).lenght <= 0 || textType < 0) {
            return;
        }
        _selectedLanguage_.languagetexts[textType].saveText(_selectedLanguage_.locale, textType, texts, function () {
            delete _selectedLanguage_.languagetexts[textType];
            _selectedLanguage_.publishedversion++;
            if ($screen.publishedversion) {
                $screen.publishedversion.html('v.' + _selectedLanguage_.publishedversion);
            }
            _renderTextTabsContent(textType, _getSearchData());
        });
        return;
    },
    _textRevision = function (keyname, languageid) {
        _selectedLanguage_.languagetexts[_getCurrentTextType()].getRevisions(languageid, keyname, function (data) {
            var $content = html.get('ModalRevisionsText', { list: data, isAdmin: _isAdmin_ }, true),
                modal = html.modal.create($content, {
                    title: 'Revision for: ' + keyname,
                    size: 'emodal-xl',
                    alignToScreenHeight: true
                });
        });
    },
    _renderTextTabsContent = function (textTypeName, searchdata) {
        var data, $tabContent;
        $screen.tabcontent.empty();
        if (!_selectedLanguage_.languagetexts || !_selectedLanguage_.languagetexts[textTypeName]) {
            ee.data.languages.loadTexts(_selectedLanguage_, textTypeName, function () {
                _renderTextTabsContent(textTypeName, searchdata);
            });
            return;
        }
        data = _selectedLanguage_.languagetexts[textTypeName];
        _renderStatistics(data.statistics);
        $tabContent = html.get('LanguageTextsContent', {}, true);
        $tabContent.appendTo($screen.tabcontent);
        //Init Pagiantion
        _pagination_ = new html.Pagination([], {
            template: 'LanguageText',
            resultcontainer: $tabContent.languagetexts,
            footercontainer: '.paginationlist',
            perpage: 25,
            paginationinfo: true
        });
        _pagination_.eventChangePage(function () {
            if ($tabContent.languagetable.find('textarea:not([readonly])').length > 0) {
                $screen.bulksavetranslations.removeAttr('disabled');
            } else {
                $screen.bulksavetranslations.attr('disabled', true);
            }
        });
        //Bid Search
        html.bindSearch($screen.searchlangtext, function (searchSTR) {
            var searchBy = $screen.searchlangtextcriteria.val();
            _pagination_.searchfilter(searchSTR, ((!searchBy) ? ['keyname', 'data'] : [searchBy]));
        });
        //Search filter
        $screen.searchlangtextcriteria.change(function () { $screen.searchinputlangtext.trigger('keyup'); });
        //Filter
        $screen.languagetextsfilter.change(function () {
            var filter = $(this).val();
            switch (filter) {
                case 'translated':
                    _pagination_.updateData(_.filter(data.data, function (data) {
                        return data.languagetextid > 0;
                    }));
                    break;
                case 'notranslated':
                    _pagination_.updateData(_.filter(data.data, { languagetextid: 0 }));
                    break;
                case 'hasnoparent':
                    _pagination_.updateData(_.filter(data.data, function (data) {
                        return !data.hasParent && data.languagetextid > 0;
                    }));
                    break;
                default:
                    _pagination_.updateData(data.getAll());
                    break;
            }
            $screen.searchlangtext.find('i.fa-search:first').click();
        });
        $screen.languagetextsfilter.change();
    },
    //Public methods
    that = {
        show: function () {
            _isAdmin_ = ee.session.can.AdminModifyLanguages || (ee.session.can && ee.session.can.AdminModifyLanguages);
            ee.frame.content.empty();
            if (!ee.data.languages) {
                ee.data.languages = new dataModel.LanguagesData();
                ee.data.languages.listeditable(ee.view.show);
                return;
            }
            if (ee.Router.action() === 'text') {
                that.showText();
                return;
            }
            //Default show
            $screen = html.get('LanguageList', { isAdmin: _isAdmin_ }, true);
            $screen.appendTo(ee.frame.content);
            _pagination_ = new html.Pagination(ee.data.languages.getAll(), {
                template: 'LanguageTable',
                resultcontainer: $screen.paginationresult,
                footercontainer: '.paginationlist',
                perpage: 25,
                paginationinfo: false,
                firstlast: false,
                param: { isAdmin: _isAdmin_ }
            });

            html.addDropdownFocus($screen.languagetable);
            //Refresh screen
            $screen.refresh.click(function () {
                ee.data.languages = false;
                ee.view.show();
            });
            //Admin options
            if (_isAdmin_ === true) {
                $screen.languageadd.click(function () {
                    _languageCreateAdmin();
                });
                $screen.languageusers.click(function () {
                    _languageUsersAdmin();
                });

                $screen.languagetable.on('click', '.emanagecontacts li', function () {
                    var $this = $(this), action = $this.data('action'),
                        selected = ee.data.languages.get('locale', $this.closest('.dropdown-menu').data('languagelocale'));
                    if (!selected) {
                        console.log('Language not found')
                        return;
                    }
                    switch (action) {
                        case 'languageedit':
                            _languageEditAdmin(selected);
                            break;
                        case 'languagedelete':
                            _languageRemoveAdmin(selected);
                            break;
                    }
                });
            }
        },
        showText: function () {
            _isAdmin_ = ee.session.can.AdminModifyLanguages || (ee.session.can && ee.session.can.AdminModifyLanguages);
            ee.frame.content.empty();
            _selectedLanguage_ = ee.data.languages.get('locale', (ee.Router.parameter('node0')));
            if (!_selectedLanguage_) {
                ee.goTo('#/admin/language');
                return;
            }
            $screen = html.get('LanguageTexts', { locale: _selectedLanguage_.locale, name: _selectedLanguage_.name, publishedversion: _selectedLanguage_.publishedversion, isAdmin: _isAdmin_ }, true);
            $screen.appendTo(ee.frame.content);
            //Refresh screen
            $screen.refresh.click(function () {
                delete _selectedLanguage_.languagetexts;
                _renderTextTabsContent(_getCurrentTextType(), _getSearchData());
            });
            $('#esearchlangtextcriteria').hide();
            //Save translations
            $screen.bulksavetranslations.click(function (e) {
                e.stopPropagation();
                var $this = $(this);
                if ($this.hasClass('disabled') === false) {
                    $this.addClass('disabled');
                    _textSave();
                }
            });
            //Admin manage translations 
            if (_isAdmin_ === true) {
                $screen.managetranslation.on('click', 'li', function () {
                    switch ($(this).data('action')) {
                        case 'translationimport':
                            _textImportAdmin();
                            break;
                        case 'translationexport':
                            ee.data.languages.downloadExport(_selectedLanguage_.locale, _getCurrentTextType());
                            break;
                        case 'translationremove':
                            _textRemoveAdmin($screen.tabcontent.find('input[name=removedata]:checked').map(function () { return this.value }).get());
                            break;
                    }
                });
            }
            //Tab change event
            $('#elanguagetextstabs').on('click', 'li', function () {
                if (this.className === 'active') return;
                ee.changeHashQuietly('/language/' + ee.Router.action() + '/' + ee.Router.parameter('node0') + '/' + this.dataset.texttypename);
                $screen.languagetextsfilter.val('');
                $screen.searchinputlangtext.val('');
                $screen.searchlangtextcriteria.val('');
                _renderTextTabsContent(this.dataset.texttypename);
            });
            // Edit translation content
            var selectedText = false
            $screen.tabcontent.on('click', 'textarea[readonly]', function () {
                $(this).removeAttr('readonly');
                selectedText = $(this).val();
            });
            $screen.tabcontent.on('blur keyup', 'textarea:not([readonly])', function (e) {
                var $this = $(this);
                if (e.type == "focusout" && selectedText === $this.val()) {
                    $this.attr('readonly', true);
                    if ($screen.tabcontent.find('textarea:not([readonly])').length === 0) {
                        $screen.bulksavetranslations.addClass('disabled').attr("disabled", true);
                    }
                    return;
                }
                $screen.bulksavetranslations.removeClass('disabled').removeAttr("disabled");
            });
            //Revision
            $screen.tabcontent.on('click', '.getrevisions', function () {
                _textRevision($(this).data('keyname'), _selectedLanguage_.languageid);
            });
            //Trigger event on first load screen
            if (!ee.Router.parameter('node1')) {

            } else {
                var tab = $screen.languagetextstabs.find('li[data-texttypename=' + ee.Router.parameter('node1') + '] a');
                if (tab.length !== 1) {
                    ee.goTo('/' + ee.Router.controller() + '/' + ee.Router.action());
                    return;
                }
                $screen.languagetextstabs.find('li[data-texttypename=' + ee.Router.parameter('node1') + '] a').click();
            }
        }
    };
    return that;
}());
