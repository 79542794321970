
(function (root) {

    const that = {
        request: function(target, name, data, method, isResponseHeadersRequired) {

            if (target.charAt(0) !== '/') {
                target = "/" + target;
            }



            if (name) {
                target = target + '/' + name;
            }

            var headers = {
                'X-ElasticEmail-ApiKey': ee.session.apikey(),
                'Accept': 'application/json'
            }

            if (sessionStorage && sessionStorage.getItem('subaccountemailtouse')) {
                $.extend(headers, {'X-ElasticEmail-ImpersonateAs': sessionStorage.getItem('subaccountemailtouse')})
            }

            return new Promise(function(resolve, reject) {
                $.ajax({
                    url: ee.api.path + target + '?version=3',
                    type: method,
                    headers: headers,
                    data: data
                })
                .done(function(resp, textStatus, request) {
                    let headers = request.getAllResponseHeaders();
                    headers = headers.split("\n");

                    let responseHeaders = {};

                    headers.forEach(function (header) {
                        header = header.split(": ");
                        var key = header.shift();
                        if (key.length == 0) {
                            return
                        }
                        // chrome60+ force lowercase, other browsers can be different
                        key = key.toLowerCase();
                        responseHeaders[key] = header.join(": ");
                    });

                    return resolve(isResponseHeadersRequired ? {...resp, responseHeaders } : resp);
                })
                .fail(function(err) {
                    return reject(err);
                })
                /*.always(function (resp) {
                    console.log(resp);
                    if (resp && resp.status > 299) {
                        console.log("reject");
                        return reject(resp);
                    }

                    if (!resp.data) { return resolve({}); }
                    return resolve(data);
                })*/
            });
        }
    };
    window.API_V3 = that;

}(this));
