(function (namespace) {
    "use strict";
    var TwoFactorAuth = function TwoFactorAuth() { },
        proto = {
            SetEmail: (email) => {
                if (ee.data.account.isuser) {
                    return EE_API.User.setTwoFactorAuthEmail({ twoFactorAuthType: 1, email: email });
                } else {
                    return EE_API.Account.setTwoFactorAuthEmail({ twoFactorAuthType: 1, email: email });
                }
            },
            SetPhone: (phonenumber) => {
                if (ee.data.account.isuser) {
                    return EE_API.User.setTwoFactorAuthSms({ twoFactorAuthType: 1, phonenumber: phonenumber });
                } else {
                    return EE_API.Account.setTwoFactorAuthSms({ twoFactorAuthType: 1, phonenumber: phonenumber });
                }
            },
            Verify: (code, method) => {
                var options = { manualerrorhandle: true };
                if (!method || method === 'mail' && ee.data.account.isuser) {
                    return EE_API.User.VerifyTwoFactorAuthEmail({ validationCode: code }, options);
                } else if (!method || method === 'mail' && !ee.data.account.isuser) {
                    return EE_API.Account.VerifyTwoFactorAuthEmail({ validationCode: code }, options);
                } else if (!method === 'mail' && ee.data.account.isuser) {
                    return EE_API.User.VerifyTwoFactorAuthSms({ validationCode: code }, options);
                } else {
                    return EE_API.Account.VerifyTwoFactorAuthSms({ validationCode: code }, options);
                }
            },
            VerifyResend: (method) => {
                if (!method || method === 'mail' && ee.data.account.isuser) {
                    return EE_API.User.ResendTwoFactorAuthVerificationEmail();
                } else if (!method || method === 'mail' && !ee.data.account.isuser) {
                    return EE_API.Account.ResendTwoFactorAuthVerificationEmail();
                } else if (!method === 'mail' && ee.data.account.isuser) {
                    return EE_API.User.ResendTwoFactorAuthVerificationSms();
                } else {
                    return EE_API.Account.ResendTwoFactorAuthVerificationSms();
                }
            },
            GenerateDisableCode: () => {
                if (ee.data.account.isuser) {
                    return EE_API.User.GenerateTwoFactorAuthDisableCode();
                } else {
                    return EE_API.Account.GenerateTwoFactorAuthDisableCode();
                }
            },
            Disable: () => {
                if (ee.data.account.isuser) {
                    return EE_API.User.DisableTwoFactorAuth().then(function () {
                        if (ee.data && ee.data.account) {
                            //-- If success then set twofactorauthsetting to "none"
                            ee.data.account.twofactorauthsetting = "None";
                        }
                    });
                } else {
                    return EE_API.Account.DisableTwoFactorAuth().then(function () {
                        if (ee.data && ee.data.account) {
                            //-- If success then set twofactorauthsetting to "none"
                            ee.data.account.twofactorauthsetting = "None";
                        }
                    });
                }
            }
        }
    dataModel.inherit(TwoFactorAuth, proto).register("TwoFactorAuth", TwoFactorAuth);
}(window.dataModel));
