﻿(function (root, namespace) {

    function __removeDoNotShow(arr) {
        var coll = new Collection.Data(arr),
            collFiltered = coll.filter('doNotShow', 'true', true, true);

        collFiltered.each(function () {
            var sub = this.sub || this.sub2;
            if (sub) {
                sub.items = __removeDoNotShow(sub.items)
            }
        })
        return collFiltered.getAll();
    };

    function __menu() {
        var menuElements = [
              //START
              {
                txt: ee.t.start,
                title: ee.t.start,
                view: "dashboard",
                icon: "home-icon",
                menutype: "menu_top",
                id: "home-menu-item"
            },
               //CAMPAIGNS
               {
                id: "campaigns-menu-item",
                txt: ee.t.campaigns,
                title: ee.t.campaigns,
                view: "campaigns/new/",
                icon: "campaigns-icon",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewCampaigns
            },
            //CONTACTS
            {
                id: "contacts-menu-item",
                txt: ee.t.contacts,
                title: ee.t.contacts,
                view: "contacts/new/overview",
                // view: ee.isLive() || ee.isReseller() ? "contacts" : "contacts/new/overview",
                icon: "contacts-icon",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewContacts
            },

            {
                id: "forms-menu-item",
                txt: ee.t.forms,
                title: ee.t.forms,
                view: "forms",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewForms,
                icon: "forms-icon"
            },
            //AUTOMATIONS
            {
                id: "automations-menu-item",
                txt: ee.t.automations,
                title: ee.t.automations,
                view: "automations",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewAutomations,
                icon: "automations-icon"
            },
            //TEMPLATES
            {
                id: "templates-menu-item",
                txt: ee.t.templates,
                title: ee.t.templates,
                tooltip: ee.t.tttemplatesmenu,
                view: "templates/new",
                icon: "templates-icon",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewTemplates
            },
            //LANDING PAGES
            {
                id: "landingpages-menu-item",
                txt: ee.t.landingpages,
                title: ee.t.landingpages,
                tooltip: ee.t.ttlandingpagessmenu,
                view: "landingpages",
                icon: "landingpage-icon",
                menutype: "menu_top",
                doNotShow: !ee.session.can.ViewTemplates
            },
            //REPORTS
            {
                id: "activity-menu-item",
                txt: ee.t.activity,
                title: ee.t.activity,
                view: "activity",
                icon: "activity-icon",
                doNotShow: !ee.session.can.ViewReports,
                menutype: "menu_top",
            },
            //SURVEYS
            {
                id: "surveys-menu-item",
                txt: ee.t.surveys,
                title: ee.t.surveysdesc,
                view: "surveys",
                id: "esurveys",
                icon: "survey-icon",
                doNotShow: !ee.session.can.ViewSurveys,
                menutype: "menu_top"

            },
              //Exports
              {
                id: "exports-menu-item",
                txt: ee.t.exports,
                title: ee.t.exports,
                view: "exports",
                id: "eexports",
                mainIconHtml: html.get('ExportsMenuIcon')[0].outerHTML,
                doNotShow: !ee.session.can.ViewAccount,
                menutype: "menu_top"

            },
            {
                id: "verification-menu-item",
                txt: ee.t.verifications,
                title: ee.t.verifications,
                view: "verifications",
                doNotShow: !ee.session.can.ViewEmailVerifications || ee.isReseller() || ee.data.account.isownedbyreseller || ee.data.account.isreseller || ee.data.account.issub,
                menutype: "menu_top",
                additionalClasses: "js-menu-item__verifications menu-item__verifications",
                mainIconHtml: html.get("VerificationsMenuLogoIcon")[0].outerHTML,
                secondIconHtml: html.get("VerificationsOpenInNewTabIcon")[0].outerHTML,
            },
            // ADD-ONS
            // {
            //     id: "addons-menu-item",
            //     txt: ee.t.addons,
            //     title: ee.t.addons,
            //     view: "addons",
            //     icon: "fas fa-tags",
            //     menutype: "menu_bottom",
            //     //doNotShow: !ee.session.can.ViewAddons,
            //     sub: {
            //         items: [

            //             {
            //                 id: "addons-x-menu-item",
            //                 txt: "x",
            //                 title: "x",
            //                 //doNotShow: !ee.session.can.ModifySurveys,
            //                 view: "addons",
            //                 menutype: "menu_bottom"
            //             }
            //         ]

            //     }
            // },
            //ACCOUNT


        ];

        if (ee.parameters.bootedAsChild) {
            menuElements = menuElements.filter((o) => o.id !== "verification-menu-item")
        }

        const menuColl = new Collection.Data(menuElements);
            // var menuTop = _.filter(menuElements, ['menutype', 'menu_top']);
            // menuTopColl = new Collection.Data(menuTop);
            // var menuBottom = _.filter(menuElements,['menutype', 'menu_bottom'] );
            // menuBottomColl = new Collection.Data(menuBottom);

        if (!ee.session.can.ViewAccount || !ee.session.can.ViewBilling) {
            /*Need to remove whole sub object to not show caret in left menu */

            var accountObj = menuColl.get('id', 'account');
            if (accountObj) {
                accountObj = _.omit(accountObj, 'sub');
            }
        }

        if (ee.parameters.custommenuitems) {
            for (var i = 0, n = ee.parameters.custommenuitems.length; i < n; i++) {
                menuColl.add(ee.parameters.custommenuitems[i]);
            }
        }
        if (ee.parameters.removemenuitems) {
            for (i = 0, n = ee.parameters.removemenuitems.length; i < n; i++) {
                menuColl.remove('view', ee.parameters.removemenuitems[i]);
            }
        }

        return __removeDoNotShow(menuColl.getAll());
    };

    function __topnavbar() {
        var parrentAccount,
            obj = {};

        try {
            parrentAccount = sessionStorage.getItem("parentemail")
        } catch(err) {
            console.log(err);
            console.log("You need to enable session storage");
        }

        

        obj.userMenu = {};
        obj.userMenu.items = [];

        obj.parentaccountusername = parrentAccount;
        //obj.accountusername = ee.data.account.username;
        obj.accountusername = ee.data.account.username;
        obj.email = ee.data.account.email;
        obj.paymentsenabled = ee.data.account.autocreditstatus == "on";

        if (ee.session.can.AdminView || (ee.session.can && ee.session.can.AdminView)) {
            obj.isAdmin = true;
            obj.isInspection = ee.session.isInspection();
        };
        obj.isReseller = ee.isReseller();
        obj.base = ee.base;
        obj.logourl = ee.parameters.logourl;
        obj.firstname = ee.data.account.firstname;
        obj.lastname = ee.data.account.lastname;
        obj.issub = ee.data.account.issub;
        obj.subaccountlist = (ee.data.account.issub === false || parrentAccount) ? true : false;
        obj.active = (window.screen.width > 1440) ? 'active' : '';
        return obj;
    };

    function config(name) {
        var CONFIG = {
            menu: __menu(),
            topnavbar: __topnavbar(),
            contactsource: ['Delivery API', 'Manual Input', 'File Upload', 'Web Form', 'Contact API'],
            contactstatus: ['Transactional', 'Engaged', 'Active', 'Invalid', 'Unsubscribed', 'Complaint', 'Inactive', 'Stale', 'NotConfirmed']
        };

        return CONFIG[name];
    }


    namespace.config = config;
}(this, ee));
