(function () {
    /**
    * Extension of the class HTML, adds methods associated with the support pagination.
    * @class
    * @param sourceList {Array} - list of elements to show in pagination
    * @param options {Object} - Options object
    * @param options.perpage {Number} - Number of elements on one page (default: 12)
    * @param options.template {String} - pagination template name REQ
    * @param options.paginationNUM {Number} - number of pages in footer (default: 5)
    * @param options.resultcontainer {String} -css selector of paginataion container (default: "#epaginationresult")
    * @param options.footercontainer {String} -css selector of footer container (default: "#eresultfooter")
    * @param options.footertemplate {String} - footer template name (default: "Pagination2")
    * @param options.paginationinfo {Boolean} - show count information in pagination footer (default: false)
    * @param options.reinitcallback {function} - set callback to invoke after pagination.refresh
    * @param options.searchparameters {Array} - set parameters to search by pagination.searchfilter
    * @param options.param {Object} - add extra parameters visible in template,
    * @param options.inFrame {Object} - check if search is made inside original context, or inside other screen (in such case don't update urls)
    * @memberof html
    */
    html.PaginationPromise = function (sourceList, options) {
        let sourceData = sourceList;
        let currentData;
        let timeoutId;
        const defaultOptions = {
                perpage: 12,
                template: "", //required
                paginationNUM: 5,
                resultcontainer: '#epaginationresult',
                footercontainer: '#eresultfooter',
                footertemplate: 'Pagination2',
                paginationinfo: false,
                reinitcallback: false,
                searchparameters: [],
                param: { columnsCount: false },
                inFrame: false
        };
        const settings = $.extend(true, {}, defaultOptions, options);
        let cache = {};
        let dataCache = {};
        let currentPage = ee.Router.parameter('page') ? ee.Router.parameter('page') : 1;
        let pageCount = 0;
        let adminPagination = location.hash.indexOf("#/admin") != -1;

        let $resultcontainer = $(settings.resultcontainer);
        let $footercontainer = $(settings.footercontainer);
        //--- PRIVATE METHODS ---//
        var _showresult = function (keepData) {
            if (ee.Router.parameter('page')) {
                currentPage = ee.Router.parameter('page');
            }

            let template;
            let data = {};
            let cacheName = 'Page' + currentPage;
            //Recalculate page count

            if (settings.totalElements && !keepData) {
                pageCount = Math.ceil(settings.totalElements / settings.perpage) || 1;
            } else {
                pageCount = Math.ceil(currentData.length / settings.perpage) || 1;
            }

            if (pageCount < currentPage) {
                currentPage = pageCount
            }

            if (settings.param.columnsCount === false) {
                settings.param.columnsCount = $resultcontainer.closest('table').find('thead th').length || 1;
            }
            //Create page or use the cache
            if (!cache[cacheName]) {
                __calcresult(keepData)
                .then((list) => {
                    if (settings.formatElements) { settings.formatElements(list) }
                    data.list = list;
                    data.admin = ee.session.can.AdminView;
                    data.param = settings.param;
                    cache[cacheName] = template = html.get(settings.template, data);

                    dataCache[cacheName] = list

                    $resultcontainer.empty();
                    template.appendTo($resultcontainer);
                    _showfooter.call();
                });
            } else {
                $resultcontainer.empty();
                currentData = dataCache[cacheName];
                template = cache[cacheName];
                template.appendTo($resultcontainer);
                _showfooter.call();
            }

        };
        var __calcresult = function (keepData) {
            const start = (currentPage - 1) * settings.perpage;
            const end =  start + settings.perpage;

            if (settings.loadElements && !keepData) {
                if (currentPage === 1 && !_.isEmpty(sourceList)) {
                    return Promise.resolve(sourceList)
                }

                ee.indiOn();

                const extraParams = settings.extraLoadParams || {};
                const params = {...extraParams, offset: start, limit: options.perpage}

                return settings.loadElements(params)
                .then((resp) => {
                    ee.indiOff();
                    sourceList = resp;
                    currentData = resp;

                    return resp;
                }).catch((err) => {
                    console.error(err);
                })
            }

            return Promise.resolve(_.slice(currentData, start, end))
        };
        var _showfooter = function () {
            var $footer, data = {};
            $footercontainer.empty();
            //Calculacte Pages
            data.pages = _footerpagetoshow();
            //Optional Set Info
            if (settings.paginationinfo) data.info = _getpaginationinfo();
            //Other Optional data
            data.pagenumber = currentPage;
            if (data.pages.length === 1) {
                data.paginationdisabled = true;
            }
            data.showprev = (currentPage === 1) ? false : true;
            data.shownext = (currentPage === pageCount) ? false : true;
            $footer = $footer = html.get(settings.footertemplate, data);
            $footer.appendTo($footercontainer);
            //Set active pagination page
            if (data.paginationdisabled !== true) {
                $footercontainer.find(".action-gotopage[data-page=" + currentPage + "]").addClass("active");
            }
        };
        var _footerpagetoshow = function () {
            var num = settings.paginationNUM,
                arr = [],
                position, start, end;
                currentPage = parseInt(currentPage);
            if (currentPage < num) {
                start = 0;
                end = num;
            } else if (currentPage >= pageCount) {
                start = pageCount - num;
                end = pageCount;
            } else {
                position = pageCount - currentPage;
                if (position === 1) {
                    start = currentPage - num + 1;
                    end = currentPage + 1;
                } else {
                    start = currentPage - num + 2;
                    end = currentPage + 2;
                }
            }
            if (end > pageCount) end = pageCount;
            for (var i = start; i < end; i++) {
                arr.push(i + 1);
            }
            return arr;
        };
        var _getpaginationinfo = function () {
            var all = currentData.length,
                start = ((currentPage - 1) * settings.perpage) + 1,
                end = currentPage * settings.perpage,
                page = pageCount;
            if (end > all) end = all;

            return {
                all: all,
                start: start,
                end: end,
                page: page
            }
        };
        var _initFooterEvents = function () {
            //Unbind all events on footer
            $footercontainer.off('click');
            //Event for footer
            $footercontainer.on('click', '.action-gotopage', function (e) {
                currentPage = parseInt($(this).data('page'));
                if (!adminPagination && !settings.inFrame)
                    html.updateRouter('page', currentPage);
                $resultcontainer.trigger('beforechangepage');
                _showresult();
                $resultcontainer.trigger('changepage');
            });
            $footercontainer.on('click', '.prev', function (e) {
                if (currentPage > 1) {
                    currentPage = parseInt(currentPage) - 1;
                    if (!adminPagination && !settings.inFrame)
                        html.updateRouter('page', currentPage);
                } else {
                    return;
                };
                $resultcontainer.trigger('beforechangepage');
                _showresult();
                $resultcontainer.trigger('changepage');
            });
            $footercontainer.on('click', '.next', function (e) {
                if (currentPage < pageCount) {
                    currentPage = parseInt(currentPage)  + 1;
                    if (!adminPagination && !settings.inFrame)
                       html.updateRouter('page', currentPage);
                } else {
                    return;
                }
                $resultcontainer.trigger('beforechangepage');
                _showresult();
                $resultcontainer.trigger('changepage');
            });
        };
        var _searchDataObj = function (val, paramArr, match) {
            if (settings.searchElements) {
                currentPage = 1
                html.updateRouter('page', currentPage);

                const extraParams = settings.extraLoadParams || {};

                const defaultParams = {
                    offset: (currentPage - 1) * settings.perpage,
                    limit: options.perpage
                };

                const params = {...extraParams, ...defaultParams}

                params[paramArr[0]] = val;

                return settings.searchElements(params)
                .then((resp) => {
                    return resp;
                })
            }

            return Promise.resolve(() => {
                var filter = Array.prototype.filter;
                return filter.call(sourceData, function (elem) {
                    var i;
                    var reg = (!match) ? new RegExp(val) : new RegExp('^' + val + '$');
                    var param = paramArr;
                    var paramlength = param.length;
                    var actparam;
                    var elemstr;
                    for (i = 0; i < paramlength; i++) {
                        actparam = param[i];
                        elemstr = elem[actparam] + '';
                        if (reg.test(elemstr.toLowerCase())) {
                            return true
                        }
                    }
                    return false
                });
            })
        }
        //--- PUBLIC METHOD --//
        var that = {
            /**
            * Refresh pagination object
            * @memberof html.Pagination
            */
            refresh: function (keepData = false) {
                cache = {};
                if (settings.reinitcallback) settings.reinitcallback();


                _showresult(keepData);
            },
            /**
            * Return result container
            * @return {jQuery.Object}
            * @memberof html.Pagination
            */
            get$Container: function () {
                return $resultcontainer;
            },
            /**
            * Return number of pages in pagination
            * @return {Number}
            * @memberof html.Pagination
            */
            getPagesCount: function () {
                return pageCount;
            },
            /**
            * Return number of items in pagination
            * @return {Number}
            * @memberof html.Pagination
            */
            getItemsCount: function() {
                return sourceData.length;
            },
            /**
            * Update pagination data but not reload display
            * @param data {Array} - new data
            * @memberof html.Pagination
            */
            updateData: function (data) {
                sourceData = data;
                currentData = sourceData;
                that.refresh();
            },
            /**
            * go on 1 page and update data
            * @param data {Array} - new data
            * @memberof html.Pagination
            */
            update: function (data) {
                that.goTo(1);
                that.updateData(data);
            },
            /**
            * go to specific page
            * @param pagenumber {Number} - page numbet
            * @memberof html.Pagination
            */
            goTo: function (pagenumber) {
                currentPage = pagenumber;
                _showresult();
            },
            /**
            * set currentPage on specific page number but not go on it
            * @param pagenumber {Number} - page number
            * @memberof html.Pagination
            */
            setPage: function (pagenumber) {
                currentPage = pagenumber;
            },
            /**
            * return current active page
            * @memberof html.Pagination
            * @return {Number}
            */
            getCurrentPage: function () {
                return currentPage;
            },
            /**
            * set elements number on one page and reload display
            * @param perpage {Number} - elements number
            * @memberof html.Pagination
            */
            setPerpage: function (perpage) {
                settings.perpage = perpage;
                currentPage = 1;
                that.refresh();
            },
            getPerpage: function () {
                return settings.perpage;
            },
            /**
            * Search in pagination
            * @param val {String} - searching value
            * @param paramArr {Array} - array with parameters name to search witch (default: options.searchparameters)
            * @param match {Boolean} - set as <b>true</b> to get exactly searching val other case contains
            * @memberof html.Pagination
            */
            searchfilter: function (val, paramArr, match) {

                if (timeoutId) { clearTimeout(timeoutId) }

                timeoutId = setTimeout(() => {
                    const parameters = (!paramArr) ? settings.searchparameters : paramArr;

                    if (!_.isEmpty(val)) {
                        ee.indiOn();
                        return _searchDataObj(val.toLowerCase(), parameters)
                        .then((elements) => {
                            ee.indiOff();
                            currentData = elements;
                            sourceList = elements;
                            currentPage = 1;
                            that.refresh(true);
                        })
                    }

                    currentData = sourceData
                    currentPage = 1;
                    that.refresh();
                    timeoutId = null
                }, 1000)
            },
            /**
            * get elements count from begining to curent page
            * @memberof html.Pagination
            * @return {Number}
            */
            getOffset: function () {
                return currentPage * settings.perpage;
            },
            /**
            * set event on before change page
            * @param event {function} - function to invoke before change page
            * @memberof html.Pagination
            * @return {this}
            */
            eventBeforeChangePage: function (event) {
                $resultcontainer.off("beforechangepage").on("beforechangepage", event);
                return that;
            },
            /**
            * set event on after change page
            * @param event {function} - function to invoke after change page
            * @memberof html.Pagination
            * @return {this}
            */
            eventChangePage: function (event) {
                $resultcontainer.off("changepage").on("changepage", event);
                return that;
            },
            /**
            * get pagination data
            * @return {Array}
            * @memberof html.Pagination
            */
            getCurrentData: function () {
                return currentData;
            }
        }
        // Initialization
        currentData = sourceData;
        _showresult();
        _initFooterEvents();
        return that;
    }
}());
