(function (namespace) {
    "use strict";

    var SurveysList = function (dataArr) {
        if (dataModel.isModel("SurveysList", ee.data.surveys)) { return ee.data.surveys; }
        Collection.Data.call(this, dataArr);
        this.isLoaded = false;
    },
        options = {
            collection: true,
            storage: {
                name: "surveys",
                load: EE_API.Survey.List
            }
        },
        proto = {
            update: function (data) {
                var surveyModel = dataModel.create('Survey');
                Collection.Data.prototype.update.call(this, data).each(surveyModel.Format);
                return this;
            },
            get: function (neddle, key) {
                if (!key) key = 'publicsurveyid';
                var index = this.getIndex(key, neddle), item;
                if (index === -1) return false;
                item = this.data[index];
                if (dataModel.isModel("Survey", item) === false) {
                    item = this.data[index] = dataModel.create('Survey', item);
                }
                return item;
            },
        }

    dataModel.inherit(SurveysList, proto, options).register("SurveysList", SurveysList);

}(window.dataModel));
