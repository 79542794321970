(function (namespace) {

    //Format raw data from backend
    function _format() {
        this.datecreatedFRMTD = moment(this.datecreated).format('L');
        this.lastactivityFRMTD = (!this.lastactivity) ? 'Never' : moment(this.lastactivity).format('L LT');
        if ($.isArray(this.useraccess) === false && typeof this.useraccess === "string") this.useraccess = this.useraccess.split(',');
        this.accesstype = html.widget.AccessManager.getAccessType(this.useraccess);
    };

    var UsersList = function Users() {
        Collection.Data.call(this, []);
    };

    var options = {
        collection: true
    };

    var proto = {
        load: function () {
            var self = this;
            return EE_API.User.List()
                .then(function (data) {
                    self.update(data);
                });
        },
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        },
        invite: function (query) {

            var self = this;

            return EE_API.User.Invite(query)
                .then(function () {
                    return self.load();
                })

        },
        removeUser: function (email) {
            var self = this;

            return EE_API.User.Delete({ username: email })
                .then(function () {
                    self.remove('username', email);
                });
        },
        editUser: function (query, users) {
            var self = users;

            return EE_API.User.Edit(query)
            .then(function () {
                var obj = self.get('username', query.username);
                obj.firstname = query.firstname;
                obj.lastname = query.lastname;
                obj.useraccess = query.useraccess;
                _format.call(obj);
            });
        }
    };

    dataModel.inherit(UsersList, proto, options).register("UsersList", UsersList);

}(window.dataModel));
