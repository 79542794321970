(function (namespace) {

    var Log = function (opt) {
        var self = this,
            data, callback;

        if (!opt) opt = {};

        data = opt.data;
        callback = opt.callback;

        self.data = data;
        //auto add attachments to obj when is not sms
        if (callback) {
            if (!self.data.issms) {
                self.loadAttachmentList(function (data) { callback(self, data) })
                return;
            } else { callback(self, undefined) }
        }
        return self;
    },
    proto = {

        getProperty: function (key) {
            return this.data[key];
        },
        setProperty: function (key, value) {
            this.data[key] = value;
            return this;
        },
        isSMS: function () {
            return this.getProperty('issms');
        },
        loadAttachmentList: function (callback) {
            var self = this;

            ee.api.request("/attachment/list", { msgid: self.getProperty('msgid') }, function (data) {
                ee.indiOff();
                if (!data.success) return html.modal.error(data);
                self.setProperty("attachments", data.data);
                if (callback) callback(data)
            });
        },
        hasAttachments: function () {
            var attachments = this.getProperty("attachments");
            return !!(attachments && attachments.length > 0);
        },
        loadHeaders: function (callback) {
            var self = this;

            ee.api.request('/email/loadheaders', { msgid: self.getProperty("msgid") }, function (data) {
                ee.indiOff();
                if (!data.success) return html.modal.error(data);
                self.setProperty("headers", data.data.replace(/\</g, '&lt;'));
                if (callback) callback(self.getProperty("headers"));
            });
        }
    };

    dataModel.inherit(Log, proto).register("Log", Log);
}(window.dataModel));
