(function LanguagesData(namespace) {
    //Private constructors
    var LanguagesData = function () {
        if (!(this instanceof LanguagesData)) {
            throw new Error('LanguagesData is a constructor and it`s need "new" operator');
            return;
        };
        Collection.Data.call(this, []);
    },
        _listedlanguages_ = false,
        _setlocale = function (locale, callback) {
            var language = ee.languages.get('locale', locale);
            ee.t = language.data;
            ee.t._locale = locale;
            ee.session.set('locale', locale);
            if (callback) callback();
        },
        proto = {
            listeditable: function (callback) {
                var self = this;
                ee.api.request("/language/listeditable", { inheritedAdmin: true }, function (response) {
                    ee.indiOff();
                    if (!response.success) return html.modal.error(response);
                    self.update(response.data);
                    if (callback) callback(self, response);
                });
            },
            loadTexts: function (language, typeText, callback) {
                if (!language.languagetexts) { language.languagetexts = {}; }
                if (!language.languagetexts[typeText]) {
                    language.languagetexts[typeText] = new dataModel.LanguagesTextData(typeText);
                }
                language.languagetexts[typeText]['loadtexts' + typeText](language.locale, callback);
            },
            list: function (callback, forcereload) {
                var self = this;
                if (_listedlanguages_ === false || forcereload === true) {
                    ee.api.request("/language/list", {}, function (response) {
                        ee.indiOff();
                        if (!response.success) return html.modal.error(response);
                        var languages = response.data;
                        for (var i = 0, n = languages.length; i < n; i++) {
                            var language = languages[i];
                            if (!self.get('locale', language.locale)) {
                                self.add({ locale: language.locale, name: language.name, data: false, version: language.version })
                            }
                        }
                        if (callback) callback();
                    });
                    _listedlanguages_ = true;
                    return;
                } else {
                    if (callback) callback();
                }
            },
            load: function (locale, callback) {
                if (!locale) { locale = 'en'; }
                var language = ee.languages.get('locale', locale);
                //-- Language is loade then set it
                if (language && language.data !== false) {
                    _setlocale(locale, callback);
                    return;
                }
                var scriptElement = $("<script>").prop({ src: ee.api.path + "/v2/language/load?locale=" + locale, async: true });
                scriptElement.one('load', function () {
                    try {
                        _setlocale(locale, callback);
                    } catch (e) {
                        scriptElement.trigger('error');
                    }
                }).one('error', function () {
                    locale = ee.parameters.locale || 'en';
                    ee.parameters.locale = false;
                    ee.languages.load(locale, callback);
                });
                document.head.appendChild(scriptElement[0]);
            },
            assign: function (locale, name, data, version) {
                //Setup default
                if (!version) { version = ''; }
                if (!data) { data = {}; }
                if (locale === 'en') {
                    this.add({ locale: 'en', name: name, data: data, version: version });
                    return;
                }
                var language = this.get('locale', locale);
                data = $.extend({}, this.get('locale', 'en').data, data)
                if (language) {
                    language.locale = locale;
                    language.name = name;
                    language.data = data;
                    language.version = version;
                } else {
                    this.add({ locale: locale, name: name, data: data, version: version });
                }
            },
            isListedLanguages: function () {
                return _listedlanguages_;
            }
        };
    LanguagesData.prototype = Object.create(Collection.Data.prototype);
    $.extend(LanguagesData.prototype, proto);
    namespace["LanguagesData"] = LanguagesData;
}(window.dataModel));
