(function (root) {

    root.Payment = {
        updateautocredit: function (query) { return root.request('/payment/updateautocredit', query); },
        disableautocredit: function (query) { return root.request('/payment/disableautocredit', query); },
        makeAutoPaypalPurchase: function (query) { return root.request('/payment/makeautopaypalpurchase', query); },
        makeAutoPurchase: function (query) { return root.request('/payment/makeautopurchase', query); },
        registerPayPal: function (query) { return root.request('/payment/registerpaypal', query); },
        makePayPalPurchase: function (query) { return root.request('/payment/makepaypalpurchase', query); },
        registerCard: function (query) { return root.request('/payment/registercard', query); },
        makePurchase: function (query) { return root.request('/payment/makepurchase', query); },
        Register: function (query) { return root.request('/payment/register', query); },
        Checkout: function (query) { return root.request('/payment/checkout', query); },
        Charge: function (query) { return root.request('/payment/charge', query); },
        loadRegisteredPaymentProvider: function (query) {return root.request('/payment/loadRegisteredPaymentProvider', query)},
        setPlan: function (query) { return root.request('/payment/changeTrialBillingPlan', query); },
        enableSuspendedPayment: function (query) { return root.request('/payment/enableautocredit', query); },
        paymentProviderStatus: function (query) {return root.request('/payment/providerstatus', query); },
        preparePayment: (query) => { return root.request('/payment/init', query); },
        removePaymentMethod: (query) => { return root.request('/payment/remove', query); },
        getBillingPlans: (query) => { return root.request('/payment/getbillingplans', query);},
        upgradeLegacyBillingPlan: (query) => { return root.request('/payment/upgradelegacybillingplan')}
    };


}(EE_API));
