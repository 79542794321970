(function(root) {

    root.Journeys = {
        GetAutomationUsage: function (name, query) { return root.request('/campaigns/automation/usage', name, query, 'GET') },
        GetJourneysList: function (query) { return root.request('/campaigns/automation/summary', query, 'GET') },
        GetJourney: function (name, query) { return root.request('/campaigns/automation', name, query, 'GET') },
        GetJourneySummary: function (name) { return root.request('/campaigns/automation/' + name + '/summary') },
        GetJourneyJson: function (query) { return root.request('/campaigns/automation', query, 'GET') },
        GetTemplate: function (name) { return root.request('/templates/' + name) },
        CreateNewJourney: function (query) { return root.request('/campaigns/automation', query, 'POST') },
        UpdateJourney: function (query) { return root.request('/campaigns/automation', query, 'PUT') },
        QuickUpdateJourney: function (name, query) { return root.request('/campaigns/automation', name + '/status', query, 'PATCH') },
        UpdateJourneyJson: function (query) { return root.request('/campaigns/automation', query, 'PUT') },
        DeleteJourney: function (name, query) { return root.request('/campaigns/automation', name, query, 'DELETE') },
        ExportJourney: (name, query) => {return root.request('/campaigns/automation', name + '/tasks/export', query)}
    };

}(API_V3));
