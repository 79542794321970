; (function (global) {
    /**
    * Namespace for Collection constructor
    * @namespace Collection
    */
    var Collection = window.Collection = {};

    /**
     * Create Collection Object for managing array of objects
     * @class
     * @memberof Collection
     * @param data {Array} - Array with objects REQ
     */
    Collection.Data = function (data, model) {

        //data = data || [];

        //if (!Array.isArray(data)) throw new Error("Collection data is not an Array!");

        this.data = data;
        if (model) {
            if (typeof model === "string") model = window.dataModel[model];
            if (typeof model === "function") {
                $.extend(this, model())
            } else { $.extend(this, model) };
        }
        return this;
    };

    Collection.Data.prototype = {
        /**
         * Returns this after added data
         * @memberof Collection.Data
         * @param unshift {String} - type "unshift" as a first parameter to add objs | arr on begining OPT
         * @param toAdd {ObjectsList | Array} - list of objects or array of objects to add REQ
         * @return {this}
         */
        add: function () {
            var action, arglist;

            if (typeof arguments[0] === 'string') {
                action = arguments[0];
                arglist = Array.prototype.slice.call(arguments, 1);
            } else {
                arglist = Array.prototype.slice.call(arguments);
            }
            if (arglist.length === 1 && Array.isArray(arglist[0])) arglist = arglist[0];
            if (action === 'unshift') {
                this.data = arglist.concat(this.data);
            } else {
                this.data = this.data.concat(arglist);
            }
            return this;
        },
        /**
         * Returns first finded Object or null
         * @memberof Collection.Data
         * @param key {String} - object property to search by REQ
         * @param val {KeyType} - find object property by with this value REQ
         * @return {Object | null}
         */
        get: function (key, val) {
            return _.find(this.data, [key, val]);
        },
        /**
        * Return new array with all value from selected key
        * @param key {String} - The name of the column to which you want to select all values
        * @return {Array}
        * @memberof Collection.Data
        */
        map: function (key) {
            var data = this.data,
                datalength = data.length,
                hasOwn = Object.hasOwnProperty,
                valArr = [];
            for (var i = 0; i < datalength; i++) {
                if (hasOwn.call(data[i], key)) valArr.push(data[i][key]);
            }
            return valArr;
        },
        /**
        * Accumulated result of running each element in collection
        * @param patternObject {Object} - Object that willby pattern forn reduce method, {newObjetProperty: "dataProperty"} ex: {ages: "age"} will itarete by all object will accoumulate age property of all and return new object with ages property.
        * @return {Object}
        * @memberof Collection.Data
        */
        reduce: function (reducePatternObj) {
            var obj = {};
            this.each(function () {
                for (var item in reducePatternObj) {
                    if (!reducePatternObj.hasOwnProperty(item)) continue;
                    var value = this[reducePatternObj[item]];
                    value = (Number.isInteger(value)) ? value : 0;
                    (obj[item] === undefined) ? obj[item] = value : obj[item] += value;
                }
            })
            return obj;
        },
        /**
         * Return new array with all Objects
         * @memberof Collection.Data
         * @return {Array}
         */
        getAll: function () {
            return [].concat(this.data);
        },
        /**
         * Gets the first element of data.
         * @memberof Collection.Data
         * @return {Object | undefined}
         */
        head: function () {
            return this.data[0];
        },
        /**
        * Gets all but the first element of array.
        * @memberof Collection.Data
        * @return {Array}
        */
        tail: function () {
            return this.data.slice(1);
        },
        /**
         * Return this if success or null when object was not founded
         * @memberof Collection.Data
         * @param key {String} - object property to search by REQ
         * @param val {KeyType} - find object to delete by property with this value REQ
         * @return {this | null}
         */
        remove: function (key, val) {
            return _.remove(this.data, [key, val]);

        },
        /**
         * Remove firts Object from collection returns shifted data array
         * @memberof Collection.Data
         * @return {Array}
         */
        removeFirst: function () {
            return this.data.shift();
        },
        /**
         * Remove last Object from collection returns poped data array
         * @memberof Collection.Data
         * @return {Array}
         */
        removeLast: function () {
            return this.data.pop();
        },
        /**
         * Change all data in collecion object by new data
         * @param data {Array} - Array of Objects REQ
         * @memberof Collection.Data
         * @return {this}
         */
        update: function (data) {
            this.data = data;
            return this;
        },
        /**
         * Replace object in collection with new object
         * @param key {String} - property name REQ
         * @param val {KeyVal} - value of property REQ
         * @param obj {Object} - object to replace finded obj REQ
         * @memberof Collection.Data
         * @return {this}
         */
        replace: function (key, val, obj) {
            var hasOwn = Object.prototype.hasOwnProperty,
                valName = val.toString();
            var targetIndex = this.getIndex(key, valName);
            if (targetIndex === -1) return null;    //TODO: fix this
            if (this.data[targetIndex] === obj) return this;
            this.data[targetIndex] = obj;
            return this;
        },
        /**
         * Returns index of finded object
         * @param key {String} - property name REQ
         * @param val {KeyVal} - value of property REQ
         * @memberof Collection.Data
         * @return {Number | null}
         */
        getIndex: function (key, val) {
            return _.findIndex(this.data, [key, val]);

        },
        /**
         * Returns length of data
         * @memberof Collection.Data
         * @return {Number}
         */
        getLength: function () {
            return this.data.length;
        },
        /**
         * Return new Collection.Data with filtered object
         * @memberof Collection.Data
         * @param propertys {String |Array} - string or array with strings, propertys names to search REQ
         * @param searchval {String | Array} - string or array with strings, values to search REQ
         * @param match {Boolean} - set as true to find exactly in other case it will contains OPT
         * @param reverse {Boolean} - set as true to find objects that not match OPT
         * @param matchToAll {Boolean} - set as true to find objects that match to all given properties OPT
         * @return {Collection.Data}
         */
        filter: function (propertys, searchval, match, reverse, matchToAll) {
            var patterns = [], propetrysLength = 0, patternsLength = 0, i = 0, j = 0, arr = [];
            //Parse input varible
            if (Array.isArray(propertys) === false) {
                propertys = [propertys];
            }
            if (Array.isArray(searchval) === false) {
                patterns.push(searchval);
            } else {
                patterns = searchval.slice(); //copy array
            }
            //Set length
            propetrysLength = propertys.length;
            patternsLength = patterns.length;
            //Create regexp
            for (i = 0; i < patternsLength; i++) {
                if (!match) {
                    patterns[i] = new RegExp(ee.tools.RegExpEscape(patterns[i]), 'i')
                } else {
                    patterns[i] = new RegExp('^' + ee.tools.RegExpEscape(patterns[i]) + '$', 'i');
                }
            }
            //filter all array elements
            arr = this.data.filter(function (elem) {
                if (reverse) {
                    if (matchToAll) {
                        return !isMatchedToAll(elem);
                    } else {
                        return !isMatched(elem);
                    }
                } else {
                    if (matchToAll) {
                        return isMatchedToAll(elem);
                    } else {
                        return isMatched(elem);
                    }
                };
            });
            //Search method
            function isMatched(objItem) {
                for (j = 0; j < propetrysLength; j++) {
                    for (i = 0; i < patternsLength; i++) {
                        //Note: we should remove "+''" ???
                        if (patterns[i].test(objItem[propertys[j]] + '') === true) return true;
                    }
                }
                return false;
            }

            function isMatchedToAll(objItem) {
                var result = (patternsLength > 0 && propetrysLength > 0);
                
                for (j = 0; j < propetrysLength; j++) {
                    for (i = 0; i < patternsLength; i++) {
                        if (!patterns[i].test(objItem[propertys[j]] + '')) return false;
                    }
                }
                return result;
            }

            return new Collection.Data(arr);
        },
        /**
         * Return new Collection.Data with filtered objects by date
         * @memberof Collection.Data
         * @param propetry {String} - name of property REQ
         * @param from {Date} - from date REQ
         * @param to {Date} - to date REQ
         * @return {Collection.Data}
         */
        filterBetweenDate: function (propetry, from, to) {
            var arr = [], rangeDate = [from, to];
            for (var i = 0, n = this.data.length; i < n; i++) {
                if (rangeDate.between(this.data[i][propetry])) {
                    arr.push(this.data[i]);
                }
            }
            return new Collection.Data(arr);
        },
        /**
         * Return summaric value of all objects property
         * @memberof Collection.Data
         * @param prop {String} - name of property REQ
         * @return {sum}
         */
        sum: function (prop) {
            var val = 0,
                datalength = this.data.length;
            for (var i = 0; i < datalength; i++) {
                val += (this.data[i][prop] * 1);
            }
            return val;
        },
        /**
         * Iterate by all objects
         * @memberof Collection.Data
         * @param cb {Collection.Data~eachFunction} - function to invoke for each object REQ
         * @return {this}
         */
        each: function (callback) {
            var data = this.data,
                length = data.length;

            for (var i = 0; i < length; i++) {
                /**
                 * It is a part of <a href="#.each">each method</a>
                 * @callback Collection.Data~eachFunction
                 * @param element {Object} - iterated data object
                 * @param index {Number} - index
                 */
                if (callback.call(data[i], i) === false) break;
            }
            return this;
        },
        /**
         * Sort objects by property name, return sorted data array
         * @memberof Collection.Data
         * @param prop {String} - property name REQ
         * @param compare_or_reverse {Boolean | function} - set as true to reverse sorting or use own compare function OPT
         * @return {Array}
         */
        sort: function (prop, compare_or_reverse) {
            var data = this.data;

            if (typeof compare_or_reverse === "function") {
                data.sort(compare_or_reverse);
                return this;
            }

            data.sort( function compare(a, b) {
                    if (a[prop] < b[prop]) {
                        return -1;
                    }
                    if (a[prop] > b[prop]) {
                        return 1;
                    }
                    return 0;
                });

            if (compare_or_reverse === true) data.reverse();

            return this;
        }
    };

    /**
     * Namespace for models constructors
     * @namespace dataModel
     */
    //global.dataModel = {};
})(this);
