(function (root, method) {

    var that = {
        AddCustomField: function (query) { return root.request('/contact/addcustomfield', query) },
        LoadCustomFields: function (query) { return root.request('/contact/loadcustomfields', query) },
        RemoveCustomField: function (query) { return root.request('/contact/removecustomfield', query) },
        LoadLinkClicks: function (query) { return root.request('/contact/loadlinkclicks', query) }
    };
    $.extend(root[method], that);

}(EE_API, "Contact"));