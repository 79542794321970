(function (namespace) {
    "use strict";
    var _format = function () {
        /* formating function shouldent use ee.data.domains. */
        var validemail = this, domain, domains = ee.data.domains,
            tmp = validemail.email.split('@');

        validemail.domain = tmp[1].toLowerCase() || '';
        validemail.username = tmp[0].toLowerCase() || '';

        if (domains) {
            //Add domain only for emails
            domain = domains.get('domain', validemail.domain);
            if (!domain) {
                domain = {
                    domain: validemail.domain,
                    defaultdomain: false,
                    onlyemails: true,
                    verify: false,
                    type: 0,
                    ownership: 0,
                    spf: false,
                    emailvalidated: false,
                    domainexcluded: validemail.domainexcluded
                };
                Collection.Data.prototype.add.call(domains, domain);
            }
            if (domain.emailvalidated === false && validemail.validated === true) {
                domain.emailvalidated = true;
            }
        }
    },
        _defaultReturnUrl = function () {
            var loc = window.location;
            return loc.protocol + '//' + loc.host + loc.pathname + "#/settings/domains/verificationsuccess/";
        },
        _sortDomain = function () {
            if (!ee.data.domains) return;
            ee.data.domains.sort('domain').sort('emailvalidated').sort('spf').sort('defaultdomain', true);
        },

        /** ValidEmails constructor
         *
         * Create ValidEmails collection model
         * @class
         * @extends Collection.Data
         * @memberof dataModel
         */
        ValidEmails = function ValidEmails() {

            if (!(this instanceof ValidEmails)) {
                throw new Error('ValidEmails is a constructor and it`s need \'new\' operator');
            }

            if (dataModel.isModel('ValidEmails', ee.data.validemails)) return ee.data.validemails;

            Collection.Data.call(this, []);
            this.domains = dataModel.create("Domains");
            this.isLoaded = false;

        },

        options = {
            collection: true,
            storage: {
                name: "validemails",
                load: EE_API.ValidEmail.List
            }
        },

        proto = {
            require: function () {
                var that = this;
                return $.when(this.domains.require()).then(function () {
                    return that._sup.require.call(that);
                }).then(function () {
                    _sortDomain();
                    return that;
                });
            },
            update: function (data) {
                Collection.Data.prototype.update.call(this, data).each(_format);
                return this;
            },
            domains: 'Domains',
            add: function (email) {
                var that = this;
                return EE_API.ValidEmail.Add({ emailaddress: email, returnurl: _defaultReturnUrl() }).then(function (data) {
                    _format.call(data);
                    Collection.Data.prototype.add.call(that, data);
                    _sortDomain();
                    return that;
                });
            },
            remove: function (validemail) {
                var that = this, domain, countDomainEmail = 0;
                return EE_API.ValidEmail.Remove({ validemailid: validemail.validemailid }).then(function (data) {
                    Collection.Data.prototype.remove.call(that, 'validemailid', validemail.validemailid);
                    //Depedency - remove domain if is onlyemails and do not have any validemails
                    countDomainEmail = that.filter(['domain'], validemail.domain).getLength();
                    if (countDomainEmail > 0) return false;
                    domain = that.domains.get('domain', validemail.domain);
                    if (!domain) return false;
                    domain.emailvalidated = false;
                    if (domain.onlyemails === true) {
                        Collection.Data.prototype.remove.call(that.domains, 'domain', validemail.domain);
                        return true;
                    }
                    return false;
                });
            },
            resendEmailVerification: function (emailaddress) {
                return EE_API.ValidEmail.ResendEmailVerification({ emailaddress: emailaddress, returnurl: _defaultReturnUrl() });
            },
            removeDomain: function (domainname) {
                var that = this,
                    deleteDomain;
                (ee.session.can.AdminModify) ? deleteDomain = that.domains.delete(domainname, ee.data.account.publicaccountid) : deleteDomain = that.domains.delete(domainname);

                return $.when(deleteDomain).then(function () {
                    that.filter(['domain'], domainname).each(_format);
                    _sortDomain();
                    return that;
                });
            },
            reload: function (skipdomains) {
                this.isLoaded = false;
                if (!skipdomains) {
                    this.domains.isLoaded = false;
                }
                return this.require();
            }
        };

    dataModel.inherit(ValidEmails, proto, options).register("ValidEmails", ValidEmails);

}(window.dataModel));
