(function () {
    /**
    * Extension of the class HTML, adds methods associated with the debug mode for admin
    * @namespace
    * @memberof html
    */
    html.Debug = function () {
        var that = {};
        var $button = false, timer;
        /*-- Private methods --*/
        $(window).on('hashchange', function () {
            if ($button) {
                $button.remove();
                $button = false;
            }
        });

        var debugGoTo = function (target, mode) {
            ee.session.set('debugmode', (mode === true ? 'true' : 'false'));
            if (!!target) {
                ee.goTo(target);
            }
        }
        /**
        * Go to testing screen
        * @param target {String} - new screen hash path
        * @memberof html.Debug
        * @return {Boolean}
        */
        var goToNewScreen = function goToNewScreen(target) {
            if (ee.session.can.AdminView === false || !target) {
                return false;
            }
            if (ee.session.get('debugmode') === 'true') {
                debugGoTo(target, true);
                return true;
            };
            $button = $('<div id="edebugDebugEnable">Goto new screen</div>');
            $button.click(function () {
                $button.remove();
                debugGoTo(target, true);
                return true;
            })
            ee.frame.find('#eheader').append($button);
            debugGoTo();
            return false;
        }
        /**
        * display information in html, with back to old screen button
        * @param backUrl {String} - old screen hash path
        * @memberof html.Debug
        */
        var displayInfo = function (backUrl) {
            ee.frame.content.find("#edebugInfoBox").remove();
            if ($button) {
                $button.remove();
                $button = false;
            }
            var debugframe = html.get('DebugInfo', true);
            ee.frame.content.append(debugframe);
            debugframe.disabledebug.click(function () {
                debugframe.remove();
               // debugframe = null;
                debugGoTo(backUrl, false);
                return;
            });
        }

        /*-- Public methods --*/
        that.goToNewScreen = goToNewScreen;
        that.displayInfo = displayInfo;
        return that;
    }()
})();
