//TODO: control the display of charts should be carried out using this module, currently placed will be here only method of initializing the asynchronous library required for charting
(function () {
    /**
    * Extension of the class HTML, adds methods associated with the display of charts
    * @namespace 
    * @memberof html
    */
    html.Chart = function () {
        var that = {};
        /*-- Private methods --*/
        /**
         * If not loaded, load charts library from cdn
         * @param cb {function} - callback after successful library load
         * @memberof html.Chart
         */
        var init = function (callback) {
            if (!window.d3 || !window.c3) {
                $.loadCssFile("https://cdnjs.cloudflare.com/ajax/libs/c3/0.4.10/c3.min.css", function () {
                    $.when( $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.16/d3.min.js"), $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/c3/0.4.10/c3.min.js") )
                    .then(function () { callback() })
                });
                return;
            } else {
                callback();
            }
         }
        /*-- Public methods --*/
        that.init = init;


        return that;
    }()
})();