(function (namespace) {
    var _format = function () {
            this.typename = _getType(this.type);
            this.fielddata = _getFieldData(this.typename);
        },
    _hasInterface = function (global) {
        if (global instanceof ContactFields) {
            return global;
        }
        return false;
    },
    _staticFields = [
            { name: "email", type: 0, label: "Email", placeholder: "Please enter your email address", length: 100, fixed: true, disabled: true, checked: true, hideRequired: true },
            { name: "firstname", type: 0, label: "First Name", placeholder: "Please enter your first name", length: 100, fixed: true },
            { name: "lastname", type: 0, label: "Last Name", placeholder: "Please enter your last name", length: 100, fixed: true },
            { name: "consenttracking", type: 4, label: "Allow tracking of my email activity such as open and clicks", consenttracking: true, fixed: true}
        ],
    _getType = function (fieldtype) {
        switch (fieldtype) {
            case 1: return 'number';
            case 2: return 'datetime';
            case 3: return 'decimal';
            case 4: return 'boolean';
            case 5: return 'bignumber';
            default:
                return 'text';
        }
    },
    _getFieldData = function (fieldtype, value) {
        switch (fieldtype) {
            case "number": return { typeinput: 'number', range: "–2147483648 to 2147483648", min: "–2147483648", max: "2147483647", type: 'number', placeholder: ee.t.placeholderinteger };
            case "datetime": return { typeinput: 'datetime', range: `${ee.tools.localDateFormatDatepicker()} HH:mm`, placeholder: ee.t.placeholderdatetime, css: "datepicker" };
            case "decimal": return { typeinput: 'number', range: "–79228162514264337593543950335 to 79228162514264337593543950335", min: "–79228162514264337593543950335", max: "79228162514264337593543950335", placeholder: ee.t.placeholderdecimal };
            case "boolean": return { typeinput: 'boolean', range: "true/false", placeholder: ee.t.placeholderboolean, isboolean: true, istrue: (value && value.toString().toLocaleLowerCase() == 'true') ? true : false };
            case "bignumber": return { typeinput: 'number', range: "–9223372036854775808 to 9223372036854775808", min: "–9223372036854775808", max: "9223372036854775807", placeholder: ee.t.placeholderdecimal };
            default:
                return { typeinput: "text", placeholder: ee.t.placeholdertext };
        }
    },

    ContactFields = function ContactFields(dataArr) {

        if (dataModel.isModel("ContactFields", ee.data.availablefields)) return ee.data.availablefields;

        Collection.Data.call(this, dataArr);
        this.isLoaded = false;
    };



    var options = {
        collection: true,
        storage: {
            name: 'availablefields',
            load: EE_API.Contact.LoadCustomFields
        }
    };

    $(document).on('change', '#econtactfield', function() {
        var value = $(this).val();
        var $fieldType = $('#efieldtype');
        var $fieldName = $('#econtactfieldname');
        var $fieldLength = $('#textfieldlength');
        const fieldStringName = $(`#econtactfield option[value=${value}]`).text();

        switch(value) {
            case '0':
                $fieldType.val('2').attr('disabled', true);
                $fieldName.removeAttr('maxlength');
                $fieldLength.val('0');
                $fieldName.val(fieldStringName);
                break;
            case '1':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '2':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '3':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '4':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '5':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '6':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '7':
                $fieldType.val('0').attr('disabled', true);
                $fieldName.attr('maxlength', '100');
                $fieldLength.val('100');
                $fieldName.val(fieldStringName);
                break;
            case '8':
                $fieldType.val('4').attr('disabled', true);
                $fieldName.removeAttr('maxlength');
                $fieldLength.val('0');
                $fieldName.val(fieldStringName);
                break;
            default:
                $fieldType.attr('disabled', false);
                $fieldName.removeAttr('maxlength');
                $fieldLength.val('0');
                $fieldName.val("");
                break;
        }
    })

    var proto = {
        update: function (data) {
            Collection.Data.prototype.update.call(this, data);
            this.add("unshift", _staticFields);
            this.each(_format)
            ee.views.querytool.resetTableDef();
            ee.views.querytool.setupTableDef(this);
            return this;
        },
        reload: function () {
            this.data = null;
            this.isLoaded = false;
            return this.require();
        },
        AddField: function (newField) {
            var that = this;

            return EE_API.Contact.AddCustomField({
                name: newField.name,
                type: newField.type,
                length: newField.length
            })
                .then(function () {
                    that.add(newField);
                    ee.views.querytool.resetTableDef();
                    ee.views.querytool.setupTableDef(that);
                });
        },
        RemoveField: function (fieldname) {

            var that = this;

            return EE_API.Contact.RemoveCustomField({ name: fieldname })
                .then(function () {
                    that.remove('name', fieldname);
                    ee.views.querytool.resetTableDef();
                    ee.views.querytool.setupTableDef(that);
                });
        },
        getFieldData: _getFieldData,
        getType: _getType
    };

    dataModel.inherit(ContactFields, proto, options).register("ContactFields", ContactFields);

}(window.dataModel));
