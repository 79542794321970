(function (namespace) {
    "use strict";
    var _regexpDefaultSender = /[\(\)]/g,
        _format = function () {
            var parts = this.domain.split(' ');
            this.domain = (parts[0] || '').trim();
            this.defaultsender = (parts[1] || '').replace(_regexpDefaultSender, '').trim();
            this.https = this.type > 0;
            this.httpsvalid = this.certificatestatus === 1;
            this.everythingverified = this.spf && this.dkim && this.isrewritedomainvalid && this.mx && this.dmarc;
            this.onlyemails = false;
            this.emailvalidated = false;
            this.domainexcluded = false;
        },
        /* Domains constructor */
        /**
         * Create Domains collection model
         * @class
         * @extends Collection.Data
         * @memberof dataModel
         */
        Domains = function Domains() {
            if (!(this instanceof Domains)) {
                throw new Error('Domains is a constructor and it`s need \'new\' operator');
            }

            if (dataModel.isModel('Domains', ee.data.domains)) return ee.data.domains;

            Collection.Data.call(this, null);
            this.isLoaded = false;
        },

        options = {
            collection: true,
            storage: {
                name: "domains",
                load: EE_API.Domain.List
            }
        },

        proto = {
            update: function (data) {
                Collection.Data.prototype.update.call(this, data).each(_format);
                return this;
            },
            add: function (domain, trackingtype) {
                //TODO: Add should always return added object
                return EE_API.Domain.Add({ domain: domain, trackingtype: trackingtype });
            },
            delete: function (domain) {
                var that = this;
                return EE_API.Domain.Delete({ domain: domain }).then(function (data) {
                    Collection.Data.prototype.remove.call(that, 'domain', domain);
                    return that;
                });
            },
            reload: function () {
                this.data = null;
                this.isLoaded = false;
                return this.require();
            },
            setDefault: function (sender) {
                var that = this;
                return EE_API.Domain.SetDefault({ domain: sender }).then(function (data) {
                    var search = sender.split('@')[1] || '', domain = that.get('domain', search.trim());
                    domain.defaultsender = sender;
                    domain.defaultdomain = true;
                    return that;
                });
            },
            verifySPF: function (domain) {
                var that = this;
                const publicaccountid = store.session.get('subaccountemailtouse');
                const params = { domain: domain.domain }
                
                if (!_.isEmpty(publicaccountid)) {
                    params.subaccountemailtouse = publicaccountid;
                }

                return $.when(EE_API.Domain.VerifySpf(params)).then(function (data) {
                    return domain.spf = data.isvalid;
                });
            },
            verifyDKIM: function (domain) {
                var that = this;
                
                const publicaccountid = store.session.get('subaccountemailtouse');
                const params = { domain: domain.domain }

                if (!_.isEmpty(publicaccountid)) {
                    params.subaccountemailtouse = publicaccountid;
                }

                return $.when(EE_API.Domain.VerifyDkim(params)).then(function (data) {
                    return domain.dkim = data === "OK";
                });
            },
            verifyTracking: function (domain) {
                var that = this;
                
                const publicaccountid = store.session.get('subaccountemailtouse');
                const params = { domain: domain.domain, trackingType: domain.type }

                if (!_.isEmpty(publicaccountid)) {
                    params.subaccountemailtouse = publicaccountid;
                }

                return $.when(EE_API.Domain.VerifyTracking(params)).then(function (data) {
                    return domain.isrewritedomainvalid = data === "OK";

                });
            },
            verifyMX: function (domain) {
                var that = this;
                
                const publicaccountid = store.session.get('subaccountemailtouse');
                const params = { domain: domain.domain }

                if (!_.isEmpty(publicaccountid)) {
                    params.subaccountemailtouse = publicaccountid;
                }

                return $.when(EE_API.Domain.VerifyMX(params)).then(function (data) {
                    return domain.mx = data === "OK";

                });
            },
            verifyDMARC: function (domain) {
                var that = this;
                return $.when(EE_API.Domain.VerifyDMARC({ domain: domain.domain })).then(function (data) {
                    return domain.dmarc = data === "OK";
                });
            }
        };

    dataModel.inherit(Domains, proto, options).register("Domains", Domains);

}(window.dataModel));
