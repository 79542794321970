﻿(function (root) {

    root.Account = {
        DeleteAccount: function (query) { return root.request('/account/deleteaccount', query) },
        LoadReferralLandingPage: function (query) { return root.request('/account/LoadReferralLandingPage', query) },
        CustomizeReferralLandingPage: function (query) { return root.request('/account/CustomizeReferralLandingPage', query) },
        LoadHelpPage: function (query) { return root.request('/v2/account/LoadHelpPage', query) },

        ActivateContactFeature: function (query) { return root.request('/account/activatecontactfeature', query) },
        DisableContactFeature: function (query) { return root.request('/account/disablecontactfeature', query) },
        Login: function (query) { return root.request('/account/login', query, { manualerrorhandle: true }) },
        Logout: (query) => {
            ee.tools.clearAllTimeouts();

            return root.request('/account/logout', query)
        },
        Forgot: function (query) { return root.request('/account/forgot', query, { manualerrorhandle: true }) },
        RestartPassword: function (query) { return root.request('/account/resetpassword', query, { manualerrorhandle: true }) },
        ChangePassword: function (query) { return root.request('/account/changepassword', query, { manualerrorhandle: true }) },
        ChangeEmail: function (query) { return root.request('/account/changeemail', query) },
        Create: function (query) { return root.request('/account/createfromdashboard', query, { manualerrorhandle: true }) },
        ResendActivation: function (query) { return root.request('/account/resendactivation', query, { manualerrorhandle: true }) },

        LoadReputationImpact: function (query) { return root.request('/account/loadreputationimpact', query) },
        LoadSpamCheck: function (query) { return root.request('/account/loadspamcheck', query) },
        LoadReputationHistory: function (query) { return root.request('/account/loadreputationhistory', query) },
        LoadOverview: function (query) { return root.request('/account/overview', query) },
        LoadInboundOptions: function (query) { return root.request('/account/loadinboundoptions', query) },
        LoadWebNotificationOptions: function (query) { return root.request('/account/loadwebnotificationoptions', query) },
        AddWebhook: function (query) { return root.request('/v2/account/addwebhook', query, { manualerrorhandle: true }) },
        UpdateWebhook: function (query) {return root.request('/v2/account/updatewebhook', query, { manualerrorhandle: true })},
        LoadWebhook: function () {return root.request('/v2/account/loadwebhook')},
        DeleteWebhook: function (query) {return root.request('/v2/account/deletewebhook', query)},

        MarkGDPRInfoAsSeen: function (query) { return root.request('/account/confirmgdprinfoseen', query) },
        LoadOverview: function (query) { return root.request('/account/overview', query) },
        SetDisableGlobalContacts: function (query) { return root.request('/account/SetDisableGlobalContacts', query) },
        LoadDPA: function (query) { return root.request('/account/generatedpa', query); },
        LoadSubscriptionUsage: function (query) { return root.request('/account/loadsubscriptionusage', query) },
        LoadUsage: function (query) { return root.request('/account/LoadUsage', query)},
        GetInvoice: function (query) { return root.request('/account/getinvoice', query) },
        GetReceipt: function (query) { return root.request('/account/getreceipt', query) },
        GetBrowserTokens: function (query) { return root.request('/browsertoken/getbrowsertokens', query) },
        SetTrusted: function (query) { return root.request('/browsertoken/settrusted', query) },
        UpdatedDeviceAlert: function (query) { return root.request('/account/updateuntrusteddevicealertsend', query) },

        ResetEmail: function (query) { return root.request('/account/resetemail', query) },
        setTwoFactorAuthEmail: function (query) { return root.request('/account/setTwoFactorAuthEmail', query); },
        setTwoFactorAuthSms: function (query) { return root.request('/v2/account/setTwoFactorAuthSms', query); },
        ResendTwoFactorAuthVerificationEmail: function () { return root.request('/account/ResendTwoFactorAuthVerificationEmail', {}); },
        ResendTwoFactorAuthVerificationSms: function () { return root.request('/account/ResendTwoFactorAuthVerificationSms', {}); },
        UpdateInboundNotifications: function (query) { return root.request('/account/UpdateInboundNotifications', query) },
        UpdateWebNotifications: function (query) { return root.request('/account/UpdateWebNotifications', query) },
        VerifyTwoFactorAuthEmail: function (query, options) { return root.request('/account/VerifyTwoFactorAuthEmail', query, options); },
        VerifyTwoFactorAuthSms: function (query, options) { return root.request('/account/VerifyTwoFactorAuthSms', query, options); },
        GenerateTwoFactorAuthDisableCode: function () { return root.request('/account/GenerateTwoFactorAuthDisableCode', {}); },
        DisableTwoFactorAuth: (query) => { return root.request('/account/disabletwofactorauth', query); },
        ResendTwoFactorAuthCode: function (query) { return root.request('/account/ResendTwoFactorAuthCode', query) },
        UpdateProfile: function (query) { return root.request('/account/updateprofile', query) },
        UpdateAdvancedOptions: function (query) { return root.request('/account/UpdateAdvancedOptions', query) },

        Load: function (query) { return root.request('/account/load', query); },
        UpdateAutoIncrementMaxContacts: function (query) { return root.request('/account/updateautoincreasecontactlimit', query); },
        SetAPIConsent: function (query) { return root.request('/account/apiconsent', query); },

        //subaccounts
        GetSubAccountList: function (query) { return root.request('/account/getsubaccountlist', query) },
        AddSubAccount: function (query, options) { return root.request('/account/addsubaccountfromdashboard', query, options); },
        UpdateSubAccount: function (query, options) { return root.request('/account/updatesubaccountsettings', query, options); },
        DeleteSubAccount: function (query, options) { return root.request('/account/deletesubaccount', query, options); },
        AddSubAccountCredits: function (query, options) { return root.request('/account/addsubaccountcredits', query, options); },
        RemoveSubAccountCredits: function (query, options) { return root.request('/account/removesubaccountcredits', query, options); },
        LoadSubAccountsEmailCreditsHistory: function (query, options) { return root.request('/account/loadsubaccountsemailcreditshistory', query, options); },

        UpdateMarketingOptions: function (query, options) { return root.request('/account/updatemarketingoptions', query, options); },
    };
}(EE_API));
