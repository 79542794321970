(function (namespace) {
    var _format = function () {
        var list = this;
        list.dateadded = ee.api.formatDate(list.dateadded);
        list.countformatted = list.count.numberFormat();
        list.rule = "List = " + list.listid;
        list.name = list.listname;
        list.rulelocal = "listname = '" + list.listname + "'";

        list.islist = true;

    },
    /* Lists constructor */
    /**
     * Create Lists collection model
     * @class
     * @extends Collection.Data
     * @memberof dataModel
     */
    Lists = function Lists(dataArr) {

        if (dataModel.isModel("Lists", ee.data.lists)) return ee.data.lists;

        Collection.Data.call(this, dataArr);

        this.isLoaded = false;

        };

    var options = {
        collection: true,
        storage: {
            name: "lists",
            load: EE_API.List.List
        }
    };

    var proto = {
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        },
        add: function (query) {
            var that = this;
            return EE_API.List.Add(query).then(function () {
                return that.reload()
            });
        },
        reload: function () {
            this.data = null;
            this.isLoaded = false;
            return this.require();
        },
        updateList: function (obj) {
            if (!obj.listid) return new Error('List ID required');
            var list = this.get('listid', obj.listid)

            $.extend(list, obj);
        }
    };

    dataModel.inherit(Lists, proto, options).register("Lists", Lists);

}(window.dataModel));
