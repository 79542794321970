//ExportList don't use a storage data
(function(namespace) {
    "use strict";

    var ExportList = function ExportList(dataArr) {
            Collection.Data.call(this, dataArr);
        },
        _format = (object) => {

            if (object.type == "Log") {
                object.log = true;
            } else if (object.type == "Contact") {
                object.contact = true;
            } else if (object.type == "Campaign") {
                object.campaign = true;
            }
             else if (object.type == "LinkTracking") {
                object.linktracking = true;
            } else if (object.type == "Survey") {
                object.survey = true;
            } else if (object.type == "LinkContacts") {
                object.type = "LinkTracking";
                object.linktracking = true;
            } else if (object.type == "UnsubscribeReason") {
                object.type = "Contact";
                object.contact = true;
            } else if (object.type == "Channel"){
                object.channel = true;
            }
            if (object.status == "Loading") {
                object.loading = true;
            } else if (object.status == "Ready") {
                object.ready = true;
            } else if (object.status == "Expired") {
                object.expired = true;
            } else if (object.status == "Error") {
                object.error = true;
            }

            if (ee.session.can.AdminView)
                object.admin = true;

            object.dateadded = ee.tools.time(object.dateadded).fromUTC("L LTS");

            if (object.logfrom && object.logto) {

                object.logfrom = ee.tools.time(object.logfrom).fromUTC("L LTS");
                object.logto = ee.tools.time(object.logto).fromUTC("L LTS");
                object.info = object.logfrom + ' - ' + object.logto;
            }

            if (object.info.length > 20) {
                object.infoShort = object.info.slice(0, 17) + '...';
            } else {
                object.infoShort = object.info;
            }
        };

    var options = {
        collection: true
    };

    var proto = {
        update: function(data) {
            this.data = data;
            _.forEach(this.data, obj => _format(obj));
            return this;
        },
        load: function() {
            var that = this;
            return EE_API.Export.List().then(function(data) {
                return that.update(data);
            });
        },
        delete: function(publicexportid) {
            var that = this;
            return EE_API.Export.Delete({
                publicexportid: publicexportid
            }).then(function() {
                that.remove("publicexportid", publicexportid);
            });
        }

    };

    dataModel.inherit(ExportList, proto, options).register("ExportList", ExportList);

}(window.dataModel));
