(function (namespace) {

    var _format = function () {
        var contact = this;

        contact.sourcename = ee.config("contactsource")[contact.source];
        if (contact.birthdate && contact.birthdate.trim() !== '') {
            contact.birthdate = ee.api.formatDate(contact.birthdate, 'yy-mm-dd', false, true);
        }
        contact.statusname = ee.config("contactstatus")[(contact.status) + 2];
        //Remove dateupdate
        if (contact.dateupdated) contact.dateupdated = ee.tools.time(contact.dateupdated).fromUTC("L LT")//ee.api.formatDate(contact.dateupdated); //Out of date
        if (contact.statuschangedate) contact.statuschangedate = contact.statuschangedate ? ee.tools.time(contact.statuschangedate).toDate("L") : ee.t.never;//ee.api.formatDate(contact.statuschangedate);
        contact.lastopened = contact.lastopened ? ee.tools.time(contact.lastopened).fromUTC("L") : ee.t.never;//ee.api.formatDate(contact.lastopened);
        contact.lastclicked = contact.lastclicked ? ee.tools.time(contact.lastclicked).fromUTC("L") : ee.t.never;//ee.api.formatDate(contact.lastclicked);
		contact.dateadded = contact.dateadded ? ee.tools.time(contact.dateadded).fromUTC("L") : ee.t.never;//ee.api.formatDate(contact.dateadded);
		contact.consentdate = contact.consentdate ? ee.tools.time(contact.consentdate).fromUTC("L") : ee.t.never;
        contact.inactive = (contact.status === 4);
        contact.gravatarhash = html.Gravatar(contact.email);
        _setPercent(contact);
        _unsubscribeReason(contact);

        contact.customfieldsObj = contact.customfields;
        contact.customfields = [];

        switch (contact.consenttracking) {
            case 0:
                contact.consenttrackingformated = "Unknown";
                break;
            case 1:
                contact.consenttrackingformated = "Allow";
                break;
            case 2:
                contact.consenttrackingformated = "Deny";
                break;
        }


        return contact;
    },

        _formatActivity = function () {
            this.eventdateformatted = ee.tools.time(ee.tools.time(this.eventdate).fromUTC()).toDate("L LT");//ee.api.formatDate(this.eventdate, "yy-mm-dd hh:ii");
        },
        _setPercent = function (contact) {
            if (contact.totalsent > 0) {
                contact.totalopenedpercent = ((contact.totalopened / contact.totalsent) * 100).toFixed(1);
                contact.totalclickedpercent = ((contact.totalclicked / contact.totalsent) * 100).toFixed(1);
                contact.totalfailedpercent = ((contact.totalfailed / contact.totalsent) * 100).toFixed(1);
            } else {
                contact.totalopenedpercent = '0.0';
                contact.totalclickedpercent = '0.0';
                contact.totalfailedpercent = '0.0';
            }
        },
        _unsubscribeReason = function (contact) {
            var contactreasons = [
                    ee.t.unsubscribereasonunknown,
                    ee.t.unsubscribereasonnolongerwant,
                    ee.t.unsubscribereasonirrelevantcontent,
                    ee.t.unsubscribereasontoofrequent,
                    ee.t.unsubscribereasonneverconsented,
                    ee.t.unsubscribereasondeceptivecontent,
                    ee.t.unsubscribereasonlistunsubscribe,
                    ee.t.unsubscribereasonthirdparty,
                    ee.t.unsubscribereasonabusereported
                ];
            contact.unsubscribeinfo = contact.unsubscribereason > 0 ? contactreasons[contact.unsubscribereason] : null;
        },
        Contact = function Contact(contactStr) {

            /*TODO Contact class is not SRP. In future we need to split it for more classes.*/

            this.data = {
                email: contactStr
            };
            this.logs = undefined;
            this.clicks = undefined;
            this.activity = undefined;
            this.query = {
                to: new Date(),
                from: new Date()
            };
        },
        proto = {
            load: function () {
                var that = this;
                return EE_API.Contact.LoadContact({ email: that.data.email })
                       .then(function (contact) {
                           that.update(contact);
                           return this;
                       });
            },
            update: function (contact) {
                this.data = _format.call(contact);
            },
            loadContactHistory: function (offset) {
                var that = this,
                    query = {
                        email: that.data.email,
                        limit: 100,
                        offset: 0
                    };

                if (offset) query.offset = offset;

                return EE_API.Contact.LoadHistory(query)
                    .then(function (data) {
                        if (offset && that.activity) {
                            that.activity.add(new Collection.Data(data).each(_formatActivity).getAll())
                        } else {
                            that.activity = new Collection.Data(data).each(_formatActivity);
                        }
                        return query.limit === data.length;
                    });
            },
            loadContactLatestMails: function () {
                var that = this;
                return EE_API.Search.Email({ email: that.data.email })
                        .then(function (data) {
                            that.logs = dataModel.create("Logs", data.emails);
                            if (data.emails) that.logs.FormatData();
                        })
                        .catch(function (err) { console.log(err) });

            },
            loadContactClicks: function () {
                var that = this;
                return EE_API.Contact.LoadLinkClicks({ email: that.data.email })
                                .then(function (data) {
                                    that.clicks = dataModel.create("Links", data);
                                })
                                .catch(function (err) { console.log(err) });
            },
            findContact: function () {
                var that = this;
                return EE_API.Contact.FindContact({ email: this.data.email }).then(function (data) {
                    $.extend(that.data, data);
                });
            },
            exportunsubscribereasoncount: function (opt, callback) {
                var self = this,
                    query = self.query,
                    exportQuery;

                if (callback === undefined && typeof opt === "function") {
                    callback = opt;
                    opt = {};
                };
                exportQuery = {
                    filename: opt.filename || ee.t.unsubscribereason,
                    fileFormat: opt.fileFormat || 'csv',
                    compressionFormat: opt.compressionFormat || 0,
                    to: ee.tools.time(opt.to).toUTC() || ee.tools.time(query.to).toUTC()
                };
                if (opt.from) exportQuery.from = ee.tools.time(opt.from).toUTC();

                return EE_API.Contact.ExportUnsubscribeReasonCount(exportQuery);
            },
        };

    dataModel.inherit(Contact, proto).register("Contact", Contact);

}(window.dataModel));
