(function (root) {

    root.Domain = {
        List: function (query) { return root.request('/domain/list', query); },
        Add: function (query) { return root.request('/domain/add', query); },
        Delete: function (query) { return root.request('/domain/delete', query); },
        SetDefault: function (query) { return root.request('/domain/setdefault', query); },
        VerifySpf: function (query) { return root.request('/domain/verifyspf', query); },
        VerifyDkim: function (query) { return root.request('/domain/verifydkim', query); },
        VerifyTracking: function (query) { return root.request('/domain/verifytracking', query); },
        VerifyMX: function (query) { return root.request('/domain/verifymx', query); },
        VerifyDMARC: function (query) { return root.request('/domain/verifydmarc', query); },
        SetVerp: function (query) { return root.request('/domain/setverp', query, { manualerrorhandle: true }); }
    };


}(EE_API));