(function (root) {
    var _useDefineFunction = function (name) {
        switch (name) {
            case 'noempty':
                return function () { return this.val().trim() !== '' };
        }
    },
        _disable = function ($submits) {
            $submits.each(function () {
                var $this = $(this);

                $this.addClass('disabled');
                $this.prop('disabled', true);
            })
        },
        _enable = function ($submits) {
            $submits.each(function () {
                var $this = $(this);
                $this.removeClass('disabled');
                $this.prop('disabled', false);
            })
        },
        /**
        * Extension of the class HTML, adds form validation.
        * @class
        * @param $form {jQuery} - form container
        * @param options {Object} - Options object
        * @param options.submit {String | jQuery} - submit button: String when button is in form, jQuery object when not
        * @param options.inputs {html.Validator~inputsObj | String} - Array with inputObj of inputs object or query selector
        * @param options.callback {cb} - when options.inputs is string use  this as validation function for all inputs. Params: (inputs, form)
        * @param [options.eventType="keyup"] {String} - when options.inputs is string use  this as event type for all inputs
        * @memberof html
        */
        Validator = function ($formContainer, opt) {
            if (!(this instanceof Validator)) {
                throw new Error('Validator is a constructor and it`s need "new" operator');
                return;
            };
            var that = this;
            var validationSuccesful = true;
            that.form = $formContainer;
            /**
            * An array, that contains inputs objects.
            * @typedef {Object} inputsObj
            * @property {String} name - name of input
            * @property {cb} validFunc - function for validation, this = input, params: (inputs Collection)
            * @property [eventType="keyup"] {String} - event type for input
            * @property [msg=ee.t.donotleaveblank] {String} - error message
            * @memberof html.Validator
            */
            that.inputs = typeof opt.inputs === "string" ? $formContainer.find(opt.inputs) : new Collection.Data(opt.inputs);
            that.submit = typeof opt.submit === "string" ? $formContainer.find(opt.submit) : opt.submit;
            that.eventType = opt.eventType;
            that.dynamicSubmit = (typeof opt.dynamicSubmit === 'boolean') ? opt.dynamicSubmit : true;
            if (opt.callback) {
                that.callback = function () { var valid = opt.callback(that.inputs, that.form); valid ? _enable(that.submit) : _disable(that.submit) };
            }

            if (typeof opt.inputs !== "string") {
                that.inputs.each(function (elem, sec) {
                    var _self = this;
                    var isName = !/^[#.]/.test(this.name);
                    _self.$elem = isName ? that.form.find('[name=' + this.name + ']'): that.form.find(this.name);
                    _self.$parent = (_self.css) ? _self.$elem.closest(_self.css.parentSelector || '.form-group') : false;
                    _self.fresh = true;
                    if (!_self.msg) _self.msg = ee.t.donotleaveblank;
                    if (_self.$elem.length === 0) _self.$elem = null;
                    if (_self.$elem) {
                        var eventType = _self.eventType || 'keyup paste click';
                        _self.$message = _self.$elem.siblings(((_self.messageSelector) ? _self.messageSelector : '.eeform_error_msg'));
                        _self.$elem.on(eventType, function (e) {
                            var keyCode = e.keyCode || e.which;
                            if (keyCode == 9) { return; }
                            setTimeout(function () {
                                _self.fresh = false;
                                that.test();
                            }, 0)
                        })
                        _self.$elem.on('blur', function () {
                            if (_self.fresh === true) {
                                return;
                            }
                            var validFunc = typeof _self.validFunc === "string" ? _useDefineFunction(_self.validFunc) : _self.validFunc;
                            if (_self.css && _self.css.error && _self.css.success) {
                                _self.$parent.removeClass(_self.css.error + ' ' + _self.css.success);
                            }
                            if (!validFunc.call(_self.$elem, that.inputs)) {
                                _self.$message.text(_self.msg);
                                if (_self.$parent && _self.css.error) _self.$parent.addClass(_self.css.error);
                            } else {
                                if (_self.$parent && _self.css.success) _self.$parent.addClass(_self.css.success);
                            }
                            that.test();
                        });
                        _self.$elem.on('focus', function () {
                            _self.$message.text('');
                            if (_self.$parent) {
                                if (_self.css.error && _self.$parent.hasClass(_self.css.error)) {
                                    _self.$parent.removeClass(_self.css.error + ((_self.css.success) ? " " + _self.css.success : ""));
                                };
                            }
                        });
                    }
                })
                if (that.dynamicSubmit === true && !that.test()) _disable(this.submit);
            } else {
                that.inputs.on(that.eventType, function () {
                    that.callback();
                });
                that.callback()
            }

            if (that.dynamicSubmit === false) {
                that.submit.on('click', function (event) {
                    if (that.test(true) === false) {
                        event.stopPropagation();
                    }
                });
                return validationSuccesful;
            }
        };

    Validator.prototype = {
        test: function (mark) {
            var self = this,
                valid = true;

            self.inputs.each(function () {
                if (!this.$elem) return;
                var validFunc = typeof this.validFunc === "string" ? _useDefineFunction(this.validFunc) : this.validFunc;
                var that = this;
                this.$elem.each(function () {
                    if (!validFunc.call($(this), self.inputs)) {
                        valid = false;
                        var validationSuccesful = false;
                        if (mark) {
                            that.$message.text(that.msg);
                            if (that.$parent && that.css.error) that.$parent.addClass(that.css.error);
                        }
                    } else {
                        if (mark) {
                            if (that.$parent && that.css.success) that.$parent.addClass(that.css.success);
                        }
                    }
                })
            });
            if (this.dynamicSubmit) {
                if (valid) { _enable(self.submit) } else { _disable(self.submit) };
            }
            return valid;
        }
    }


    root.Validator = Validator;
}(html));
