(function (namespace) {

    var IpPools = function IpPools(apikey) {
        var self = this;

        if (!(self instanceof IpPools)) {
            throw new Error('IpPools is a constructor and it`s need "new" operator');
            return;
        };

        return self;
    };

    var proto = {
        Load: function (callback) {
            var self = this;
            ee.api.request('/pool/list', {}, function (data) {
                ee.indiOff();
                if (!data.success) return html.modal.error(data);
                self.update(data.data);
                if (callback) callback(self, data);
            }, undefined, undefined, undefined, undefined, true );
        }
    };

    //Inherits from Collection
    //create new prototape obj with inherits from Collection.Data;
    IpPools.prototype = Object.create(Collection.Data.prototype);
    //extend prototype with our Logs methods
    $.extend(IpPools.prototype, proto);

    namespace["IpPools"] = IpPools;

}(window.dataModel));
