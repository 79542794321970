(ee.views['account'] = function () {
    var _chart;
    var _dir = 'account';
    var _terms = false;
    var chart;
    var _getAvgClass = function (val) {
        if (val > 0) return "up";
        if (val < 0) return "down";
        return "zero";
    };
    function _scrpath(name) {
        return name === undefined ? '#/' + _dir : '#/' + _dir + '/' + name;
    };

    var that = {
        show: function show() {
            if (!ee.data.account) return ee.api.loadAccount();

            switch (ee.Router.action()) {
                case "reputation":
                    ee.logEvent('Account - Load reputation.');
                    return ee.goTo('#/reputation');
                case "subaccounts":
                    if (!ee.data.account.issub && ee.session.can.ViewSubAccounts) {
                        ee.logEvent('Account - Load subaccounts.');
                        ee.views.subaccount.showList();
                    }
                    break;
                case "referrals":
                    if (!ee.data.account.issub) {
                        ee.logEvent('Account - Load referrals.');
                        return that.showReferralsMain();
                    }
                    break;
                case "users":
                    ee.logEvent('Account - Users.');
                    if (!ee.Router.parameter('node0')) {
                        html.widget.UsersUI.show();
                    } else {
                        html.widget.AccessManager.init("fullscreen", "user", ee.Router.parameter('node1'));
                        return;
                    }
                    break;
                case "profile":
                    if (!ee.session.can.ModifyProfile) { return ee.goTo("#/dashboard"); }

                    ee.logEvent('Account - Load profile.');
                    return that.showProfile();
                    break;
                case "legal":
                    if (!ee.session.can.ViewBilling) { return ee.goTo("#/dashboard"); }

                    ee.logEvent('Account - Load legal.');
                    return that.showLegal();
                case "security":
                if (ee.session.can.Security){
                    ee.logEvent('Account - Load security.');
                    return that.showSecurity();
                } else {
                    return ee.goTo("#/dashboard");
                }
                default:
                    ee.changeHashQuietly(_scrpath());
                    return that.showMain();
            }


        },
        showMain: function () {

            ee.goTo('#/admin')
            return;
            
            if (ee.base && window.hj) {
                window.hj('trigger', 'account');
            }

            const {activePlan} = ee.data.account;

            ee.logEvent('My Account loaded.');
            var account = $.extend({}, ee.data.account);
            account.disablesubaccount = ee.parameters.disablesubaccount;
            account.hidechangeemail = ee.parameters.hideChangeEmail;
            account.hidechangepassword = ee.parameters.hideChangePassword;
            account.plans2017 = ee.data.account.pricingplan > 2;

            let plandata = {
                planname: (activePlan.name) ? activePlan.name : ''
            };
            switch(ee.data.account.pricingplan) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    plandata.payasyougo = true;
                    plandata.remainingEmailsPayasyougo = (ee.data.account.freeemails + ee.data.account.emailsremaining).numberFormat();
                    break;
                case 5:
                    plandata.plandesc = ee.t.emailapi_desc;
                    break;
                case 6:
                    plandata.plandesc = ee.t.emailapipro_desc;
                    break;
                case 7:
                    plandata.plandesc = ee.t.unlimitedplan_desc;
                    plandata.contactBasedPlan = true;
                    plandata.subscriptionbilling = moment(ee.data.account.pausesubscriptionuntil ? ee.data.account.pausesubscriptionuntil : ee.data.account.subscriptionbillingdate).format('L');
                    plandata.subscriptiondatedescription = ee.data.account.pausesubscriptionuntil ? ee.t.plan_paused : moment(ee.data.account.subscriptionbillingdate).diff(moment()) > 0 ? ee.data.account.autocreditenabled ? ee.t.plan_renews : ee.t.plan_expires : ee.t.expired_plan;
                    break;
                case 8:
                    plandata.plandesc = ee.t.unlimitedprodescription;
                    plandata.contactBasedPlan = true;
                    plandata.subscriptionbilling = moment(ee.data.account.pausesubscriptionuntil ? ee.data.account.pausesubscriptionuntil : ee.data.account.subscriptionbillingdate).format('L');
                    plandata.subscriptiondatedescription = ee.data.account.pausesubscriptionuntil ? ee.t.plan_paused : moment(ee.data.account.subscriptionbillingdate).diff(moment()) > 0 ? ee.data.account.autocreditenabled ? ee.t.plan_renews : ee.t.plan_expires : ee.t.expired_plan;
                    break;
                default:
                    plandata.contactBasedPlan = activePlan.typedesc === 'ContactSubscription'
                    plandata.subscriptionbilling = moment(ee.data.account.pausesubscriptionuntil ? ee.data.account.pausesubscriptionuntil : ee.data.account.subscriptionbillingdate).format('L');
                    plandata.subscriptiondatedescription = ee.data.account.pausesubscriptionuntil ? ee.t.plan_paused : moment(ee.data.account.subscriptionbillingdate).diff(moment()) > 0 ? ee.data.account.autocreditenabled ? ee.t.plan_renews : ee.t.plan_expires : ee.t.expired_plan;
                    break;
            }
            var emailApiAvailableEmails = parseInt(account.freeemails + account.emailcredits + (account.credit / account.priceperemail)) > 0 ? parseInt(account.freeemails + account.emailcredits + (account.credit / account.priceperemail)) : 0;
            var options = {
                acc: account,
                emailcreditssubaccount: (ee.data.account.issub && ee.data.account.requiresemailcredits),
                trialorbasic: ee.data.account.istrialbillingplan || ee.data.account.pricingplan === 2,
                getNumberOfColumns: function () {
                    if (ee.data.account.pricingplan === 2 || ee.data.account.istrialbillingplan  || (ee.data.account.issub && ee.data.account.requiresemailcredits)) {
                        return 'col-md-4';
                    } else {
                        return 'col-md-8';
                    }
                },
                //contactBasedPlan: ee.data.account.pricingplan == 7 || ee.data.account.pricingplan == 8,
                otherPricing: ee.data.account.pricingplan < 5  && !ee.data.account.allowlegacybillingplanchange, 
                advancedpricingplan: ee.data.account.pricingplan === 3,
                basicpricingplan: ee.data.account.pricingplan === 2,
                pricing2015: ee.data.account.pricingplan === 1,
                emailapiprotrial: ee.data.account.pricingplan === 6 && ee.data.account.istrialbillingplan,
                emailapitrial: (ee.data.account.pricingplan === 6 || ee.data.account.pricingplan === 5) && ee.data.account.istrialbillingplan,
                unlimited: (ee.data.account.pricingplan === 7) && !ee.data.account.istrialbillingplan,
                unlimitedtrial: (ee.data.account.pricingplan === 7 || ee.data.account.pricingplan === 8) && ee.data.account.istrialbillingplan,
                unlimitedprotrial: ee.data.account.pricingplan === 8 && ee.data.account.istrialbillingplan,

                unlimitedpro: ee.data.account.pricingplan === 8 && !ee.data.account.istrialbillingplan,
                emailapi: ee.data.account.pricingplan === 5 && !ee.data.account.istrialbillingplan,
                emailapipro: ee.data.account.pricingplan === 6 && !ee.data.account.istrialbillingplan,
                trial: ee.data.account.istrialbillingplan,
                emailApiAvailableEmails: emailApiAvailableEmails.numberFormat(),
                securitydisplay: ee.session.can.Security,
                legaldisplay: ee.data.account.issub || (!ee.session.can.ViewAccount && !ee.session.can.ViewBilling),
                discountpercent: ee.data.account.discountpercent ? ee.data.account.discountpercent * 100 : null,
                discountedprice: (ee.data.account.monthlysubscription * (1 - ee.data.account.discountpercent)).toFixed(2),
                discountactive: (ee.data.account.discountpercent && !ee.data.account.discountexpiry) || ee.data.account.discountexpiry && moment().diff(ee.data.account.discountexpiry, 'days', 'hours', 'minutes') <= 0,
                verificationlimit: ee.data.account.verificationlimit.numberFormat(),
                verifiedemails: ee.data.account.verifiedemails.numberFormat()
            }
            let screenoptions = _.extend(options, plandata)
            var screen = html.get("AccountDetail", options);
            var rephistory = dataModel.create("Reputation");
            screen.appendTo(ee.frame.content);
            var AccountPlanData = html.get("AccountPlanData", screenoptions);
            AccountPlanData.appendTo($('#AccountPlanData'));

            if (ee.data.account?.activePlan?.dailycost) {
                const dailyCost = ee.data.account.activePlan.dailycost.toFixed(2);

                const pricePerEmailTxt = $('#priceemail');
                const pricePerEmailCost = $('#pricePerThousand');

                if (pricePerEmailTxt.length && pricePerEmailCost.length) {
                    const pricePerDay = $('<p></p>')
                    .text(ee.t.priceperday)
                    const pricePerDayCost = $('<p></p>')
                    .css('font-weight', 'bold')
                    .text(`$ ${dailyCost}`)

                    pricePerEmailTxt.after(pricePerDay);
                    pricePerEmailCost.after(pricePerDayCost)
                }
            }

            ee.view.screen = screen;
            //check stats for last 24h
            var query = {};

            query.to = new Date();
            query.from = new Date();

            $('#modifyPricing').on('click', function () {
                if (ee.data.account.allowlegacybillingplanchange) {
                    html.modal.confirm(
                        ee.t.migratefromgrandfathreplanmodal,
                        ee.t.confirm,
                        function() {
    
                            if (ee.data.account.can.ModifyBilling) {
                                ee.indiOn();
                                EE_API.Payment.upgradeLegacyBillingPlan()
                                .then(() => {
                                    ee.goTo('#/payments');
                                    window.location.reload();
                                });
                            }
                        }
                    )

                    return;
                }

                ee.goTo('#/payments');
            });

            ee.api.loadaccountstats(query, function () {
                /* NOTE: Hard to find! Mayby should by in template? */
                $('#allcontacts').find('.allremainingcontacts').html(ee.data.account.contactscount.numberFormat());
                $('#lastdaysend').find('.emailssenttoday').html(ee.data.account.lastdaysendFormatted);
                $('#allcontacts').find('.maxcontacts').html(ee.data.account.maxcontacts.numberFormat());
                $('#lastdaysend').find('.contactlimit').html(ee.data.account.maxcontacts.numberFormat());
                $('#lastdaysend').find('.dailylimit').html(ee.data.account.dailysendlimit.numberFormat());

            }, true);
            $('.accountnavigation').on('click', (e) =>{
                let that = e.delegateTarget;
                ee.logEvent(that.dataset.event);
                ee.goTo('#/' + that.dataset.target);
            })

            $('#cancelplanmodal').on('click', function(){
                html.modal.confirm(ee.t.cancelplanmodaldesc
                    .replace(/{{subscriptionbilling}}/, moment(ee.data.account.pausesubscriptionuntil ? ee.data.account.pausesubscriptionuntil : ee.data.account.subscriptionbillingdate).format('L'))
                    .replace(/{{planname}}/, (ee.data.account.pricingplan === 7 ? ee.t.unlimited:ee.t.unlimitedpro)), ee.t.cancelplanheader, function() {
                        ee.logEvent('Account - Disable autocredit.');
                    EE_API.Payment.disableautocredit()
                    .then(function() {
                        ee.view.show;
                        html.modal.info(ee.t.confirmcancelplan)
                        $('.emodal-close').on('click', function() {
                            location.reload()
                        })
                    })

                }, "", { confirmTitle: ee.t.cancelplan, confirmCSS: "btn_lg btn_destructive btn-danger"});

            })

            if (!ee.data.account.autocreditenabled) {
                $('#cancelplanmodal').hide();
            }

            if (screen.disableautocredit) {
                screen.disableautocredit.on("click", function () {
                    ee.session.can.AdminModify ? ee.goTo("#/payments", { autocreditamount: ee.data.account.autocreditamount }) : EE_API.Payment.disableautocredit().then(ee.view.show);
                });
            }

            if (ee.data.account.dailysendlimit === 0) {
                    $('#dailylimittext').hide();
                    $('#dailylimit').hide();
                }
                if (ee.data.account.maxcontacts === 0) {
                    $('#contactlimittext').hide();
                    $('#contactlimit').hide();
                }

                if (!ee.data.account.requiresemailcredits) {
                    $('#remainingemailssubtext').hide();
                    $('#remainingemailssub').hide();
                }
        },
        showReferralsMain: function () {
            // Get general screen. Used on both screens: Welcome(for 0 refs) & Stats
            var $screen = html.get('ReferralsMainScreenFrame', {
                account: ee.data.account,
                referrallink: ee.data.account.affiliatelink
            });
            var Referrals;
            /*NOTE:In future Referrals model should by part of Account model */
            if (!ee.data.referrals) {
                Referrals = dataModel.create("Referrals");
                ee.data.referrals = Referrals;
            } else { Referrals = ee.data.referrals }

            Referrals.Load(function () {
                __loadReferrals();
            });

            ee.frame.content.empty();
            $screen.appendTo(ee.frame.content);

            function __loadReferrals() {
                Referrals.LoadHistory(function (Referrals) {
                    var minWithdrawAmount = 100;
                    var details = Referrals.details;
                    details.credits = numeral(details.credits).format('0,0.00');
                    var hasPayoutsHistory = Referrals.payouthistory.data.length > 0;
                    var hasReferrals = details.currentreferralcount > 0 || details.currentreferralcredit > 0;
                    var params = {
                        details: details,
                        referrallink: ee.data.account.affiliatelink,
                        active: hasReferrals,
                        account: ee.data.account,
                        hasPayoutsHistory: hasPayoutsHistory
                    };
                    var $content = html.get('ReferralsMainScreen', params);
                    // Hide all unnecessary elements on Referrals welcome screen
                    // for users with no referrals
                    if (!hasReferrals) {
                        $screen.find(".js-hide-referral-not-active").addClass("hidden");
                    }

                    // Attach click handler - show withdraw referral credits modal
                    $(".js-withdraw-referrals").on("click", function() {
                        var withdrawModal = html.modal.confirm(html.get("ReferralsWithdrawModal", { availableCredits: details.credits }), ee.t.withdrawmodaltitle, confirmWithdrawRequestHandler, function(e, modal) {
                            modal.close();
                        }, {
                            confirmTitle: ee.t.withdrawreferrals,
                            confirmCSS: "btn--withdraw-confirm js-withdraw-confirm disabled",
                            cancelTitle: ee.t.cancel,
                            cancelCSS: "btn--withdraw-cancel",
                            manualClose: true
                        });

                        withdrawModal.$container.addClass('withdraw-modal');
                        withdrawModal.$container.addClass('js-withdraw-modal');
                        // Validate withdrawal amount input
                        withdrawModal.$container.find(".js-withdraw-input").on("keyup", function() {
                            var modal = $(this).parents(".js-withdraw-modal");
                            var credits = details.currentreferralcredit;
                            var userInput = this.value;
                            // Leave only numbers
                            var match = userInput.match(/[0-9]+\.{0,1}[0-9]{0,2}/g);
                            var cleanInput = match ? match[0] : "";
                            // Leave only a number that's <= credits
                            this.value = cleanInput > credits ?
                                (Math.round(credits * 100) / 100) :
                                cleanInput;
                            // Validate
                            var modal = $(this).parents('.js-withdraw-modal');
                            validateWithdrawal(modal, minWithdrawAmount);
                        });
                        // Validate withdrawal email address
                        withdrawModal.$container.find(".js-withdraw-email-input").on("keyup", function(){
                            var modal = $(this).parents('.js-withdraw-modal');
                            validateWithdrawal(modal, minWithdrawAmount);
                        });

                        // Display withdrawal email input on withdraw type selection
                        withdrawModal.$container.find('.js-withdraw-modal__withdrawal-type').on("change", function() {
                            // Display email input
                            var $emailInputWrapper = withdrawModal.$container.find('.js-withdraw-modal__withdrawal-email-wrapper');
                            var $inputLabel = withdrawModal.$container.find('.js-withdraw-modal__email-input-label');
                            var elasticOptionLabel = ee.t.pleaseconfirmelasticemail;
                            var payPalOptionLabel = ee.t.pleaseprovidepaypayemail;
                            $emailInputWrapper.removeClass('hidden');
                            $inputLabel.html($(this).val() === "Email" ? elasticOptionLabel : payPalOptionLabel);
                            // Validation
                            var modal = $(this).parents('.js-withdraw-modal');
                            validateWithdrawal(modal, minWithdrawAmount);
                        });
                    });

                    function confirmWithdrawRequestHandler(ev, modalObj) {
                        var modal = $(ev.currentTarget).parents('.js-withdraw-modal');
                        var amount = Number(modal.find(".js-withdraw-input").val());
                        var email = modal.find('#withdraw-modal__withdrawal-email').val();
                        var message = modal.find('textarea').val();
                        var payoutType = modal.find('#withdraw-modal__withdrawal-type').val();

                        validateWithdrawal(modal, minWithdrawAmount);

                        // Check most important inputs manually for the last time
                        if (amount <= details.currentreferralcredit &&
                        amount >= minWithdrawAmount && ee.tools.isEmail(email)) {
                            // Send withdrawal request
                            ee.api.request('/account/referralwithdrawal', {
                                apikey: ee.session.apikey(),
                                amount: amount,
                                message: message,
                                payoutType: payoutType,
                                payoutEmail: email
                            }, function(data) {
                                ee.indiOff();
                                html.widget.Toast.info(ee.t.withdrawrequestsent, "", {fadeIn: 250, faeOut: 250, timeOut: 1000});
                                modalObj.close();
                            });
                        }
                    }

                    // Attach copy listeners to currently visible button
                    var $copyBtnActiveRefs = $screen.find(".js-cp-to-clip");
                    var $copyBtnInactiveRefs = $content.find(".js-cp-to-clip");
                    $copyBtnActiveRefs.on("click", function() {
                        var copyText = $(this).data("copy");
                        ee.tools.copyTextToClipboard(copyText);
                    });
                    $copyBtnInactiveRefs.on("click", function() {
                        var copyText = $(this).data("copy");
                        ee.tools.copyTextToClipboard(copyText);
                    });

                    $screen.referrals_container.empty();
                    $content.appendTo($screen.referrals_container);
                    var graphContainer = $content.find('#graph-container');

                    var showCustomizeModal = function (customizeData) {
                        var customizeLandingModal = html.modal.confirm(
                            html.get(
                                "ReferralsCustomizeLandingModal",
                                {
                                    isCustomiseChecked: customizeData.isCustomiseChecked,
                                    company: customizeData.company,
                                    website: customizeData.website,
                                    logo: customizeData.logo
                                }
                            ),
                            "Customize referral landing page",
                            function(e, modal) {
                                var isCustomiseChecked = modal.$content.find("#referralsCustomizeCheckbox").is(":checked");
                                var company = modal.$content.find("#referralsCustomizeCompanyName").val();
                                var website = modal.$content.find("#referralsCustomizeWebsiteUrl").val();
                                var logo = modal.$content.find("#referralsCustomizeLogoUrl").val();
                                EE_API.Account.CustomizeReferralLandingPage({
                                    referralCustomBranded: isCustomiseChecked,
                                    company: company,
                                    website: website,
                                    logoUrl: logo
                                }).then(function(data) {
                                    ee.indiOff();
                                    modal.close();
                                });
                            }, function(e, modal) {
                                modal.close();
                            }, {
                                confirmTitle: ee.t.save,
                                confirmCSS: "btn btn_md btn_primary",
                                cancelTitle: ee.t.cancel,
                                cancelCSS: "btn btn_md_basic btn_basic",
                                manualClose: true
                            }
                        );
                        // Handle Pick Logo Button
                        var $btnPickLogo = customizeLandingModal.$container.find(".js-referrals-customize__pick-logo");
                        var $logoUrlInput = customizeLandingModal.$container.find(".js-referrals-customize__input--logo-url");
                        $btnPickLogo.on("click", function() {
                            var isCustomiseChecked = customizeLandingModal.$content.find("#referralsCustomizeCheckbox").is(":checked");
                            var company = customizeLandingModal.$content.find("#referralsCustomizeCompanyName").val();
                            var website = customizeLandingModal.$content.find("#referralsCustomizeWebsiteUrl").val();
                            var logo = customizeLandingModal.$content.find("#referralsCustomizeLogoUrl").val();
                            html.widget.FileManager.init(function (url, listname) {
                                $logoUrlInput.val(url);
                                showCustomizeModal({
                                    isCustomiseChecked: isCustomiseChecked,
                                    company: company,
                                    website: website,
                                    logo: url
                                });
                            }, "images", function() {
                                showCustomizeModal({
                                    isCustomiseChecked: isCustomiseChecked,
                                    company: company,
                                    website: website,
                                    logo: logo
                                });
                            });
                            customizeLandingModal.close();
                        });
                    }

                    // Init customize referrals landing page button
                    $('.linkContainer_customizeBtn').on("click", function() {
                        EE_API.Account.LoadReferralLandingPage({
                            publicAccountID: ee.data.account.affiliatelink
                        }).then(function(data) {

                            showCustomizeModal({
                                isCustomiseChecked: data.referralcustombranded,
                                company: data.company,
                                website: data.website,
                                logo: data.logourl
                            });
                        });
                    });

                    // Attach payout history click listener
                    $screen.find('#epayouthistory').on('click', function () {
                        Referrals.LoadHistory(function(Referrals) {
                            var totalPayouts = 0;
                            // 246px is height of everything except table cells
                            // The larger this number the lesser tds each page
                            var contentWrapperHeight = $(window).height() - 256;
                            // 18px is the height of first item each page, 48px - rest
                            var countPerPage = Math.floor((contentWrapperHeight - 18) / 48) + 1;
                            // Modify data for display and math purposes
                            var allPayouts = Referrals.payouthistory.data.map(function(el) {
                                var newEl = _.cloneDeep(el);
                                newEl.date = moment(el.date).format('DD/MM/YYYY');
                                newEl.amount = -el.amount;
                                totalPayouts += newEl.amount;
                                return newEl;
                            });
                            // If screen height too small to fit all records
                            if (allPayouts.length > countPerPage) {
                                var payoutPages = [];
                                var tempChunk = [];
                                var pageIndex = 1;
                                for(var i = 1; i < (allPayouts.length +1); i++) {
                                    // Round each payout to 2 decimals
                                    allPayouts[i-1].amount = allPayouts[i-1].amount%1 !== 0 ? parseFloat(Math.round(allPayouts[i-1].amount * 100) / 100).toFixed(2) : allPayouts[i-1].amount;

                                    if (i%countPerPage === 0) {
                                        tempChunk.push(allPayouts[i-1]);
                                        var page = html.get("SidebarPayoutsContent", {
                                            index: pageIndex,
                                            payouts: tempChunk
                                        });
                                        if (i === countPerPage) {
                                            page.addClass('active');
                                        }
                                        payoutPages.push(page);
                                        pageIndex++;
                                        tempChunk = [];
                                    } else {
                                        tempChunk.push(allPayouts[i-1]);
                                    }
                                }
                                if (tempChunk.length > 0) {
                                    payoutPages.push(html.get("SidebarPayoutsContent", {index: pageIndex, payouts: tempChunk}));
                                }
                                var pagination = html.get("SidebarPayoutsPaging");
                                payoutPages.push(pagination);

                                var payoutsSidebar = html.Sidebar(ee.t.payouthistory, payoutPages, function(Sidebar) {
                                    var prev = Sidebar.$sidebar.find('.js-payouts-prev');
                                    var next = Sidebar.$sidebar.find('.js-payouts-next');
                                    // Append total payouts under title
                                    appendTotal(Sidebar, totalPayouts);
                                    // Payouts pagination prev btn
                                    prev.on("click", function(e) {
                                        e.preventDefault()
                                        var currIndex = Sidebar.$sidebar.find(".table-wrapper.active").data("index");
                                        if (currIndex > 1) {
                                            switchPage(Sidebar, -1);
                                        }
                                    });
                                    // Payouts pagination next btn
                                    next.on("click", function(e) {
                                        e.preventDefault();
                                        var currIndex = Sidebar.$sidebar.find(".table-wrapper.active").data("index");
                                        if (currIndex < (payoutPages.length - 1)) {
                                            switchPage(Sidebar, 1);
                                        }
                                    });

                                    // Admin record payout button
                                    if (ee.session.can.AdminModifyBilling) {
                                        // Prepare button markup
                                        var $recordPayoutBtn = $('<a href="#">');
                                        $recordPayoutBtn.attr("id", "adminRecordPayout");
                                        $recordPayoutBtn.addClass('admin__btn--recordpayout');
                                        $recordPayoutBtn.html(ee.t.recordpayout);

                                        Sidebar.$sidebar.find('h2').append($recordPayoutBtn);

                                        $recordPayoutBtn.on("click", function(e) {
                                            e.preventDefault();
                                            initRecordPayoutsSidebar(payoutsSidebar, $screen);
                                        });
                                    }
                                });
                            } else { // If all records fit on one screen
                                var content = html.get("SidebarPayoutsContent", {
                                    index: 1,
                                    payouts: allPayouts
                                });
                                content.addClass('active');
                                // Init sidebar
                                html.Sidebar(ee.t.payouthistory, content, function(Sidebar) {
                                    // Append total payouts under title
                                    appendTotal(Sidebar, totalPayouts);
                                    // Admin record payout button
                                    if (ee.session.can.AdminModifyBilling) {
                                        // Prepare button markup
                                        var $recordPayoutBtn = $('<a href="#">');
                                        $recordPayoutBtn.attr("id", "adminRecordPayout");
                                        $recordPayoutBtn.addClass('admin__btn--recordpayout');
                                        $recordPayoutBtn.html(ee.t.recordpayout);

                                        Sidebar.$sidebar.find('h2').append($recordPayoutBtn);

                                        $recordPayoutBtn.on("click", function(e) {
                                            e.preventDefault();
                                            initRecordPayoutsSidebar(payoutsSidebar, $screen);
                                        });
                                    }
                                });
                            }
                        })
                    });

                    if (!hasPayoutsHistory) {
                        // Hide payouts history btn
                        var $btnPayoutHistory = $screen.find('#epayouthistory');
                        $btnPayoutHistory.addClass('hidden');
                        var $btnRecordPayout = $screen.find('#recordFirstPayout');
                        if (ee.session.can.AdminModifyBilling && $btnRecordPayout.length > 0) {
                            $btnRecordPayout.on("click", function() {
                                initRecordPayoutsSidebar(false, false, function() {
                                    $btnRecordPayout.remove();
                                    $btnPayoutHistory.removeClass('hidden');
                                });
                            });
                        }
                    }
                    if (graphContainer.length > 0) {
                        if (!window.c3) {
                            html.Chart.init(function () { chart = initChart('#graph-container', details.referraldistribution); });
                        } else {
                            initChart('#graph-container', details.referraldistribution);
                        }
                    }
                });
            }

            // Validate withdraw referrals credits modal
            function validateWithdrawal(modal, minWithdrawAmount) {
                var $amountInput = modal.find(".js-withdraw-input");
                var amount = Number($amountInput.val());
                var $emailInput = modal.find('#withdraw-modal__withdrawal-email');
                var email = $emailInput.val();
                var $payoutTypeInput = modal.find('#withdraw-modal__withdrawal-type');
                var payoutType = $payoutTypeInput.val();
                var isEmailErrorDisplayed = $emailInput.parent().parent().find('.validation_error_text').length > 0;
                var isAmountErrorDisplayed = $amountInput.parent().parent().find('.validation_error_text').length > 0;
                var isSelectTypeErrorDisplayed = $payoutTypeInput.parent().parent().find('.validation_error_text').length > 0;
                var $withdrawButton = modal.find('.js-withdraw-confirm');

                // Prepare error span
                var errorSpan = $("<span></span>");
                errorSpan.addClass('validation_error_text');

                // Validate amount value
                if (amount < minWithdrawAmount) {
                    if(!isAmountErrorDisplayed) {
                        // Display min amount error
                        tmpErrorSpan = errorSpan.clone(true);
                        tmpErrorSpan.insertAfter($amountInput.parent());
                        tmpErrorSpan.html(ee.t.errorreferralcreditstoolow);
                    }
                } else {
                    // Remove min amount error
                    $amountInput.parent().parent().children('.validation_error_text').remove();
                }

                // Validate payout type selection
                if (!payoutType) {
                    if (!isSelectTypeErrorDisplayed) {
                        // Display choose payout type error
                        tmpErrorSpan = errorSpan.clone(true);
                        tmpErrorSpan.insertAfter($payoutTypeInput.parent());
                        tmpErrorSpan.html(ee.t.pleasepickpayouttype);
                    }
                } else {
                    // Remove choose payout type error
                    $payoutTypeInput.parent().parent().children('.validation_error_text').remove();
                    // Validate email
                    if (!ee.tools.isEmail(email)) {
                        if(!isEmailErrorDisplayed) {
                            // Display email error
                            tmpErrorSpan = errorSpan.clone(true);
                            tmpErrorSpan.insertAfter($emailInput.parent());
                            tmpErrorSpan.html(ee.t.emailinvalid);
                        }
                    } else {
                        // Remove email error
                        $emailInput.parent().parent().children('.validation_error_text').remove();
                    }
                }
                // If all valid - enable withdraw btn
                if (amount >= minWithdrawAmount && payoutType && ee.tools.isEmail(email)) {
                    $withdrawButton.removeClass('disabled');
                    return true;
                } else {
                    $withdrawButton.addClass('disabled');
                    return false;
                }
            }

            // Append total payouts under sidebar title
            function appendTotal(Sidebar, totalPayouts) {
                var $totalPayoutsMarkup = $('<div>').addClass('total');
                $totalPayoutsMarkup.html("Total payout: $"+(Math.round(totalPayouts * 100) / 100));
                Sidebar.$sidebar.find('.sidebar__title').append($totalPayoutsMarkup);
            }

            // Switch page +1/-1
            // @param Sidebar - sidebar obj
            // @param interval {Number} - (-1/1) prev page/next page
            function switchPage(Sidebar, interval) {
                var nextIndex = $(".table-wrapper.active").data("index") + interval;
                Sidebar.$sidebar.find(".table-wrapper.active").removeClass('active');
                Sidebar.$sidebar.find(".table-wrapper[data-index="+nextIndex+"]").addClass('active');
            }

            // Inits a Sidebar with Record Payout form
            // @param payoutsSidebar (optional) - Sidebar to close after recording
            // @param $screen (optional) - If passed, looks for #epayouthistory and
            //   fires a click event on it to display new, refreshed payouts history
            // @param callback {function} (optional) - fires up after recording
            // return Sidebar {object}
            function initRecordPayoutsSidebar(payoutsSidebar, $screen, callback) {
                var $recordPayoutForm = html.get("SidebarRecordPayout");
                // Init Record Payouts Sidebar
                return html.Sidebar(ee.t.recordpayout, $recordPayoutForm, function(Sidebar) {
                    var recordBtn = Sidebar.$sidebar.find('.js-record-payout__btn');
                    var amountInput = Sidebar.$sidebar.find('.js-record-amount');
                    var title = "Payout for Referrals";

                    // Allow 2 decimals max
                    amountInput.on("keyup", function() {
                        var userInput = this.value;
                        var match = userInput.match(/[0-9]+\.{0,1}[0-9]{0,2}/g);
                        var cleanInput = match ? match[0] : "";
                        $(this).val(cleanInput);
                    });

                    // Save new withdrawal request
                    recordBtn.on("click", function() {
                        var amount = Number(Sidebar.$sidebar.find('#recordPayoutAmount').val());
                        var notes = Sidebar.$sidebar.find('#payoutNote').val();
                        ee.api.adminRecordPayout(amount, title, notes, function() {
                            Sidebar.destroy();
                            if (payoutsSidebar) {
                                payoutsSidebar.destroy();
                            }
                            html.widget.Toast.info("Payout record added", "", {fadeIn: 250, faeOut: 250, timeOut: 2000});
                            if ($screen) {
                                $screen.find('#epayouthistory').click();
                            }
                            if (callback) {
                                callback(Sidebar);
                            }
                        });
                    });
                });
            }
            /*
            * Init referrals registered / day chart
            * @params bindingElement {String} - container css selector (ex. "#graph")
            * @params data {Array[{Objects{dateCreated, count}}]} -referrals count/day
            * return {c3} - created chart instance
            */
            function initChart(bindingElement, data) {
                var x = ['x'];
                var y = ['Referrals'];

                // Set max count per day on Y axis
                var values = [];
                for(var i = 0; i < 101; i++) {
                    values.push(i);
                }
                // Get all dates into array starting with 'x' elmnt
                x = x.concat(data.map(function(el) {
                    return el.datecreated.split("T")[0];
                }));
                // Get all referral counts into array starting with 'Referrals' elmnt
                y = y.concat(data.map(function(el) {
                    return el.count;
                }));
                var chart = c3.generate({
                    bindto: bindingElement,
                    data: {
                        x: "x",
                        columns: [x, y],
                        colors: {
                            Referrals: "#5457FF"
                        }
                    },
                    padding: {
                        top: 30,
                        right: 70,
                        bottom: 20,
                        left: 70,
                    },
                    size: {
                        height: 400
                    },
                    bar: {
                        width: 10
                    },
                    axis: {
                        x: {
                            type: 'timeseries',
                            tick: {
                                format: '%Y-%m-%d'
                            }
                        },
                        y: {
                            label: {
                                text: ee.t.referralsregistered,
                                position: "outer-top"
                            },
                            tick: {
                                values: values
                            }
                        }
                    },
                    zoom: {
                        enabled: true,
                        extent: [1, 100],
                        rescale: true
                    },
                    legend: {
                        show: false
                    }
                });
                var lastRefDate = x[x.length - 1];
                if (x.length > 2) {
                    chart.zoom([getDateMinusXDays(lastRefDate, 70), lastRefDate]);
                } else {
                    chart.zoom([getDateMinusXDays(lastRefDate, 2), lastRefDate]);
                }
                return chart;
            }
            /*
            * Craft a date 30 days earlier than given one
            * @params date {String}("YYYY-MM-DD") - date to subtract 30d from
            * return {String}("YYYY-MM-DD") - created chart instance
            */
            function getDateMinusXDays(date, daysCount) {
                return moment(date).subtract(daysCount, 'days').format().split('T')[0];
            }
        },
        showProfile: function () {
            return html.widget.ProfileUI.show();
        },
        showLegal: function () {
            var screen,
                dpaurl = `${ee.api.path}/account/generatedpa?version=2&apikey=${ee.session.apikey()}`;

            ee.frame.content.empty();

            if (!_terms) {
                $.ajax({
                    dataType: "json",
                    url: "https://elasticemail.com/wp-json/wp/v2/posts?categories=8",
                    cache: true,
                    success: function (rep) {
                        _terms = rep;
                        screen = html.get("AccountLegal", { account: ee.data.account, terms: _terms });
                        screen.appendTo(ee.frame.content);
                        that.prepareLegal(screen, dpaurl);
                    }
                });

            } else {
                screen = html.get("AccountLegal", { account: ee.data.account, terms: _terms });
                screen.appendTo(ee.frame.content);
                that.prepareLegal(screen, dpaurl);
            }
        },
        prepareLegal: function (screen, dpaurl) {
            var legalTab;

            screen.dpabtn.on('click', function () {
                var modal = html.modal.create(html.get("DPADownloadModal", { dpaurl: dpaurl }), {
                    title: ee.t.downloadconfirmationtitle,
                    size: 'emodal-sm',
                    cancel: {
                        css: "closeDPADownloadBtn"
                    }
                });
                modal.$title.addClass('titleDPADownload');
                modal.$footer.addClass('footerDPADownload');
                modal.$container.addClass('containerDPADownload');

                modal.$content.find('#edpagenerate').on('click', function () {
                    modal.close();
                });
            });

            switch (ee.Router.currentPath()) {
                case 'account/legal/dpa':
                    legalTab = 'dpatab';
                    break;
                case "account/legal/termsofuse":
                    legalTab = 'terms-of-usetab';
                    break;
                case "account/legal/privacypolicy":
                    legalTab = 'privacy-policytab';
                    break;
                case "account/legal/acceptableusepolicy":
                    legalTab = 'acceptable-use-policytab';
                    break;
                case "account/legal/antispampolicy":
                    legalTab = 'anti-spam-policytab';
                    break;
                case "account/legal/security":
                    legalTab = 'securitytab';
                    break;
                case "account/legal/cookies":
                    legalTab = 'cookiestab';
                    break;
                default:
                    window.location.hash = '/account/legal/dpa';
                    return;
            }

            if (legalTab != undefined) {
                screen.termslist.find('a[href="#' + legalTab + '"]').trigger('click');
            }

            screen.termslist.off().on("click", 'li a', function () {
                var target = $(this).attr('href').substring(1);
                ee.view.manageLegalTabs(target);
            });
            screen.termslist.on('click', 'li', function (e) {
                if ($(e.target).data('toggle') !== 'tab') $(this).find('[data-toggle="tab"]').click();
            });
        },
        manageLegalTabs: function (legalTab) {
            if (legalTab == undefined) legalTab = 'dpatab';

            if (legalTab == 'terms-of-usetab') {
                window.location.hash = '/account/legal/termsofuse';
            } else if (legalTab == 'privacy-policytab') {
                window.location.hash = '/account/legal/privacypolicy';
            } else if (legalTab == 'acceptable-use-policytab') {
                window.location.hash = '/account/legal/acceptableusepolicy';
            } else if (legalTab == 'anti-spam-policytab') {
                window.location.hash = '/account/legal/antispampolicy';
            } else if (legalTab == 'securitytab') {
                window.location.hash = '/account/legal/security';
            } else if (legalTab == 'cookiestab') {
                window.location.hash = '/account/legal/cookies';
            } else if (legalTab == 'dpatab') {
                window.location.hash = '/account/legal/dpa';
            }
        },
        showSecurity: function () {
            return html.widget.AccountSecurityUI();
        }

    };
    var publicUtils = {
        _checkProfileComplete: function () {
            var acc = ee.data.account;
            return !(!acc.countryName || !acc.address1 || !acc.city || !acc.zip || !acc.firstname || !acc.lastname || acc.needssmsverification);
        },
        _checkProfileCompleteWithoutSMS: function () {
            var acc = ee.data.account;
            return !(!acc.countryName || !acc.address1 || !acc.city || !acc.zip || !acc.firstname || !acc.lastname);
        },
        _enablepremiumtools: function () {
            var premiumtemplate = html.getraw("EnablePremiumTools", { featuredescription: ee.t['featuredescription' + ee.data.account.pricingplan] });
            html.modal.confirm(premiumtemplate, ee.t.enablecontactfeatures, function () {
                ee.api.activateContactFeatures(function () { ee.view.show(); });
            }, "", { size: "700px", confirmTitle: ee.t.enable });
        },
        _adddedicatedsupport: function () {
            if (ee.data.account.pricingplan == 7 || ee.data.account.pricingplan == 8) {
                if (ee.data.account.autocreditenabled && !ee.data.account.istrialbillingplan) {
                    var cart = {};
                    cart.type = "supportPlan";
                    cart.supportPlan = 2; //1 for priority support, 2 for premium support
                    html.widget.PaymentPanel.init(cart, function () { delete ee.data.account; ee.view.show(); });
                } else {
                    var infotext = ee.data.account.istrialbillingplan ? ee.t.plannotactive : ee.t.paymentnotactive;
                    var activeplanneeded = html.modal.confirm(infotext, "", function(){
                        ee.goTo("#/payments")
                    }, "", { size: "600px", confirmTitle: ee.t.activate });
                }
                return;
            } else {
                var modalTitle = ee.t.requestpremiumsupport;
                const emailapiplans = (ee.data.account.pricingplan == 5 || ee.data.account.pricingplan == 6);
                var addDedicatedSupport = html.get("AddDedicatedSupport", {emailapiplans : emailapiplans}),
                    modal = html.modal.create(addDedicatedSupport, {
                        title: modalTitle,
                        buttons: [{
                            name: modalTitle,
                            css: 'btn_primary btn_lg',
                            buttonID: 'esuccess',
                            callback: function () {
                                ee.api.addDedicatedSupport(addDedicatedSupport.supportplan.val());
                                modal.close();
                            }
                        }]
                    });
                modal.$footer.find('.btn_primary').hide();

                addDedicatedSupport.supportplan.change(function () {
                    if ($(this).val() == "0") {
                        addDedicatedSupport.dedicatedsupportdescription.text(ee.t.emailsupportdesc);
                        modal.$footer.find('.btn_primary').hide();
                    }
                    else if ($(this).val() == "1") {
                        addDedicatedSupport.dedicatedsupportdescription.text(ee.t.prioritysupportdesc);
                        modal.$footer.find('.btn_primary').show();
                        modal.$footer.find('.btn_primary').text(ee.t.addprioritysupport);
                    }
                    else if ($(this).val() == "2") {
                        addDedicatedSupport.dedicatedsupportdescription.text(ee.t.premiumsupportdesc);
                        modal.$footer.find('.btn_primary').show();
                        modal.$footer.find('.btn_primary').text(ee.t.addpremiumsupport);
                    }
                });

            }

        },
        _purchaseIP: function () {
            const {activePlan} = ee.data.account;

            if (activePlan.typedesc === "ContactSubscription") {
                if (ee.data.account.autocreditenabled && !ee.data.account.istrialbillingplan || (ee.data.account.issub && ee.data.account.enableprivateiprequest)) {
                    html.widget.PaymentPanel.init({
                        type: 'privateip',
                        privateip: true,
                    });
                } else {
                    var infotext = ee.data.account.istrialbillingplan ? ee.t.plannotactive : ee.t.paymentnotactive;
                    html.modal.confirm(infotext, "", function(){
                        ee.goTo("#/payments")
                    }, "", { size: "600px", confirmTitle: ee.t.activate });


                }
                return;
            } else {
                var modalTitle = (ee.data.account.mailerid > 0) ? ee.t.requestadditionalprivateip : ee.t.purchaseprivateip;
                var modalText = (ee.data.account.mailerid > 0) ? ee.t.descadditionalprivateip : ee.t.descprivateip;
                var privateIPDetailedCost = (activePlan.typedesc !== "ContactSubscription") ? ee.t.privateipcosttextperdayend : ee.t.privateipcosttextpermonthend;
                var privateIPCostText = (ee.data.account.isprivatebrandedsub) ? "" : " " + ee.t.privateipcosttext + ee.data.account.privateipcost + privateIPDetailedCost;
                var purchasePrivateIP = html.get("PurchasePrivateIP", ee.data);
                var modal = html.modal.create(purchasePrivateIP, {
                    title: modalTitle,
                    buttons: [{
                        name: modalTitle,
                        css: 'btn_primary btn_lg',
                        callback: function () {

                            ee.api.purchasePrivateIP(purchasePrivateIP.region.val(), purchasePrivateIP.ipquantity.val(), function () {
                                delete ee.data.account;
                                ee.view.show();
                            });
                            modal.close();
                        }
                    }]
                });
            purchasePrivateIP.privateiptext.text(modalText + privateIPCostText);
            }

        }
    }
    return $.extend(that, publicUtils);

}());
