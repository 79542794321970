(function (namespace) {
    "use strict";
        var _format = function () {
            var form = this;
            return form;
        },

        Form = function Form(data) {
            //Set Default Property
            this.data = { formid: 0, name: "New Form", html: "", formscope: 0 };

            if (!data) return;
            //this.data.publicFormID = data.formid || 0;
        },
        proto = {
            load: function (data) {
                var that = this;
                return EE_API.Form.Load({ name: data.formName }).then(function (data) {
                    if (that.data.screenshot) that.screenshotpath = ee.api.path + "/userfile" + that.data.screenshot;
                    if (that.data.mediumscreenshot) that.mediumscreenshotpath = ee.api.path + "/userfile" + that.data.mediumscreenshot;
                    if (that.data.thumbnail) that.thumbnailpath = ee.api.path + "/userfile" + that.data.thumbnail;
                    that.update(data);
                    return that;
                });
            },


            saveThumbnail: function (imageSrc, formName) {
                return EE_API.Form.SaveScreenshot({ base64image: imageSrc.split(',')[1], formName : formName });
            },

            update: function (data) {
                this.data = _format.call(data);
            },

        };

    dataModel.inherit(Form, proto).register("Form", Form);

}(window.dataModel));
