(function (namespace) {
    "use strict";
    var _format = function () {

    };
    /**
     * Create TemplateList collection model
     * @class
     * @extends Collection.Data
     * @memberof dataModel
     * @param  {obj} dataArr - List of Templates
     */
    var TemplateList = function TemplateList(dataArr) {

        this.statusCount = {};
    };

    var options = {
        storage: {
            name: "templatelist",
            load: EE_API.Template.GetList
        }
    };

    var proto = {
        update: function (data) {
            //Collection.Data.prototype.update.call(this, data).each(_format);
            this.templates = new Collection.Data(data.templates).each(_format);
            this.globaltemplate = new Collection.Data(data.globaltemplate).each(_format);
            this.drafttemplate = new Collection.Data(data.drafttemplate).each(_format);
            return this;
        },
        getUserTemplates: function () {
            return this.templates.getAll();
        }
    };

    dataModel.inherit(TemplateList, proto, options).register("TemplateList", TemplateList);

}(window.dataModel));
