(ee.views.reputation = function() {
    const _dir = 'reputation';

    function _scrpath(name) {
        return name === undefined ? `#/${  _dir}` : `#/${  _dir  }/${  name}`;
    };

    function _generateReputationHistoryChart(historyColl) {
        console.log(historyColl)
        if (window.c3) {
            c3.generate({
                bindto: "#egraph",
                legend: {
                    show: false
                },
                padding: {
                    top: 30,
                    right: 40,
                    bottom: 20,
                    left: 40,
                },
                data: {
                    labels: false,
                    x: 'date',
                    xFormat: '%d/%m/%Y',
                    type: 'line',
                    columns: [
                        ['date'].concat(historyColl.map(['graphdate'])),
                        ['Reputation'].concat(historyColl.map(['reputation'])),
                        ['Abuse percent'].concat(historyColl.map(['abusepercent'])),
                        ['Unknown users percent'].concat(historyColl.map(['unknownuserspercent'])),
                        ['Opened percent'].concat(historyColl.map(['openedpercent'])),
                        ['Clicked percent'].concat(historyColl.map(['clickedpercent'])),
                        ['Average spam score'].concat(historyColl.map(['averagespamscore']))
                    ],
                    colors: {
                        ['Reputation']: "#1f77b4",
                        ['Abuse percent']: "#000",
                        ['Unknown users percent']: "#66a3a3",
                        ['Opened percent']: "#008000",
                        ['Clicked percent']: "#ffa200",
                        ['Average spam score']: "#ff0000"
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: "%Y-%m-%d"
                        },
                    },
                    y: {
                        min: 0,
                        max: 100,
                        padding: { bottom: 0, top: 0 }
                    }
                }
            });

        }

    };

    const that = {
        show: function show() {
            if (!ee.data.account) return ee.api.loadAccount(); //TODO: check this
            switch (ee.Router.action()) {
                case "contentanalysis":
                    return that.contentAnalysis();
                case "reputationhistory":
                    return that.reputationHistory();
                default:
                    that.reputationDetails();
            }
        },
        getAccoundIdParam: function() {
            const locationHash = window.location.hash;
            const accountidParam = 'accountid=';
            const accountid = locationHash.includes(accountidParam) ? locationHash.split(accountidParam)[1] : '';
            return accountid && parseInt(accountid, 10);
        },
        /* REPUTATION */
        reputationDetails: function reputationDetails() {

            const accountid = that.getAccoundIdParam();
            const reputation = dataModel.create("Reputation");
            const screen = html.get("Reputation", {
                issub: ee.data.account.issub,
                accountid,
            });

            screen.appendTo(ee.frame.content);
            reputation.loadImpact(accountid)
                .then( () => {
                    screen.reputationimpact.empty();
                    screen.reputationstars.empty();
                    screen.reputationimpact.append(html.get('ReputationImpact', reputation.impact));
                    screen.reputationstars.append(html.get('ReputationStars', reputation.impact));
                    screen.on('click', '.econtentanalysis', () => { ee.goTo(_scrpath('contentanalysis')); });
                })
                .catch(err => {
                    console.log(err);
                });
        },
        contentAnalysis: function checkSpamHistory() {
            const accountid = that.getAccoundIdParam();
            const reputation = dataModel.create("Reputation");
                const screen = html.get("SpamCheckHistory", {
                    issub: ee.data.account.issub,
                    accountid
                });

            screen.appendTo(ee.frame.content);

            reputation.loadSpamCheck(accountid)
                .then( () => {
                const pagination = new html.Pagination(reputation.spamcheck.getAll(), {
                    template: 'SpamCheckHistoryTable',
                    resultcontainer: screen.paginationresult,
                    footercontainer: screen.find('.paginationlist'),
                    perpage: 10,
                    paginationinfo: false,
                    firstlast: false,
                });

                screen.on("click", ".eviewemail", (e) => {
                    const msgid = $(e.target).data("msgid");
                        const email = reputation.spamcheck.get("msgid", msgid);
                    dataModel.create("Log", {
                        data: email,
                        callback (log) {
                            ee.views.activity.showViewEmail(log);
                        }
                    });
                });

            });
        },
        reputationHistory: function reputationHistory() {
            const accountid = that.getAccoundIdParam();
            const reputation = dataModel.create("Reputation");
                const screen = html.get("ReputationHistory", {
                    issub: ee.data.account.issub,
                    accountid
                });

            screen.appendTo(ee.frame.content);

            reputation.loadReputationHistory(accountid)
                .then(() => {

                const revertedreputationhistory = reputation.history.sort(null, true);
                const pagination = new html.Pagination(revertedreputationhistory.getAll(), {
                    template: "ReputationHistoryTable",
                    resultcontainer: screen.paginationresult,
                    footercontainer: '.paginationlist',
                    perpage: 15
                });
                console.log(reputation.history);
                if (!(window.c3)) {
                    html.Chart.init( () => {
                        _generateReputationHistoryChart(reputation.history)
                    })
                } else {
                    _generateReputationHistoryChart(reputation.history);
                }
            });
        }
    };

    return that;

}());

