/**
* Utils namespace
* @namespace
* @memberof ee
*/
ee.tools = (function Tools() {
    var regexp = {
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        filename: /[\\\/\:\*\?\"\<\>\+\#\&\|]/,
        domain: /^(((?!-))(xn--)?[a-z0-9-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
        url: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        search: /[\-\[\]{}()*+?.,\\\^$|#\s]/g,
        cleanemoji: /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g,
    },
        that = {
            /**
            * convert bytes to the selected unit
            * @param bytes {Number} - number of bytes
            * @param {Object} [options] - conversion options
            * @param {Number} [options.precision=1] - conversion accuracy, number of decimal places
            * @param {String} [options.unit] - to which unit the bytes should be converted to: Bytes, KB, MB, GB or TB. If we do not specify the unit, the number will be converted to the nearest unit
            * @param {Boolean} [options.isNum=false] - If set to false, the method will return a string (number with unit), if set to true, the method will return a number (without unit)
            * @return {Number|String}
            * @memberof ee.tools
            */
            bytesToSize: function (bytes, options) {
                options = options || {};
                if (bytes === 0) return '0 Byte';
                var defaults = {
                    precision: 1,
                    i: parseInt(Math.floor(Math.log(bytes) / Math.log(1024))),
                    isNum: false
                };
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
                    settings = $.extend(defaults, options);

                if (options.unit) {
                    settings.i = sizes.indexOf(options.unit);
                }
                if (settings.isNum) {
                    return (bytes / Math.pow(1024, settings.i)).toFixed(settings.precision || 1);
                } else {
                    return (bytes / Math.pow(1024, settings.i)).toFixed(settings.precision || 1) + ' ' + sizes[settings.i];
                }
            },

            clearAllTimeouts: () => {
                //intercept all timeout ids and clear them out;
                let ids = window.setTimeout(function() {}, 0);

                if (ids.length > 0) {
                    while (ids--) {
                        window.clearTimeout(ids);
                    }
                }
            },
            getSendingPermission: function (permission) {
                return {
                    sms: (permission & 8) ? true : false,
                    dashboard: (permission & 4) ? true : false,
                    api: (permission & 2) ? true : false,
                    smtp: (permission & 1) ? true : false
                };
            },
            /* Valid methods */
            /**
            *check if a email address syntax is valid
            * @param email {String} - email adress
            * @memberof ee.tools
            * @return {Boolean}
            */

            isValidFileName: function (filename) {
                return (filename && !regexp.filename.test(filename));
            },

            isValidEmail: function isValidEmail(email) {
                return (email && regexp.email.test(email));
            },
            /**
            *check if a domain address syntax is valid
            * @param domain {String} - domain adress
            * @memberof ee.tools
            * @return {Boolean}
            */
            isValidDomain: function isValidDomain(domain) {
                return (domain && regexp.domain.test(domain));
            },
            isValidPhone: function (val) {
                var val = val.replace(/[() -]/g, '');
                if (val.substr(0, 2) === "00") val = '+' + val.slice(2);
                if(val.length < 6) return false;
                if (!(/^\+[0-9]+$/.test(val)) || val.substr(0, 3) === "+00") { return false; }
                return val;
            },
            /**
            * check if a url address syntax is valid
            * @param url {String} - url adress
            * @memberof ee.tools
            * @return {Boolean}
            */
            isValidUrl: function (url) {
                return regexp.url.test(url);
            },
            /**
            * check if a parameter is null or empty string ("").
            * @param value {all} - value to check
            * @memberof ee.tools
            * @return {Boolean}
            */
            isNullOrEmpty: function (value) {
                return value === "" || value === null;
            },
            /* Checks the type of the user's browser */
            /**
            *check if a user browser is from Microsofr family (IE or Edge)
            * @memberof ee.tools
            * @return {Boolean}
            */
            isMSIE: function isMSIE() {
                return !(navigator.userAgent.indexOf("MSIE") === -1 && !navigator.userAgent.match(/Trident.*rv\:11\./) && !/Edge/.test(navigator.userAgent))
            },
            /**
            *check if a user browser is FireFox
            * @memberof ee.tools
            * @return {Boolean}
            */
            isFF: function isFF() {
                return /.+Firefox.+/gi.test(navigator.userAgent)
            },
            /**
            *check if a user browser is Mac
            * @memberof ee.tools
            * @return {Boolean}
            */
            isMac: function isMac() {
                return navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) || false;
            },
            /* Misc methods */
            /**
            * Disable backspace on dashboard
            * @memberof ee.tools
            */
            disableBackSpace: function disableBackSpace() {
                
                $(document).keydown(function (e) {
                    var elid = $(document.activeElement).is('input:focus, textarea:focus, div.edit, div.redactor-editor, .elastictext-editor, .step-text-media, div.rx-editor.rx-reset.rx-content');
                    if (e.keyCode === 8 && !elid) {
                        return false;
                    };
                });
            },
            doInline: function() {
                /**
                 * @param stylesArray - the array of string
                 *          "{name}:{value};" pairs that are to be broken down
                 *
                 */
                function _createCSSRuleObject (stylesArray) {
                    var cssObj = {};
                    for(var _s = 0; _s < stylesArray.length; _s++) {
                        var S = stylesArray[_s].split(":");
                        if(S[0].trim()==""||S[1].trim()=="") continue;
                        if(S[1].indexOf('!important') > 0) {
                            S[1] = S[1].replace("!important", "");
                            console.log(S[1]);
                        }
                        cssObj[S[0].trim()] = S[1].trim();
                    }
                    return cssObj;
                }

                /**
                 * @param $out - the tmp html content
                 *
                 */
                function _interpretAppendedStylesheet ($out) {
                    var stylesheet = $out[0].styleSheets[0]; // first stylesheet
                    if(stylesheet) {
                        for(r in stylesheet.cssRules){
                            try{
                                var rule = stylesheet.cssRules[r];
                                if(!isNaN(rule))break; // make sure the rule exists
                                var $destObj = $out.find(rule.selectorText);
                                var obj = rule.cssText.replace(rule.selectorText, '');
                                obj = obj.replace('{','').replace('}',''); // clean up the { and }'s
                                var styles = obj.split(";"); // separate each
                                _createCSSRuleObject(styles);
                                var styleObj = _createCSSRuleObject(styles);
                                $destObj.css(styleObj); // do the inline styling
                            } catch (e) { }
                        }
                    }
                };

                /**
                 * The main method - inlinify
                 *  this utilizes two text areas and a div for final output -
                 *      (1) css input textarea for the css to apply
                 *      (2) html content for the css to apply TO
                 */
                function _inlinify (input) {
                    var tmpWindow = window.open("", "tmpHtml", "width=0,height=0");
                    window.blur(); // re focus on main window
                    var tmpDoc = tmpWindow.document; // create a window that we can use
                    var $tmpDoc = jQuery(tmpDoc); // jquerify the temp window

                    tmpDoc.write(input); // write the HTML out to a new window doc
                    _interpretAppendedStylesheet($tmpDoc); // apply styles to the document just created

                    var doctype = '';

                    if(tmpDoc.doctype) { // check if there's a doctype inside HTML editor
                        var doctypeNode = tmpDoc.doctype; // get doctype from editor markup
                        doctype = "<!DOCTYPE " // create doctype tag as string
                            + doctypeNode.name
                            + (doctypeNode.publicId ? ' PUBLIC "' + doctypeNode.publicId + '"' : '')
                            + (!doctypeNode.publicId && doctypeNode.systemId ? ' SYSTEM' : '')
                            + (doctypeNode.systemId ? ' "' + doctypeNode.systemId + '"' : '')
                            + '>';
                    }

                    var newHTML = $tmpDoc[0].documentElement.outerHTML;

                    tmpWindow.close();

                    return doctype + newHTML;
                };

                return function(input) {
                    return _inlinify(input);
                }
            }(),
            /**             * Return when should be done autorecharges (when credits below)
            * @param val {Number} - purchase value
            * @return {Number}
            * @memberof ee.tools
            */
            getAutoCreditLevel: function getAutoCreditLevel(val) {
                    switch(val) {
                        case 5:
                            return 1;
                        case 10:
                            return 2;
                        case 20:
                            return 5;
                        case 50:
                            return 10;
                        case 100:
                            return 20;
                        case 150:
                            return 30;
                        case 250:
                            return 50;
                        case 500:
                            return 100;
                        case 600:
                            return 150;
                        case 1000:
                            return 250;
                        case 1500:
                            return 300;
                        default:
                            throw val + " auto credit value not supported";
                }
            },

            getLocalDateFormat: () => {
                let locale = store.session("locale");
                moment.locale(locale);
                return moment().creationData().locale._longDateFormat.L;
            },

            recognizeRTLLanguage: () => {
                if (_.includes(['he', 'fa'], ee.session.get("locale"))) {//he, fa
                    $('html').attr("dir", "rtl")
                } else {
                    $('html').removeAttr("dir");
                }
            },

            getLanguageName: (locale) => {
                switch(locale) {
                    case "he":
                        return "Hebrew"
                    case "fr":
                        return "Française"
                    case "it":
                        return "Italian"
                    case "pt":
                        return "Portuguese"
                    case "es":
                        return "Spanish"
                    case "nl":
                        return "Dutch"
                    case "vi":
                        return "Vietnamese";
                    case "de":
                        return "Deutsch"
                    case "pl":
                        return "Polski"
                    case "ro":
                        return "Romanian"
                    case "ru":
                        return "Russian";
                    case "en":
                        return "English";
                    default:
                        return "English";
                }
            },

            determinateDateFormatDatepicker: () => {
                let locale = store.session("locale");
                switch(locale) {
                    case "he":
                    case "fr":
                    case "it":
                    case "pt":
                    case "es":
                    case "nl":
                    case "vi":
                        return "DD-MM-YYYY";
                    case "de":
                    case "pl":
                    case "ro":
                    case "ru":
                        return "DD.MM.YYYY";
                    case "en":
                        return "MM-DD-YYYY";
                    default:
                        return "YYYY-MM-DD";
                }
            },
            setupDropdownPositionBehaviour: (tablerow) => {
                if (tablerow.find('ul').length !== 0) {
                    tablerow.each((index, elem)=> {
                    let drop;
                    let $tableRow = $(elem);
                    let dropdownDiv = $tableRow.find('.dropdown')
                    let openOn = $tableRow.data('open-on') || 'click';
                    let $menuButton = $tableRow.find('.eicontainer');

                    drop = new Drop({
                        target: $menuButton[0],
                        position: 'bottom right',
                        constrainToWindow: true,
                        constrainToScrollParent: false,
                        openOn: 'click',

                        content: function(data) {
                            if($tableRow.find('.dropdown-menu-tether')[0] != null){

                                $(window).on( 'hashchange', () => {
                                    drop.close();
                                })
                                $('.dropdown-menu-tether').on('click', ()=>{
                                    drop.close();
                                })
                               
                                return $tableRow.find('.dropdown-menu-tether')[0]
                            } else {
                                let dataContent = data.content;
                                let ulElement = $(dataContent).find('.dropdown-menu-tether')[0];
                                return ulElement;
                            }
                        },
                        remove: true
                        });
                        return drop;
                    })

                }

            },
            
            setupMutationObserver: (rowClass, isClicked,setupOnClickFunc, onClickFuncParam) => {

                let setupMutationObserver = ()=>{};//prevent multiple invocation
        
                const onElementInserted = (containerSelector, elementSelector, callback) => { 
                    const onMutationsObserved = function(mutations) {
                        mutations.forEach(function(mutation) {
                            if (mutation.addedNodes.length) {
                                const elements = $(mutation.addedNodes).find(elementSelector);
                                elements.each((index, element) => {callback(element);})
                            }
                        });
                    };
                
                    const target = $(containerSelector)[0];
                    
                    const config = { childList: true, subtree: true };
                    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
                    const observer = new MutationObserver(onMutationsObserved);    
                    observer.observe(target, config);
                
                };
                onElementInserted('body', '.drop-content', (element) => {
                    if (!isClicked) {
                        setupOnClickFunc(element, onClickFuncParam);
                    }
                    
                });
                onElementInserted('body', rowClass, (element) => {
                    ee.tools.setupDropdownPositionBehaviour($(element));
                });
                if ($(rowClass).length > 0 ) {
                    $(rowClass).each((i, element) => {
                        ee.tools.setupDropdownPositionBehaviour($(element));
                    })
                }
            },
            determinateDateFormat: (date) => {
                const dateFormats = {
                    "short_date" : 'DD MM YYYY',
                    "short_dash_date_v2": "MM DD YYYY",
                    "iso_date_short": "YYYY MM DD",
                    "iso_date_time": "YYYY MM DD HH:MM:SS",
                    "iso_date_time_utc": "YYYY MM DD HH:MM:SSZ"
                }

                return _.find(dateFormats, (format) => {
                    if (moment(date, format).isValid()) {
                        return format;
                    }
                })
            },

            localDateFormatDatepicker: function() {
                let date = this.getLocalDateFormat().toLowerCase();
                let count = 0;
                date = _.filter(date, (char) => {
                    if(char === 'y' && count < 2) {
                        count++;
                        return char;
                    } else if (char !== 'y') {
                        return char;
                    }
                })
                date = date.join("");
                return date;
            },

            localDateFormatGraph: function() {
                let date = this.localDateFormatDatepicker().toLowerCase().split("");
                date[_.indexOf(date, 'y')] = '%';
                date[_.indexOf(date, 'm')] = '%';
                date[_.indexOf(date, 'd')] = '%';
                date = date.join("");

                date = date.replace(/\//g, '-');
                date = date.replace(/y/g, 'Y');

                return date;
            },
            /**
            * Return substring with extra chart when last chart is non-BMP character
            * @param text {String} - text for substring
            * @param maxlength {Number} - substring max length
            * @return {String}
            * @memberof ee.tools
            */
            subStringUtf8mb4: function (text, maxlenght) {
                if (text.length > maxlenght) {
                    text = text.substr(0, maxlenght);
                    /*NOTE: slice extra chart when last chart is non-BMP character (only when is first from surogate pairs) */
                    if (/[\uD800-\uDBFF]/.test(text.charAt((maxlenght - 1)))) {
                        text = text.substr(0, (maxlenght - 1));
                    }
                }
                return text;
            },
            XSSProtectHtml: function (bodyhtml) {
                return bodyhtml.replace(/ on\w+=(("[^"]*")|'[^']*')/ig, '').replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
            },
            /**
            * Remove emoji from string
            * @param str {String} - String with emoji
            * @return {String}
            * @memberof ee.tools
            */
            StringRemoveEmoji: function (str) {
                return str.replace(regexp.cleanemoji, '').trim();
            },

            /**
            * Return array with country objects
            * @return {Array}
            * @memberof ee.tools
            */
            CountryList: function () {
                var countrylist = [
                    {
                        "name": "Unknown",
                        "ID": 0,
                        "code": "-",
                        "callingcode": ""
                    }, {
                        "name": "Afghanistan",
                        "ID": 1,
                        "code": "AF",
                        "callingcode": "93"
                    }, {
                        "name": "Albania",
                        "ID": 2,
                        "code": "AL",
                        "callingcode": "355"
                    }, {
                        "name": "Algeria",
                        "ID": 3,
                        "code": "DZ",
                        "callingcode": "213"
                    }, {
                        "name": "American Samoa",
                        "ID": 4,
                        "code": "AS",
                        "callingcode": "1684"
                    }, {
                        "name": "Andorra",
                        "ID": 5,
                        "code": "AD",
                        "callingcode": "376"
                    }, {
                        "name": "Angola",
                        "ID": 6,
                        "code": "AO",
                        "callingcode": "244"
                    }, {
                        "name": "Anguilla",
                        "ID": 7,
                        "code": "AI",
                        "callingcode": "1264"
                    }, {
                        "name": "Antarctica",
                        "ID": 8,
                        "code": "AQ",
                        "callingcode": "672"
                    }, {
                        "name": "Antigua And Barbuda",
                        "ID": 9,
                        "code": "AG",
                        "callingcode": "1268"
                    }, {
                        "name": "Argentina",
                        "ID": 10,
                        "code": "AR",
                        "callingcode": "54"
                    }, {
                        "name": "Armenia",
                        "ID": 11,
                        "code": "AM",
                        "callingcode": "374"
                    }, {
                        "name": "Aruba",
                        "ID": 12,
                        "code": "AW",
                        "callingcode": "297"
                    }, {
                        "name": "Australia",
                        "ID": 13,
                        "code": "AU",
                        "callingcode": "61"
                    }, {
                        "name": "Austria",
                        "ID": 14,
                        "code": "AT",
                        "callingcode": "43"
                    }, {
                        "name": "Azerbaijan",
                        "ID": 15,
                        "code": "AZ",
                        "callingcode": "994"
                    }, {
                        "name": "Bahamas",
                        "ID": 16,
                        "code": "BS",
                        "callingcode": "1242"
                    }, {
                        "name": "Bahrain",
                        "ID": 17,
                        "code": "BH",
                        "callingcode": "973"
                    }, {
                        "name": "Bangladesh",
                        "ID": 18,
                        "code": "BD",
                        "callingcode": "880"
                    }, {
                        "name": "Barbados",
                        "ID": 19,
                        "code": "BB",
                        "callingcode": "1246"
                    }, {
                        "name": "Belarus",
                        "ID": 20,
                        "code": "BY",
                        "callingcode": "375"
                    }, {
                        "name": "Belgium",
                        "ID": 21,
                        "code": "BE",
                        "callingcode": "32"
                    }, {
                        "name": "Belize",
                        "ID": 22,
                        "code": "BZ",
                        "callingcode": "501"
                    }, {
                        "name": "Benin",
                        "ID": 23,
                        "code": "BJ",
                        "callingcode": "229"
                    }, {
                        "name": "Bermuda",
                        "ID": 24,
                        "code": "BM",
                        "callingcode": "1441"
                    }, {
                        "name": "Bhutan",
                        "ID": 25,
                        "code": "BT",
                        "callingcode": "975"
                    }, {
                        "name": "Bolivia",
                        "ID": 26,
                        "code": "BO",
                        "callingcode": "591"
                    }, {
                        "name": "Bosnia And Herzegovina",
                        "ID": 27,
                        "code": "BA",
                        "callingcode": "387"
                    }, {
                        "name": "Botswana",
                        "ID": 28,
                        "code": "BW",
                        "callingcode": "267"
                    }, {
                        "name": "Bouvet Island",
                        "ID": 29,
                        "code": "BV",
                        "callingcode": "47"
                    }, {
                        "name": "Brazil",
                        "ID": 30,
                        "code": "BR",
                        "callingcode": "55"
                    }, {
                        "name": "British Indian Ocean Territory",
                        "ID": 31,
                        "code": "IO",
                        "callingcode": "246"
                    }, {
                        "name": "Brunei Darussalam",
                        "ID": 32,
                        "code": "BN",
                        "callingcode": "673"
                    }, {
                        "name": "Bulgaria",
                        "ID": 33,
                        "code": "BG",
                        "callingcode": "359"
                    }, {
                        "name": "Burkina Faso",
                        "ID": 34,
                        "code": "BF",
                        "callingcode": "226"
                    }, {
                        "name": "Burundi",
                        "ID": 35,
                        "code": "BI",
                        "callingcode": "257"
                    }, {
                        "name": "Cambodia",
                        "ID": 36,
                        "code": "KH",
                        "callingcode": "855"
                    }, {
                        "name": "Cameroon",
                        "ID": 37,
                        "code": "CM",
                        "callingcode": "237"
                    }, {
                        "name": "Canada",
                        "ID": 38,
                        "code": "CA",
                        "callingcode": "1"
                    }, {
                        "name": "Cape Verde",
                        "ID": 39,
                        "code": "CV",
                        "callingcode": "238"
                    }, {
                        "name": "Cayman Islands",
                        "ID": 40,
                        "code": "KY",
                        "callingcode": "1345"
                    }, {
                        "name": "Central African Republic",
                        "ID": 41,
                        "code": "CF",
                        "callingcode": "236"
                    }, {
                        "name": "Chad",
                        "ID": 42,
                        "code": "TD",
                        "callingcode": "235"
                    }, {
                        "name": "Chile",
                        "ID": 43,
                        "code": "CL",
                        "callingcode": "56"
                    }, {
                        "name": "China",
                        "ID": 44,
                        "code": "CN",
                        "callingcode": "86"
                    }, {
                        "name": "Christmas Island",
                        "ID": 45,
                        "code": "CX",
                        "callingcode": "53"
                    }, {
                        "name": "Cocos (Keeling) Islands",
                        "ID": 46,
                        "code": "CC",
                        "callingcode": "61"
                    }, {
                        "name": "Colombia",
                        "ID": 47,
                        "code": "CO",
                        "callingcode": "57"
                    }, {
                        "name": "Comoros",
                        "ID": 48,
                        "code": "KM",
                        "callingcode": "269"
                    }, {
                        "name": "Congo",
                        "ID": 49,
                        "code": "CG",
                        "callingcode": "242"
                    }, {
                        "name": "Congo, The Democratic Republic Of The",
                        "ID": 50,
                        "code": "CD",
                        "callingcode": "243"
                    }, {
                        "name": "Cook Islands",
                        "ID": 51,
                        "code": "CK",
                        "callingcode": "682"
                    }, {
                        "name": "Costa Rica",
                        "ID": 52,
                        "code": "CR",
                        "callingcode": "506"
                    }, {
                        "name": "Cote Divoire",
                        "ID": 53,
                        "code": "CI",
                        "callingcode": "225"
                    }, {
                        "name": "Croatia",
                        "ID": 54,
                        "code": "HR",
                        "callingcode": "385"
                    }, {
                        "name": "Cuba",
                        "ID": 55,
                        "code": "CU",
                        "callingcode": "53"
                    }, {
                        "name": "Cyprus",
                        "ID": 56,
                        "code": "CY",
                        "callingcode": "357"
                    }, {
                        "name": "Czech Republic",
                        "ID": 57,
                        "code": "CZ",
                        "callingcode": "420"
                    }, {
                        "name": "Denmark",
                        "ID": 58,
                        "code": "DK",
                        "callingcode": "45"
                    }, {
                        "name": "Djibouti",
                        "ID": 59,
                        "code": "DJ",
                        "callingcode": "253"
                    }, {
                        "name": "Dominica",
                        "ID": 60,
                        "code": "DM",
                        "callingcode": "1767"
                    }, {
                        "name": "Dominican Republic",
                        "ID": 61,
                        "code": "DO",
                        "callingcode": "1809"
                    }, {
                        "name": "Ecuador",
                        "ID": 62,
                        "code": "EC",
                        "callingcode": "593"
                    }, {
                        "name": "Egypt",
                        "ID": 63,
                        "code": "EG",
                        "callingcode": "20"
                    }, {
                        "name": "El Salvador",
                        "ID": 64,
                        "code": "SV",
                        "callingcode": "503"
                    }, {
                        "name": "Equatorial Guinea",
                        "ID": 65,
                        "code": "GQ",
                        "callingcode": "240"
                    }, {
                        "name": "Eritrea",
                        "ID": 66,
                        "code": "ER",
                        "callingcode": "291"
                    }, {
                        "name": "Estonia",
                        "ID": 67,
                        "code": "WW",
                        "callingcode": ""
                    }, {
                        "name": "Ethiopia",
                        "ID": 68,
                        "code": "ET",
                        "callingcode": "251"
                    }, {
                        "name": "Falkland Islands (Malvinas)",
                        "ID": 69,
                        "code": "FK",
                        "callingcode": "500"
                    }, {
                        "name": "Faroe Islands",
                        "ID": 70,
                        "code": "FO",
                        "callingcode": "298"
                    }, {
                        "name": "Fiji",
                        "ID": 71,
                        "code": "FJ",
                        "callingcode": "679"
                    }, {
                        "name": "Finland",
                        "ID": 72,
                        "code": "FI",
                        "callingcode": "358"
                    }, {
                        "name": "France",
                        "ID": 73,
                        "code": "FR",
                        "callingcode": "33"
                    }, {
                        "name": "French Guiana",
                        "ID": 74,
                        "code": "GF",
                        "callingcode": "594"
                    }, {
                        "name": "French Polynesia",
                        "ID": 75,
                        "code": "PF",
                        "callingcode": "689"
                    }, {
                        "name": "French Southern Territories",
                        "ID": 76,
                        "code": "TF",
                        "callingcode": "262"
                    }, {
                        "name": "Gabon",
                        "ID": 77,
                        "code": "GA",
                        "callingcode": "241"
                    }, {
                        "name": "Gambia",
                        "ID": 78,
                        "code": "GM",
                        "callingcode": "220"
                    }, {
                        "name": "Georgia",
                        "ID": 79,
                        "code": "GE",
                        "callingcode": "995"
                    }, {
                        "name": "Germany",
                        "ID": 80,
                        "code": "DE",
                        "callingcode": "49"
                    }, {
                        "name": "Ghana",
                        "ID": 81,
                        "code": "GH",
                        "callingcode": "233"
                    }, {
                        "name": "Gibraltar",
                        "ID": 82,
                        "code": "GI",
                        "callingcode": "350"
                    }, {
                        "name": "Greece",
                        "ID": 83,
                        "code": "GR",
                        "callingcode": "30"
                    }, {
                        "name": "Greenland",
                        "ID": 84,
                        "code": "GL",
                        "callingcode": "299"
                    }, {
                        "name": "Grenada",
                        "ID": 85,
                        "code": "GD",
                        "callingcode": "1473"
                    }, {
                        "name": "Guadeloupe",
                        "ID": 86,
                        "code": "GP",
                        "callingcode": "590"
                    }, {
                        "name": "Guam",
                        "ID": 87,
                        "code": "GU",
                        "callingcode": "1671"
                    }, {
                        "name": "Guatemala",
                        "ID": 88,
                        "code": "GT",
                        "callingcode": "502"
                    }, {
                        "name": "Guernsey",
                        "ID": 89,
                        "code": "GG",
                        "callingcode": "44"
                    }, {
                        "name": "Guinea",
                        "ID": 90,
                        "code": "GN",
                        "callingcode": "224"
                    }, {
                        "name": "Guinea-bissau",
                        "ID": 91,
                        "code": "GW",
                        "callingcode": "245"
                    }, {
                        "name": "Guyana",
                        "ID": 92,
                        "code": "GY",
                        "callingcode": "592"
                    }, {
                        "name": "Haiti",
                        "ID": 93,
                        "code": "HT",
                        "callingcode": "509"
                    }, {
                        "name": "Heard Island And Mcdonald Islands",
                        "ID": 94,
                        "code": "HM",
                        "callingcode": "672"
                    }, {
                        "name": "Holy See (Vatican City State)",
                        "ID": 95,
                        "code": "VA",
                        "callingcode": "39"
                    }, {
                        "name": "Honduras",
                        "ID": 96,
                        "code": "HN",
                        "callingcode": "504"
                    }, {
                        "name": "Hong Kong",
                        "ID": 97,
                        "code": "HK",
                        "callingcode": "852"
                    }, {
                        "name": "Hungary",
                        "ID": 98,
                        "code": "HU",
                        "callingcode": "36"
                    }, {
                        "name": "Iceland",
                        "ID": 99,
                        "code": "IS",
                        "callingcode": "354"
                    }, {
                        "name": "India",
                        "ID": 100,
                        "code": "IN",
                        "callingcode": "91"
                    }, {
                        "name": "Indonesia",
                        "ID": 101,
                        "code": "ID",
                        "callingcode": "62"
                    }, {
                        "name": "Iran, Islamic Republic Of",
                        "ID": 102,
                        "code": "IR",
                        "callingcode": "98"
                    }, {
                        "name": "Iraq",
                        "ID": 103,
                        "code": "IQ",
                        "callingcode": "964"
                    }, {
                        "name": "Ireland",
                        "ID": 104,
                        "code": "IE",
                        "callingcode": "353"
                    }, {
                        "name": "Isle Of Man",
                        "ID": 105,
                        "code": "IM",
                        "callingcode": "+44"
                    }, {
                        "name": "Israel",
                        "ID": 106,
                        "code": "IL",
                        "callingcode": "972"
                    }, {
                        "name": "Italy",
                        "ID": 107,
                        "code": "IT",
                        "callingcode": "39"
                    }, {
                        "name": "Jamaica",
                        "ID": 108,
                        "code": "JM",
                        "callingcode": "1876"
                    }, {
                        "name": "Japan",
                        "ID": 109,
                        "code": "JP",
                        "callingcode": "81"
                    }, {
                        "name": "Jersey",
                        "ID": 110,
                        "code": "JE",
                        "callingcode": "44"
                    }, {
                        "name": "Jordan",
                        "ID": 111,
                        "code": "JO",
                        "callingcode": "962"
                    }, {
                        "name": "Kazakhstan",
                        "ID": 112,
                        "code": "KZ",
                        "callingcode": "7"
                    }, {
                        "name": "Kenya",
                        "ID": 113,
                        "code": "KE",
                        "callingcode": "254"
                    }, {
                        "name": "Kiribati",
                        "ID": 114,
                        "code": "KI",
                        "callingcode": "686"
                    }, {
                        "name": "Korea, Democratic People Republic Of",
                        "ID": 115,
                        "code": "KP",
                        "callingcode": "850"
                    }, {
                        "name": "Korea, Republic Of",
                        "ID": 116,
                        "code": "KR",
                        "callingcode": "82"
                    }, {
                        "name": "Kuwait",
                        "ID": 117,
                        "code": "KW",
                        "callingcode": "965"
                    }, {
                        "name": "Kyrgyzstan",
                        "ID": 118,
                        "code": "KG",
                        "callingcode": "996"
                    }, {
                        "name": "Lao People Democratic Republic",
                        "ID": 119,
                        "code": "LA",
                        "callingcode": "856"
                    }, {
                        "name": "Latvia",
                        "ID": 120,
                        "code": "LV",
                        "callingcode": "371"
                    }, {
                        "name": "Lebanon",
                        "ID": 121,
                        "code": "LB",
                        "callingcode": "961"
                    }, {
                        "name": "Lesotho",
                        "ID": 122,
                        "code": "LS",
                        "callingcode": "266"
                    }, {
                        "name": "Liberia",
                        "ID": 123,
                        "code": "LR",
                        "callingcode": "231"
                    }, {
                        "name": "Libyan Arab Jamahiriya",
                        "ID": 124,
                        "code": "LY",
                        "callingcode": "218"
                    }, {
                        "name": "Liechtenstein",
                        "ID": 125,
                        "code": "LI",
                        "callingcode": "423"
                    }, {
                        "name": "Lithuania",
                        "ID": 126,
                        "code": "LT",
                        "callingcode": "370"
                    }, {
                        "name": "Luxembourg",
                        "ID": 127,
                        "code": "LU",
                        "callingcode": "352"
                    }, {
                        "name": "Macao",
                        "ID": 128,
                        "code": "MO",
                        "callingcode": "853"
                    }, {
                        "name": "Macedonia",
                        "ID": 129,
                        "code": "MK",
                        "callingcode": "389"
                    }, {
                        "name": "Madagascar",
                        "ID": 130,
                        "code": "MG",
                        "callingcode": "261"
                    }, {
                        "name": "Malawi",
                        "ID": 131,
                        "code": "MW",
                        "callingcode": "265"
                    }, {
                        "name": "Malaysia",
                        "ID": 132,
                        "code": "MY",
                        "callingcode": "60"
                    }, {
                        "name": "Maldives",
                        "ID": 133,
                        "code": "MV",
                        "callingcode": "960"
                    }, {
                        "name": "Mali",
                        "ID": 134,
                        "code": "ML",
                        "callingcode": "223"
                    }, {
                        "name": "Malta",
                        "ID": 135,
                        "code": "MT",
                        "callingcode": "356"
                    }, {
                        "name": "Marshall Islands",
                        "ID": 136,
                        "code": "MH",
                        "callingcode": "692"
                    }, {
                        "name": "Martinique",
                        "ID": 137,
                        "code": "MQ",
                        "callingcode": "596"
                    }, {
                        "name": "Mauritania",
                        "ID": 138,
                        "code": "MR",
                        "callingcode": "222"
                    }, {
                        "name": "Mauritius",
                        "ID": 139,
                        "code": "MU",
                        "callingcode": "230"
                    }, {
                        "name": "Mayotte",
                        "ID": 140,
                        "code": "YT",
                        "callingcode": "269"
                    }, {
                        "name": "Mexico",
                        "ID": 141,
                        "code": "MX",
                        "callingcode": "52"
                    }, {
                        "name": "Micronesia, Federated States Of",
                        "ID": 142,
                        "code": "FM",
                        "callingcode": "961"
                    }, {
                        "name": "Moldova, Republic Of",
                        "ID": 143,
                        "code": "MD",
                        "callingcode": "373"
                    }, {
                        "name": "Monaco",
                        "ID": 144,
                        "code": "MC",
                        "callingcode": "377"
                    }, {
                        "name": "Mongolia",
                        "ID": 145,
                        "code": "MN",
                        "callingcode": "976"
                    }, {
                        "name": "Montenegro",
                        "ID": 146,
                        "code": "ME",
                        "callingcode": "382"
                    }, {
                        "name": "Montserrat",
                        "ID": 147,
                        "code": "MS",
                        "callingcode": "1664"
                    }, {
                        "name": "Morocco",
                        "ID": 148,
                        "code": "MA",
                        "callingcode": "212"
                    }, {
                        "name": "Mozambique",
                        "ID": 149,
                        "code": "MZ",
                        "callingcode": "258"
                    }, {
                        "name": "Myanmar",
                        "ID": 150,
                        "code": "MM",
                        "callingcode": "95"
                    }, {
                        "name": "Namibia",
                        "ID": 151,
                        "code": "NA",
                        "callingcode": "264"
                    }, {
                        "name": "Nauru",
                        "ID": 152,
                        "code": "NR",
                        "callingcode": "674"
                    }, {
                        "name": "Nepal",
                        "ID": 153,
                        "code": "NP",
                        "callingcode": "977"
                    }, {
                        "name": "Netherlands",
                        "ID": 154,
                        "code": "NL",
                        "callingcode": "31"
                    }, {
                        "name": "Netherlands Antilles",
                        "ID": 155,
                        "code": "NL",
                        "callingcode": "31"
                    }, {
                        "name": "New Caledonia",
                        "ID": 156,
                        "code": "NC",
                        "callingcode": "687"
                    }, {
                        "name": "New Zealand",
                        "ID": 157,
                        "code": "NZ",
                        "callingcode": "64"
                    }, {
                        "name": "Nicaragua",
                        "ID": 158,
                        "code": "NI",
                        "callingcode": "505"
                    }, {
                        "name": "Niger",
                        "ID": 159,
                        "code": "NE",
                        "callingcode": "227"
                    }, {
                        "name": "Nigeria",
                        "ID": 160,
                        "code": "NG",
                        "callingcode": "234"
                    }, {
                        "name": "Niue",
                        "ID": 161,
                        "code": "NU",
                        "callingcode": "683"
                    }, {
                        "name": "Norfolk Island",
                        "ID": 162,
                        "code": "NF",
                        "callingcode": "672"
                    }, {
                        "name": "Northern Mariana Islands",
                        "ID": 163,
                        "code": "MP",
                        "callingcode": "1670"
                    }, {
                        "name": "Norway",
                        "ID": 164,
                        "code": "NO",
                        "callingcode": "47"
                    }, {
                        "name": "Oman",
                        "ID": 165,
                        "code": "OM",
                        "callingcode": "968"
                    }, {
                        "name": "Pakistan",
                        "ID": 166,
                        "code": "PK",
                        "callingcode": "92"
                    }, {
                        "name": "Palau",
                        "ID": 167,
                        "code": "PW",
                        "callingcode": "680"
                    }, {
                        "name": "Palestinian Territory, Occupied",
                        "ID": 168,
                        "code": "PS",
                        "callingcode": "970"
                    }, {
                        "name": "Panama",
                        "ID": 169,
                        "code": "PA",
                        "callingcode": "507"
                    }, {
                        "name": "Papua New Guinea",
                        "ID": 170,
                        "code": "PG",
                        "callingcode": "675"
                    }, {
                        "name": "Paraguay",
                        "ID": 171,
                        "code": "PY",
                        "callingcode": "595"
                    }, {
                        "name": "Peru",
                        "ID": 172,
                        "code": "PE",
                        "callingcode": "51"
                    }, {
                        "name": "Philippines",
                        "ID": 173,
                        "code": "PH",
                        "callingcode": "63"
                    }, {
                        "name": "Pitcairn",
                        "ID": 174,
                        "code": "PN",
                        "callingcode": "64"
                    }, {
                        "name": "Poland",
                        "ID": 175,
                        "code": "PL",
                        "callingcode": "48"
                    }, {
                        "name": "Portugal",
                        "ID": 176,
                        "code": "PT",
                        "callingcode": "351"
                    }, {
                        "name": "Puerto Rico",
                        "ID": 177,
                        "code": "PR",
                        "callingcode": "1787"
                    }, {
                        "name": "Qatar",
                        "ID": 178,
                        "code": "QA",
                        "callingcode": "974"
                    }, {
                        "name": "Reunion",
                        "ID": 179,
                        "code": "RE",
                        "callingcode": "262"
                    }, {
                        "name": "Romania",
                        "ID": 180,
                        "code": "RO",
                        "callingcode": "40"
                    }, {
                        "name": "Russian Federation",
                        "ID": 181,
                        "code": "RU",
                        "callingcode": "7"
                    }, {
                        "name": "Rwanda",
                        "ID": 182,
                        "code": "RW",
                        "callingcode": "250"
                    }, {
                        "name": "Saint Helena",
                        "ID": 183,
                        "code": "SH",
                        "callingcode": "290"
                    }, {
                        "name": "Saint Kitts And Nevis",
                        "ID": 184,
                        "code": "KN",
                        "callingcode": "1869"
                    }, {
                        "name": "Saint Lucia",
                        "ID": 185,
                        "code": "LC",
                        "callingcode": "1758"
                    }, {
                        "name": "Saint Pierre And Miquelon",
                        "ID": 186,
                        "code": "PM",
                        "callingcode": "508"
                    }, {
                        "name": "Saint Vincent And The Grenadines",
                        "ID": 187,
                        "code": "VC",
                        "callingcode": "1784"
                    }, {
                        "name": "Samoa",
                        "ID": 188,
                        "code": "WS",
                        "callingcode": "685"
                    }, {
                        "name": "San Marino",
                        "ID": 189,
                        "code": "SM",
                        "callingcode": "378"
                    }, {
                        "name": "Sao Tome And Principe",
                        "ID": 190,
                        "code": "ST",
                        "callingcode": "239"
                    }, {
                        "name": "Saudi Arabia",
                        "ID": 191,
                        "code": "SA",
                        "callingcode": "966"
                    }, {
                        "name": "Senegal",
                        "ID": 192,
                        "code": "SN",
                        "callingcode": "221"
                    }, {
                        "name": "Serbia",
                        "ID": 193,
                        "code": "RS",
                        "callingcode": "381"
                    }, {
                        "name": "Seychelles",
                        "ID": 194,
                        "code": "SC",
                        "callingcode": "248"
                    }, {
                        "name": "Sierra Leone",
                        "ID": 195,
                        "code": "SL",
                        "callingcode": "232"
                    }, {
                        "name": "Singapore",
                        "ID": 196,
                        "code": "SG",
                        "callingcode": "65"
                    }, {
                        "name": "Slovakia",
                        "ID": 197,
                        "code": "SK",
                        "callingcode": "421"
                    }, {
                        "name": "Slovenia",
                        "ID": 198,
                        "code": "SI",
                        "callingcode": "386"
                    }, {
                        "name": "Solomon Islands",
                        "ID": 199,
                        "code": "SB",
                        "callingcode": "677"
                    }, {
                        "name": "Somalia",
                        "ID": 200,
                        "code": "SO",
                        "callingcode": "252"
                    }, {
                        "name": "South Africa",
                        "ID": 201,
                        "code": "ZA",
                        "callingcode": "27"
                    }, {
                        "name": "South Georgia And The South Sandwich Islands",
                        "ID": 202,
                        "code": "GS",
                        "callingcode": "500"
                    }, {
                        "name": "Spain",
                        "ID": 203,
                        "code": "ES",
                        "callingcode": "34"
                    }, {
                        "name": "Sri Lanka",
                        "ID": 204,
                        "code": "LK",
                        "callingcode": "94"
                    }, {
                        "name": "Sudan",
                        "ID": 205,
                        "code": "SD",
                        "callingcode": "249"
                    }, {
                        "name": "Suriname",
                        "ID": 206,
                        "code": "SR",
                        "callingcode": "597"
                    }, {
                        "name": "Svalbard And Jan Mayen",
                        "ID": 207,
                        "code": "SJ",
                        "callingcode": "47"
                    }, {
                        "name": "Swaziland",
                        "ID": 208,
                        "code": "SZ",
                        "callingcode": "268"
                    }, {
                        "name": "Sweden",
                        "ID": 209,
                        "code": "SE",
                        "callingcode": "46"
                    }, {
                        "name": "Switzerland",
                        "ID": 210,
                        "code": "CH",
                        "callingcode": "41"
                    }, {
                        "name": "Syrian Arab Republic",
                        "ID": 211,
                        "code": "SY",
                        "callingcode": "963"
                    }, {
                        "name": "Taiwan",
                        "ID": 212,
                        "code": "TW",
                        "callingcode": "886"
                    }, {
                        "name": "Tajikistan",
                        "ID": 213,
                        "code": "TJ",
                        "callingcode": "992"
                    }, {
                        "name": "Tanzania, United Republic Of",
                        "ID": 214,
                        "code": "TZ",
                        "callingcode": "255"
                    }, {
                        "name": "Thailand",
                        "ID": 215,
                        "code": "TH",
                        "callingcode": "66"
                    }, {
                        "name": "Timor-leste",
                        "ID": 216,
                        "code": "TL",
                        "callingcode": "670"
                    }, {
                        "name": "Togo",
                        "ID": 217,
                        "code": "TG",
                        "callingcode": "228"
                    }, {
                        "name": "Tokelau",
                        "ID": 218,
                        "code": "TK",
                        "callingcode": "690"
                    }, {
                        "name": "Tonga",
                        "ID": 219,
                        "code": "TO",
                        "callingcode": "676"
                    }, {
                        "name": "Trinidad And Tobago",
                        "ID": 220,
                        "code": "TT",
                        "callingcode": "1868"
                    }, {
                        "name": "Tunisia",
                        "ID": 221,
                        "code": "TN",
                        "callingcode": "216"
                    }, {
                        "name": "Turkey",
                        "ID": 222,
                        "code": "TR",
                        "callingcode": "90"
                    }, {
                        "name": "Turkmenistan",
                        "ID": 223,
                        "code": "TM",
                        "callingcode": "993"
                    }, {
                        "name": "Turks And Caicos Islands",
                        "ID": 224,
                        "code": "TC",
                        "callingcode": "1649"
                    }, {
                        "name": "Tuvalu",
                        "ID": 225,
                        "code": "TV",
                        "callingcode": "688"
                    }, {
                        "name": "Uganda",
                        "ID": 226,
                        "code": "UG",
                        "callingcode": "256"
                    }, {
                        "name": "Ukraine",
                        "ID": 227,
                        "code": "UA",
                        "callingcode": "380"
                    }, {
                        "name": "United Arab Emirates",
                        "ID": 228,
                        "code": "AE",
                        "callingcode": "971"
                    }, {
                        "name": "United Kingdom",
                        "ID": 229,
                        "code": "GB",
                        "callingcode": "44"
                    }, {
                        "name": "United States",
                        "ID": 230,
                        "code": "US",
                        "callingcode": "1"
                    }, {
                        "name": "United States Minor Outlying Islands",
                        "ID": 231,
                        "code": "UM",
                        "callingcode": "1"
                    }, {
                        "name": "Uruguay",
                        "ID": 232,
                        "code": "UY",
                        "callingcode": "598"
                    }, {
                        "name": "Uzbekistan",
                        "ID": 233,
                        "code": "UZ",
                        "callingcode": "998"
                    }, {
                        "name": "Vanuatu",
                        "ID": 234,
                        "code": "VU",
                        "callingcode": "678"
                    }, {
                        "name": "Venezuela",
                        "ID": 235,
                        "code": "VE",
                        "callingcode": "58"
                    }, {
                        "name": "Vietnam",
                        "ID": 236,
                        "code": "VN",
                        "callingcode": "84"
                    }, {
                        "name": "Virgin Islands, British",
                        "ID": 237,
                        "code": "VG",
                        "callingcode": "1284"
                    }, {
                        "name": "Virgin Islands, U.S.",
                        "ID": 238,
                        "code": "VI",
                        "callingcode": "1340"
                    }, {
                        "name": "Wallis And Futuna",
                        "ID": 239,
                        "code": "WF",
                        "callingcode": "681"
                    }, {
                        "name": "Western Sahara",
                        "ID": 240,
                        "code": "EH",
                        "callingcode": "212"
                    }, {
                        "name": "Yemen",
                        "ID": 241,
                        "code": "YE",
                        "callingcode": "967"
                    }, {
                        "name": "Zambia",
                        "ID": 242,
                        "code": "ZM",
                        "callingcode": "260"
                    }, {
                        "name": "Zimbabwe",
                        "ID": 243,
                        "code": "ZW",
                        "callingcode": "263"
                    }, {
                        "name": "Lland Islands",
                        "ID": 244,
                        "code": "AX",
                        "callingcode": "358"
                    }
                ];
                return countrylist;
            },
            /**
            *  Detects whether browser storage is both supported and available.
            * @param type {String} - localStorage or sessionStorage REQ
            * @memberof ee.tools
            * @return {Boolean}
            */
            storageAvailable: function (type) {
                try {
                    var storage = window[type],
                        x = '__storage_test__';
                    storage.setItem(x, x);
                    storage.removeItem(x);
                    return true;
                }
                catch (e) {
                    return false;
                }
            },

            /**
            *   Escape regexp search string, avoid error: "Invalid regular expression Unterminated character class"
            */
            RegExpEscape: function (value) {
                if (!value) return '';
                return value.toString().replace(regexp.search, "\\$&");
            },

            /**
            *
            */
            createDateAsUTC: function (date) {
                if (typeof date === 'string') date = new Date(date);
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
            },
            /**
            *
            */
            compareShallowObject: function (a, b) {
                var ka = 0, kb = 0;
                for (var key in a) {
                    if (a.hasOwnProperty(key) && a[key] !== b[key]) return false;
                    ka++;
                }
                for (var key in b) {
                    if (b.hasOwnProperty(key)) kb++;
                }
                return ka === kb;
            },

            arraysEqual: function (a, b) {
                if (a === b) return true;
                if (a == null || b == null) return false;
                if (a.length != b.length) return false;
                a.sort();
                b.sort();

                for (var i = 0; i < a.length; ++i) {
                    if (a[i] !== b[i]) return false;
                }
                return true;
            },

            getBrowserLanguage: function () {
                var language = navigator.language || navigator.browserLanguage;
                if (!language) return ee.parameters.locale || 'en';
                return language.toLocaleLowerCase().substr(0, 2);
            },

            loadScript: function(path) {
                return new Promise(function(resolve, reject) {
                    return $.ajax({
                            url: path,
                            dataType: "script",
                            timeout: 64000,
                            cache: true,
                        })
                        .done(function(script, textStatus) {
                            return resolve(textStatus)
                        })
                        .fail(function(jqxhr, settings, exception) {
                            return reject(exception);
                        });
                });
            },

            getLibPath: () => {
                if ( ee.isLive()) {
                    return `${ee.getProtocol()}//d3kqdymskb72x7.cloudfront.net/`;
                }

                return '/';
            },
            loadImage: function(path) {
                return new Promise(function(resolve, reject) {

                    var img = new Image();
                    img.onload =  function() { resolve(img); }
                    img.onerror = function() {
                        resolve(true);
                    }
                    img.src = path;

                });
            },
            /*
            * Check whether image size is bigger than 2MB
            * @params file {File} - File from input's(type="file") FileList
            * return {Boolean} - resolves as an compressed image File
            */
            _isImgTooBig: function (file) {
                if(typeof file == "object" && file.size !== "undefined") {
                    return (file.size / 1024 / 1024) > 2;
                } else {
                    throw "File size not specified."
                }
            },
            /*
            * Compress image
            * @params file {File} - File from input's(type="file") FileList
            * @params quality {Number}(range 0-1) - image quality after compression
            * @params maxWidth {Number} - desired maximum image width
            * return {Promise} - resolves as an compressed image File
            */
            compressImage: function (file, quality, maxWidth) {
                quality = quality || 0.6;
                maxWidth = maxWidth || 1600;
                // Resolves on FileReader's onload
                return new Promise(function (resolve, reject) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        // Create an <img> with an original b64
                        // Needs to be a promise so it loads the image
                        //                      and its width & height
                        var imagePromise = new Promise(function (resolve, reject) {
                            var img = document.createElement("img");
                            img.src = e.target.result;
                            img.onerror = function (e) { reject(e) };
                            resolve(img);
                        });
                        imagePromise.then(function (img) {
                            var width = img.naturalWidth;
                            var height = img.naturalHeight;
                            // Check whether image width is too big
                            if (width > maxWidth) {
                                // Set width & count height to sustain dimensions
                                width = maxWidth;
                                var coefficient = img.naturalWidth / img.naturalHeight;
                                height = width / coefficient;
                            }
                            // Draw Image
                            var canvas = document.createElement("canvas");
                            var ctx = canvas.getContext("2d");
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(img, 0, 0, width, height);
                            // Get compressed base64 code
                            var compressedB64 = canvas.toDataURL(file.type, quality);

                            // Convert b64 back to File instance
                            var sliceSize = 512;
                            var byteCharacters = atob(compressedB64.split(",")[1]);
                            var byteArrays = [];

                            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                var slice = byteCharacters.slice(offset, offset + sliceSize);

                                var byteNumbers = new Array(slice.length);
                                for (var i = 0; i < slice.length; i++) {
                                    byteNumbers[i] = slice.charCodeAt(i);
                                }

                                var byteArray = new Uint8Array(byteNumbers);

                                byteArrays.push(byteArray);
                            }
                            var newFile = new File(byteArrays, file.name, { type: file.type });

                            resolve(newFile);
                        })
                    };
                    reader.onerror = function (e) {
                        reject(e);
                    };
                    reader.readAsDataURL(file);
                });
            },
            /*
            * Puts text into clipboard.
            * @params text {String} - string to copy
            * return null            */
            copyTextToClipboard: function(text, successMessage) {
                var success   = true;
                var range     = document.createRange();
                var selection;

                // For IE.
                if (window.clipboardData) {
                    window.clipboardData.setData("Text", text);
                } else {
                    // Create a temporary element off screen.
                    var tmpElem = $('<div>');
                    tmpElem.css({
                        position: "absolute",
                        left:     "-1000px",
                        top:      "-1000px",
                    });
                    // Add the input value to the temp element.
                    tmpElem.text(text);
                    $("body").append(tmpElem);
                    // Select temp element.
                    range.selectNodeContents(tmpElem.get(0));
                    selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange (range);
                    // Lets copy.
                    try {
                        success = document.execCommand("copy", false, null);
                    }
                    catch (e) {} // Sentry ?
                    if (success) {
                        html.widget.Toast.info(successMessage || ee.t.reflinkcopied, "", {fadeIn: 250, faeOut: 250, timeOut: 1000});
                        // remove temp element.
                        tmpElem.remove();
                    }
                }
            },
            isEmail: function(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            isSlashOrBackslash: function(string) {
                return /[\/\\]/.test(string);
            },
            validateEntityName: function(opt) {
                new html.Validator(opt.screen, {
                    submit: opt.submit,
                    inputs: [
                        {
                            name: opt.inputname, css: { success: "", error: "incorrect_input_value", parentSelector: opt.inputname  },
                            msg: ee.t.backslashesinname,
                            messageSelector: '.validation_error_text',
                            validFunc: function () {
                                if (!opt.disableSlash) {
                                    return !ee.tools.isSlashOrBackslash(this.val().trim());
                                } else {
                                    return true;
                                }
                            }
                        },
                        {
                            name: opt.inputname, css: { success: "", error: "incorrect_input_value", parentSelector: opt.inputname },
                            msg: opt.emptyname ? opt.emptyname: ee.t.donotleaveblank,
                            messageSelector: '.validation_error_text',
                            validFunc: function () {
                                if (!opt.disableEmpty) {
                                    return this.val().trim();
                                } else {
                                    return true
                                }
                            }
                        }
                    ]
                });
            },

            //MS edge is not supporting new File() construction 02.04.2019
            createFile: function(filename, content) {

                var newFile;
                if (!navigator.msSaveBlob) { // detect if not Edge
                    newFile = new File([content], filename);
                } else {
                    newFile = new Blob([content]);
                    newFile.name = filename
                }

                return newFile;
            },
            time: (date, inputformat) => {
                let locale = ee.session.get("locale") || ee.tools.getBrowserLanguage();
                const tz = moment.tz.guess();
                if (_.includes(['he', 'fa'], locale)) {
                    locale = "en-gb";
                }
                /*
                * Universal datechanger
                * Usage: ee.tools.time(_DATE_).toUTC(setHours) - takes local datetime and returns an UTC datetime.setHours helps with specific hour selection
                * Usage: ee.tools.time(_DATE_).fromUTC(outputformat) - takes UTC date and returns local date (formatted to locale with hours by default, or to chosen format with outputformat param)
                */
                let debug = ee.isDebug();
                let fromUTC = (outputformat)=> {
                    /*
                    * Simple cheatsheet from output formats:
                    * L - simple date
                    * L LT - date + time
                    * more: https://momentjs.com/docs/#/displaying/format/
                    */
                    let servertime = moment.utc(date).tz(tz);
                    let outputdate = servertime.locale(locale).format(outputformat ? outputformat : 'L LT');
                    if (outputdate == "Invalid date" && debug) {
                        console.error(date, "fromUTC")
                    }
                    return date ? outputdate : "";
                };
                let toUTC = (setHours) => {
                    let format;
                    let recognizedformat = getFormat();

                    //if we are setting hours, we will be using default format (not reall)
                    if (setHours) {
                        format = `YYYY-MM-DDT${setHours}`;
                    }
                    let utcdate = moment(date, recognizedformat).locale(locale).utc().format(format ? format : false);
                    if (utcdate == "Invalid date" && debug) {
                        console.error(date,  "toUTC")
                    }
                    return date ? utcdate : "";
                }
                let toDate = (format) => { //formats date format to date time string
                    let recognizedformat = inputformat ? inputformat : getFormat();
                    let isdate = moment(date, recognizedformat).locale(locale).format(format ? format : moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    if (isdate == "Invalid date" && debug) {
                        console.error(date, "toDate")
                    }
                    return date ? isdate : "";

                }
                let getFormat = () => {
                    let formats = moment().creationData().locale._longDateFormat;
                    let format = moment(date,[
                        formats.L,
                        `${formats.L} ${formats.LT}`,
                        `${formats.L} ${formats.LTS}`,
                        'YYYY-MM-DD'
                    ])
                    return format.creationData().format;
                }
                this.fromUTC = fromUTC;
                this.toUTC = toUTC;
                this.toDate = toDate;
                this.getFormat = getFormat
                return this;
            },
            detectBrowser: {
                isMobile: function() {
                    return /Mobi/.test(navigator.userAgent);
                },

                getBrowserName: function() {
                    // Opera 8.0+
                    if ((window.opr && window.opr.addons)
                        || window.opera
                        || navigator.userAgent.indexOf(' OPR/') >= 0) {
                        return 'Opera';
                    }

                    // Firefox 1.0+
                    if (typeof InstallTrigger !== 'undefined') {
                        return 'Firefox';
                    }

                    // Safari 3.0+ "[object HTMLElementConstructor]"
                    if (/constructor/i.test(window.HTMLElement) || (function (p) {
                        return p.toString() === '[object SafariRemoteNotification]';
                    })(!window['safari'])) {
                        return 'Safari';
                    }

                    // Internet Explorer 6-11
                    if (/* @cc_on!@*/false || document.documentMode) {
                        return 'Internet Explorer';
                    }

                    // Edge 20+
                    if (!(document.documentMode) && window.StyleMedia) {
                        return 'Microsoft Edge';
                    }

                    // Chrome
                    if (window.chrome) {
                        return 'Chrome';
                    }
                },

                getOSName: function() {
                    var os;
                    if (ee.tools.detectBrowser.isMobile()) {
                        if (/Windows/.test(navigator.userAgent)) {
                            os = 'Windows';
                            if (/Phone 8.0/.test(navigator.userAgent)) {
                                os += ' Phone 8.0';
                            } else if (/Phone 10.0/.test(navigator.userAgent)) {
                                os += ' Phone 10.0';
                            }
                        } else if (/Android/.test(navigator.userAgent)) {
                            function androidVersion() {
                                if (/Android/.test(navigator.appVersion)) {
                                    var v = (navigator.appVersion).match(/Android ([0-9.]+)/);
                                    return v;
                                }
                            }

                            var ver = androidVersion();
                            os = ver[0];
                        } else if (/iPhone;/.test(navigator.userAgent)) {
                            function iOSversion() {
                                if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
                                    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                                    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                                }
                            }

                            var ver = iOSversion();
                            os = 'iOS ' + ver[0] + '.' + ver[1] + '.' + ver[2];
                        } else if (/iPad;/.test(navigator.userAgent)) {
                            function iOSversion() {
                                if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
                                    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                                    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                                }
                            }

                            var ver = iOSversion();
                            os = 'iOS ' + ver[0] + '.' + ver[1] + '.' + ver[2];
                        } else if (/BBd*/.test(navigator.userAgent)) {
                            os = 'BlackBerry';
                        }
                    } else {
                        if (/Windows/.test(navigator.userAgent)) {
                            os = 'Windows';
                            if (/5.1;/.test(navigator.userAgent)) {
                                os += ' XP';
                            } else if (/6.0;/.test(navigator.userAgent)) {
                                os += ' Vista';
                            } else if (/6.1;/.test(navigator.userAgent)) {
                                os += ' 7';
                            } else if (/6.2/.test(navigator.userAgent)) {
                                os += ' 8';
                            } else if (/10.0;/.test(navigator.userAgent)) {
                                os += ' 10';
                            }

                            if (/64/.test(navigator.userAgent)) {
                                os += ' 64-bit';
                            } else {
                                os += ' 32-bit';
                            }
                        } else if (/Macintosh/.test(navigator.userAgent)) {
                            os = 'Macintosh';
                            if (/OS X/.test(navigator.userAgent)) {
                                os += ' OS X';
                            }
                        }
                    }

                    return os;
                },

                getBrowser: function() {
                    return {
                        os: ee.tools.detectBrowser.getOSName(),
                        browser: ee.tools.detectBrowser.getBrowserName(),
                        language: navigator.language,
                        languages: navigator.languages,
                        user_agent: navigator.userAgent,
                        device: ee.tools.detectBrowser.isMobile() ? 'Mobile' : 'Desktop',
                        referrer: document.referrer || 'N/A',
                        online: navigator.onLine,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        screen_resolution: screen.width + ' x ' + screen.height,
                        cookie_enabled: navigator.cookieEnabled,
                    };
                }
            },

            convertSeconds: function (number, unit) {
                var d, h, m, s

                if (_.isNaN(number)) {
                    throw new TypeError('Value sent to seconds-converter must be a number.')
                }

                if (unit === 'sec' || unit === 'seconds') {
                    s = number
                } else if (unit === 'ms' || unit === 'milliseconds' || !unit) {
                    s = Math.floor(number / 1000)
                } else {
                    throw new TypeError("Unit must be 'sec' or 'ms'")
                }

                m = Math.floor(s / 60)
                s = s % 60
                h = Math.floor(m / 60)
                m = m % 60
                d = Math.floor(h / 24)
                h = h % 24

                return {days: d, hours: h, minutes: m, seconds: s}
            },

            getUrlParameter(name) {
                const isQueryStringPresent = location.href.indexOf("?") >= 0;
                const isParamPresent = location.href.indexOf(name) >= 0;
                let queryString;
                let paramString;
                let parsedParamValue = null;

                if (isQueryStringPresent && isParamPresent) {
                    queryString = location.href.split("?")[1];
                    paramString = queryString.split("&").find( element => {
                        return element.indexOf(name) >= 0;
                    });
                    parsedParamValue = paramString.split("=")[1];
                }

                return parsedParamValue;
            },

            isAbSplitCampaign: function (campaignChannel) {
                return campaignChannel.templatechannels.length > 1
            },

            isOldAbSplitCampaign: function (campaignChannel) {
                const templateChannels = campaignChannel.templatechannels;

                const isAbSplit = templateChannels.length > 1;
                const winnerChannelIndex = _.findIndex(templateChannels, (channel) => channel.splitwinner);
                const winnerChannel = winnerChannelIndex >= 0 && templateChannels[winnerChannelIndex];
                const isOldAbSplit = isAbSplit && winnerChannel && (_.reduce(templateChannels, (sum, channel) => channel.templateid === winnerChannel.templateid ? sum + 1 : sum, 0) === 1) && !_.isNil(winnerChannel.templateid);

                return isOldAbSplit;
            },
            decodeURI: function(stringToDecode){
                try{
                    return decodeURIComponent(stringToDecode);
                    
                }catch(e){
                    return stringToDecode;
                }
            },
            browserRemInPx: function() {
                return parseFloat(getComputedStyle(document?.documentElement)?.fontSize) || 10;
            }
        }
    return that;})();
