﻿/**
* @namespace ee.Sso
*/

(function () {
    ee.sso = (function Sso() {

        const showContainer = function() {
            if (window.location.hash !== '#/dashboard') {
                window.setTimeout(showContainer, 100);
            } else {
                ee.container.show();
                ee.indiOff();
            }
        };
        return that = {
            login: function(token) {
                ee.container.hide();
                ee.indiOn();
                const userName = "";
                const password = "";
                const rememberme = true;
                const authProviderToken = token;
                const query = {
                    username: userName,
                    password: password,
                    authProviderToken: authProviderToken,
                }
                return new Promise(function(resolve, reject) {
                    EE_API.Account.Login(query)
                    .then(function (data) {
                        if (data.status && typeof data.status === 'string') {
                            data.status = data.status.toLowerCase();
                            if (data.status === 'twofactorcoderequired' || (data.status === 'twofactorcodeexpired' && !query.twofactorauthcode)) {
                                ee.indiOff();
    
                                ee.views.login.showTwoFactorAuth(userName, password, rememberme, googleIdToken="", authProviderToken);
                                return data;
                            }
                            if (query.disable2FA) {
                                data.status += 'backupcode';
                            }
                            if ($screen.twofapasscode) { //if login error occured during this step there must be some problem with 2fa code - remove it and show error.
                                $screen.twofapasscode.val("").trigger("blur");
                            }
                            throw new Error(data.status);
                        }
    
                        return data;
                    })
                    .then(function(data) {
                        const apiKey = ((typeof data === "string") ? data : data.apikey);
                        if (query.disable2FA) {
                            try {
                                sessionStorage.setItem('disabled2FA', 'true');
                            } catch(err) {
                                console.log(err);
                                console.log("You need to enable session storage");
                            }
                        }
    
                        ee.session.setAccount(apiKey, rememberme, "#/dashboard", userName);
                    })
                    .then(function() {
                        showContainer();
                        resolve({success: true});
                    })
                    .catch(function(err) {
                        reject(err);
                    });
                  });
            },
            logout: function() {
                ee.logEvent('Logged Out.');
                ee.session.can = {};
                ee.views['login'].logout();
            },
        }
    })();
})();
