(ee.views["activity"] = function () {

    let sortType = 0;
    let dripPagination;
    let allJourneysList = [];
    let dripTable = [];
    let graphType;
    let journeyTable = [];
    let journeyAll = false;
    let emailsList = [];
    let dripAll;
    let paginationOffset = 0;
    let statuses = [];
    let logStatuses = ['0'];
    let FullData = [];
    let singleAutomation;
    let graphDataLoaded = false;
    let AUTOpagination;
    let dataOBJ = {};
    let searchForContact = false;
    let dropdownMenuIsClicked;
    let dropdownMenuLinkTrackingIsClicked;
    let collIteration = 0;
    let includeSms = false;
    let includeMail = true;
    let emailLogData;
    let selectedDateRange;
    var msgcategory;
    var cache,
        SUMMARY,
        CHANNELS,
        logtimeout,
        mainchart, compareChart, qualityChart,
        mainlocation = '#/activity', goMain,
        _thumbnailOptions = { target: '.etemplateboxtd', canvaswidth: 60, canvasheight: 60 },
        channellist,
        autoresend,
        maingraphQuery,
        campaignlist,
        changePage,
        summary, inprogressFormatted, cmptable = [], linkTrakingQuery, linksCollection,
        linkloadingflag, CHLpagination, CMPpagination, links,
        chantable = [], autotable = [], cmpsAll = false, chanAll = false, autoAll = false,
        _bindSummaryDropdown = function ($dropdown) {
            $dropdown.on("click", function (e) {

                e.stopPropagation();

                var graphmenu = $('#eselectgraph');
                graphmenu.show();
                _bindHideDropdown(graphmenu);
            });

        },
        _bindHideDropdown = function (graphmenu) {
            $(window).on("click.hidegraphmenu", function (e) {
                graphmenu.hide();
                $(window).off("click.hidegraphmenu");
            })
        },
        _manageGraphsFiltersDisplay = function ($screen) {
            $screen.selectgraph.find('li[data-toggle="tab"]').on('shown.bs.tab', function (e) {

                var $this = $(this),
                    tabId = $this.data("target"),
                    $egraphfilters = $('#egraphfilters'),
                    $namedisplay = $('#egraphname'),
                    $listItem = $('#esummary_list_item');
                switch (tabId) {

                    case '#equantitycontainer':
                        $egraphfilters.find('.filter').hide();
                        $egraphfilters.find('.quan').show();
                        $namedisplay.text(ee.t.quantitygraph);
                        break;
                    case '#equalitycontainer':
                        $egraphfilters.find('.filter').hide();
                        $egraphfilters.find('.qual').show();
                        $namedisplay.text(ee.t.qualitygraph);
                        break;
                    case '#ecomparecontainer':
                        $egraphfilters.find('.filter').hide();
                        $egraphfilters.find('.comp').show();
                        $namedisplay.text(ee.t.comparegraph);
                        break;
                }

                //check which item is selected
                if (ee.Router.action()) {
                    //                  { $screen.maintablist.find('li[data-hash="' + ee.Router.action() + '"]').trigger('click') };
                } else {
                    // $listItem.find('a').trigger('click');
                }
            });
        },
        _manageMainFilters = function ($tabscontainer) {

            $tabscontainer.find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

                var $this = $(this),
                    filter = $this.data("filter"),
                    $filterscontainer = $('#emainfilters');

                $filterscontainer.find('.filter').hide();
                if (filter) $filterscontainer.find('.' + filter).show();
            });
        },
        _getMJRError = function (msgID, callback) {
            var query = { msgID: msgID };
            EE_API.Admin.GetLogError(query).always(function () { ee.indiOff() }).then(
                function (data) {
                    html.modal.info(data, ee.t.error, "400px;");
                }
            ).catch(function (data) { html.modal.error(data); });
        };

    //Format bounced data
    function _changeDataLinkTracking(screen) {
        linkloadingflag = true;
        ee.api.loadlinktracking(linkTrakingQuery, function (data) {
            ee.data.links = new Collection.Data(data.data.trackedlink);
            links.updateData(ee.data.links.getAll());
            if (ee.data.links.data.length > 0) {
                $('#eexportbtn').prop('disabled', false);
                $('#eexportbtn').removeClass('disabled')
            } else {
                $('#eexportbtn').prop('disabled', true);
                $('#eexportbtn').addClass('disabled');
            }
            linkloadingflag = false;

            if (location.hash.includes('linktracking')) {
                dropdownMenuLinkTrackingIsClicked = false;
                ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuLinkTrackingIsClicked, that.setupMenuItemsLinkTrackingOnClick);
            }
        });
    }
    function morelinks() {
        if (location.hash == '#/activity/emails') {
            $('#egoemails').hide();
        } else if (location.hash == '#/activity/bouncedetails') {
            $('#egobouncedetails').hide();
        }
        $('#egoprogress').on('click', function(){
            location.hash = '#/activity/progress'
        });
        $('#egoemails').on('click', function(){
            location.hash = '#/activity/emails'
        });
        $('#egobouncedetails').on('click', function(){
            location.hash = '#/activity/bouncedetails'
        });
        $('#egounsubscribereasons').on('click', function(){
            location.hash = '#/activity/unsubscribereasons'
        });
        $('#egonotificationlog').on('click', function(){
            location.hash = '#/activity/notifications'
        });
    }
    function _changeDateLnkTrac(screen) {
        screen.on("click", "#edateselect_lnktrac li:not(.drop-first)", function () {
            dropdownMenuLinkTrackingIsClicked = false;
            var range = $(this).data("range")
            let to = moment();
            let from = moment();

            switch (range) {
                case "day":
                    from = moment(to).subtract(1, 'days');
                    break;
                case "week":
                    from = moment(to).subtract(1, 'weeks');
                    break;
                case "2weeks":
                    from = moment(to).subtract(2, 'weeks');
                    break;
                case "month":
                    from = moment(to).subtract(1, 'months');
                    break;
                case "3months":
                    from = moment(to).subtract(3, 'months');
                    break;
                case "halfyear":
                    from = moment(to).subtract(6, 'months');
                    break;
                case "year":
                    from = moment(to).subtract(1, 'years');
                    break;
            }

            linkTrakingQuery.to = ee.tools.time(to).toUTC();// moment(to).format(ee.tools.determinateDateFormatDatepicker());
            linkTrakingQuery.from = ee.tools.time(from).toUTC(); //moment(from).format(ee.tools.determinateDateFormatDatepicker());
            screen.find('#etimeselecttitle_lnktrac').html($(this).html());
            screen.find('#efromto_lnktrac').addClass("hide").css("display", "none");
            _changeDataLinkTracking(screen);
        });
        screen.on('click', '#edateselect_lnktrac li.drop-first', function () {
            screen.find('#etimeselecttitle_lnktrac').html($(this).html());
            screen.find('#efromto_lnktrac').removeClass("hide").css("display", "inline-block");

        });
        screen.find('#edatefrom_lnktrac')
        .datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() })
        .datepicker("setDate", new Date(linkTrakingQuery.from))
        .change(function () {
            linkTrakingQuery.from = ee.tools.time($(this).datepicker("getDate")).toUTC();
            _changeDataLinkTracking(screen);
        });
        screen.find('#edateto_lnktrac')
        .datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() })
        .datepicker("setDate", new Date(linkTrakingQuery.to))
        .change(function () {
            linkTrakingQuery.to = ee.tools.time($(this).datepicker("getDate")).toUTC();
            _changeDataLinkTracking(screen);
        });
        $('#edateto_lnktrac')[0].value = moment(linkTrakingQuery.to).format('L');
        $('#edatefrom_lnktrac')[0].value = moment(linkTrakingQuery.from).format('L');

        $(document).on('click', 'body', function(e) {
        var calendar = $('#ui-datepicker-div');
            if(!$(e.target).hasClass('hasDatepicker')){
            calendar.addClass("hide").css("display","none");
            } else {
                calendar.removeClass("hide").css("display","block");
            }
        })

    };

    function _formatBouncedData(data, callback) {
        let bouncedCategoryName = {
            spam: { name: ee.t.spam, sn: '2' },
            blacklisted: { name: ee.t.blacklisted, sn: '3' },
            nomailbox: { name: ee.t.nomailbox, sn: '4' },
            greylisted: { name: ee.t.greylisted, sn: '5' },
            throttled: { name: ee.t.throttledcategory, sn: '6' },
            timeout: { name: ee.t.timeout, sn: '7' },
            connectionproblem: { name: ee.t.connectionproblem, sn: '8' },
            spfproblem: { name: ee.t.spfproblem, sn: '9' },
            accountproblem: { name: ee.t.accountproblem, sn: '10' },
            dnsproblem: { name: ee.t.dnsproblem, sn: '11' },
            codeerror: { name: ee.t.codeerror, sn: '13' },
            connectionterminated: { name: ee.t.connectionterminated, sn: '15' }
            //notdelivered: { name: ee.t.suppressed, sn: '16' }
        };
        let formatted = [];
        let all = 0;
        let suppressed = 0;
        let hasOwn = Object.prototype.hasOwnProperty;

        for (let key in data) {
            if (hasOwn.call(data, key)) {
                if (data[key] > 0) {
                    if (bouncedCategoryName[key] !== undefined) {
                        formatted.push({ name: bouncedCategoryName[key].name, sn: bouncedCategoryName[key].sn, val: data[key] })
                        all += data[key];
                    } else {
                        //all else goes to suppress
                        suppressed += data[key];
                    }
                }
            }
        }
        formatted.unshift({ name: ee.t.allbounced, sn: '0', val: all });
        if (suppressed > 0) {
            formatted.push({ name: ee.t.suppressed, sn: '12,14,16', val: suppressed });
        }
        if (callback) { callback(formatted) } else { return formatted }
    };
    function _showBouncedData(screen, bouncedCategoriesColl, data) {
        bouncedCategoriesColl.update(data);
        screen.bouncebutton.html(html.get('BouncedBtnResult', data[0]));
        screen.bouncebutton.removeClass('disabled');
        screen.bounceselect.html(html.get('BounceCategoryFilterDrpdwnList', { list: data }));
    };
    //Show and get export modal

    function _showExportModal(opt, callback) {
        if (!callback) {
            throw new Error('callback require');
        }
        var content = html.get('ExportDialog', { filename: opt.filename }),
            modal = html.modal.create(content, {
                title: opt.title,
                buttons: [
                    {
                        name: ee.t.save, css: 'btn_primary btn_lg', callback: function () {
                            var value = {
                                filename: content.filename.val(),
                                fileFormat: content.fileformat.val(),
                                compressionFormat: Number(content.compressed.is(':checked'))
                            };
                            if (!ee.tools.isValidFileName(value.filename)) return html.modal.info(ee.t.exportfilenamechartsmsg);
                            callback(modal, content, value);
                        }
                    }
                ]
            });
    };
    //view Email or SMS-- use this with Logs collection
    function __logpreview(LogsObj, msgid) {
        LogsObj.getLog('msgid', msgid, function (log) {
            if (log.isSMS()) {
                return that.showViewSMS(log.data);
            }
            that.showViewEmail(log);
        });
    };

    //channels list
    function checkChannelLists() {
        var camp;
        if (!cache.channellist) {
            cache.channellist = CHANNELS.filter(['iscampaign'], 'false');
            camp = CHANNELS.filter(['iscampaign'], 'true').filter(['status'], ['6'], true, true);
            cache.campaignlist = camp.filter(['triggertype'], ['0', '1', '2'], true);
            cache.autoresend = camp.filter(['triggertype'], ['0', '1', '2'], true, true);
        }
    };
    function getStatuses(drplist) {
        var arr = [],
            inputs = drplist.find('input:checked');

        inputs.each(function () {
            arr.push($(this).val());
        });
        return arr;
    };
    //Checkbox
    function __loadinData(containerjQ, template) {
        if (!template) template = "LogLoadingTemplate";
        var templateHtml = html.get(template, {});
        containerjQ.find('tbody').html(templateHtml);
    };
    function __clearTableInputs(arr) {
        arr.forEach(function (elem) {
            if (typeof elem === 'object') {
                elem.prop('checked', false);
                elem.parent().parent().removeClass('echecked');
            }
        });
    };
    function __showContactOverlay(email, opt) {
        html.widget.ContactDetailsUI.init(email, opt);
    };
    /*NOTE* eportResult - method used only on main screen */
    function exportResult(type, query) {
        var modal = html.modal.create(
            html.get('ExportDialog'),
            {
                size: 'emodal-sm',
                title: "Export",
                buttons: [{
                    name: ee.t.exportdata, css: "btn_primary btn_lg", callback: function () {
                        query.filename = (modal.$content.find('#efilename').val()).replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
                        query.fileformat = modal.$content.find('#efileformat').val();
                        query.limit = 0;
                        query.offset = 0;
                        query.compressionformat = (modal.$content.find('#ecompressed').prop('checked')) ? 'Zip' : 'None';
                        switch (type) {
                            case 'inprogress':
                                if (!query.filename) query.filename = 'InProgress';
                                ee.api.exportData(query, '/log/export', goToExports);
                                break;
                            case 'logs':
                                if (!query.filename) query.filename = 'Log';
                                ee.api.exportData(query, '/log/export', goToExports);
                                break;
                            case 'links':
                                if (!query.filename) query.filename = 'LinkTracking';
                                ee.api.exportData(query, '/log/exportlinktracking', goToExports);
                                break;
                            case 'contacts':
                                if (!query.filename) query.filename = query.link.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
                                ee.api.exportData(query, '/log/exportlinkcontacts', goToExports);
                                break;
                        }
                    }
                }]
            }
        );
    };
    function __selectAllON(jQueryElem) {
        jQueryElem.find('input').prop('checked', true);
        jQueryElem.find('tbody tr').addClass('echecked');
        jQueryElem.find('.eslcAll').addClass('on');
    };
    function __selectAllOFF(jQueryElem) {
        jQueryElem.find('input').prop('checked', false);
        jQueryElem.find('tbody tr').removeClass('echecked');
        jQueryElem.find('.eslcAll').removeClass('on');
    };
    //Filters
    /*NOTE* _timeSelect && _dataGraphFilter - methods used only on main screen */
    function _timeSelect(rangeval, screen, query) {
        const dateTo = new Date();
        const dateFrom = moment().startOf('day');
        if (ee.views.activity.isTimeZoneBehindGMT()) {
            dateTo = ee.views.activity.addExtraDayToDate(dateTo);
        }
        query.to = dateTo;
        screen.dateto.val(moment().format('L'))

        switch (rangeval) {
            case 'week':
                dateFrom.subtract(1, 'weeks');
                break;
            case 'day':
                dateFrom.subtract(1, 'days');
                break;
            case '2weeks':
                dateFrom.subtract(2, 'weeks');
                break;
            case 'month':
                dateFrom.subtract(1, 'months');
                break;
            case '3months':
                dateFrom.subtract(3, 'months');
                break;
            case 'halfyear':
                dateFrom.subtract(6, 'months');
                break;
            case 'year':
                dateFrom.subtract(1, 'year');
                break;
        };

        query.from = dateFrom;

        screen.datefrom.val(dateFrom.format('L'));

        $('#etimefromselect').prop('disabled', true).val('00:01');
        $('#etimetoselect').prop('disabled', true).val('23:59');
        changeDate(query, screen);
    };
    function _dataGraphFilter(screen, target, $this) {
        var $input = $this.find('input'),
            targetCheck = $(target).hasClass('edatacbox'),
            val = $input.val(),
            inputAttr = (targetCheck) ? !$input.prop('checked') : $input.prop('checked');
        if (inputAttr) {
            mainchart.hide(val);
            qualityChart.hide(val);
            screen.find('.egrid-' + val).hide();
            for (var i = 0; i < cache.datatoshow.length; i++) {
                if (cache.datatoshow[i] === val) {
                    cache.datatoshow.splice(i, 1);
                    break;
                }
            }
            screen.find('.estatscontainer[data-focus="' + val + '"]').addClass('off');
        } else {
            mainchart.show(val);
            qualityChart.show(val);
            if (cache.qualityAvarage === true) screen.find('.egrid-' + val).show();
            cache.datatoshow.push(val);
            screen.find('.estatscontainer[data-focus="' + val + '"]').removeClass('off');
        }

        if ($(target).hasClass('edatacbox')) {
            return;
        } else {
            $input.prop('checked', !$input.prop('checked'));
        }
        return false;
    };
    //Navigation
    function goToExports(query) {
        if (query) {
            delete query.filename;
            delete query.fileformat;
            delete query.compressionformat;
        }
        ee.goTo("#/exports");
    };
    //Query
    function _monthQuery() {

        var query = {
            interval: 'summary',
            channelid: 0
        };


        query.to = new Date();
        query.from = new Date();
        query.from.setTime((query.to.getTime() - 3.024e+9));

        if (ee.views.activity.isTimeZoneBehindGMT()) {
            query.to = ee.views.activity.addExtraDayToDate(query.to);
        }

        return query;
    };
    //Graphs Data
    function generateMainChartData(coll) {
        if (!coll) {
            coll = _.cloneDeep(SUMMARY);
        }
        const activityData = _.cloneDeep(coll);
        dataOBJ = {
            'Submitted': activityData.map('email'),
            'Delivered': activityData.map('delivered'),
            'Opened': activityData.map('opened'),
            'Clicked': activityData.map('clicked'),
            'Unsubscribed': activityData.map('unsubscribed'),
            'Bounced': activityData.map('bounced'),
            'Complaints': activityData.map('complaint'),
            'Suppressed': activityData.map('suppressed'),
        };
        dataOBJ.x = activityData.map('date');
            dataOBJ.x = _.map(dataOBJ.x, (date) => {
                let formatteddate = ee.tools.time(date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate("L HH:mm");//moment(date, `YYYY-MM-DD HH:mm`).format('L HH:mm');
                return formatteddate.replace(/\//g, '-');
            });
        return dataOBJ;
    };
    function generateCompareChartData(collOBJ) {
        const chartData = [
            ['x'].concat(collOBJ.map('name')),
            ['Delivered'].concat(collOBJ.map('deliveredPERC')),
            ['Opened'].concat(collOBJ.map('openedPERC')),
            ['Clicked'].concat(collOBJ.map('clickedPERC')),
            ['Bounced'].concat(collOBJ.map('bouncedPERC'))
        ];
        return chartData;
    };
    //Graphs
    function _mainChart(hourly) {
        var xFormat = `${ee.tools.localDateFormatGraph()} %H:%M`;
        mainchart = null;

        if (window.c3) {
            mainchart = c3.generate({
                bindto: '#egraph',
                padding: {
                    top: 30,
                    right: 70,
                    bottom: 20,
                    left: 70,
                },
                size: {
                    height: 500
                },
                legend: {
                    show: false
                },
                data: {
                    x: 'x',
                    hide: true,
                    xFormat: xFormat,
                    type: 'area',
                    json: generateMainChartData(),
                    colors: {
                        Submitted: '#66a3a3',
                        Delivered: '#0099ff',
                        Opened: '#008000',
                        Clicked: '#ffbb1c',
                        Unsubscribed: '#ffa200',
                        Bounced: '#ff0000',
                        Complaints: '#000000',
                        Suppressed: '#998d86',
                        DeliverRATIO: '#b6b70f'
                    },
                    axes: {
                        DeliverRATIO: 'y2'
                    },
                    types: {
                        DeliverRATIO: 'line'
                        //data2: 'area-spline'
                    }
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: " " + xFormat + "\xa0UTC ",
                        }
                    },
                    y2: {
                        show: true,
                        label: {
                            text: 'Quality',
                            position: 'outer-middle',
                            max: 100
                        },
                        max: 100,
                        min: 0,
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    }
                },
                zoom: {
                    enabled: true
                }
            });
        } else {
            html.Chart.init(function () { _mainChart(hourly) });
            return;
        }

        setTimeout(() => {
            if (mainchart) {
                mainchart.resize();
            }
        }, 100);


        mainchart.show(cache.datatoshow);
        showstats('#estatsshow');
    };
    //STATS
    function _calculateRatio() {
        this.deliverRATIO = (((+this.delivered) / (+this.email * 1)) * 100).toFixed(2);
        if (this.deliverRATIO === 'NaN' || this.deliverRATIO === 'Infinity') { this.deliverRATIO = null };
        this.openRATIO = (((+this.opened) / (+this.delivered)) * 100).toFixed(2);
        if (this.openRATIO === 'NaN' || this.openRATIO === 'Infinity') { this.openRATIO = null };
        this.clickRATIO = (((+this.clicked) / (+this.delivered)) * 100).toFixed(2);
        if (this.clickRATIO === 'NaN' || this.clickRATIO === 'Infinity') { this.clickRATIO = null };
        this.bounceRATIO = (((+this.bounced) / (+this.email)) * 100).toFixed(2);
        if (this.bounceRATIO === 'NaN' || this.bounceRATIO === 'Infinity') { this.bounceRATIO = null };
        this.suppressRATIO = (((+this.suppressed) / (+this.email)) * 100).toFixed(2);
        if (this.suppressRATIO === 'NaN' || this.suppressRATIO === 'Infinity') { this.suppressRATIO = null };
    };
    function showQualityStats(selector, obj) {
        var template = html.get('ReportsQualityStatsSection', obj), $obj = $(selector);
        if ($obj) $('.y-average').hide();

        cache.qualityAvarage = $("#eshowaverage").hasClass('on');

        $obj.empty();
        $obj.html(template);
        $.each(cache.datatoshow, function (i, el) {
            qualityChart.show(el);
            if (cache.qualityAvarage === true) $('.egrid-' + el).show();
            $('.estatscontainer[data-focus=' + el + ']').removeClass('off');
        });
    };
    function showstats(container) {
        var $container = $(container),
            statsdata = _getStats(SUMMARY, ['email', 'delivered', 'opened', 'clicked', 'unsubscribed', 'bounced', 'complaint', 'suppressed']),
            statstemplate = html.get('ReportsStatsSection', statsdata);
        $container.empty();
        statstemplate.appendTo($container);
        $container.find('.estatscontainer').addClass('off');
        for (var i = 0; i < cache.datatoshow.length; i++) {
            $container.find('.estatscontainer[data-focus="' + cache.datatoshow[i] + '"]').removeClass('off');
        };
    };
    function _getStats(CollOBJ, propArr, formatNUM) {
        var stats = {};
        for (var i = 0; i < propArr.length; i++) {
            stats[propArr[i]] = CollOBJ.sum(propArr[i]);
        }
        if (stats.email) {
            stats.emailPERC = '100.00';
            if (stats.opened !== undefined) {
                stats.openedPERC = stats.opened ? (stats.opened / stats.delivered * 100).toFixed(2) : 0;
                stats.opened = stats.opened.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.clicked !== undefined) {
                stats.clickedPERC = stats.clicked ? (stats.clicked / stats.delivered * 100).toFixed(2) : 0;
                stats.clicked = stats.clicked.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.bounced !== undefined) {
                stats.bouncedPERC = stats.bounced ? (stats.bounced / (stats.email - (stats.suppressed ? stats.suppressed : 0)) * 100).toFixed(2) : 0;
                stats.bounced = stats.bounced.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.unsubscribed !== undefined) {
                stats.unsubscribedPERC = stats.unsubscribed ? (stats.unsubscribed / stats.delivered * 100).toFixed(2) : 0;
                stats.unsubscribed = stats.unsubscribed.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.manualcancel !== undefined) {
                stats.manualcancelPERC = stats.manualcancel ? (stats.manualcancel / stats.email * 100).toFixed(2) : 0;
                stats.manualcancel = stats.manualcancel.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.suppressed !== undefined) {
                stats.suppressedPERC = stats.suppressed ? (stats.suppressed / stats.email * 100).toFixed(2) : 0;
                stats.suppressed = stats.suppressed.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.complaint !== undefined) {
                stats.complaintPERC = stats.complaint ? (stats.complaint / stats.delivered * 100).toFixed(2) : 0;
                stats.complaint = stats.complaint.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }
            if (stats.delivered !== undefined) {
                stats.deliveredPERC = stats.delivered ? (stats.delivered / stats.email * 100).toFixed(2) : 0;
                stats.delivered = stats.delivered.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
            }

            stats.email = stats.email.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        } else {
            stats.suppressedPERC = stats.emailPERC = stats.manualcancelPERC = stats.deliveredPERC = stats.openedPERC = stats.clickedPERC = stats.unsubscribedPERC = stats.bouncedPERC = stats.complaintPERC = '0.00'
        }
        return stats;
    };
    function showLiveStats(ratecontainer) {
        var delivered = ee.data.reports.delivered,
            template = html.get('LiveStatsRate', {
                SENDrate: delivered.sentrate.toFixed(1),
                inprogres: delivered.count.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator),
                rts: delivered.ready.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator),
                wtr: delivered.wtr.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator)
            });
        ratecontainer.empty();
        template.appendTo(ratecontainer);
    };
    //Time range
    function getDefaultDateTimeRange(channel) {
        let hour = 3600000;
        let day = 24 * hour;
        var daterange = { from: null, to: null };
        if (channel.triggertype > 0 && channel.triggertype <= 2 && channel.triggercount === 1) {
            //Regular or split campaign send one time.
            /* NOTE: after consultation with backedn we set from date on dateadded when campaign has trigerrtype on 1 and triggercount on 1*/
            /*
            triggertypes:
            SendNow	1
            FutureScheduled	2
            OnAdd	3
            OnOpen	4
            OnClick	5
            */
            daterange.from = ee.tools.createDateAsUTC((channel.triggertype === 1 && channel.triggercount === 1) ? new Date(channel.dateadded) : new Date(channel.triggerdate || channel.dateadded));
            daterange.to = ee.tools.createDateAsUTC(new Date());
            daterange.to.setTime(daterange.from.getTime() + (3 * day)); //72h later than base FROM date
            daterange.from.setTime(daterange.from.getTime() - (2 * hour)); //set FROM on graphs 2h before sending
        } else {
            //Repeat campaign, Automation and channels display total date range
            daterange.to = ee.tools.createDateAsUTC(new Date(channel.lastactivity || channel.dateadded));
            daterange.from = ee.tools.createDateAsUTC(new Date());
            daterange.from.setTime(daterange.to.getTime() - (35 * day));
            //Move time to end of day
            daterange.to.setDate(daterange.to.getDate() + 1);
            daterange.from.setDate(daterange.from.getDate() - 1);
        }
        return daterange;
    };

    function changeDate(query, screen, callback) {
        const hourly = query.interval === "hourly";
        const $automationName = $('#automationNameTitle');
        const $datepickerFrom = $('#edatefrom')
        const $datepickerTo = $('#edateto');
        const hourFrom = (hourly) ? moment($('#etimefromselect').val(), 'HH:mm') :  moment('00:00', 'HH:mm');
        const hourTo = (hourly) ? moment($('#etimetoselect').val(), 'HH:mm') :  moment('23:59', 'HH:mm');
        let specificTimeRange = $('#etimeselecttitle').text() === ee.t.specifictimerange;
        if (cache.dateinited) { return; }

        cache.dateinited = true;
        cache.titlename = screen.campaigntitle.html();

       



        //setting default dastes if the one from date range are missing
        const datesFromPickerInputs = {
            from: $datepickerFrom.datepicker('getDate') || moment().subtract(7,'days').toDate(),
            to: $datepickerTo.datepicker('getDate') || moment().toDate()
        }

        $('ecampaigntitle').html('<span>' + ee.t.loading + '</span>');
        const dateFrom = new moment(datesFromPickerInputs.from);
        const dateTo = new moment(datesFromPickerInputs.to);
        dateFrom.hour(hourFrom.hour()).minute(hourFrom.minute());
        dateTo.hour(hourTo.hour()).minute(hourTo.minute());

        if (dateFrom.isAfter(dateTo)) {
          
            screen.campaigntitle.html(cache.titlename);
            delete cache.dateinited;
            delete cache.titlename;

            return;
        }
       

        query = {
            ...query,
            from: specificTimeRange? dateFrom : query.from,
            to:  specificTimeRange? dateTo : query.to
        };

        if ($automationName.length) {
            const dates = {
                from: specificTimeRange? dateFrom : query.from,
                to: specificTimeRange? dateTo : query.to 
            }

            getAutomations($automationName.text(), dates)
        }

        //TODO: this need to be prmosified #PP
        ee.api.loadstats(query, function (data) {
            SUMMARY.update(data.data.dailylogstatussummary);
            SUMMARY.each(_calculateRatio);
            _mainChart(hourly);
            that.showQualityGraph();

            query = {
                ...query,
                from: dateFrom.format( `${ee.tools.localDateFormatGraph()} HH:mm`),
                to: dateTo.format(`${ee.tools.localDateFormatGraph()} HH:mm`)
            };
            screen.campaigntitle.html(cache.titlename);
            delete cache.dateinited;
            delete cache.titlename;
            if (callback) callback()
        });
    };

    function isMatchingTemplateId(channel1, channel2) {
        return channel1 && channel2 && channel1.templateid && channel2.templateid && (channel1.templateid === channel2.templateid);
    }
    //NOTE: showdata used only on single Campaign report
    function showdata(container, obj) {
        let splitvalues = 'abcdefghijklmnoprstwx';
        let tempchan = obj.templatechannels;
        let datatemplate;
        let enableippools = (!ee.data.account.issub || (ee.data.account.issub && ee.data.account.enableprivateiprequest)) && ee.data.account.mailerid > 0;
        let splitSTR;

        const isAbSplit = tempchan.length > 1;
        const winnerChannelIndex = _.findIndex(tempchan, (channel) => channel.splitwinner);
        const winnerChannel = winnerChannelIndex >= 0 && tempchan[winnerChannelIndex];
        const isOldAbSplit = isAbSplit && winnerChannel && !_.isNil(winnerChannel.templateid) && (_.reduce(tempchan, (sum, channel) => channel.templateid === winnerChannel.templateid ? sum + 1 : sum, 0) === 1);

        if (isAbSplit && !isOldAbSplit) {
            tempchan.splice(winnerChannelIndex, 1);
        }

        if (tempchan.length > 0) tempchan[0].active = true;
        if (isAbSplit) obj.split = true;

        if (obj.split) {

            if (obj.splitoptimization) {
                splitSTR = "clickedPERC";
            } else {
                splitSTR = "openedPERC";
            }

        }
        for (var i = 0; i < tempchan.length; i++) {
            tempchan[i].splitChar = splitvalues.charAt(i);
            tempchan[i].enableippools = enableippools;

            if (tempchan[i].recipientcount) {
                if (isAbSplit && !isOldAbSplit && isMatchingTemplateId(tempchan[i], winnerChannel)) {
                    tempchan[i].sentcount += winnerChannel.sentcount;
                    tempchan[i].recipientcount += winnerChannel.recipientcount;
                    tempchan[i].openedcount += winnerChannel.openedcount;
                    tempchan[i].clickedcount += winnerChannel.clickedcount;
                }

                tempchan[i].deliveredPERC = (tempchan[i].sentcount / tempchan[i].recipientcount * 100).toFixed(2);
                //this.deliveredClass = colorClass(this.deliveredPERC, 'delivered');
                tempchan[i].openedPERC = (tempchan[i].openedcount / tempchan[i].recipientcount * 100).toFixed(2);
                //this.openedClass = colorClass(this.openedPERC, 'opened');
                tempchan[i].clickedPERC = (tempchan[i].clickedcount / tempchan[i].recipientcount * 100).toFixed(2);
            } else {
                tempchan[i].deliveredPERC = tempchan[i].openedPERC = tempchan[i].clickedPERC = '0.00';
                tempchan[i].deliveredClass = tempchan[i].openedClass = tempchan[i].clickedClass = 'eterrible';
            }
            tempchan[i].template = ee.views.templates.getTemplate(tempchan[i].templateid);

            switch (tempchan[i].status) {
                case -1:
                    tempchan[i].statusSTR = ee.t.deleted;
                    break;
                case 0:
                    tempchan[i].statusSTR = ee.t.active;
                    break;
                case 1:
                    tempchan[i].statusSTR = ee.t.processing;
                    break;
                case 2:
                    tempchan[i].statusSTR = ee.t.sending;
                    break;
                case 3:
                    tempchan[i].statusSTR = ee.t.completed;
                    break;
                case 4:
                    tempchan[i].statusSTR = ee.t.paused;
                    break;
                case 5:
                    tempchan[i].statusSTR = ee.t.canceled;
                    break;
                case 6:
                    tempchan[i].statusSTR = ee.t.draft;
                    break;
            }
        }

        if (isAbSplit && winnerChannel) {
            for (var i = 0; i < tempchan.length; i++) {
                if (!isOldAbSplit && tempchan[i].templateid === winnerChannel.templateid) {
                    tempchan[i].winning = true;
                } else if (isOldAbSplit && tempchan[i].splitwinner) {
                    tempchan[i].winning = true;
                }
            }
        }

        datatemplate = html.get('ReportsSingleCampaignData', obj);
        html.widget.TemplateThumbnail.init(datatemplate);
        datatemplate.appendTo(container);
    };
    function _viewDetailsOnHightResolutions(logModel) {
        var msgid = logModel.getProperty("msgid"),
            apiurl = ee.api.apiurl,
            buttons = [];

        if (ee.session.can.AdminView) {
            //Save Suspicious Message
            buttons.push({
                name: "Save Suspicious Message",
                css: "btn_md_basic btn_basic pull-left",
                callback: function () {
                    logModel.saveSuspiciousMessage(function () { html.modal.info("Suspicious message has been saved."); });
                }
            });
            //Show Spam Check
            buttons.push({
                name: ee.t.showspamcheck,
                css: "btn_md_basic btn_basic pull-left",
                callback: function () { window.open(apiurl + '/v2/admin/spamcheck?msgid=' + msgid + '&api_key=' + (ee.session.isInspection() ? store.session('adminkey') : store.session('key')), "_blank"); }
            });
        }

        //Email headres
        buttons.push({
            name: ee.t.emailheaders,
            css: "btn_md_basic btn_basic pull-left",
            callback: function () {
                logModel.loadHeaders(function (headers) {
                    html.modal.create('<pre>' + headers + '</pre>', { title: ee.t.emailheaders, size: "emodal-lg" });
                })
            }
        });

        //View in new window
        buttons.push({
            name: ee.t.viewinnewwindow,
            css: "btn_md_basic btn_basic pull-left",
            callback: function () { window.open(apiurl + '/view?notracking=true&msgid=' + msgid + ((ee.session.can.AdminView === true) ? "&nosecuritycheck=true" : ""), "_blank"); }
        });
        return buttons;
    };
    function _viewDetailsOnLowResolutions(template, logModel) {
        var msgid = logModel.getProperty("msgid"),
            apiurl = ee.api.apiurl;

        template.selectviewemailoptions.on("change", function () {
            var value = $(this).val();

            switch (value) {
                case '1':
                    logModel.saveSuspiciousMessage(function () { html.modal.info("Suspicious message has been saved."); });
                    break;
                case '2':
                    window.open(apiurl + '/v2/admin/spamcheck?msgid=' + msgid + '&api_key=' + (ee.session.isInspection() ? store.session('adminkey') : store.session('key')), "_blank");
                    break;
                case '3':
                    logModel.loadHeaders(function (headers) {
                        html.modal.create('<pre>' + headers + '</pre>', { title: ee.t.emailheaders, size: "emodal-lg" });
                    })
                    break;
                case '4':
                    window.open(apiurl + '/view?notracking=true&msgid=' + msgid + ((ee.session.can.AdminView === true) ? "&nosecuritycheck=true" : ""), "_blank");
                    break;
            }
            template.selectviewemailoptions.val("0");
        });

        template.emaildetailsbtn.on("click", function () {
            if (template.emaildetails.hasClass('hide')) {
                template.emaildetails.removeClass('hide');
                template.emaildetailsbtn.text(ee.t.hidedetails);
            } else {
                template.emaildetails.addClass('hide');
                template.emaildetailsbtn.text(ee.t.showdetails);
            }
        });
    };
    function createUnsubscribeReasonsChart(data) {
        html.Chart.init(function () {

            var chart = c3.generate({
                bindto: '#chart',
                data: {
                    columns: [
                        [ee.t.unknown, data.unknown],
                        [ee.t.listunsubscribeheader, data.listunsubscribe],
                        [ee.t.thirdpartyunsubscribe, data.thirdparty],
                        [ee.t.nolongerwant, data.nolongerwant],
                        [ee.t.irrelevantcontent, data.irrelevantcontent],
                        [ee.t.toofrequent, data.toofrequent],
                        [ee.t.neverconsented, data.neverconsented],
                        [ee.t.deceptivecontent, data.deceptivecontent],
                        [ee.t.externalabusereport, data.abusereported]
                    ],
                    type: 'donut',
                },
                legend: {
                    show: false
                }
            });

            $('.legend-lielem').empty();
            d3.select('.container_chart .legend-lielem').selectAll('li')
                .data([
                    { id: ee.t.unknown, name: ee.t.unknown, count: data.unknown },
                    { id: ee.t.listunsubscribeheader, name: ee.t.listunsubscribeheader, count: data.listunsubscribe },
                    { id: ee.t.thirdpartyunsubscribe, name: ee.t.thirdpartyunsubscribe, count: data.thirdparty },
                    { id: ee.t.nolongerwant, name: ee.t.nolongerwant, count: data.nolongerwant },
                    { id: ee.t.irrelevantcontent, name: ee.t.irrelevantcontent, count: data.irrelevantcontent },
                    { id: ee.t.toofrequent, name: ee.t.toofrequent, count: data.toofrequent },
                    { id: ee.t.neverconsented, name: ee.t.neverconsented, count: data.neverconsented },
                    { id: ee.t.deceptivecontent, name: ee.t.deceptivecontent, count: data.deceptivecontent },
                    { id: ee.t.externalabusereport, name: ee.t.externalabusereport, count: data.abusereported }
                ])
                .enter()
                .append('li')
                .attr('class', 'item')
                .attr('data-id', function (id) { return id.id; })
                .each(function (id) {

                    d3.select(this)
                        .insert('span')
                        .attr('class', 'color')
                        .style('background-color', chart.color(id.id));
                    d3.select(this)
                        .insert('span')
                        .attr('class', 'title')
                        .html(id.name);
                    d3.select(this)
                        .insert('span')
                        .attr('class', 'count')
                        .html(id.count);
                })
                .on('mouseover', function (id) {
                    chart.focus(id.id);
                })
                .on('mouseout', function (id) {
                    chart.revert();
                });
        })
    };
    function loadUnsubscribeReasonsData(query, screen) {
        ee.indiOn();
        EE_API.Contact.CountByUnsubscribeReason(query)
            .then(function (data) {
                var i = 0;

                $.each(data, function (key, val) {
                    if (val !== 0) i++;
                });
                if (i > 0) {
                    $('.shownoresult').addClass('hide');
                    screen.container_chart.removeClass('hide');
                    createUnsubscribeReasonsChart(data);
                }
                else {
                    screen.container_chart.addClass('hide');
                    $('.shownoresult').removeClass('hide');
                }
                ee.indiOff();
            }).catch(function (err) {
                html.modal.error(err);
            });
            morelinks();
    };

    function getAutomationDates(jounreys) {

        let from = moment().endOf('day');
        const to = moment().startOf('day');

        _.forEach(jounreys, (journey) => {
            const dateAdded = moment(journey.dateadded);

            if (!dateAdded.isBefore(from)) { return; }

            from = dateAdded;
        });
        return {
            from: from.format('YYYY-MM-DD HH:mm'),
            to: to.format('YYYY-MM-DD HH:mm')
        }
    };

    function automationsSummedData(obj, dates) {
        const intervals = dates || getAutomationDates(obj);
        const requests = [];
        const query = {
            interval: 'summary',
            ...intervals
        }
        _.forEach(obj, (o) => {
            query.channelid = o.channelid;
            requests.push(EE_API.Log.GetDailyStats(query))
        });

        return requests;
    };

    function getAutomations(selectedAutomation, dates) {
        const channelsArray = [];

        return API_V3.Journeys.GetJourneySummary(selectedAutomation)
        .then(function(data) {
            _.forEach(data.StepsSummary, (el) => {
                if (!el.Channel) { return; }

                const channel = _.find(ee.data.channels.data, (o) => { return el.Channel === o.name });
                channelsArray.push(channel);
            });

            const requests = automationsSummedData(channelsArray, dates);

            return Promise.all(requests)
            .then(function(summaries) {
                summaries = _.flatMap(summaries, (o) => { return o.dailylogstatussummary; });
                const parsedData = _(summaries)
                .groupBy('date')
                .map((o, date) => {
                    const keys = Object.keys(o[0]).filter((o) => o !== 'date');
                    const result = {};

                    _.forEach(keys, (key) => { result[key] = _.sumBy(o, key) });

                    result.suppressed = result.notdelivered;
                    result['date'] = date;

                    return result
                })
                .value();

                SUMMARY.update(parsedData);
                SUMMARY.each(_calculateRatio);
                _mainChart();

                const dateTo = (dates) ? dates.to : mainGraphRef.to;
                const dateFrom = (dates) ? dates.from : mainGraphRef.from;

                $('#edateto').datepicker('setDate', new Date(dateTo));
                $('#edatefrom').datepicker('setDate', new Date(dateFrom));
                $('#etimefromselect').prop('disabled', true).val('00:01');
                $('#etimetoselect').prop('disabled', true).val('23:59');

                $('#ecampaigntitle').html(`<span id="automationNameTitle">${selectedAutomation}</span>`);
            })
            .catch(err => {
                console.error(err);
            })
        })
        .catch(function(err) {
            console.error(err);
        });
    };

    let mainGraphRef = null;

    var that = {
        show: function () {
            ee.indiOn();

            const that = this;
            //init some private variables for all screens
            if (!goMain) goMain = function () { ee.goTo(mainlocation) };

            //Make data space
            if (!ee.data.reports) {
                cache = {};
                ee.data.reports = {};
            }
            //not load c3 lib & template list & campaignlist when on inprogress
            if (ee.Router.action() === 'progress') { return that.showProgress(); }
            if (!ee.data.channels && (ee.session.can.ViewCampaigns || ee.session.can.ViewChannels)) {
                return ee.api.loadcampaigns();
            }

            CHANNELS = ee.data.channels;

            let breadcrumb = {}
            breadcrumb.url = "#/activity";
            breadcrumb.name = ee.t.activity;
            switch (ee.Router.action()) {
                case 'emails':
                    ee.logEvent('Activity - show emails.');
                    that.showEmails({breadcrumb});
                    return false;
                case 'ipreport':
                    if (!ee.data.account.issub && ee.data.account.mailerid > 0) {
                        ee.logEvent('Activity - show ip reports.');
                        that.showIPreports({breadcrumb});
                        return false;
                    }
                    break;
                case 'bouncedetails':
                    ee.logEvent('Activity - bounced emails.');
                    that.showEmails({breadcrumb});
                    return false;
                case 'notifications':
                    if (!ee.session.can.ViewReports) {
                        ee.goTo('#/activity')
                    } else {
                        ee.logEvent('Activity - show notifications.');
                        that.showEmails({ breadcrumb, page: 'ReportsNotificationScreen', template: 'ReportsNotificationLog' });
                        return false;
                    }
                    break;
                case 'unsubscribereasons':
                    ee.logEvent('Activity - show unsubscribe reasons.');
                    that.showUnsubscribeReasons({ breadcrumb, page: 'ReportsUnsubscribeReasonsScreen', template: 'ReportsUnsubscribeReasonsScreen' });
                    return false;
                case 'geography':
                case 'devicedetails':
                    if (ee.session.can.ViewReports) {
                        ee.initNextGen();
                    } else {
                        ee.goTo('#/activity')
                    }
                    break;
                case "report":
                    const channelname = ee.Router.parameter('node0')
                    let channel = CHANNELS.get('name', decodeURIComponent(channelname));
                    return that.showCampaign(channel.channelid, breadcrumb);
                default:
                    breadcrumb.url = "#/" + ee.Router.prevPath();
                    switch (ee.Router.prevPath()) {
                        case "activity/campaigns":
                            breadcrumb.name = ee.t.campaigns;
                            break;
                        case "activity/channels":
                            breadcrumb.name = ee.t.channels;
                            break;
                        case "activity/automation":
                            breadcrumb.name = ee.t.automation;
                            break;
                        case "activity/autoresponder":
                            breadcrumb.name = ee.t.autoresponder;
                            break;
                        case "campaigns/drafts":
                            breadcrumb.name = ee.t.draft;
                            break;
                        default:
                            breadcrumb.url = "#/activity"
                            breadcrumb.name = ee.t.activity;
                            break;
                    }
                    var channelid = parseInt(ee.Router.action(), 10);
                    //Show channel details
                    if (isNaN(channelid) === false && CHANNELS.get('channelid', channelid)) {
                        that.showCampaign(channelid, breadcrumb);
                        return;
                    }
                        //ee.changeHashQuietly('#/activity');
                    if(ee.isAutomationsEnabled() &&  _.isEmpty(allJourneysList)) {
                        return API_V3.Journeys.GetJourneysList()
                        .then(function(data) {
                            allJourneysList = data;
                            ee.logEvent('Activity screen loaded.');
                            that.showMain({breadcrumb});
                        })
                        .catch(function(err) {
                            console.error(err);
                        })
                    }

                    ee.logEvent('Activity screen loaded.');
                    that.showMain({breadcrumb});


            }
        },
        setupMenuItemsLinkTrackingOnClick: (row) => {
            dropdownMenuLinkTrackingIsClicked = true;
            if (!row) {return;}
            $(row.parentElement).off('click');
            $(row.parentElement).on('click', '.eexportlinkcontacts', (e) => {
                dropdownMenuLinkTrackingIsClicked = false;
                exportResult('contacts', $.extend({ link: $(e.target).data('link') }, linkTrakingQuery));
            });
        },
        setupMenuItemsOnClick: (row, channelId) => {
            const Links = dataModel.create("Links");
            
            Links.query.from = ee.tools.time($('#edatefrom').val()).toDate();
            Links.query.to = ee.tools.time($('#edateto').val()).toDate();
            //Links Pagination Events
            dropdownMenuIsClicked = true;
            if (!row) {return;}
            $(row.parentElement).off('click');
            $(row.parentElement).on('click', '.eexportlinkcontacts', (e) => {
                dropdownMenuIsClicked = false;
                var link = $(e.target).data('link');
                _showExportModal({
                    filename: link.replace(/[^a-zA-Z0-9]/g, '_'),
                    title: 'Links'
                }, function (modal, content, value) {
                    ee.indiOn();
                    Links.exportContact({
                        link: link,
                        channelid: channelId,
                        filename: value.filename,
                        fileFormat: value.fileFormat,
                        compressionFormat: value.compressionFormat
                    }).then(function () {
                        ee.goTo('#/exports');
                    });
                })
            });
            $(row.parentElement).on('click', '.eview', (e) => {
                dropdownMenuIsClicked = false;
                var linkQuery = {
                    link: $(e.target).data('link'),
                    channelID: channelId,
                    from: ee.tools.time(Links.getDateFrom()).toUTC(),//ee.api.formatDate(Links.getDateFrom(), '', true),
                    to: ee.tools.time(Links.getDateTo()).toUTC()//ee.api.formatDate(Links.getDateTo(), '', true)
                };

                ee.api.loadLinkContacts(linkQuery, function (data) {
                    if (!data.data.contacts.length) return html.modal.info(ee.t.nologsforlinktracking);
                    var temp = html.get('ReportsContactsClickModal', {}),
                        modal = html.modal.create(temp, { title: ee.t.overallcontactsclicks }),
                        pagination = new html.Pagination(data.data.contacts, {
                            template: 'ReportsContactsClickModalResult',
                            resultcontainer: '#econtactscliks',
                            footercontainer: '#econtactscliksfooter',
                            perpage: 10
                        });
                    modal.$content.on('click', '.eview', function () {
                        var email = $(this).data('email');
                        modal.hide();
                        __showContactOverlay(email, {
                            beforeClose: function () { modal.show() }
                        });
                    })

                });
            });
        },
        showIPreports: function () {
            if (!ee.data.account.issub && ee.data.account.mailerid < 0) return that.showMain();
            var $doc = ee.frame.content,
                $screen = html.get('ReportIP'),
                inprogress,
                ips, pagination;

            $screen.appendTo($doc);

            pagination = new html.Pagination([], {
                resultcontainer: '#eipreports',
                template: 'PREMIUMipManage'
            });

            ee.indiOff();

            inprogress = true;
            var initialDate = new Date();
            initialDate.setDate(initialDate.getDate() - 30);
            ee.api.request('/pool/ipsearch', { from: ee.api.formatDate(initialDate, '', true).substr(0, 10) }, function (data) {
                if (!data.success) return html.modal.error(data);
                inprogress = false;
                ips = new Collection.Data(data.data);
                ips.each(function () {
                    __getIPsRate(this);

                    var domains = new Collection.Data(this.domains);
                    domains.each(function () {
                        __getIPsRate(this);
                    })
                })
                pagination.goTo(1);
                pagination.updateData(ips.getAll())
            }, undefined, undefined, undefined, undefined, true);

            $screen.timeselect.on('click', '.erangeelem', function () {
                if (inprogress) return false;
                inprogress = true;
                var timerange = $(this).data('range'),
                    text = $(this).text(),
                    query = {},
                    overall = false,
                    to = new Date(), from = new Date(),
                    toSTR = ee.api.formatDate(to, '', true).substr(0, 10), fromSTR;

                switch (timerange) {
                    case "overall":
                        overall = true;
                        break;
                    case '24h':
                        from.setDate(to.getDate() - 1);
                        break;
                    case 'week':
                        from.setDate(to.getDate() - 7);
                        break;
                    case '2weeks':
                        from.setDate(to.getDate() - 14);
                        break;
                    case 'month':
                        from.setDate(to.getDate() - 30);
                        break;
                }
                if (!overall) {
                    fromSTR = ee.api.formatDate(from, '', true).substr(0, 10);
                    pagination.updateData([]);
                    query = { to: toSTR, from: fromSTR };
                }
                ee.api.request('/pool/ipsearch', query, function (data) {
                    if (!data.success) return html.modal.error(data);
                    inprogress = false;
                    ips = new Collection.Data(data.data);
                    ips.each(function () {
                        __getIPsRate(this);

                        var domains = new Collection.Data(this.domains);
                        domains.each(function () {
                            __getIPsRate(this);
                        })
                    })
                    pagination.goTo(1);
                    pagination.updateData(ips.getAll())
                    $screen.title.text(text);
                }, undefined, undefined, undefined, undefined, true);
            })

            //utilities
            function __getIPsRate(obj) {
                if (obj.sentcount && obj.recipientcount) {
                    obj.deliveryrate = ((obj.sentcount / obj.recipientcount) * 100).toFixed(2);
                    obj.openedrate = ((obj.openedcount / obj.sentcount) * 100).toFixed(2);
                    obj.clickedrate = ((obj.clickedcount / obj.sentcount) * 100).toFixed(2);
                } else {
                    obj.clickedrate = obj.openedrate = obj.deliveryrate = '0.00';
                }
            }
        },

        isTimeZoneBehindGMT: () => {
            const today = moment();
            return today.utc().isAfter(today, 'day');
        },

        addExtraDayToDate: (date) => {
            return new moment(date.getTime()).add(1, 'day').toDate();
        },

        mainGraph: null,

        showMain: function () {
            const doc = ee.frame.content;
            const that = this;
            let screen = {};

            //get data
            let maingraphQuery = _monthQuery();
            that.mainGraph = maingraphQuery;
            mainGraphRef = maingraphQuery;

            if (ee.base && window.hj) { window.hj('trigger', 'reports'); }

            if (!cache.monthsummary) {
                ee.api.loadstats(maingraphQuery, function (data) {
                    cache.datastats = data;
                    cache.bouncecategory = data.data.bouncedcategorysummary;
                    cache.monthsummary = data.data.dailylogstatussummary;
                    that.showMain();
                });
                return false;
            }


            //append screen
            SUMMARY = new Collection.Data(cache.monthsummary);
            SUMMARY.each(_calculateRatio);
            checkChannelLists();

            channellist = cache.channellist;
            autoresend = cache.autoresend;
            campaignlist = cache.campaignlist;
            doc.empty();
            screen = html.get('ReportsMainTemplate', {
                admin: ee.session.can.AdminView,
                lnkon: ee.data.account.linktracking,
                channels: channellist ? channellist.getAll() : false,

                autoresend: autoresend ? autoresend.getAll() : false,

                campaigns:  campaignlist ? campaignlist.getAll() : false,

                allJourneysList: allJourneysList ? allJourneysList : false,

                enablecontactfeatures: ee.data.account.enablecontactfeatures
            });
            screen.appendTo(doc);

            if (!cache.datatoshow) cache.datatoshow = ['Submitted', 'Delivered', 'Opened', 'Clicked', 'Unsubscribed', 'Bounced', 'Complaints'];

            ee.indiOff();
            
            ee.api.livestats(function (data) {
                if (data.count > 0) screen.goprogress.append('<span class="badge ereportsbadge">' + data.count.numberFormat() + '</span>');
            }, true);

            _bindSummaryDropdown(screen.find('.tab-dropdown'));
            _manageGraphsFiltersDisplay(screen);
            _manageMainFilters(screen.maintablist);
            if (location.hash === '#/activity') {
                $('#esearchcampaign').hide();
            } else {
                $('#esearchcampaign').show();
            }
            $('#emaintablist li').on('click', (e) => {
                if (e.target.id === 'summarytab-button' || e.target.firstChild.id === 'summarytab-button' || $(e.target).hasClass('summary-dropdown-option')) {
                    $('#esearchcampaign').hide();
                } else {
                    $('#esearchcampaign').show();
                }
            })
            screen.goemails.on('click', function () { window.location.hash = mainlocation + '/emails' });
            screen.gobouncedetails.on('click', function () {window.location.hash = mainlocation + '/bouncedetails' });
            if (screen.gonotificationlog)
                screen.gonotificationlog.on('click', function () { window.location.hash = mainlocation + '/notifications' });
            screen.gounsubscribereasons.on('click', function () { window.location.hash = mainlocation + '/unsubscribereasons' });
            screen.find('.eshowprogress').on('click', function () { window.location.hash = mainlocation + '/progress' });
            screen.goexports.on('click', function () { ee.goTo('#/exports') });

            $('#egogeography').on('click', () => { ee.goTo('#/activity/geography'); });
            $('#egodevicedetials').on('click', () => { ee.goTo('#/activity/devicedetails') } );

            screen.maintablist.on('click', 'li.tabview', function (e) {
                var $this = $(this), hash = $this.data('hash');
                ee.Router.updateHash(mainlocation + '/' + hash, !e.originalEvent);
            });

            //check if router contains information about one of tabs and params
            if (ee.Router.action()) { screen.maintablist.find('li[data-hash="' + ee.Router.action() + '"] a').trigger('click'); }
            //Quantity graph filters
            screen.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", maingraphQuery.from).change(function (e) {
                changeDate(maingraphQuery, screen)
            });
            screen.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", maingraphQuery.to).change(function () {
                changeDate(maingraphQuery, screen);
            });

            $('#edateto')[0].value = moment(maingraphQuery.to).format('L');
            $('#edatefrom')[0].value = moment(maingraphQuery.from).format('L');

            screen.timefromselect.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    changeDate(maingraphQuery, screen);
                    screen.timefromselect.blur();
                } else { return false };
            });
            screen.timetoselect.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    changeDate(maingraphQuery, screen);
                    screen.timetoselect.blur();
                } else { return false };
            });
            screen.timeselect.on('click', 'li.drop-first', function () {
                screen.timeselecttitle.html($(this).html());
                screen.fromto.removeClass("hide").css("display", "inline-block");
            });
            screen.timeselect.on('click', 'li:not(.drop-first)', function () {
                screen.timeselecttitle.html($(this).html());
                screen.fromto.addClass("hide").css("display", "none");
                _timeSelect($(this).data('range'), screen, maingraphQuery);
            });
            screen.graphdropdwn.on('click', function () {
                var inputs = screen.datagraphselect.find('input'),
                    input, statuses = cache.datatoshow;

                inputs.prop('checked', false);

                for (var i = 0; i < statuses.length; i++) {
                    input = screen.datagraphselect.find('input[value="' + statuses[i] + '"]');
                    input.prop('checked', true);
                }
            });
            screen.refreshBtn.on("click", function () {
                delete ee.data.channels;
                delete cache.monthsummary;
                delete ee.data.logs;
                that.show();

                var cmpLeng = cmptable.length,
                    chanLeng = chantable.length,
                    autoLeng = autotable.length;

                if (cmpLeng) {
                    __clearTableInputs(cmptable);
                    cmptable = [];
                    __selectAllOFF($('#ecampaignstable'));
                    cmpsAll = false;
                };
                if (chanLeng) {
                    __clearTableInputs(chantable);
                    chantable = [];
                    __selectAllOFF($('#echannelstable'));
                    $('#echannelstable').find('input').prop('checked', false);
                    chanAll = false;
                };
                if (autoLeng) {
                    __clearTableInputs(autotable);
                    autotable = [];
                    __selectAllOFF($('#eautotable'));
                    autoAll = false;
                };
                if (journeyTable.length) {
                    __clearTableInputs(journeyTable);
                    journeyTable = [];
                    __selectAllOFF($('#ejourneytable'));
                    journeyAll = false;
                };
                screen.cboxnavi.trigger('input-checked');
            });
            screen.qualitycontainer.on('click', '#eshowaverage', function () {
                var $this = $(this);
                if ($this.hasClass('on')) {
                    $this.removeClass('on');
                    cache.qualityAvarage = false;
                    $('.c3-ygrid-line').hide();
                } else {
                    $this.addClass('on');
                    cache.qualityAvarage = true;
                    screen.statsqualityshow.find('.estatscontainer:not(.off)').each(function () {
                        var stat = $(this).data('focus');
                        $('.egrid-' + stat).show();
                    });

                }

            })
            screen.datagraphselect.on('click', 'li:not(.drop-first)', function (e) {
                return _dataGraphFilter(screen, e.target, $(this));
            });
            screen.on('mouseenter', '.estatscontainer', function () {
                var target = $(this).data('focus');
                if ($(this).data('chart') === 'quality')
                    qualityChart.focus(target);
                else
                    mainchart.focus(target);
            });
            screen.on('mouseenter', '.estatscontainer.off', function () {

                if ($(this).data('chart') === 'quality')
                    qualityChart.revert();
                else
                    mainchart.revert();
            });
            screen.on('mouseleave', '.estatscontainer', function () {
                if ($(this).data('chart') === 'quality')
                    qualityChart.revert();
                else
                    mainchart.revert();
            });
            screen.on('click', '.estatscontainer', function () {
                var $this = $(this),
                    target = $this.data('focus');
                if ($this.hasClass('off')) {
                    mainchart.show(target);
                    mainchart.focus(target);
                    qualityChart.show(target);
                    if (cache.qualityAvarage === true) screen.find('.egrid-' + target).show();
                    $('.estatscontainer[data-focus=' + target + ']').removeClass('off');
                    cache.datatoshow.push(target);
                } else {
                    mainchart.hide(target);
                    mainchart.revert();
                    qualityChart.hide(target);
                    screen.find('.egrid-' + target).hide();
                    $('.estatscontainer[data-focus=' + target + ']').addClass('off');
                    for (var i = 0; i < cache.datatoshow.length; i++) {
                        if (cache.datatoshow[i] === target) { cache.datatoshow.splice(i, 1); break; }
                    };
                }
            });
            screen.find('.eallsent').on('click', function () {
                if (!maingraphQuery.on) {
                    maingraphQuery = _monthQuery();
                    maingraphQuery.on = true;
                    ee.api.loadstats(maingraphQuery, function (data) {
                        cache.datastats = data;
                        cache.monthsummary = data.data.dailylogstatussummary;
                        SUMMARY.update(data.data.dailylogstatussummary);
                        SUMMARY.each(_calculateRatio);
                        _mainChart();
                        that.showQualityGraph();
                        screen.dateto.datepicker('setDate', new Date(maingraphQuery.to));
                        screen.datefrom.datepicker('setDate', new Date(maingraphQuery.from));
                        $('#etimefromselect').prop('disabled', true).val('00:01');
                        $('#etimetoselect').prop('disabled', true).val('23:59');
                        screen.campaigntitle.html('<span>' + ee.t.ovractivity + '</span>');
                        delete maingraphQuery.on;
                    });
                } else { return false; }
            });
            screen.campaignselect.on('click', 'li:not(.drop-first)', function () {

                graphType = 'campaign';

                if (!maingraphQuery.on) {
                    var obj = CHANNELS.get('channelid', $(this).data('channelid'));

                    screen.campaigntitle.html('<span>' + ee.t.loading + '</span>');

                    maingraphQuery.interval = 'hourly';
                    maingraphQuery.channelid = obj.channelid;
                    maingraphQuery.from = (obj.triggertype === 1 && obj.triggercount === 1) ? new Date(obj.triggerdate || obj.dateadded) : new Date(obj.lastactivity || obj.dateadded);
                    maingraphQuery.to = new Date();
                    maingraphQuery.to.setTime(moment().toDate());
                    maingraphQuery.from.setTime(moment(maingraphQuery.from).toDate());
                    maingraphQuery.on = true;

                    ee.api.loadstats(maingraphQuery, function (data) {
                        var last;
                        cache.datastats = data;
                        SUMMARY.update(data.data.dailylogstatussummary);
                        SUMMARY.each(_calculateRatio);
                        _mainChart(maingraphQuery.interval);
                        last = SUMMARY.data.length - 1;
                        screen.dateto.datepicker('setDate', new Date(maingraphQuery.to));
                        screen.datefrom.datepicker('setDate', new Date(maingraphQuery.from));
                        $('#etimefromselect').prop('disabled', false).val(ee.tools.time(SUMMARY.data[0].date).toDate('LT'));
                        $('#etimetoselect').prop('disabled', false).val(ee.tools.time(SUMMARY.data[last].date).toDate('LT'));
                        screen.campaigntitle.html('<span>' + obj.name + '</span>');
                        screen.channeltitle.html('<span>' + ee.t.ovractivity + '</span>');
                        delete maingraphQuery.on;
                    });
                }
            });

            if (screen.dateselect_cmp) {
                screen.dateselect_cmp.on("click", "li", function () {
                    const range = $(this).data("range");
                    let tmpData;

                    const to = moment().endOf('day');
                    const from = moment().startOf('day');
                    $("#edaterange_btn").text($(this).text());


                    switch (range) {
                        case "day":
                            from.subtract(1, 'days');
                            break;
                        case "week":
                            from.subtract(1, 'weeks');
                            break;
                        case "2weeks":
                            from.subtract(2, 'weeks');
                            break;
                        case "month":
                            from.subtract(1, 'months');
                            break;
                        case "3months":
                            from.subtract(3, 'months');
                            break;
                        case "halfyear":
                            from.subtract(6, 'months');
                            break;


                    }

                    if (screen.searchinputcampaign.val()) {
                        tmpData = cache.campaignlist.filter(['name'], screen.searchinputcampaign.val());
                    } else {
                        tmpData = cache.campaignlist;
                    }


                    if (range === "all") {

                        campaignlist = tmpData;

                    } else {
                        campaignlist = tmpData.filterBetweenDate('dateTime', from.toDate(), to.toDate());
                    }


                    CMPpagination.update(campaignlist.getAll());
                    html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);
                })
            }

            $('#journeyselect li:not(.drop-first)').on('click', function() {
                getAutomations($(this).data('journeyname'));
            });

            screen.channelselect.on('click', 'li:not(.drop-first)', function () {

                graphType = 'channel';

                if (!maingraphQuery.on) {
                    var obj = CHANNELS.get('channelid', $(this).data('channelid'));
                    screen.campaigntitle.html('<span>' + ee.t.loading + '</span>');

                    maingraphQuery.interval = "summary";
                    maingraphQuery.channelid = obj.channelid;
                    maingraphQuery.to = (obj.lastactivity) ? new Date(obj.lastactivity) : new Date();
                    maingraphQuery.from = new Date();
                    maingraphQuery.from.setTime(maingraphQuery.to.getTime() - 3.024e+9);
                    maingraphQuery.on = true;
                    ee.api.loadstats(maingraphQuery, function (data) {
                        cache.datastats = data;
                        SUMMARY.update(data.data.dailylogstatussummary);
                        SUMMARY.each(_calculateRatio);
                        _mainChart();
                        that.showQualityGraph();
                        screen.dateto.datepicker('setDate', new Date(maingraphQuery.to));
                        screen.datefrom.datepicker('setDate', new Date(maingraphQuery.from));
                        $('#edateto')[0].value = moment(maingraphQuery.to).format('L');
                        $('#edatefrom')[0].value = moment(maingraphQuery.from).format('L');
                        $('#etimefromselect').prop('disabled', true).val('00:01');
                        $('#etimetoselect').prop('disabled', true).val('23:59');
                        screen.campaigntitle.html('<span>' + obj.name + '</span>');
                        screen.channeltitle.html('<span>' + obj.name + '</span>');
                        delete maingraphQuery.on;
                    });
                } else { return false; }
            });
            //Tables events
            screen.reportslist.on('click', '.etemplateboxtd', function () {
                ee.views.templates.previewContent(this.dataset.templateid);
            });

            screen.reportslist.on('click', 'td:first-child', function (e) {
                if (!($(e.target).hasClass('ecbox'))) {
                    $(this).find('.ecbox').click();
                };
            });
            screen.reportslist.on('click', '.ecbox', function (e) {

                var $this = $(this),
                    cboxtable,
                    showAll,
                    cmptype = $this.data('cmptype'),
                    name = $this.data('cmpname');
                if (name !== '') {
                    switch (cmptype) {
                        case 1:
                            cboxtable = cmptable;
                            showAll = cmpsAll;
                            break;
                        case 2:
                            cboxtable = chantable;
                            showAll = chanAll;
                            break;
                        case 3:
                            cboxtable = autotable;
                            showAll = autoAll;
                            break;
                        case 4:
                            cboxtable = dripTable;
                            showAll = dripAll;
                            break;
                        case 6:
                            cboxtable = journeyTable;
                            showAll = journeyAll;
                            break;
                    }

                    if ($this.is(':checked')) {
                        cboxtable.push(name);
                        $this.parent().parent().addClass('echecked');
                    } else {
                        _.pull(cboxtable, name);
                        $this.parent().parent().removeClass('echecked');
                    }
                    screen.cboxnavi.trigger('input-checked');
                } else {
                    __checkAll($this, cmptype)
                }
            });

            function __checkAll(elem, type) {

                if (elem.prop('checked')) {
                    switch (type) {
                        case 1:
                            __clearTableInputs(cmptable);
                            cmptable = campaignlist.map('channelid');
                            __selectAllON($('#eCMPpaginationresult'));
                            cmpsAll = true;
                            break;
                        case 2:
                            __clearTableInputs(chantable);
                            chantable = channellist.map('channelid');
                            __selectAllON($('#eCHLpaginationresult'));
                            chanAll = true;
                            break;
                        case 3:
                            __clearTableInputs(autotable);
                            _.forEach(FullData, function(el) {
                                if (el.ChannelID) {
                                    autotable.push(el.ChannelID);
                                }
                            });
                            __selectAllON($('#eautopaginationresult'));
                            autoAll = true;
                            break;
                        case 4:
                            __clearTableInputs(dripTable);
                            dripTable = autoresend.map('channelid');
                            __selectAllON($('#drippaginationresult'));
                            dripAll = true;
                            break;
                        case 6:
                            __clearTableInputs(journeyTable);
                            journeyTable = _.map(allJourneysList, el => el.Name);
                            __selectAllON($('#ejourneypaginationresult'));
                            journeyAll = true;
                            break;
                    }
                } else {
                    switch (type) {
                        case 1:
                            cmptable = [];
                            cmpsAll = false;
                            __selectAllOFF($('#eCMPpaginationresult'));
                            break;
                        case 2:
                            chantable = [];
                            chanAll = false;
                            __selectAllOFF($('#eCHLpaginationresult'));
                            break;
                        case 3:
                            autotable = [];
                            autoAll = false;
                            __selectAllOFF($('#eautopaginationresult'));
                            break;
                        case 4:
                            dripTable = [];
                            dripAll = false;
                            __selectAllOFF($('#drippaginationresult'));
                            break;
                        case 6:
                            journeyTable = [];
                            journeyAll = false;
                            __selectAllOFF($('#ejourneypaginationresult'));
                            break;
                    }
                }
                screen.cboxnavi.trigger('input-checked');
            };
            screen.cboxnavi.on('click', '#ecboxexport', function () {
                var modal = html.modal.create(
                    html.get('ExportDialog'),
                    {
                        size: 'emodal-sm',
                        title: "Export",
                        buttons: [{
                            name: ee.t.exportdata, css: "btn_primary btn_lg", callback: function () {
                                var filename = modal.$content.find('#efilename').val() || 'Campaigns',
                                    fileformat = modal.$content.find('#efileformat').val(),
                                    compressionformat = (modal.$content.find('#ecompressed').prop('checked')) ? 'Zip' : 'None',
                                    channelidsArr = [],
                                    toExport = [],
                                    cmpLeng = cmptable.length;

                                if (cmpLeng) toExport = toExport.concat(cmptable);
                                if (chantable.length) toExport = toExport.concat(chantable);
                                if (autotable.length) toExport = toExport.concat(autotable);
                                if (journeyTable.length) toExport = toExport.concat(journeyTable);

                                const query = {
                                    fileformat: fileformat,
                                    compressionformat: compressionformat,
                                    filename: filename
                                }

                                if(journeyTable.length) {
                                    _.forEach(toExport, el => {
                                        API_V3.Journeys.ExportJourney(el, query)
                                        .catch(err => {
                                            console.log(err)
                                        })
                                    })
                                    cmptable = [];
                                    chantable = [];
                                    autotable = [];
                                    journeyTable = [];
                                    toExport = [];
                                    ee.goTo('#/exports')
                                } else {
                                    toExport.forEach(function (elem) {
                                        if (typeof elem === 'object') {
                                            channelidsArr.push(elem.data('cmpname'));
                                        } else {
                                            channelidsArr.push(elem);
                                        }
                                    });
                                    cmptable = [];
                                    chantable = [];
                                    autotable = [];
                                    journeyTable = [];
                                    toExport = [];
                                    ee.api.exportCampaigns(channelidsArr, fileformat, compressionformat, filename, null);
                                }
                            }
                        }]
                    }
                );
            });
            screen.cboxnavi.on('click', '#ecboxdeselect', function () {
                var cmpLeng = cmptable.length,
                    chanLeng = chantable.length,
                    autoLeng = autotable.length;

                if (cmpLeng) {
                    __clearTableInputs(cmptable);
                    cmptable = [];
                    __selectAllOFF($('#ecampaignstable'));
                    cmpsAll = false;
                };
                if (chanLeng) {
                    __clearTableInputs(chantable);
                    chantable = [];
                    __selectAllOFF($('#echannelstable'));
                    $('#echannelstable').find('input').prop('checked', false);
                    chanAll = false;
                };
                if (autoLeng) {
                    __clearTableInputs(autotable);
                    autotable = [];
                    __selectAllOFF($('#eautotable'));
                    autoAll = false;
                };
                if (journeyTable.length) {
                    __clearTableInputs(journeyTable);
                    journeyTable = [];
                    __selectAllOFF($('#ejourneytable'));
                    journeyAll = false;
                };
                screen.cboxnavi.trigger('input-checked');

            });
            screen.cboxnavi.on('input-checked', function () {

                var elemLength = cmptable.length + chantable.length + autotable.length + journeyTable.length;

                screen.cboxnavi.find('.ecboxitem').text((elemLength < 2) ? ee.t.itemsingle : ee.t.itemmulti);

                if (elemLength) {
                    screen.cboxnavi.fadeIn()
                } else {
                    screen.cboxnavi.fadeOut()
                }
                screen.cboxnavi.find('.ecboxcount').text(elemLength);
            });

            changePage = function () {
                if ($('.etogglepercents').hasClass('on')) {
                    $('.enumber').hide().end().find('.epercent').show();
                } else {
                    $('.enumber').show().end().find('.epercent').hide()
                };
                html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);
                html.reloadTooltip(screen);
                if (cmpsAll && $('#eCMPpaginationresult').find('.eslcAll').hasClass('on')) {
                    __setTableInputs(cmptable);
                } else if (cmpsAll) {
                    __selectAllON($('#eCMPpaginationresult'));
                } else if (!cmpsAll && $('#eCMPpaginationresult').find('.eslcAll').hasClass('on')) {
                    __selectAllOFF($('#eCMPpaginationresult'));
                }

                if (chanAll && $('#eCHLpaginationresult').find('.eslcAll').hasClass('on')) {
                    __setTableInputs(chantable);
                } else if (chanAll) {
                    __selectAllON($('#eCHLpaginationresult'));
                } else if (!chanAll && $('#eCHLpaginationresult').find('.eslcAll').hasClass('on')) {
                    __selectAllOFF($('#eCHLpaginationresult'));
                }

                if (autoAll && $('#eautopaginationresult').find('.eslcAll').hasClass('on')) {
                    __setTableInputs(autotable);
                } else if (autoAll) {
                    __selectAllON($('#eautopaginationresult'));
                } else if (!autoAll && $('#eautopaginationresult').find('.eslcAll').hasClass('on')) {
                    __selectAllOFF($('#eautopaginationresult'));
                }

                if (journeyAll && $('#ejourneypaginationresult').find('.eslcAll').hasClass('on')) {
                    __setTableInputs(journeyTable);
                } else if (journeyAll) {
                    __selectAllON($('#ejourneypaginationresult'));
                } else if (!journeyAll && $('#ejourneypaginationresult').find('.eslcAll').hasClass('on')) {
                    __selectAllOFF($('#ejourneypaginationresult'));
                }
                //bind sorting of elements
            };

            function sortByElement(collection, pagination, container) {
                container.on('click', 'th', function() {
                    var element = $(this).data('pascalname');
                    if(sortType !== 1) {
                        collection = _.orderBy(collection, element, 'asc');
                        sortType = 1;
                    } else if(sortType === 1) {
                        collection = _.orderBy(collection, element, 'desc');
                        sortType = 2;
                    }
                    pagination.setPage(1);
                    pagination.updateData(collection);
                });
            };

            if(!ee.isAutomationsEnabled()) {
                $('li[data-hash="automation"]').addClass('hidden');
            }

            function _prepareSorting(container, pagination, datacollection) {
                container.on("click", 'th', function () {
                    var $that = $(this);
                    var prop = $that.data("name");
                    var reverse = ($that.data('order') === 'asc') ? true : false;
                    datacollection.sort(prop, reverse)
                    pagination.setPage(1);
                    pagination.updateData(datacollection.getAll());
                    if (reverse === true) {
                        $that.data('order', 'desc');
                    } else {
                        $that.data('order', 'asc');
                    }
                    html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);
                });
            };
            switch (ee.Router.action()) {
                case "automation":
                    $('#eCHLresultfooter').replaceWith("<div id='eautoresultfooter' class='clearfix eautoresultfooter'></div>");
                    break;
                case "dripcampaigns":
                    $('#eCHLresultfooter').replaceWith("<div id='edripresultfooter' class='clearfix edripresultfooter'></div>");
                    break;
                case "channels":
                    $('#eCHLresultfooter').replaceWith("<div id='eCHLresultfooter' class='clearfix eCHLresultfooter'></div>");
                    break;
                case "campaigns":
                    $('#eCHLresultfooter').replaceWith("<div id='eCMPresultfooter' class='clearfix eCMPresultfooter'></div>");
                    break;
                case "linktracking":
                    $('#eCHLresultfooter').replaceWith("<div id='elinkresultfooter' class='clearfix elinkresultfooter'></div>");
                    break;
                default:
                    $('#eCHLresultfooter').replaceWith(" ");
                    break;
            }

            if (campaignlist) {
                CMPpagination = new html.Pagination(campaignlist.getAll(), {
                    perpage: 25,
                    resultcontainer: '#eCMPpaginationresult',
                    footercontainer: '.eCMPresultfooter',
                    template: 'ReportsBasicPagination',
                    param: { cmptype: 1, containerid: "eCMPpaginationresult", noresultmsg: ee.t.nocamplistmsg }
                }).eventChangePage(changePage);
                //_prepareSorting(screen.campaignstable, CMPpagination, campaignlist);
            }
            if (channellist) {
                CHLpagination = new html.Pagination(channellist.getAll(), {
                    perpage: 25,
                    resultcontainer: '#eCHLpaginationresult',
                    footercontainer: '.eCHLresultfooter',
                    template: 'ReportsBasicPagination',
                    param: { cmptype: 2, containerid: "eCHLpaginationresult", noresultmsg: ee.t.nochannelslistmsg }
                }).eventChangePage(changePage);
                //_prepareSorting(screen.channelstable, CHLpagination, channellist);
            }

            if (autoresend) {
                dripPagination = new html.Pagination(autoresend.getAll(), {
                    perpage: 25,
                    resultcontainer: '#drippaginationresult',
                    footercontainer: '.dripresultfooter',
                    template: 'ReportsBasicPagination',
                    param: { cmptype: 4, containerid: "drippaginationresult", noresultmsg: ee.t.noautomationlistmsg }
                }).eventChangePage(changePage);
                //_prepareSorting(screen.driptable, dripPagination, autoresend);
            }

            function automationPercentages(allSent, percentage) {
                var result = (percentage / allSent) * 100;
                if (isNaN(result) || result === 0) {
                    result = 0;
                } else {
                    result = result.toFixed(2);
                }
                return result;
            }

            const convertAutomationStatus = obj => {
                switch(obj.Status) {
                    case -1:
                        obj.Status = "Invalid";
                        break;
                    case 0:
                        obj.Status = "Unpublished";
                        break;
                    case 1:
                        obj.Status = "Paused";
                        break;
                    case 2:
                        obj.Status = "Published";
                        break;
                }
            }

            //List of all journeys
            if (ee.Router.action() === 'automation' && (!ee.Router.allParameter()[0] || !ee.Router.allParameter()[0].value)) {
                $('#eperctoggle').remove();
                GetAllJourneys();
            }
            function GetAllJourneys() {
                return API_V3.Journeys.GetJourneysList()
                .then(function(data){
                        //Calculation of percentages for delivered, bounced, clicked etc.
                        _.forEach(data, function(el) {
                            convertAutomationStatus(el);
                            el.BouncedPercentage = automationPercentages(el.TotalSubmitted, el.TotalBounced);
                            el.ClickedPercentage = automationPercentages(el.TotalSubmitted, el.TotalClicked);
                            el.DeliveredPercentage = automationPercentages(el.TotalSubmitted, el.TotalDelivered);
                            el.OpenedPercentage = automationPercentages(el.TotalSubmitted, el.TotalOpened);
                        })
                        //Populating "pagination" for automations tab
                        if (ee.Router.action() === 'automation' && (!ee.Router.allParameter()[0] || !ee.Router.allParameter()[0].value) && ee.isAutomationsEnabled()) {
                        allJourneysList = data;
                        AUTOpagination = new html.Pagination(data, {
                            perpage: 25,
                            resultcontainer: '#ejourneypaginationresult',
                            footercontainer: '.eautoresultfooter',
                            template: 'ReportsAutomationsPagination',
                            param: { cmptype: 6, containerid: "ejourneypaginationresult", noresultmsg: ee.t.noautomationlistmsg }
                        }).eventChangePage(changePage);
                        sortByElement(data, AUTOpagination, $('#ejourneytable'));
                        }
                        return allJourneysList;
                })
                .catch(function(error){
                    console.error(error);
                });
            }

            //Function for traversing n-ary tree
            function visitNode(node, hashMap, array) {
                if(!hashMap[node.data]) {
                    hashMap[node.data] = true;
                    array.push(node);
                }
            }

            function convertTreeToList(root) {
                const stack = [];
                const array = [];
                const hashMap = {};
                const tempNode = [];
                stack.push(root);

                while(stack.length !== 0) {
                    let node = stack.pop();
                    if(node.Children === null) {
                        visitNode(node, hashMap, array);
                    } else {
                        for(let i = node.Children.length - 1; i >= 0; i--) {
                            stack.push(node.Children[i]);
                            if(node.Children[i].CategoryName === 'SendEmail') {
                                tempNode.push(node.Children[i]);
                            }
                        }
                    }
                }

                return tempNode;
            }

            //Campaigns from selected journey
            function getSingleAutomation() {

                if ( (ee.Router.allParameter()[0] && ee.Router.allParameter()[0].value) && ee.Router.action() === 'automation') {
                    ee.indiOn();
                    FullData = [];
                    let Name = ee.Router.allParameter()[0].value;
                    let JourneyURL = Name;
                    const promiseArr = [];
                    Name = Name.replace(/%20/g, ' ');

                    $('.automationEditor-link').attr('href', '#/automations/editor/' + JourneyURL);
                    $('.automation-links').removeClass('hidden');
                    $('#eperctoggle').remove();
                    $('#ereports .dropdown.pull-right').remove();
                    $('th[data-name="status"]').remove();

                    return API_V3.Journeys.GetJourneySummary(Name)
                    .then(function(data) {
                        convertAutomationStatus(data);
                        FullData.Status = data.Status;
                        _.forEach(data.StepsSummary, function(el){
                            if (el.Channel) {
                                FullData.push(el);
                            }
                        });
                        _.forEach(FullData, function(ele) {
                            _.forEach(ee.data.channels.data, function(el) {
                                if(el.name === ele.Channel) {
                                    ele.ChannelID = el.channelid;
                                }
                            });
                            ele.BouncedPercentage = automationPercentages(ele.SubmittedCount, ele.BouncedCount);
                            ele.ClickedPercentage = automationPercentages(ele.SubmittedCount, ele.ClickedCount);
                            ele.DeliveredPercentage = automationPercentages(ele.SubmittedCount, ele.DeliveredCount);
                            ele.OpenedPercentage = automationPercentages(ele.SubmittedCount, ele.OpenedCount);
                        });
                        return FullData;
                    })
                    .then(function(FullData) {
                        return API_V3.Journeys.GetJourney(Name)
                        .then(function(data) {
                            if (!data.Steps) return FullData;
                            _.forEach(FullData, function(el) {
                                _.forEach(convertTreeToList(data.Steps), function(ele) {
                                    if(el.StepID === ele.ID) {
                                        el.firsttemplateid = ele.Content.TemplateID;
                                        if(ele.Content.TemplateName) {
                                            promiseArr.push(API_V3.Journeys.GetTemplate(ele.Content.TemplateName))
                                        }
                                    }
                                })
                            });
                            return Promise.all(promiseArr)
                            .then(function(data){
                                let smallerArray = FullData.length > data.length ? data.length : FullData.length;
                                for(let i = 0; i < smallerArray; i++) {
                                    FullData[i].firstmediumscreenshotpath = `${ee.api.path}/userfile${data[i].MediumScreenshot}`;
                                    FullData[i].firstthumbnailpath = `${ee.api.path}/userfile${data[i].Thumbnail}`;
                                }
                                return FullData;
                            })
                        })
                    })
                    .then(function(FullData) {
                        $('#eautopaginationresult').empty();
                        $('.activity-title').empty();
                        let SingleAutomationTitle = new html.get('SingleAutomationTitle', {
                            Name: decodeURIComponent(Name),
                            Status: FullData.Status,
                            base: ee.base
                        });
                        $('.activity-title').append(SingleAutomationTitle);
                        singleAutomation = new html.Pagination(FullData, {
                            perpage: 25,
                            resultcontainer: '#eautopaginationresult',
                            footercontainer: '.eautoresultfooter',
                            template: 'ReportsSingleAutomation',
                            param: { cmptype: 3, containerid: "eautopaginationresult", noresultmsg: ee.t.noautomationlistmsg }
                        }).eventChangePage(changePage);
                        ee.indiOff();
                    })
                    .catch(function(error) {
                        console.error(error);
                    })
                }
            }

            $('#ejourneypaginationresult').on('click', '.viewjourney', function() {
                var name = $(this).data();
                ee.goTo('#/activity/automation/single?name=' + name.autoname);
                $('#ejourneyntab').removeClass('active');
                $('#eautomationtab').addClass('active');
            });

            if(ee.Router.allParameter().length && ee.Router.allParameter()[0].key === 'name') {
                $('#ejourneyntab').removeClass('active');
                $('#eautomationtab').addClass('active');
            }

            $('a[href="#ejourneyntab"]').on('click', () => {
                $('#eautomationtab').removeClass('active');
                $('#ejourneyntab').addClass('active');
                $('#ejourneypaginationresult').append(allJourneysList);
                $('.activity-title').html(`<h2>${ee.t.activity}</h2>`);
                GetAllJourneys();
            });

            $('#eautopaginationresult').on('click', '#viewAutoStats', function() {
                var automationID = $(this).data();
                ee.goTo('#/activity/' + automationID.autoname);
            });

            getSingleAutomation();

            $(document).on('click', '#link-to-auto', () => {
                ee.goTo('#/activity/automation/');
                $('#eautomationtab').removeClass('active');
                $('#ejourneyntab').addClass('active');
                $('#ejourneypaginationresult').append(allJourneysList);
                GetAllJourneys();
            });

            links = new html.Pagination([], {
                perpage: 25,
                resultcontainer: '#elnkresult',
                footercontainer: '.elinkresultfooter',
                template: 'ReportsLinkTrackingResult'
            }).eventChangePage(function () {
                html.reloadTooltip(screen);
                if (location.hash.includes('linktracking')) {
                    dropdownMenuLinkTrackingIsClicked = false;
                    ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuLinkTrackingIsClicked, that.setupMenuItemsLinkTrackingOnClick)
                }
            });

            __loadinData(links.get$Container());

            html.addDropdownFocus(screen.lnkresult);
            linkTrakingQuery = {
                channelid: 0,
                limit: 100,
                offset: 0,
                from: ee.tools.time(moment().subtract(1, "months")).toUTC(),
                to: ee.tools.time(moment()).toUTC()
            };
            _changeDataLinkTracking(screen);
            _changeDateLnkTrac(screen);

            function searchFilter(obj, searchValue) {
                const arr= [];
                    _.forEach(obj, function(el) {
                        if (el.Channel) {
                            if (_.includes(_.toLower(el.Channel), _.toLower(searchValue))) {
                                arr.push(el);
                            }
                        } else if (el.Name) {
                            if (_.includes(_.toLower(el.Name), _.toLower(searchValue))) {
                                arr.push(el);
                            }
                        }
                    })
                return arr;
            }

            screen.lnkresult.on('click', '.eview', function () {
                var linkQuery = {
                    link: $(this).data('link')
                };
                ee.api.loadLinkContacts(linkQuery, function (data) {
                    var temp = html.get('ReportsContactsClickModal', {})
                    modal = html.modal.create(temp, { title: ee.t.overallcontactsclicks }),
                        pagination = new html.Pagination(data.data.contacts, {
                            template: 'ReportsContactsClickModalResult',
                            resultcontainer: '#econtactscliks',
                            footercontainer: '#econtactscliksfooter',
                            perpage: 10
                        });
                    modal.$content.on('click', '.eview', function () {
                        var email = $(this).data('email');
                        modal.hide();
                        __showContactOverlay(email, {
                            beforeClose: function () { modal.show() }
                        });
                    });
                });
            });

            html.bindSearch(screen.searchcampaign, function () {
                const searchSTR = screen.searchinputcampaign.val();
                let linksObj = ee.data.links;
                let campObj = campaignlist;
                let dripObj = autoresend;
                let autoObj = FullData;
                let channObj = channellist;

                if (searchSTR !== '') {
                    if (linksObj) {
                        linksObj = linksObj.filter(['link'], searchSTR);

                        if (location.hash.includes('linktracking')) {
                            ee.indiOn();
                            return EE_API.Log.LinkTracking({from: linkTrakingQuery.from, to: linkTrakingQuery.to, linkSearch: searchSTR})
                            .then((data) => {
                                ee.data.links = new Collection.Data(data.trackedlink);
                                linksObj = ee.data.links;

                                if (linksObj) {
                                    links.updateData(linksObj.getAll());
                                }
                                ee.indiOff();
                            })
                            .catch(err => {
                                console.error(err);
                                ee.indiOff();
                            });
                        }
                    }
                    if (campObj) {
                        campObj = campObj.filter(['name'], searchSTR);
                        campaignlist = campObj;
                    }
                    if (channObj) {
                        channObj = channObj.filter(['name'], searchSTR);
                        channellist = channObj;
                    }
                    if (ee.Router.allParameter().length && ee.Router.allParameter()[0].key === 'name' && autoObj) {
                        autoObj = searchFilter(autoObj, searchSTR);
                    }
                    if (allJourneysList) {
                        allJourneysList = searchFilter(allJourneysList, searchSTR);
                    }

                    if ((campObj && campObj.data.length === 0 ) && (channObj && channObj.data.length === 0)) {
                        if ( ee.session.can.ViewCampaigns ) {
                            return EE_API.Campaign.List({search: searchSTR})
                            .then((data) => {

                                ee.data.channels = new Collection.Data(data);
                                CHANNELS = ee.data.channels;
                                campaignList = CHANNELS.filter(['iscampaign'], 'true');
                                channellist = CHANNELS.filter(['iscampaign'], 'false');
                                campObj = campaignList;
                                channObj = channellist;

                                CHLpagination.setPage(1);
                                if (channObj) {
                                    CHLpagination.updateData(channObj.getAll());
                                }
                                CMPpagination.setPage(1);
                                if (campObj) {
                                    CMPpagination.updateData(campObj.getAll());
                                }
                            })
                            .catch(err => console.error(err))
                        } else if ( ee.session.can.ViewChannels ) {
                            return EE_API.Campaign.ChannelList({search: searchSTR})
                            .then((data) => {
                                ee.data.channels = new Collection.Data(data);
                                CHANNELS = ee.data.channels;
                                campaignList = CHANNELS.filter(['iscampaign'], 'true');
                                channellist = CHANNELS.filter(['iscampaign'], 'false');
                                campObj = campaignList;
                                channObj = channellist;

                                CHLpagination.setPage(1);
                                if (channObj) {
                                    CHLpagination.updateData(channObj.getAll());
                                }
                                CMPpagination.setPage(1);
                                if (campObj) {
                                    CMPpagination.updateData(campObj.getAll());
                                }
                            })
                            .catch(err => console.error(err))
                        }
                    }
                };

                links.setPage(1);
                if (linksObj) {
                    links.updateData(linksObj.getAll());
                }

                CMPpagination.setPage(1);
                if (campObj) {
                    CMPpagination.updateData(campObj.getAll());
                }

                CHLpagination.setPage(1);
                if (channObj) {
                    CHLpagination.updateData(channObj.getAll());
                }

                if (ee.Router.allParameter().length && ee.Router.allParameter()[0].key === 'name' && autoObj) {
                    singleAutomation.updateData(autoObj);
                }
                if (AUTOpagination) {
                    AUTOpagination.setPage(1);
                    if (allJourneysList) {
                        AUTOpagination.updateData(allJourneysList);
                    }
                }

                dripPagination.setPage(1);
                if(dripObj) {
                    dripPagination.updateData(dripObj);
                }
                if(!autoObj) {
                    html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);
                }
                if (location.hash.includes('linktracking')) {
                    dropdownMenuLinkTrackingIsClicked = false;
                    ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuLinkTrackingIsClicked, that.setupMenuItemsLinkTrackingOnClick)
                }
            });

            screen.exportbtn.on('click', function () {
                exportResult('links', $.extend({}, linkTrakingQuery));
            });

            screen.dataselect.on('click', 'li', function (e, arr) {
                var input;
                if ($(e.target).hasClass('edatacbox')) {
                    return $(e.target).parent().trigger('click', [true]);
                } else {
                    input = $(this).find('.edatacbox')
                    if (!arr) input.prop('checked', !input.prop('checked'));
                    CMPpagination.setPage(1);
                    CMPpagination.updateData(campaignlist.filter(['status'], getStatuses(screen.dataselect), true).getAll());
                    html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);
                    return false;
                }
            });

            html.reloadTooltip(screen);

            screen.find('.etogglepercents').on('click', function () {
                if ($(this).hasClass('off')) {
                    $(this).removeClass('off').addClass('on');
                    $('.enumber').hide().end().find('.epercent').show();
                    if(navigator.cookieEnabled) {
                        Cookies.set('campaignmode', "showpercentages", { expires: 14 });
                    } else {
                        try {
                            sessionStorage.setItem('campaignmode', "showpercentages");
                        } catch(err) {
                            console.log(err);
                            console.log("Cookies and sessionStorage disabled.");
                        }
                    }
                } else {
                    $(this).removeClass('on').addClass('off')
                    $('.enumber').show().end().find('.epercent').hide();
                    if(navigator.cookieEnabled) {
                        Cookies.set('campaignmode', false, { expires: 14 });
                    } else {
                        try {
                            sessionStorage.setItem('campaignmode', false);
                        } catch(err) {
                            console.log(err);
                            console.log("Cookies and sessionStorage disabled.");
                        }
                    }
                }
            })
            changePage();

            if(navigator.cookieEnabled) {
                if (Cookies.get('campaignmode') === "showpercentages") {
                    $('.etogglepercents').trigger('click')
                };
            } else {
                try {
                    if (sessionStorage.getItem('campaignmode') === "showpercentages") {
                        $('.etogglepercents').trigger('click')
                    };
                } catch(err) {
                    console.log(err);
                    console.log("Cookies and sessionStorage disabled.");
                }
            }

            $('#summarytab-button').on('click', function() {
                $('#epagination').addClass('hidden');
            });

            screen.find('.epaginationresult').on('click', '.eviewcampaign', function () {
                window.location.hash = '#/activity/' + $(this).data('channelid');
                //window.location.reload();
            });

            // load c3 lib
            if (!window.c3) {
                html.Chart.init(function () {
                    _mainChart()
                    if (CHANNELS)
                    that.showCompareGraph(screen.comparecontainer);
                    that.showQualityGraph();
                });
                return;
            } else {
                _mainChart()

                if (CHANNELS)
                    that.showCompareGraph(screen.comparecontainer);

                that.showQualityGraph();
            }



        },//end showMain
        showCompareGraph: function (container) {
            var campaignList = CHANNELS.filter(['iscampaign'], 'true'),
                selectedChannels = [],
                screen = ee.frame,
                SELECTED,
                stats,
                activeChannels = campaignList.filter(['recipientcount'], '0', true, true),
                template = html.get('ReportsCompareScreen', {});

            if (activeChannels.data.length > 5) {
                for (var i = 0; i < 5; i++) {
                    selectedChannels.push(activeChannels.data[i])
                }
            } else if (activeChannels.data.length < 5 && activeChannels.data.length > 1) { selectedChannels = activeChannels.getAll() };
            template.appendTo(container);
            SELECTED = new Collection.Data(selectedChannels);
            SELECTED.sort('dateTime');
            if (window.c3) {
                compareChart = c3.generate({
                    bindto: '#egraphcompare',
                    padding: {
                        top: 30,
                        right: 70,
                        bottom: 20,
                        left: 70,
                    },
                    size: {
                        height: 500
                    },
                    data: {
                        x: 'x',
                        columns: generateCompareChartData(SELECTED),
                        type: 'spline',
                        colors: {
                            Email: '#66a3a3',
                            Delivered: '#0099ff',
                            Opened: '#008000',
                            Clicked: '#ffbb1c',
                            Unsubscribed: '#ffa200',
                            Bounced: '#ff0000',
                            Complaints: '#000000',
                            Canceled: '#998d86',
                            DeliverRATIO: '#b6b70f'
                        }
                    },
                    axis: {
                        x: {
                            type: 'category',
                        },
                        y: {
                            max: 100,
                            min: 0,
                            tick: {
                                format: function (d) { return d + '%' }
                            }
                        }
                    }
                });
            } else {
                html.Chart.init(function () { _mainChart(hourly) });
                return;
            }
            stats = html.get('ReportsCompareSection', { list: (SELECTED.data.length >= 1) ? SELECTED.getAll() : false });
            stats.appendTo(container.find('#estatsshowcompare'));
            container.find('#estatsshowcompare').on('click', '.eremoveChannel', function () {
                var stats;
                SELECTED.remove('channelid', $(this).data('channelid'));
                if (SELECTED.data.length >= 1) {
                    compareChart.load(
                        {
                            unload: [],
                            columns: generateCompareChartData(SELECTED)
                        }
                    );
                } else { compareChart.unload() }
                container.find('#estatsshowcompare').empty();
                stats = html.get('ReportsCompareSection', { list: SELECTED.getAll() });
                stats.appendTo(container.find('#estatsshowcompare'));
            });
            screen.find('#echancomparebutton').on('click', function () {
                var tohide = SELECTED.map('channelid'),
                    arrLength = tohide.length,
                    list = screen.find('#echancompareselect');
                list.find('li').removeClass('eselected').css('background', '');
                for (var i = 0; i < arrLength; i++) {
                    list.find('li[data-channelid="' + tohide[i] + '"]').addClass('eselected').css('background', '#fff6e5');
                }

            });

            screen.find('#echancompareselect').on('click', 'li', function () {
                if ($(this).hasClass('enochannel')) return;
                if ($(this).hasClass('eselected')) {
                    SELECTED.remove('channelid', $(this).data('channelid'));
                } else {
                    SELECTED.add(CHANNELS.get('channelid', $(this).data('channelid')));
                    SELECTED.sort('dateTime');
                }
                if (SELECTED.data.length >= 1) {
                    compareChart.load(
                        {
                            unload: [],
                            columns: generateCompareChartData(SELECTED)
                        }
                    );
                } else { compareChart.unload() }
                container.find('#estatsshowcompare').empty();
                stats = html.get('ReportsCompareSection', { list: SELECTED.getAll() });
                stats.appendTo(container.find('#estatsshowcompare'));
            });
            screen.find('#ecampcomparebutton').on('click', function () {
                var tohide = SELECTED.map('channelid'),
                    arrLength = tohide.length,
                    list = screen.find('#ecampcompareselect');
                list.find('li').removeClass('eselected').css('background', '');
                for (var i = 0; i < arrLength; i++) {
                    list.find('li[data-channelid="' + tohide[i] + '"]').addClass('eselected').css('background', '#fff6e5');
                }

            });
            screen.find('#ecampcompareselect').on('click', 'li', function () {
                if ($(this).hasClass('enochannel')) return;
                if ($(this).hasClass('eselected')) {
                    SELECTED.remove('channelid', $(this).data('channelid'));
                } else {
                    SELECTED.add(CHANNELS.get('channelid', $(this).data('channelid')));
                    SELECTED.sort('dateTime');
                }
                if (SELECTED.data.length >= 1) {
                    compareChart.load(
                        {
                            unload: [],
                            columns: generateCompareChartData(SELECTED)
                        }
                    );
                } else { compareChart.unload() }
                container.find('#estatsshowcompare').empty();
                stats = html.get('ReportsCompareSection', { list: SELECTED.getAll() });
                stats.appendTo(container.find('#estatsshowcompare'));
            });
        }, // end of showCompareGraph

        generateloadLogsParams: (logsParams) => {
            let from;
            let to;
            let includeSms;
            let includeMail;
            let callStatus;
            if (logsParams.screen.dateto && logsParams.screen.datefrom && !logsParams.range) {
                let dateFrom = logsParams.screen.datefrom[0].value;
                let timeFrom = (logsParams.screen.timefromselect && logsParams.screen.timefromselect[0].value) || '00:01';
                let dateTo = logsParams.screen.dateto[0].value;
                let timeTo = (logsParams.screen.timetoselect && logsParams.screen.timetoselect[0].value) || '23:59';
                let fromDateAndTimeString =`${dateFrom} ${timeFrom}`;
                let toDateAndTimeString = `${dateTo} ${timeTo}`;

                from = moment(fromDateAndTimeString,"L LT").toISOString();
                to = moment(toDateAndTimeString,"L LT").toISOString();
            } else if (logsParams.dateTo && logsParams.dateFrom) {
                from = moment(logsParams.dateFrom).toISOString()
                to = moment(logsParams.dateTo).toISOString();
            } else if (logsParams.range || selectedDateRange) {
                to = moment().toISOString();
                switch (logsParams.range || selectedDateRange) {
                    case 'today':
                        from = moment().startOf('day').toISOString()
                        break;
                    case 'day':
                        from = moment(to).subtract(1, 'days').toISOString()
                        break;
                    case 'week':
                        from = moment(to).subtract(7, 'days').toISOString()
                        break;
                    case '2weeks':
                        from = moment(to).subtract(14, 'days').toISOString()
                        break;
                    case 'month':
                        from = moment(to).subtract(30, 'days').toISOString()
                        break;
                    default:
                        from = moment(to).subtract(14, 'days').toISOString()
                }
            } else { //default view - depending on Extended logs Access Level
                to = moment().toISOString()
                from = moment(to).subtract(ee.data.account.can.ExtendedLogs ? 35 : 7, "days").toISOString()
            }

            if (logsParams.screen.datalogselect) {
                let filterStatuses = [];
                filterStatuses = logsParams.screen.datalogselect.find('input:checked').map((index, checkedStatus) => {return checkedStatus.value}) || [];
                if (_.includes(filterStatuses, 'issms')) { 
                    includeSms = true;
                    includeMail = false;
                    _.pull(filterStatuses, 'issms');
                    if(!filterStatuses.length) {
                        filterStatuses = ['0'];
                    }
                } else {
                    includeSms = false;
                    includeMail = true;
                }
                
                callStatus = _.filter(filterStatuses, function(status) {return typeof status === 'string'})
            }

            return {    
                to: to,
                from: from,
                statuses: callStatus,
                includeSMS: includeSms,
                includeEmail: includeMail
            }
        },

        showProgress: function () {

            var that = this,
                timeout,
                STATS,
                statuses = ['1,3', '2'],
                livestats,
                screen,
                loadedLogs = false,
                progressList,
                notAll,
                ratecontainer,
                requestInprogress,
                deliveryQ,
                doc = ee.frame.content,
                //prepare Logs obj
                Logs = dataModel.create("Logs");

            if (!ee.data.reports.delivered) {
                ee.api.livestats(function (data) {
                    that.showProgress()
                })
                return false;
            }

            if (!ee.data.reports.progress) ee.data.reports.progress = new Collection.Data([]);
            doc.empty();
            let AccountInActive = false;
            let AccountMaintenanceInProgress = false;
            switch (ee.data.reports.delivered.deliverypausedreason) {
                case 'AccountInActive':
                    AccountInActive = true;
                    break;
                case 'AccountMaintenanceInProgress':
                    AccountMaintenanceInProgress = true;
                    break;
                default:
                    break;
            }
            var cancelInProgress = _.includes(ee.data.account.accessLevelNew.data, "ModifyCampaigns") || _.includes(ee.data.account.accessLevelNew.data, "ModifyChannels");
            screen = html.get('ReportsProgressScreen', { issub: ee.data.account.issub, admin: ee.session.can.AdminView, cancelInProgress: cancelInProgress, accInActive: AccountInActive, accmaintenanceinprogress: AccountMaintenanceInProgress });

            screen.appendTo(doc);

            screen.find('.ebackbutton').on('click', goMain);
            ratecontainer = screen.statsshow;

            STATS = ee.data.reports.delivered;

            showLiveStats(ratecontainer);
            ee.indiOff();

            progressList = new html.Pagination([], {
                perpage: 25,
                resultcontainer: '#einprogpagination',
                footercontainer: '.einprogfooter',
                template: 'ReportsLogResult',
            });

            deliveryQ = new html.Pagination(STATS.domains.getAll(), {
                perpage: 25,
                resultcontainer: '#edeliverypagination',
                footercontainer: '.edeliveryfooter',
                template: 'ReportsDeliveryTable',
            });

            screen.inprogress.find('.enoresult').text(ee.t.loading);

            livestats = function () {
                clearTimeout(timeout);
                ee.api.livestats(function (data) {
                    if (data.error) {
                        if ($('#ereports').hasClass('eprogress')) {
                            timeout = setTimeout(livestats, 1000);
                        };
                        return false;
                    }
                    cache.inprogresscount = data.totalinprogress;
                    if (ee.data.reports && ee.data.reports.delivered) {
                        ee.data.reports.delivered = data;
                        showLiveStats(ratecontainer);
                        if (deliveryQ) {
                            deliveryQ.updateData(data.domains.getAll());
                        }
                        if ($('#ereports').hasClass('eprogress')) {
                            timeout = setTimeout(livestats, 1000);
                        }
                    } else { return false }
                });
            };
            timeout = setTimeout(livestats, 1000);
            if (ee.session.can.Export) {
                screen.exportlogbtn.on('click', function () {
                    if (screen.exportlogbtn.hasClass('disabled')){
                        return;
                    }

                    _showExportModal({
                        filename: "Logs_In_Progress",
                        title: ee.t.downloadlog
                    }, function (modal, content, val) {
                        Logs.Export({
                            filename: content.filename.val(),
                            fileFormat: content.fileformat.val(),
                            compressionFormat: Number(content.compressed.is(':checked')),
                            statuses: ['1, 2, 3']
                        },
                            function () {
                                ee.goTo("#/exports");
                            });
                    })
                });

                screen.exporthistory.on('click', function () {
                    ee.goTo("#/exports")
                });
            }
            morelinks();

            screen.maintablist.on('click', 'li', function (e) {
                var filter = screen.filtersrow;

                filter.find('#edatadropdwn').parent().removeClass('open');

                if ($(e.target).data('filter') === 'on') {
                    filter.removeClass('hidden');
                } else if ($(e.target).data('filter') === 'off') {
                    filter.addClass('hidden');
                }


            });
            screen.InProgressTab.on('click', function () {
                if (loadedLogs) return;
                screen.refresh.trigger('click');
            });

            screen.refresh.on('click', function () {
                var $this = $(this),
                    statuses;

                if (!loadedLogs) {
                    statuses = ["1,3", "2"];
                    loadedLogs = true;
                } else { statuses = Logs.getStatuses() }

                if (!($this.hasClass('on'))) {
                    $this.addClass('on');
                    if (screen.exportlogbtn) screen.exportlogbtn.addClass('disabled');
                    Logs.LoadInProgress({
                        statuses: statuses
                    }, function (self, data) {
                        progressList.update(Logs.getAll());
                        if (Logs.getLength() > 0 && screen.exportlogbtn) screen.exportlogbtn.removeClass('disabled');
                        $this.removeClass('on');
                        notAll = (data.data.recipients.length === 500);
                    });
                };
            });
            screen.datadropdwn.on('click', function () {
                var inputs = screen.dataselect.find('input'),
                    input, statuses = Logs.getStatuses();
                inputs.prop('checked', false);

                for (var i = 0; i < statuses.length; i++) {
                    input = screen.dataselect.find('input[value="' + statuses[i] + '"]');
                    input.prop('checked', true);
                }
            });
            screen.cancel.on('click', function () {
                ee.api.cancelinprogress({ channelid: 0 }, function () {
                    cache = {};
                    that.showProgress()
                });
            });
            if (screen.reactivatefromlog) {
                screen.reactivatefromlog.on('click', function () {
                    ee.api.adminReactivateFromLog(function () {
                        cache = {};
                        that.showProgress()
                    });
                });
            }
            $('#ehowtospeed').on('click', function () {
                window.location.href = "http://help.elasticemail.com/activity/how-to-manage-reputation-and-delivery";
            });
            screen.dataselect.on('click', 'li:not(.drop-first)', function (e) {
                var $this = $(this),
                    $input = $this.find('input'),
                    isChecked = ($(e.target).is('input')) ? !$input.prop('checked') : !!$input.prop('checked'),//convert to boolean
                    inputs = screen.dataselect.find('input:checked'),
                    checkedInputsLength = ($(e.target).is('input')) ? inputs.length + 1 : inputs.length;
                if (isChecked && checkedInputsLength <= 1) return false;
                $this.find('input').addClass('ewaiting');
                if (screen.exportlogbtn) screen.exportlogbtn.addClass('disabled');
                clearTimeout(logtimeout);
                logtimeout = setTimeout(function () {
                    let params = that.generateloadLogsParams({screen});

                    Logs.Load({
                        query: {
                            ...params
                        }
                    },
                        function (self, data) {
                            if (screen.exportlogbtn && Logs.getLength() > 0) screen.exportlogbtn.removeClass('disabled');
                            progressList.update(Logs.getAll());
                            $('input.ewaiting').removeClass('ewaiting');
                            notAll = (data.data.recipients.length === 500);
                        })
                }, 1000);

                if ($(e.target).hasClass('edatacbox')) {
                    return;
                } else {
                    $input.prop('checked', !$input.prop('checked'));
                }
                return false;
            });
            screen.inprogpagination.on('click', '.eview', function () {
                var msgid = $(this).data('msgid');
                __logpreview(Logs, msgid);
            });
            screen.inprogpagination.on('click', '.eemaildetail', function () {
                var email = $(this).data('email');
                __showContactOverlay(email);
            });
            screen.inprogpagination.on('click', '.ebouncemsg', function () {
                that.showBounceDetail(Logs.get('msgid', $(this).data('msgid')));
            });
            progressList.eventChangePage(function (e) {
                if (notAll && !requestInprogress && progressList.getOffset() > (Logs.getLength() - 1000)) {
                    requestInprogress = true;
                    Logs.LoadMore(function (data) {
                        progressList.updateData(Logs.getAll())
                        requestInprogress = false;
                        notAll = (data.data.recipients.length === 500);
                    })
                };
                html.reloadTooltip(screen);
            });

        },//end of showProgress
        showViewSMS: function (recipient) {
            //show sms
            var win = html.get("ViewSMS", recipient);
            html.modal.create(win, { title: ee.t.viewsms });
        },//end of showViewSMS
        showViewEmail: function (logModel) {
            /*set additional properties */
            var template = html.get("ViewEmail", {
                email: logModel.data,
                showattachment: logModel.hasAttachments(),
                isAdmin: ee.session.can.AdminView,
                apipath: ee.api.path,
                lowresolution: (window.screen.height <= 900) ? true : undefined
            });

            if (window.screen.height >= 900) {
                var buttons = _viewDetailsOnHightResolutions(logModel);
            } else {
                _viewDetailsOnLowResolutions(template, logModel);
            }

            var modal = html.modal.create(template, {
                title: ee.t.viewemail,
                size: "emodal-lg",
                alignToScreenHeight: true,
                onShow: function ($modal) {
                    var $content = $modal.$content;
                    $content.contentemail.height($content.outerHeight(true) - $content.infoemail.outerHeight(true));
                },
                buttons: buttons,
                cancel: { title: ee.t.close }
            });
            template.emailviewloading.show();
            template.iframe.on('load', function () { template.emailviewloading.hide() });
        },
        showQualityGraph: function (hourly) {
            var graphdata = [],
                graphlength,
                average = {},
                GRAPH = new Collection.Data([]);
            SUMMARY.each(function () {
                if (new Date(this.date).getTime() > 1469577600000) {
                    if (this.email) {
                        let elementToPush = _.clone(this);
                        graphdata.push(elementToPush);
                    }
                }
            });
            _.forEach(graphdata, function(el) {
                if (el.date.length > 10) {
                    el.date = ee.tools.time(el.date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate("L HH:mm");//moment(el.date).format(`L HH:mm`);
                    el.date = el.date.replace(/\//g, '-');
                } else {
                    el.date = ee.tools.time(el.date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate("L HH:mm");//moment(el.date, ['YYYY-MM-DD', ee.tools.determinateDateFormat(el.date)]).format('L');
                    el.date = el.date.replace(/\//g, '-');
                }
                if(moment(el.date, `${ee.tools.localDateFormatGraph()} HH:mm`).format(`${ee.tools.localDateFormatGraph()} HH:mm`) === el.date) {
                    hourly = true;
                } else {
                    hourly = false;
                }
            })
            let xFormat = `${ee.tools.localDateFormatGraph()} %H:%M`;
            GRAPH.update(graphdata);
            graphlength = GRAPH.data.length;
            if (graphlength > 0) {
                average.email = '100.00';
                average.delivered = (GRAPH.sum('deliverRATIO') / graphlength).toFixed(2);
                average.opened = (GRAPH.sum('openRATIO') / graphlength).toFixed(2);
                average.bounced = (GRAPH.sum('bounceRATIO') / graphlength).toFixed(2);
                average.clicked = (GRAPH.sum('clickRATIO') / graphlength).toFixed(2);
                average.suppressed = (GRAPH.sum('suppressRATIO') / graphlength).toFixed(2);
                if (window.c3) {
                    qualityChart = c3.generate({
                        bindto: '#egraphquality',
                        padding: {
                            top: 50,
                            right: 70,
                            bottom: 20,
                            left: 70,
                        },
                        legend: {
                            show: false
                        },
                        size: {
                            height: 500
                        },
                        data: {
                            x: 'x',
                            hide: true,
                            xFormat: xFormat, // 'xFormat' can be used as custom format of 'x'
                            columns: [
                                ['x'].concat(GRAPH.map('date')),
                              //['x', '20130101', '20130102', '20130103', '20130104', '20130105', '20130106'],
                                ['Delivered'].concat(GRAPH.map('deliverRATIO')),
                                ['Opened'].concat(GRAPH.map('openRATIO')),
                                ['Clicked'].concat(GRAPH.map('clickRATIO')),
                                ['Bounced'].concat(GRAPH.map('bounceRATIO')),
                                ['Suppressed'].concat(GRAPH.map('suppressRATIO'))
                            ],
                            colors: {
                                Email: '#66a3a3',
                                Delivered: '#0099ff',
                                Opened: '#008000',
                                Clicked: '#ffbb1c',
                                Unsubscribed: '#ffa200',
                                Bounced: '#ff0000',
                                Complaints: '#000000',
                                Suppressed: '#998d86'
                            }
                        },
                        axis: {
                            x: {
                                type: 'timeseries',
                                tick: {
                                    format: '%Y-%m-%d'
                                }
                            },
                            y: {
                                tick: {
                                    format: function (d) { return d + '%' }
                                }
                            }
                        },
                        grid: {

                            y: {
                                lines: [
                                    { value: average.delivered, 'class': 'egrid-Delivered y-average', text: 'Delivered average ' + average.delivered + '%' },
                                    { value: average.opened, 'class': 'egrid-Opened y-average', text: 'Opened average ' + average.opened + '%' },
                                    { value: average.bounced, 'class': 'egrid-Bounced y-average', text: 'Bounced average ' + average.bounced + '%' },
                                    { value: average.clicked, 'class': 'egrid-Clicked y-average', text: 'Clicked average ' + average.clicked + '%' },
                                    { value: average.suppressed, 'class': 'egrid-Suppressed y-average', text: 'Suppressed average ' + average.suppressed + '%' },
                                ]
                            }
                        }
                    });

                    showQualityStats('#estatsqualityshow', average);
                } else {
                    html.Chart.init(function () { _mainChart(hourly) });
                    return;
                }

            } else {
                $('#egraphquality').empty().html('<p style="padding: 230px 0;font-size: 28px;font-style: italic;color: #757575;text-align:center;">No data</p>')
                $('#estatsqualityshow').css('background', 'transparent');
                $('#eshowaverage').hide();
                qualityChart = $('#egraphquality');
            }
        },//end of showQualityGraph
        showCampaign: function (id, breadcrumb) {
            var doc = ee.frame.content,
                screen,
                mainchart,
                summary,
                query = {
                    channelid: +id,
                    interval: 'summary'
                },
                bouncedLoadinBtn,
                logsPagination,
                linksPagination,
                channelOBJ = CHANNELS.get('channelid', +id),
                daterange = getDefaultDateTimeRange(channelOBJ),
                bouncedCategoriesColl = new Collection.Data([]),
                statuses = ['Submitted', 'Delivered', 'Opened', 'Clicked', 'Unsubscribed', 'Bounced', 'Complaints', 'Suppressed'],
                //Prepare Logs Collection
                Logs = dataModel.create("Logs"),
                Links = dataModel.create("Links");
            let requestInProgress = false;
            //Set DateTime for graph query
            query.from = moment(daterange.from).toISOString();
            query.to = moment(daterange.to).toISOString();

            if (channelOBJ.iscampaign && channelOBJ.triggertype < 3) {
                query.interval = 'hourly';
            }
            Logs.query.to = query.to;
            Logs.query.from = query.from;

            doc.empty();
            channelOBJ.lnkon = ee.data.account.linktracking;
            channelOBJ.showtimeselect = (query.interval === 'hourly');
            channelOBJ.breadcrumb = breadcrumb;
            screen = html.get('ReportsSingleCampaign', channelOBJ);
            bouncedLoadinBtn = html.get('BouncedBtnLoading', {});
            screen.appendTo(doc);
            bouncedLoadinBtn.appendTo(screen.bouncebutton);
            ee.api.loadstats($.extend({}, query), function (data) {
                summary = new Collection.Data(data.data.dailylogstatussummary).each(_calculateRatio);
                if (query.interval === 'hourly') {
                    _generateChart(true);
                } else {
                    _generateChart(false);
                };
                _formatBouncedData(data.data.bouncedcategorysummary, function (data) {
                    _showBouncedData(screen, bouncedCategoriesColl, data);
                });
            });
            $('#elnksection').on('click', (e) => {
                if ($(e.target).hasClass('action-gotopage')){
                    dropdownMenuIsClicked = false; 
                    ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick, isNaN(channelid)? id : channelid)
                }
            })
            // Init refresh button
            var $refreshBtn = screen.find('.js-refresh');
            $refreshBtn.on("click", () => {
                screen.dateto.change();
            });

            //Stats section
            screen.datadropdwn.on('click', function () {
                var inputs = screen.datagraphselect.find('input'),
                    input;

                inputs.prop('checked', false);

                for (var i = 0; i < statuses.length; i++) {
                    input = screen.datagraphselect.find('input[value="' + statuses[i] + '"]');
                    input.prop('checked', true);
                }
            });

            //Date picker
            if (channelOBJ.showtimeselect) {
                screen.timefromselect.val(ee.api.formatDate(query.from, "hh:00", false, true));
                screen.timetoselect.val(ee.api.formatDate(query.to, "hh:00", false, true));
                screen.timefromselect.on('keyup', function (e) {
                    if (e.keyCode === 13) {
                        __changeTimeRange();
                        screen.timefromselect.blur();
                    } else { return false };
                });
                screen.timetoselect.on('keyup', function (e) {
                    if (e.keyCode === 13) {
                        __changeTimeRange();
                        screen.timetoselect.blur();
                    }
                });

            }

            screen.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", query.from).change(function () { __changeTimeRange() });
            screen.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", query.to).change(function () { __changeTimeRange() });

            screen.statsshow.on('mouseenter', '.estatscontainer', function () {
                var target = $(this).data('focus');
                mainchart.focus(target);
            });
            screen.statsshow.on('mouseenter', '.estatscontainer.off', function () {
                mainchart.revert();
            });
            screen.statsshow.on('mouseleave', '.estatscontainer', function () {
                mainchart.revert();
            });
            screen.statsshow.on('click', '.estatscontainer', function () {
                var $this = $(this),
                    target = $this.data('focus');
                if ($this.hasClass('off')) {
                    mainchart.show(target);
                    mainchart.focus(target);
                    $this.removeClass('off');
                    statuses.push(target);
                } else {
                    mainchart.hide(target);
                    mainchart.revert();
                    $this.addClass('off');
                    for (var i = 0; i < statuses.length; i++) {
                        if (statuses[i] === target) { statuses.splice(i, 1); break; }
                    };
                }
            });
            screen.datagraphselect.on('click', 'li:not(.drop-first)', function (e) {
                return __dataGraphFilter(screen, e.target, $(this));
            });

            //data section is show only when channelOBJ.templatechannel exist
            if (screen.data_container) {
                showdata(screen.data_container, channelOBJ);
                html.widget.TemplateThumbnail.init(screen, _thumbnailOptions);

                //Data section event
                screen.data_container.on('click', '.gallery-item', function () {
                    ee.views.templates.previewContent(this.dataset.templateid);
                });
            }
            const channelid = parseInt(ee.Router.action(), 10);
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick, isNaN(channelid)? id : channelid)
            //Paginations
            logsPagination = new html.Pagination([], {
                perpage: 9,
                resultcontainer: '#epaginationresult',
                footercontainer: '.resultfooter',
                template: 'ReportsLogResult'
            }).eventChangePage(function (e) {
                let currentPage = ee.Router.parameter('page') || 1;

                if ((currentPage > prevPage) && !requestInProgress && (logsPagination.getOffset() >= logsData.length - 9) ) {
                    requestInProgress = true;
                    offset += 500;

                    Logs.LoadAll(function () {
                        _.map(Logs.getAll(), (obj) => {
                            logsData.push(obj);
                        });
                        logsPagination.updateData(logsData);
                        requestInProgress = false;
                    }, 500, offset);
                }

                prevPage = currentPage;
            });

            let prevPage = ee.Router.parameter('page') || 1;
            let offset = 0;
            let logsData;

            linksPagination = new html.Pagination([], {
                perpage: 9,
                resultcontainer: '#elnkresult',
                footercontainer: '.elnkfooter',
                template: 'ReportsLinkTrackingResult',
                param: { contactfeatures: ee.data.account.enablecontactfeatures }
            });
            html.addDropdownFocus(screen.lnkresult);
            //Load logs
            /*NOTE: show indicator in tbody when first loading */
            screen.paginationresult.find('tbody').html(html.get('LoadingPaginationResult', { colspan: 7, padding: true }).html());
            __logsLoading();
            Logs.setChannelID(id);
            Logs.LoadAll(function () {
                logsData = Logs.getAll();
                __logsLoaded();
            }, 500);
            //Load links
            __linksLoading();
            Links.setChannelID(id);
            Links.setDateFrom(query.from);
            Links.setDateTo(query.to);
            Links.load().then( () => {
                __linksLoaded();
                dropdownMenuIsClicked = false;
                ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick, isNaN(channelid)? id : channelid)
            })
            $('#edateto')[0].value = moment(query.to).format('L');
            $('#edatefrom')[0].value = moment(query.from).format('L');
            //Logs Pagination Events
            screen.paginationresult.on('click', '.eview', function () {
                var msgid = $(this).data('msgid');
                __logpreview(Logs, msgid);
            });
            screen.paginationresult.on('click', '.eemaildetail', function () {
                var email = $(this).data('email');
                __showContactOverlay(email);
            });
            screen.paginationresult.on('click', '.ebouncemsg', function () {
                that.showBounceDetail(Logs.get('msgid', $(this).data('msgid')));
            });

            //Logs bouncecategory filter
            screen.bouncecategoryfilter.on('click', 'li', function () {
                msgcategory = $(this).data('sn');
                __logsLoading();
                Logs.LoadBouncedCategory(msgcategory, function () {
                    var categoryObj = bouncedCategoriesColl.get('sn', String(msgcategory))
                    __logsLoaded();
                    screen.bouncebutton.removeClass('disabled');
                    screen.bouncebutton.html(html.get('BouncedBtnResult', categoryObj))
                });
            });
            screen.bouncecategoryfilter.on('update', function () {
                var date = Logs.getDateTime();
                ee.api.request('/log/summary', {
                    interval: "hourly",
                    from: date[0],//ee.api.formatDate(date[0], '', true),
                    to: date[1],//ee.api.formatDate(date[1], '', true),
                    channelid: Logs.getChannelID()
                }, function (data) {
                    if (!data.success) return false;

                    ee.data.activity = data.data;

                    _formatBouncedData(data.data.bouncedcategorysummary, function (data) {
                        _showBouncedData(screen, bouncedCategoriesColl, data);
                    })
                }, undefined, undefined, undefined, undefined, true)
            });

            //Logs filters
            //Statuses filters
            screen.logdatadropdwn.on('click', function () {
                var inputs = screen.datalogselect.find('input'),
                    input, statuses = Logs.getStatuses();

                inputs.prop('checked', false);

                for (var i = 0; i < statuses.length; i++) {
                    input = screen.datalogselect.find('input[value="' + statuses[i] + '"]');
                    input.prop('checked', true);
                }
            });
            screen.datalogselect.on('click', 'li:not(.drop-first)', function (e) {
                var $this = $(this),
                    $input = $this.find('input'),
                    isChecked = ($(e.target).is('input.statuscheck')) ? !$input.prop('checked') : !!$input.prop('checked'),//convert to boolean
                    inputs = screen.datalogselect.find('input.statuscheck:checked'),

                    checkedInputsLength = ($(e.target).is('input.statuscheck')) ? inputs.length + 1 : inputs.length;
                if (isChecked && checkedInputsLength <= 1) return false;
                __logsLoading();
                $this.find('input').addClass('ewaiting');
                if (screen.exportlogbtn) screen.exportlogbtn.addClass('disabled');
                clearTimeout(logtimeout);
                logtimeout = setTimeout(function () {
                    let params = that.generateloadLogsParams({screen});

                    Logs.Load({
                        query: {
                            ...params
                        }
                    },
                        function () {
                            __logsLoaded();
                            screen.bouncebutton.html(html.get('BouncedBtnResult', bouncedCategoriesColl.get('sn', '0')))
                            screen.bouncebutton.removeClass('disabled');
                            screen.datalogselect.find('input').removeClass('ewaiting');
                        })
                }, 1000);

                if ($(e.target).hasClass('edatacbox')) {
                    return;
                } else {
                    $input.prop('checked', !$input.prop('checked'));
                }
                return false;
            });
            //Logs export

            screen.logssectionreports.find('#eexportlogbtn').on('click', function () {


                var issms;
                if (screen.smsonly) {
                    issms = screen.smsonly.prop('checked') ? 1 : 0;
                }
                _showExportModal({
                    filename: "Logs",
                    title: ee.t.downloadlog
                }, function (modal, content, val) {

                    Logs.Export({
                        filename: content.filename.val(),
                        fileFormat: content.fileformat.val(),
                        compressionFormat: Number(content.compressed.is(':checked')),
                        issms: issms ? issms : 0
                    },
                        function () {
                            ee.goTo("#/exports");
                        });
                })
            });
            screen.logssectionreports.find('#eexporthistory').on('click', function () {
                ee.goTo("#/exports")
            });

            screen.lnksection.find('.eexportlogs-btn').on('click', function () {
                _showExportModal({
                    filename: "Links",
                    title: ee.t.linktracking
                }, function (modal, content, val) {
                    ee.indiOn();
                    Links.export({
                        filename: content.filename.val(),
                        fileFormat: content.fileformat.val(),
                        compressionFormat: Number(content.compressed.is(':checked'))
                    })
                        .then(function () { ee.goTo("#/exports"); })
                })
            });

            //Utils
            function _generateChart(hourly) {
                var xFormat = `${ee.tools.localDateFormatGraph()} %H:%M`;
                if (window.c3) {
                    mainchart = c3.generate({
                        bindto: '#egraph',
                        padding: {
                            top: 30,
                            right: 70,
                            bottom: 20,
                            left: 70,
                        },
                        size: {
                            height: 500
                        },
                        legend: {
                            show: false
                        },
                        data: {
                            x: 'x',
                            xFormat: xFormat,
                            type: 'area',
                            json: generateMainChartData(summary),
                            colors: {
                                Submitted: '#66a3a3',
                                Delivered: '#0099ff',
                                Opened: '#008000',
                                Clicked: '#ffbb1c',
                                Unsubscribed: '#ffa200',
                                Bounced: '#ff0000',
                                Complaints: '#000000',
                                Suppressed: '#998d86',
                                DeliverRATIO: '#b6b70f'
                            },
                            axes: {
                                DeliverRATIO: 'y2'
                            },
                            types: {
                                DeliverRATIO: 'line'
                                //data2: 'area-spline'
                            }
                        },
                        axis: {
                            x: {
                                type: 'timeseries',
                                tick: {
                                    format: xFormat
                                }
                            },
                            y2: {
                                show: true,
                                label: {
                                    text: 'Quality',
                                    position: 'outer-middle',
                                    max: 100
                                },
                                max: 100,
                                min: 0,
                                padding: {
                                    top: 10,
                                    bottom: 10
                                }
                            }
                        },
                        zoom: {
                            enabled: true
                        }
                    });
                } else {
                    html.Chart.init(function () { _generateChart(hourly) });
                    return;
                }
                __showstats('#estatsshow');
            };
            function __showstats(container) {
                var $container = $(container),
                    statsdata = _getStats(summary, ['email', 'delivered', 'opened', 'clicked', 'unsubscribed', 'bounced', 'complaint', 'suppressed']),
                    statstemplate = html.get('ReportsStatsSection', statsdata);
                $container.empty();
                statstemplate.appendTo($container);
            };
            //Logs Loading
            function __linksLoading() {
                screen.exportlinks.addClass('disabled').attr('disabled', 'disabled');
                screen.lnkresult.addClass('eloading');
            };
            function __graphLoading() {
                screen.graph.addClass('eloading');
                screen.statsshow.addClass('eloading');
                screen.filterinputs.find('input').addClass('disabled').attr('disabled', 'disabled');
            };
            function __graphLoaded() {
                screen.graph.removeClass('eloading');
                screen.statsshow.removeClass('eloading');
                screen.filterinputs.find('input').removeClass('disabled').removeAttr('disabled');
            };
            function __linksLoaded() {
                if (Links.getLength() > 0) screen.exportlinks.removeClass('disabled').removeAttr('disabled');
                screen.lnkresult.removeClass('eloading');
                linksPagination.update(Links.getAll());
            }
            function __logsLoading() {
                //screen.exportlogbtn.addClass('disabled').attr('disabled', 'disabled');
                screen.bouncebutton.addClass('disabled');
                screen.paginationresult.addClass('eloading');
                screen.bouncebutton.html(bouncedLoadinBtn);
            };
            function __logsLoaded() {
                if (Logs.getLength() > 0) screen.exportlogbtn.removeClass('disabled').removeAttr('disabled');
                logsPagination.update(Logs.getAll());
                screen.paginationresult.removeClass('eloading');
            };
            function __changeTimeRange() {
              
                var fromH = $('#etimefromselect').val() || '00:01',
                    toH = $('#etimetoselect').val() || '23:59',
                    dateTo = screen.dateto.datepicker('getDate'),
                    dateFrom = screen.datefrom.datepicker('getDate');
                dateFrom.setHours(+(fromH.split(":")[0]));
                dateFrom.setMinutes(+(fromH.split(":")[1]));
                dateTo.setHours(+(toH.split(":")[0]));
                dateTo.setMinutes(+(toH.split(":")[1]));
                if (dateFrom.getTime() < dateTo.getTime()) {
                    query.from = dateFrom;
                    query.to = dateTo;
                    __graphLoading();
                    ee.api.loadstats($.extend({}, query), function (data) {
                        summary = new Collection.Data(data.data.dailylogstatussummary).each(_calculateRatio);
                        if (query.interval === 'hourly') {
                            _generateChart(true);
                        } else { _generateChart(false); };
                        __graphLoaded();
                    });

                    __logsLoading();
                    Logs.Load({
                        query: {
                            to: dateTo,
                            from: dateFrom
                        }
                    },
                        function () {
                            __logsLoaded();
                            screen.bouncecategoryfilter.trigger('update');
                        })
                    __linksLoading();
                    Links.setDateFrom(dateFrom);
                    Links.setDateTo(dateTo);
                    Links.load().then( () => {
                        __linksLoaded();
                        dropdownMenuIsClicked = false;
                        ee.tools.setupMutationObserver('#elnkresult tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick, isNaN(channelid)? id : channelid)
                    })

                }
            };
            function __dataGraphFilter(screen, target, $this) {
                var $input = $this.find('input'),
                    targetCheck = $(target).hasClass('edatacbox'),
                    val = $input.val(),
                    inputAttr = (targetCheck) ? !$input.prop('checked') : $input.prop('checked');

                if (inputAttr) {
                    mainchart.hide(val);
                    for (var i = 0; i < statuses.length; i++) {
                        if (statuses[i] === val) { statuses.splice(i, 1); break; }
                    }
                    screen.statsshow.find('.estatscontainer[data-focus="' + val + '"]').addClass('off');
                } else {
                    mainchart.show(val);
                    statuses.push(val);
                    screen.statsshow.find('.estatscontainer[data-focus="' + val + '"]').removeClass('off');
                }

                if ($(target).hasClass('edatacbox')) {
                    return;
                } else {
                    $input.prop('checked', !$input.prop('checked'));
                }
                return false;
            };
        },
        showBounceDetail: function (msgOBJ) {

            var link = ee.parameters.urlerrorcategories || "http://help.elasticemail.com/activity/what-are-the-bounce-error-categories-and-filters",
                modal,
                content,
                activateContact,
                buttons = [],
                isCustomBranding = ee.data.account.privatebrandingurl && ee.data.account.issub;

            if (msgOBJ.allowResend) {
                /*Push to buttons array, resend button, when message is allowed to resend */
                buttons.push({
                    name: ee.t.resend,
                    css: 'btn_md btn_primary btn-info ebtn-resend',
                    callback: function () {
                        var query = {
                            messageID: msgOBJ.msgid,
                            createNew: true
                        };

                        if (activateContact) {
                            query.activateContact = true;
                        }
                        $(this).addClass('disabled');
                        ee.api.request('/log/ResendEmail', query, function (data) {
                            ee.indiOff();
                            if (!data.success) return html.modal.error(data);
                            modal.close();
                            html.modal.info(ee.t.successresentmsg);
                        });
                    }
                });

            }

            if (ee.session.can.AdminView) {
                /*Make additional request when user has admin access */
                buttons.push({
                    name: 'Dev',
                    css: 'btn-info btn_md btn_destructive btn-admin',
                    callback: function () {
                        _getMJRError(msgOBJ.msgid);
                    }
                });
                var content = html.get("BounceDetailsModal", {
                    isCustomBranding: isCustomBranding,
                    details: msgOBJ.message,
                    learnMore: link,
                    emailTo: msgOBJ.to
                });

                modal = html.modal.create(content, {
                    title: ee.t.bouncedetails,
                    buttons: buttons
                });

                if (msgOBJ.confirmResend) {
                    __confirmResend()
                };
            } else {
                content = html.get("BounceDetailsModal", {
                    isCustomBranding: isCustomBranding,
                    details: msgOBJ.message,
                    learnMore: link,
                    emailTo: msgOBJ.to
                });
                modal = html.modal.create(content, {
                    title: ee.t.bouncedetails,
                    buttons: buttons
                });
            }

            if (!ee.session.can.AdminModify && msgOBJ.confirmResend) {
                __confirmResend()
            }

            function __confirmResend() {
                var content = $('#eBounceDetailsModal'),
                    confirmHtml = html.get("ConfirmResend"),
                    resendButton = modal.$footer.find('.ebtn-resend');

                confirmHtml.appendTo(content);
                resendButton.addClass('disabled');

                confirmHtml.confirmCheckBox.on('click', function () {

                    if ($(this).is(':checked')) {
                        resendButton.removeClass('disabled');
                        activateContact = true;
                    } else {
                        resendButton.addClass('disabled');
                        activateContact = false;
                    }

                });
            }

        },
        showUnsubscribeReasons: function () {
            var doc = ee.frame.content,
                screen = html.get('ReportsUnsubscribeReasonsScreen', {}),
                to = new Date(), from = new Date(),
                query = {},
                Contact = dataModel.create('Contact');

            doc.empty();
            screen.appendTo(doc);

            loadUnsubscribeReasonsData(query, screen);

            function updateDate() {
                var fromH = $('#etimefromselect').val(),
                    toH = $('#etimetoselect').val(),
                    dateTo = screen.dateto.datepicker('getDate'),
                    dateFrom = screen.datefrom.datepicker('getDate');
                let from = ee.tools.time(dateFrom).toUTC(fromH+":00")
                let to = ee.tools.time(dateTo).toUTC(toH+":59")

                if (dateFrom.getTime() < dateTo.getTime()) {
                    query.from = from;
                    query.to = to;
                    loadUnsubscribeReasonsData(query, screen);
                }
                return query;
            }


            if (screen.exportlogbtn) {
                screen.exportlogbtn.on('click', function () {

                    if (screen.exportlogbtn.hasClass('disabled')){
                        return;
                    }

                    _showExportModal({
                        filename: ee.t.unsubscribereasons,
                        title: ee.t.dataexport
                    }, function (modal, content) {
                        Contact.exportunsubscribereasoncount({
                            filename: content.filename.val(),
                            fileFormat: content.fileformat.val(),
                            compressionFormat: Number(content.compressed.is(':checked')),
                            from: query.from,
                            to: query.to
                        }).then(function () {
                            ee.goTo('#/exports');
                        }).catch(function (err) {
                            html.modal.error(err);
                        });
                    })
                });
            }

            screen.refresh.on("click", function () {
                that.show();
            });
            screen.timeselect.on('click', 'li.drop-first', function () {
                screen.timeselecttitle.html($(this).html());
                screen.fromto.removeClass("hide").css("display", "inline-block");
            });
            screen.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", from).change(function (e) {
                updateDate();
            });
            screen.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", to).change(function (e) {
                updateDate();
            });
            $('#edateto')[0].value = moment(to).format('L');
            $('#edatefrom')[0].value = moment(from).format('L');

            screen.timefromselect.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    updateDate();
                    screen.timefromselect.blur();
                } else { return false };
            });
            screen.timetoselect.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    updateDate();
                    screen.timetoselect.blur();
                } else { return false };
            });

            screen.timeselect.on('click', '.erangeelem', function () {
                query = {};
                screen.fromto.addClass("hide").css("display", "none");

                var timerange = $(this).data('range'),
                    text = $(this).text(),
                    overall = false,
                    to = new Date(), from = new Date(),
                    toSTR,
                    fromSTR;

                switch (timerange) {
                    case "overall":
                        overall = true;
                        break;
                    case 'day':
                        from.setDate(to.getDate() - 1);
                        break;
                    case 'week':
                        from.setDate(to.getDate() - 7);
                        break;
                    case '2weeks':
                        from.setDate(to.getDate() - 14);
                        break;
                    case 'month':
                        from.setDate(to.getDate() - 30);
                        break;
                }
                screen.timeselecttitle.text(text);

                if (!overall) {
                    fromSTR = ee.tools.time(from).toUTC(),//ee.api.formatDate(from, "", true);
                    toSTR = ee.tools.time(to).toUTC()//ee.api.formatDate(to, "", true);
                    query = { from: fromSTR, to: toSTR };
                }

                loadUnsubscribeReasonsData(query, screen);
            })
        },
        showUserAgentReports: () => {
            ee.indiOff();
            const $frame =  ee.frame.content;
            $frame.empty();
        },

        showEmails: function (options) {
            options = options || {};
            paginationOffset = 0;
            let doc = ee.frame.content;
            let screen;
                //logs state variables
            let notAllItems = true;
            let screentitle = false;
            let requestInprogress = false;
            let bouncedLoadinBtn;
            let contactsLink = '/contactshards/list';
            let bouncedCategoriesColl = new Collection.Data([]);
                //Prepare Log Collection OBJ;
            let Logs = dataModel.create("Logs");
            let bouncedetailsscreen = ee.Router.action() === "bouncedetails";
            let notificationlogscreen = ee.Router.action() === "notifications";
            let pagination;
            var defaults = {
                page: 'ReportsEmailsScreen',
                template: 'ReportsLogResult'
            };
            var settings = $.extend(defaults, options);
            checkChannelLists();

            screen = html.get(settings.page, {
                breadcrumb: options.breadcrumb,
                autoresend: cache.autoresend.getAll(),
                campaigns: cache.campaignlist.getAll(),
                channels: cache.channellist.getAll(),
                bouncedscreen: bouncedetailsscreen,
                notificationscreen: notificationlogscreen,
                sendingpermissionSMS: ee.data.account.grantedSendingPermission.sms
            });
            bouncedLoadinBtn = html.get('BouncedBtnLoading', {});

            doc.empty();
            screen.appendTo(doc);
            bouncedLoadinBtn.appendTo(screen.bouncebutton);

            ee.indiOff();

            if (ee.session.can.Export) {
                screen.exportlogbtn.on('click', function () {
                    if (screen.exportlogbtn.hasClass('disabled')){
                        return;
                    }

                    _showExportModal({
                        filename: ee.t.emaillogs,
                        title: ee.t.downloadlog
                    }, function (modal, content, val) {
                        Logs.Export({
                            filename: content.filename.val(),
                            fileFormat: content.fileformat.val(),
                            compressionFormat: Number(content.compressed.is(':checked')),
                            statuses: ee.Router.action() === 'emails' ? logStatuses : null
                        },
                            function () {
                                ee.goTo("#/exports");
                            });
                    })
                });

                screen.exporthistory.on('click', function () {
                    ee.goTo("#/exports")
                });
            }

            morelinks();

            $('.edatacbox:not(:first)').on('click', function() {
                if($(this)[0].checked && _.indexOf(logStatuses, $(this)[0].value) === -1) {
                    logStatuses.push($(this)[0].value);
                } else {
                    _.pull(logStatuses, $(this)[0].value);
                }
                if(_.includes(logStatuses, '0')) {
                    _.pull(logStatuses, '0');
                }
            });

            $('.edatacbox:first').on('click', function() {
                if(!$(this)[0].checked) {
                    _.pull(logStatuses, '0');
                } else {
                    logStatuses = ['0'];
                    $(this).attr('checked')
                    _.forEach($('.edatacbox:not(:first)'), checkbox => {
                        $(checkbox).prop('checked', false);
                    })
                }
            });

            $('.edatacbox').on('click', function() {
                $('#edatalogselect').on('click', () => {
                    if(!$('#elogdatadropdwn').parent().hasClass('open')) {
                        $('#elogdatadropdwn').parent().addClass('open');
                    }
                })
                if (logStatuses.length && logStatuses !== ['0']) {
                    $('.edatacbox:first').prop('checked', false);
                }
                if(logStatuses.length === 1 && _.includes(logStatuses, '0')) {
                    $('.edatacbox:first').prop('checked', true);
                }
                if(!logStatuses.length) {
                    $('#filter-logs').prop('disabled', true);
                } else {
                    $('#filter-logs').prop('disabled', false);
                }
            });

            $('#filter-logs').on('click', function() {
                paginationOffset = 0;
                requestInprogress = true;

                let query = that.generateloadLogsParams({screen})

                paginationOffset += 125;
                const logQuery = {
                    limit: 125,
                    channelId: -1,
                    ...query
                }
                ee.Router.action() === 'notifications' ? null : delete logQuery.channelId;
                return EE_API.Log.Load(logQuery)
                .then(data => {
                    emailsList = [...data.recipients]

                    emailsList = _.orderBy(emailsList, log => {
                        const utcDate = moment.utc(log.date);
                        const local = moment(utcDate).local().format('MM/DD/YYYY h:mm A');
                        return log.date = `${local} ${moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset())}`;
                    }, ['desc'])
                    _.forEach(emailsList, email => {
                        switch(email.status) {
                            case "Submitted":
                                email.statusname = ee.t.submitted
                                break;
                            case "ReadyToSend":
                                email.statusname = ee.t.readytosend
                                break;
                            case "WaitingToRetry":
                                email.statusname = ee.t.waitingtoretry
                                break;
                            case "Sending":
                                email.statusname = ee.t.sending
                                break;
                            case "Bounced":
                                email.statusname = ee.t.bounced;
                                break;
                            case "Sent":
                                email.statusname = ee.t.sent
                                break;
                            case "Opened":
                                email.statusname = ee.t.opened
                                break;
                            case "Clicked":
                                email.statusname = ee.t.clicked
                                break;
                            case "Unsubscribed":
                                email.statusname = ee.t.unsubscribed
                                break;
                            case "AbuseReport":
                                email.statusname = ee.t.complaint
                                break;
                        }
                    })
                    Logs.data = emailsList;
                    pagination.updateData(emailsList);
                    requestInprogress = false;
                    notAllItems = (data.recipients.length === 125);
                })
                .catch(err => {
                    console.error(err);
                })
            })
            pagination = new html.Pagination([], {
                perpage: 25,
                resultcontainer: '#epaginationresult',
                footercontainer: '.resultfooter',
                template: settings.template,
                param: settings.param
            })
            .eventChangePage(function (e) {

                if (notAllItems && !requestInprogress && pagination.getOffset() >= _.size(emailsList) - 25) {
                    requestInprogress = true;
                    let to = moment();
                    let from = moment(to).subtract(35, 'days');
                    channelID = ee.Router.action() === 'notifications' ? -1 : null;
                    msgcategory ? logStatuses = 4 : null;
                    paginationOffset += 125;
                    let logQuery = {
                        statuses: logStatuses,
                        limit: 125,
                        offset: paginationOffset,
                        to: to,
                        from: from,
                        channelId: -1,
                        email: searchForContact,
                        messagecategory: msgcategory,
                        includeSMS: includeSms,
                        includeEmail: includeMail
                    }
                    searchForContact ? null : delete logQuery.email;
                    msgcategory ? null : delete logQuery.msgcategory;
                    ee.Router.action() === 'notifications' ? null : delete logQuery.channelId;

                    function __getStatusName(name) {
                        var statusnames = {
                            "Submitted": ee.t.submitted,
                            "ReadyToSend": ee.t.readytosend,
                            "WaitingToRetry": ee.t.waitingtoretry,
                            "Sending": ee.t.sending,
                            "Bounced": ee.t.bounced,
                            "Sent": ee.t.sent,
                            "Opened": ee.t.opened,
                            "Clicked": ee.t.clicked,
                            "Unsubscribed": ee.t.unsubscribed,
                            "AbuseReport": ee.t.complaint
                        }
                        return statusnames[name];
                    };
                    Logs.Load({
                        query: logQuery
                    },
                        function () {
                            recipients = Logs.getAll();
                            emailsList = [...emailsList, ...recipients];
                            emailsList = emailsList.map((email) => {
                                email.statusName = __getStatusName(email.status);
                                email = ee.api.generateGenericMsg(email)
                                return email;
                            })
                            Logs.data = emailsList;
                            _.forEach(emailsList, email => {
                                if(email.status === 'Bounced' && (email.messagecategory === 'NotDeliveredCancelled' || 'ManualCancel' || 'NotDelivered')) {
                                    email.status = 'Suppressed'
                                }
                            })
                            pagination.updateData(emailsList);
                            requestInprogress = false;
                            notAllItems = (recipients.length === 125);
                    })
                };
                html.reloadTooltip(screen);
            });

            $('#edatefrom, #edateto, #etimefromselect, #etimetoselect').on('change', () => {
                pagination.update(emailsList);
            });

            $('#etimeselect:not(:first-child)').on('click', () => {
                pagination.update(emailsList);
            });

            /*NOTE: show indicator in tbody when first loading */
            screen.paginationresult.find('tbody').html(html.get('LoadingPaginationResult', { colspan: 7 }).html());
            __screenLoading();
            if (bouncedetailsscreen) {
                Logs.LoadBounced(function () {
                    __screenLoaded(ee.t.ovractivity);
                });
            } else if (notificationlogscreen) {
                Logs.setChannelID(-1);
                Logs.LoadAll(function () {
                    __screenLoaded(ee.t.ovractivity);
                });
            } else {
                Logs.LoadAll(function() {
                    __screenLoaded(ee.t.ovractivity);
                }, 125);
            }
            //back button event
            screen.find('.ebackbutton').on('click', goMain);
            screen.find('.eexportlogs-btn').on('click', function () {
                var issms;
                if (screen.smsonly) {
                    issms = screen.smsonly.prop('checked') ? 1 : 0;
                }
                if (screen.exportlogbtn.hasClass('disabled')){
                    return;
                }

                _showExportModal({
                    filename: "Logs",
                    title: ee.t.downloadlog
                }, function (modal, content) {
                    Logs.Export({
                        filename: content.filename.val(),
                        fileFormat: content.fileformat.val(),
                        compressionFormat: Number(content.compressed.is(':checked')),
                        issms: issms ? issms : 0
                    },
                        function () {
                            ee.goTo("#/exports");
                        });
                })
            });

          
            //Refresh
            screen.refresh.on("click", function () {
                delete ee.data.channels;
                delete cache.monthsummary;
                delete ee.data.logs;
                delete ee.data.activity;
                that.show();
                logStatuses = ['0'];
            });

            //Search by email
            if (screen.contacsearchcontainer) {
                html.bindSearch(screen.searchcampaign, function (val) {

                    $('#econtacsearchpanel').remove();
                    if (val === '') {
                        searchForContact = false
                        return;
                    }

                    ee.indiOn();
                    var searchMetod = __generatContactsSearchMetod("( Email contains '" + val.trim() + "' )");

                    searchMetod(function (data, notAll) {
                        if (!data.length) {
                            data.push({email: val.trim()})
                        }
                        ee.indiOff();
                        var panel = html.get('ContactsPanel', { notAll: notAll }),
                            list = html.get('ContactsPanelList', { list: data });
                            searchForContact = val.trim();
                        panel.appendTo(screen.contacsearchcontainer);
                        list.appendTo(panel.contactsList);
                        
                        if (notAll) {
                            panel.loadnextcontacts.on('click', function () {
                                panel.contactsList.css('opacity', '0.5');
                                searchMetod(function (data, notAll) {
                                    var list = html.get('ContactsPanelList', { list: data });
                                    panel.contactsList.css('opacity', '1');
                                    if (data.length < 10) panel.loadnextcontacts.addClass('disabled');
                                    if (data.length === 0) { return }
                                    panel.contactsList.empty();
                                    panel.contactsList.css('opacity', '1');
                                    list.appendTo(panel.contactsList);
                                })
                            })
                        }

                        //hide results if container loses focus
                        screen.contacsearchcontainer.on("focusout", function() {
                            panel.hide();
                        });
                        screen.contacsearchcontainer.on("focusin", function() {
                            panel.show();
                        })

                    })

                }, { autoSearch: false });

                screen.contacsearchcontainer.on('click', 'li[data-email]', function () {
                    var email = $(this).data('email');
                    //screen.searchcampaign.removeClass('active');
                    screen.searchinputcampaign.val('').trigger('keyup');
                    $('#econtacsearchpanel').remove();
                    __screenLoading();
                    Logs.setEmail(email);
                    Logs.LoadAll(function () {
                        __screenLoaded(ee.t.ovractivity);
                        screen.bouncecategoryfilter.addClass('hidden');
                        screen.emailalertcontainer.empty();
                        var alertInfo = html.get("EmailLogsAlert", { email: email });
                        alertInfo.appendTo(screen.emailalertcontainer);
                    });
                });
                screen.emailalertcontainer.on('click', '.eback_all', function () {
                    __screenLoading();
                    $(this).text(ee.t.loading);
                    $(this).addClass('disabled');
                    Logs.removeEmail();
                    Logs.LoadAll(function () {
                        __screenLoaded(ee.t.ovractivity);
                        screen.bouncecategoryfilter.removeClass('hidden');
                        screen.bouncecategoryfilter.trigger('update');
                        screen.emailalertcontainer.empty();
                    });
                });
            }
            //Bouncecategory filter
            screen.bouncecategoryfilter.on('click', 'li', function () {
                var title = __screenLoading(true);
                msgcategory = $(this).data('sn');
                Logs.LoadBouncedCategory(msgcategory, function () {
                    var categoryObj = bouncedCategoriesColl.get('sn', String(msgcategory))
                    __screenLoaded(title);
                    screen.bouncebutton.removeClass('disabled');
                    screen.bouncebutton.html(html.get('BouncedBtnResult', categoryObj))
                });
            });
            screen.bouncecategoryfilter.on('update', function () {
                var date = Logs.getDateTime();
                    EE_API.Log.GetDailyStats({
                        interval: "hourly",
                        from: date[0],//ee.api.formatDate(date[0], '', true),
                        to: date[1],//ee.api.formatDate(date[1], '', true),
                        channelid: Logs.getChannelID()
                    }).then(data => {
                        ee.data.activity = data;
                        _formatBouncedData(data.bouncedcategorysummary, function (data) {
                            _showBouncedData(screen, bouncedCategoriesColl, data);
                        });
                        
                        $('#edatefrom')[0].value = moment(date[0]).format('L');
                        $('#edateto')[0].value = moment(date[1]).format('L');

                    });
            });

            if (!ee.data.activity) {
                screen.bouncecategoryfilter.trigger('update');
            } else {
                _formatBouncedData(ee.data.activity.bouncedcategorysummary, function (data) {
                    _showBouncedData(screen, bouncedCategoriesColl, data);
                })
            }

            //Time filters
            screen.timeselect.on('click', 'li.drop-first', function () {
                screen.timeselecttitle.html($(this).html());
                screen.fromto.removeClass("hide").css("display", "inline-block");
            });
            screen.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", Logs.getDateTime("from")).change(function (e) {
                __changeTimeRange();
            });
            screen.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", Logs.getDateTime("to")).change(function () {
                __changeTimeRange();
            });
            $('#edateto')[0].value = moment(Logs.getDateTime("to")).format('L');
            $('#edatefrom')[0].value = moment(Logs.getDateTime("from")).format('L');

            // screen.timefromselect.on('keyup', function (e) {
            //     if (e.keyCode === 13) {
            //         __changeTimeRange();
            //         screen.timefromselect.blur();
            //     } else { return false };
            // });
            // screen.timetoselect.on('keyup', function (e) {
            //     if (e.keyCode === 13) {
            //         __changeTimeRange();
            //         screen.timetoselect.blur();
            //     } else { return false };
            // });
            screen.timeselect.on('click', 'li:not(.drop-first)', function () {
                screen.timeselecttitle.html($(this).html());
                screen.fromto.addClass("hide").css("display", "none");
                selectedDateRange = $(this).data('range');
                __timeSelect($(this).data('range'));
            });
            //Select Campaign / Channel

            /*NOTE: We can now made one event for channels and campaigndropdwn */
            if (screen.campaignselect) {
                screen.campaignselect.on('click', 'li:not(.drop-first)', function () {
                    var obj = CHANNELS.get('channelid', $(this).data('channelid')),
                        title = obj.name;

                    __screenLoading();
                    Logs.setChannelID(obj.channelid);
                    if (bouncedetailsscreen) {
                        Logs.LoadBounced(function () {
                            __screenLoaded(title);
                            __changeTimeInputs();
                            screen.bouncecategoryfilter.trigger('update');
                        })
                    } else {
                        Logs.LoadAll(function () {
                            __screenLoaded(title);
                            __changeTimeInputs();
                            screen.bouncecategoryfilter.trigger('update');
                        })
                    }
                });
            }
            if (screen.channelselect) {
                screen.channelselect.on('click', 'li:not(.drop-first)', function () {
                    var obj = CHANNELS.get('channelid', $(this).data('channelid')),
                        title = obj.name;

                    __screenLoading();
                    Logs.setChannelID(obj.channelid);
                    if (bouncedetailsscreen) {
                        Logs.LoadBounced(function () {
                            __screenLoaded(title);
                            __changeTimeInputs();
                            screen.bouncecategoryfilter.trigger('update');
                        })
                    } else {
                        Logs.LoadAll(function () {
                            __screenLoaded(title);
                            screen.bouncecategoryfilter.trigger('update');
                        })
                    }
                });
            }
            //Select all
            screen.find('.eallsent').on('click', function () {
                __screenLoading();
                Logs.removeChannelID();
                if (bouncedetailsscreen) {
                    Logs.LoadBounced(function () {
                        __screenLoaded(ee.t.ovractivity);
                        screen.bouncecategoryfilter.trigger('update');
                    })
                } else {
                    Logs.LoadAll(function () {
                        __screenLoaded(ee.t.ovractivity);
                        screen.bouncecategoryfilter.trigger('update');
                    })
                }
            });

            //Pagination events
            screen.paginationresult.on('click', '.eview', function () {
                var msgid = $(this).data('msgid');
                __logpreview(Logs, msgid);
            });
            screen.paginationresult.on('click', '.eemaildetail', function () {
                var email = $(this).data('email');
                __showContactOverlay(email);
            });
            screen.paginationresult.on('click', '.ebouncemsg', function () {
                that.showBounceDetail(Logs.get('msgid', $(this).data('msgid')));
            });
            //Utils
            function __changeTimeRange() {
                
                var fromH = $('#etimefromselect').val() || '00:01',
                    toH = $('#etimetoselect').val() || '23:59',
                    dateTo = screen.dateto.datepicker('getDate'),
                    dateFrom = screen.datefrom.datepicker('getDate');
                
                dateFrom.setHours(+(fromH.split(":")[0]));
                dateFrom.setMinutes(+(fromH.split(":")[1]));
                dateTo.setHours(+(toH.split(":")[0]));
                dateTo.setMinutes(+(toH.split(":")[1]));

                if (dateFrom.getTime() < dateTo.getTime()) {
                    var title = __screenLoading(true);

                    const logsParams={dateTo, dateFrom, screen}
                    let query = that.generateloadLogsParams(logsParams)
                   
                    Logs.Load({
                        query: {
                            ...query,
                        }
                    },
                        function () {
                            __screenLoaded(title);
                            screen.bouncecategoryfilter.trigger('update');
                            delete cache.dateinited;
                        })
                }
            };
            function __screenLoading(returnOldTitle) {
                var title = (returnOldTitle) ? screen.campaigntitle.text().trim() : undefined;
                screen.campaigntitle.html('<span>' + ee.t.loading + '</span>');

                var $exportlogbtn = $('#eexportlogbtn');
                var $bouncebutton = $('#ebouncebutton');
                var $paginationresult = $('#paginationresult');

                if ($exportlogbtn) { $exportlogbtn.addClass('disabled'); }
                if ($bouncebutton) {
                    $bouncebutton.addClass('disabled');
                    $bouncebutton.html(bouncedLoadinBtn);
                }
                if ($paginationresult) { $paginationresult.addClass('eloading') }

                return title;
            };
            function __generatContactsSearchMetod(rule) {
                var offset = 0,
                    limit = 10;
                return function __generatedFn(callback) {

                    ee.api.request(contactsLink, {
                        limit: limit,
                        offset: offset,
                        rule: rule
                    }, function (data) {
                        if (!data.success) return html.modal.error(data);
                        var notAll = false;
                        if (data.data.length === limit) {
                            offset += data.data.length;
                            notAll = true;
                        };
                        if (callback) callback(data.data, notAll);
                    }, 300000, undefined, undefined, undefined, true);
                }
            };
            function __timeSelect(range) {
                const generateParamsQuery = {range, screen}
                const loadQuery = that.generateloadLogsParams(generateParamsQuery);
              
                let title = __screenLoading(true);
                Logs.Load({
                    query: {
                        ...loadQuery,
                    }
                },
                function () {
                    __screenLoaded(title);
                    __changeTimeInputs();
                    screen.bouncecategoryfilter.trigger('update');
                });
            };
            function __changeTimeInputs() {
                var timeFrom = Logs.getDateTime("from"),
                    timeTo = Logs.getDateTime("to");
                screen.datefrom.datepicker("setDate", timeFrom);
                screen.dateto.datepicker("setDate", timeTo);
                screen.timefromselect?.val(ee.api.formatDate(timeFrom, "hh:ii", false, true));
                screen.timetoselect?.val(ee.api.formatDate(timeTo, "hh:ii", false, true));
            };
            function __screenLoaded(title) {
                var logsLength = Logs.getLength();
                emailsList = Logs.getAll();
                pagination.update(Logs.getAll());
                screen.paginationresult.removeClass('eloading');
                if (logsLength > 0) {
                    var $exportlogbtn = $('#eexportlogbtn');
                    if ($exportlogbtn) {  $exportlogbtn.removeClass('disabled'); }
                }
                screen.campaigntitle.html('<span>' + title + '</span>');
                notAll = (logsLength > 0 && (logsLength % 500) === 0);
            };
        }
    }; // end of public obj

    return that;

}());