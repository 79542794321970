(function (namespace) {

    //Format raw data from backend
    function _format() {
        this.expiresFRMTD = (this.expires) ? ee.tools.time(this.expires).fromUTC('L') : ee.t.never;
        this.datecreatedFRMTD = moment(this.datecreated).format('L');
        this.lastactivityFRMTD = (!this.lastuse) ? 'Never' : moment(this.lastuse).format('L LT');
        if (this.accesslevel && !Array.isArray(this.accesslevel)) this.accesslevel = this.accesslevel.split(',');
        this.accesstype = html.widget.AccessManager.getAccessType(this.accesslevel);
    };

    var ApikeysList = function Apikeys() {
        Collection.Data.call(this, []);
    };

    var options = {
        collection: true
    };

    var proto = {
        load: function (type) {
            let self = this;
            let method;
            if (type === "smtp") {
                method = EE_API.Access.ListSmtp
            } else {
                method = EE_API.Access.ListApikeys
            }
            return method()
                .then(function (data) {
                    self.update(data);
                });
        },
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        },
        invite: function (query) {

            var self = this;
            isapikey = query.type === "APIKey"
            return EE_API.Access.Add(query)
                .then(function (data) {
                    let newapikey = html.get("NewAccessModal", { apikey: data, query, isapikey, smtp: ee.data.account.smtp, smtpalt: ee.data.account.smtpalternative });
                    let options = {
                        confirmTitle: `${ee.t.returnto} ${isapikey ? ee.t.api :ee.t.smtp}`,
                        confirmCSS: "btn_basic"
                    }
                    let newapikeymodal = html.modal.info(newapikey, isapikey ? ee.t.apikeycreated : ee.t.smtpcreated, {}, function () {
                        if (query.type === "APIKey") {
                        ee.goTo("#/settings/apikey")
                        return EE_API.Access.ListApikeys();
                        } else {
                            ee.goTo("#/settings/smtp")
                            return EE_API.Access.ListSmtp();
                        }
                    }, options);
                    if (html.clickToCopy.test()) {
                        html.clickToCopy.init(newapikey);
                    }
                })
        },
        removeApikey: function (name, AccessTokenType) {
            var self = this;
            let tokenName = name;
            return EE_API.Access.Delete(tokenName, AccessTokenType )
                .then(function () {
                    self.remove('name', name);
                });
        },
        editApikey: function (query, apikeys) {
            var self = apikeys;
            return EE_API.Access.Edit(query)
            .then(function () {
                var obj = self.get('name', query.tokenName);
                obj.accesslevel = query.accesslevel;
                _format.call(obj);
            });
        }
    };

    dataModel.inherit(ApikeysList, proto, options).register("ApikeysList", ApikeysList);

}(window.dataModel));
