(function () {
    function APICOM() { };
    APICOM.prototype = {
        path: "https://api.elasticemail.com",
        usegzip: true,
        xhrPool: [],
        request: function (target, data, callback, timeout, usepost, version, datatype, quiet) {
            if (ee.session.isSessionExpired) {

                ee.indiOff();
                return;
            }

            var timeoutCheck = false, actualview = ee.Router.controller(), apikey = ee.session.apikey();

            if (!data.apikey && apikey) {
                data.apikey = apikey;
            }

            if (!timeout) timeout = 30000;
            if (!version) version = 2;

            ee.session.setImpersonation(data);

            var currentLanguage = Cookies.get("elocale");
            if (!currentLanguage) {
                if(ee.parameters.locale){
                    currentLanguage = ee.parameters.locale
                } else {
                    currentLanguage = 'en'
                }
            }

            const logoutAction = () => {
                const defaultClickAction = () => {
                    ee.views.login.logout(false);
                };

                html.modal.info(
                    ee.t.apikeyexpiredtext,
                    ee.t.apikeyexpiredtitle,
                    '600px',
                    defaultClickAction,
                    { strictClose: true }
                );
            };

            return $.ajax({
                type: "POST",
                url: this.path + target + "?version=" + version,
                dataType: ((!datatype) ? "json" : datatype),
                cache: false,
                headers: {
                    'Accept-Language': currentLanguage
                },
                data: this.parameterize(data),
                beforeSend: function (jqXHR, settings) {
                    if (!quiet) {
                        ee.indiOn();
                        //timeoutError - to manage lack of information from server about success or error
                        timeoutCheck = setTimeout(function () {
                            // do not show errors from different page
                            if (ee.Router.controller() != actualview) {
                                return;
                            }
                            //templates send many requests at once
                            if (ee.Router.controller() != "templates") {
                                ee.api.abortAll();
                            }
                            
                        }, timeout);

                        ee.api.xhrPool.push(jqXHR);
                    }
                },
                success: (resp) => {


                    if (resp.error && resp.error.toLowerCase().indexOf("apikey expired") >= 0) {
                        //automatic logout for dashboardsession expiry
                        localStorage.removeItem('remember');
                        sessionStorage.removeItem('remember');

                        logoutAction();

                        ee.session.isSessionExpired = false;
                        ee.indiOff();

                        return
                    }

                    callback(resp)
                },
                timeout: timeout,
                error: function (jqXHR, textStatus, errorThrown) {
                    jqXHR.usepost = usepost;
                    ee.api.ajaxError(jqXHR, textStatus, errorThrown);
                },
                complete: function (jqXHR) {

                    var index = ee.api.xhrPool.indexOf(jqXHR);
                    if (index > -1) {
                        ee.api.xhrPool.splice(index, 1);
                    }
                    clearTimeout(timeoutCheck);

                }
            })
        },
        abortAll: function () {
            $(ee.api.xhrPool).each(function (idx, jqXHR) {
                jqXHR.abort();
            });
            ee.api.xhrPool = [];
        },
        ajaxError: function (jqXHR, textStatus, errorThrown, callback) {
            if (textStatus !== 'timeout') {
                $(".emodal").remove();
            }
            console.log(textStatus, errorThrown);
            if (typeof this.errorHandler == "function")
                this.errorHandler(textStatus, errorThrown);
            else
                jqXHR.success({ error: errorThrown });
        },
        parameterize: function (obj) {
            var params = "";
            if (obj == null)
                return params;
            for (var id in obj) {
                var val = obj[id] + "";
                params += "&" + encodeURIComponent(id) + "=" + encodeURIComponent(val);
            }
            return params.substring(1);
        },
        uploadFile: function (formdata, target, options) {
            var host = this.path + (target || "");
            var that = $.extend({
                silent: false,
                params: {},
                onProgress: function (event) { if (that.fsb === false) { that.fsb = true; } },
                onSuccess: function (event) { ee.indiOff(); },
                onAbort: function (event) { ee.indiOff(); },
                onError: function (event) {
                    ee.indiOff();
                    if (typeof event === "string") {
                        html.modal.closeAll();
                        html.modal.info(event, ee.t.error);
                    } else if (event.target.responseText) {
                        html.modal.info(event.target.status + "<br/>" + event.target.responseText, ee.t.error);
                    }
                },
            }, options);
            that.target = host;
            that.fsb = true;
            that.formdata = formdata;
            that.xhr = new XMLHttpRequest();
            that.start = function () {
                this.xhr.upload.addEventListener("progress", function (e) {
                    that.onProgress(e);
                }, false);
                this.xhr.addEventListener("load", function (e) {
                    try {
                        var response = JSON.parse(e.target.responseText);
                    } catch (ex) {
                        that.onError(ex.message);
                    }
                    if (!response || response.success === false) {
                        that.onError(response.error);
                    } else {
                        if (that.onSuccess) {
                            that.onSuccess(response);
                        }
                    }
                }, false);
                this.xhr.addEventListener("error", that.onError, false);
                this.xhr.addEventListener("abort", that.onAbort, false);
                if ($.isEmptyObject(that.params) === false) {
                    host += "?" + ee.api.parameterize(that.params);
                    try {
                        if (sessionStorage.getItem('subaccountemailtouse')) host += '&subaccountemailtouse=' + encodeURIComponent(sessionStorage.getItem('subaccountemailtouse'));
                    } catch(err) {
                        console.log(err);
                        console.log("You need to enable session storage");
                    }
                }
                if (that.silent === false) {
                    ee.indiOn();
                }
                this.xhr.open("POST", host, true);
                this.xhr.send(formdata);
            }
            return that;
        },
        sortTemplatesBy: (currTemplateType, pagination, filterTemplates, $screen) => {
            $('#sortby-button').on('click', () => {
                $('.sorticon').toggleClass('icondown iconup');
                $('.sorticon').toggleClass('fa-sort-down fa-sort-up');
                window.addEventListener('click', () => {
                    if ($('.sortby').hasClass('open')) {
                        $('.sorticon').addClass('iconup');
                        $('.sorticon').addClass('fa-sort-up');
                        $('.sorticon').removeClass('icondown');
                        $('.sorticon').removeClass('fa-sort-down');
                    } else {
                        $('.sorticon').removeClass('iconup');
                        $('.sorticon').removeClass('fa-sort-up');
                        $('.sorticon').addClass('icondown');
                        $('.sorticon').addClass('fa-sort-down');
                    }
                })
            });
            
            $('#templatesortby li').on('click', (e) => { 
                $('#sortbytitlebutton').text(e.target.textContent);
                let templateorder;
                switch (e.target.getAttribute('data-range')) {
                    case 'newestfirst':
                        templateorder = 1;
                        break;
                    case 'oldestfirst':
                        templateorder = 0;
                        break;
                    case 'firstmodified':
                        templateorder = 4;
                        break;
                    case 'lastmodified':
                        templateorder = 5;
                        break;
                    case 'nameaz':
                        templateorder = 2
                        break;
                    case 'nameza':
                        templateorder = 3
                        break;
                    default:
                        templateorder = 1; //default is newest first
                        break;
                }
                const searchstring = $('#esearchinputtemplates').val();
                let query = {templateOrder: templateorder, search: searchstring}
                EE_API.Template.GetList(query)
                .then ((response) => {
                    response[currTemplateType].forEach(element => {
                        element.thumbnailpath = `${ee.api.path}/userfile${element.thumbnail}`;
                        element.mediumscreenshotpath = `${ee.api.path}/userfile${element.mediumscreenshot}`;
                    })
                    
                    ee.data[currTemplateType] = new Collection.Data(response[currTemplateType]);
                    pagination.updateData(filterTemplates(ee.data[currTemplateType].data));
                    pagination.refresh();
                    html.widget.TemplateThumbnail.init($screen);
                })
                
                window.addEventListener('hashchange', () => {
                    if (templateorder !== 1) {
                        templateorder = 1;
                    }
                });
                
            })
        },
        //Template API
        loadTemplates: function (callback, quiet) {
            var path = "/template/getlist",
                query = {};
            ee.api.request(path, query, function (response) {
                ee.indiOff();
                if (response.success) {
                    var data = response.data, n = data.templates.length;
                    //separate template for branches
                    for (let i = 0; i < n; i++) {
                        ee.api._parseTemplate(data.templates[i]);
                    }
                    n = data.drafttemplate.length;
                    for (let i = 0; i < n; i++) {
                        ee.api._parseTemplate(data.drafttemplate[i]);
                        data.drafttemplate[i].isdraft = true;
                    }
                    n = data.globaltemplate.length;
                    for (let i = 0; i < n; i++) {
                        ee.api._parseTemplate(data.globaltemplate[i]);
                    }
                    n = data.parenttemplate.length;
                    for (let i = 0; i < n; i++) {
                        ee.api._parseTemplate(data.parenttemplate[i]);
                    }

                    ee.data.templates = new Collection.Data(data.templates);
                    ee.data.templatescount = data.templatescount;
                    ee.data.templatesdraft = new Collection.Data(data.drafttemplate);
                    ee.data.templatesdraftcount = data.drafttemplatescount;
                    ee.data.templatesglobal = new Collection.Data(data.globaltemplate);
                    ee.data.templatesglobalcount = data.globaltemplatescount;
                    ee.data.templatesparent = new Collection.Data(data.parenttemplate);
                    ee.data.templatesparentcount = data.parenttemplatescount;
                    ee.data.templatesglobalandparent = new Collection.Data(ee.data.templatesglobal.data.concat(ee.data.templatesparent.data))

                    if (callback) {
                        callback();
                    } else {
                        ee.view.show();
                    }
                } else {
                    html.modal.error(response, { path: path });
                }
            }, false, false, false, false, quiet);
        },
        loadTemplateContent: function (template, callback) {
            ee.indiOn();
            var path = "/template/loadtemplate",
                query = { templateid: template.templateid, ispublic: (template.ispublic || false) };
            ee.api.request(path, query, function (response) {
                ee.indiOff();
                if (response.success) {
                    var loadedTemplate = ee.api._parseTemplate(response.data);
                    loadedTemplate.contentLoaded = true;
                    if (callback) {
                        callback(loadedTemplate);
                    } else
                        ee.view.show();
                } else {
                    html.modal.error(response, { path: path });
                }
            });
        },
        _parseTemplate: function (template, loaded) {
            template.typename = ee.editors.core._editorsTypeName[template.templatetype];
            template.contentLoaded = false;
            if (template.thumbnail) template.thumbnailpath = `${ee.api.path}/userfile${template.thumbnail}`;
            if (template.mediumscreenshot) template.mediumscreenshotpath = `${ee.api.path}/userfile${template.mediumscreenshot}?timestamp=${(new Date().getTime())}`;
            if (template.screenshot) template.screenshotpath = `${ee.api.path}/userfile${template.screenshot}`;
            if (template.bodytext == "null")
                template.bodytext = "";
            if (template.bodyamp == null || template.bodyamp == "null")
                template.bodyamp = "";
            if (template.css == "null")
                template.css = "";
            return template;
        },
        saveTemplate: function (template, callback, refreshScreenshot, silent) {
            var path = "/template/add";
            if (template.templateid > 0) {
                path = "/template/update";
            }
            if (refreshScreenshot === false) {
                template.removeScreenshot = false;
            } else {
                template.removeScreenshot = true;
                //set out of date for a screenshot
                template.screenshotpath = ee.api.path + "/userfile/" + ee.data.account.publicaccountid + "/templates" + template.screenshot + "?timestamp=" + (new Date().getTime());
            }
            template.name = template.name.substring(0, 50);
            if (ee.tools.isNullOrEmpty(template.bodytext)) delete template.bodytext;
            if (ee.tools.isNullOrEmpty(template.bodyhtml)) delete template.bodyhtml;
            if (ee.tools.isNullOrEmpty(template.bodyamp)) delete template.bodyamp;
            if (ee.tools.isNullOrEmpty(template.css)) delete template.css;
            if (ee.tools.isNullOrEmpty(ee.virpath)) delete ee.virpath;
            delete template.t;
            ee.api.request(path, template, function (data) {
                ee.indiOff();
                template.isdirty = false;
                template.ispublic = false;
                delete template.apikey;
                if (template.templateid > 0) {
                    if (template.templatescope === 2) {
                        ee.data.templatesdraft.replace('templateid', template.templateid, template);
                    } else {
                        //TODO: move this to get default data template core
                        if (template.isdefault === true) {
                            template.originaltemplateid = (template.originaltemplateid === 0) ? -1 : Math.abs(template.originaltemplateid) * -1
                            ee.data.templates.each(function () {
                                if (this.isdefault && this.templateid !== template.templateid && this.templatetype === template.templatetype) {
                                    this.isdefault = false;
                                    this.originaltemplateid = Math.abs(this.originaltemplateid);
                                }
                            });
                        }
                        ee.data.templates.replace('templateid', template.templateid, template);
                    }
                } else {
                    template.templateid = data.data;
                    if (template.templatescope === 2) {
                        ee.data.templatesdraft.add("unshift", template);
                    } else {
                        ee.data.templates.add("unshift", template);
                        ee.data.selectedtemplate = template;
                    }
                }
                if (data.success) {
                    if (callback) {
                        callback(data);
                    } else {
                        ee.view.show();
                    }
                }
                else {
                    html.modal.error(data, { path: path, query: template });
                }
            }, 30000, true, 2, false, silent);
        },
        deleteTemplate: function (templateid, scope, callback, silent) {
            ee.indiOn();
            if (isNaN(parseInt(scope, 10))) scope = 0;
            var path = "/template/delete",
                query = { templateid: templateid };
            // (target, data, callback, timeout, usepost, version, datatype, quiet)
            ee.api.request(path, query, function (data) {
                ee.indiOff();

                if (data.success) {
                    var listTemplate = (scope === 2) ? ee.data.templatesdraft : ee.data.templates;
                    listTemplate.remove('templateid', parseInt(templateid, 10));
                    (ee.data.templatescount) ? ee.data.templatescount-- : '';
                    (callback) ? callback() : ee.view.show();
                }
                else {
                    html.modal.error(data, { path: path });
                }
            }, false, false, false, false, silent);
        },
        saveTemplateSnapshot: function (snapshot, callback) {
            ee.api.request('/template/createsnapshot', snapshot, function (response) {
                ee.indiOff();
                if (response.success) {
                    callback(response.data);
                } else {
                    html.modal.error(response, { path: '/template/createsnapshot', query: snapshot });
                }
            }, 30000, true, 2, false, true);

        },
        loadTemplateSnapshots: function (templateid, callback) {
            ee.api.request('/template/listsnapshot', { templateid: templateid }, function (response) {
                ee.indiOff();
                if (response.success) {
                    callback(response.data.templates);
                } else {
                    html.modal.error(response, { path: '/template/createsnapshot', query: snapshot });
                }
            });

        },
        
        
        //Pools/IPs API
        loadPools: function (callback) {
            ee.api.request('/pool/list', {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.account.pools = new Collection.Data(data.data);
                    ee.data.account.pools.each(function () {
                        this.value = this.name.toLowerCase();
                        if (this.ip.length <= 0) this.isempty = true;
                    });
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                } else { html.modal.error(data); };
            });
        },
        purchasePrivateIP: function (region, quantity, callback) {
            var path = "/account/purchaseprivateip",
                query = { region: region, quantity: quantity };
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    var message = !_.includes(["EmailApi", "Unlimited"], ee.getPricingPlanName(ee.data.account.pricingplan)) ? ee.t.iprequestmessage + " " + data.data.privateips[0].rdnsconfiguration : ee.t.iprequestmessage;
                    html.modal.info(message, ee.t.success);
                    if (callback)
                        callback();
                } else {
                    html.modal.error(data,
                        {
                            path: path,
                            onClose: function() {
                                ee.goTo("#/payments")
                            }
                        });
                }
            });
        },
        addDedicatedSupport: function (supportplan, callback) {
            var path = "/account/adddedicatedsupport",
                query = { supportplan: supportplan };
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    location.reload();
                    if (callback)
                        callback();
                } else {
                    html.modal.error(data,
                        {
                            path: path,
                            onClose: function() {
                                ee.goTo("#/payments")
                            }
                        });
                }
            });
        },
        loadIPAddresses: function (callback) {
            var path = "admin/loadipaddresses",
                query = {
                    mailerid: (ee.data.account.assignedmailerid ? ee.data.account.assignedmailerid : ee.data.account.mailerid),
                };
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    var account = ee.data.account;
                    account.ipaddresses = new Collection.Data(data.ipaddresses);

                    if (ee.session.can.AdminViewIP && ee.session.can.AdminModifyIP) {
                        account.displayipdetails = true;
                        account.allowrdnsedit = true;
                    }
                    if (callback) {
                        callback();
                    } else {
                        ee.view.show();
                    }
                }
                else {
                    html.modal.error(data, { path: path });
                }
            });
        },
        //email/send version 2.0
        send: function (data, callback) {
            data.encodingtype = ee.data.account.contenttransferencoding;
            ee.indiOn();
            ee.api.request("/email/send", data, function (result) {
                ee.indiOff();
                if (result.success === true) {
                    html.modal.info(ee.t.testemailsent);
                    if (callback) callback(result);
                } else {
                    html.modal.info(result.error);
                }
            }, 30000, true);
        },
        saveCampaign: function (campaign, callback) {
            ee.indiOn();
            campaign.channelid = parseInt(campaign.channelid, 10);
            campaign.name = ee.tools.StringRemoveEmoji(campaign.name);
            var apiPath = "/campaign/add";
            if (campaign.channelid > 0) {
                apiPath = "/campaign/update";
                ee.data.channelscreenshotrefresh = campaign.channelid;
            }
            var query = { campaign: JSON.stringify(campaign) };
            ee.api.request(apiPath, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    //Note: Temporary force reload the screen reports
                    if (ee.data.reports) {
                        ee.data.reports = false;
                    }
                    delete ee.data.channels;
                    if (callback) {
                        callback();
                    } else {
                        ee.goTo("#/campaigns");
                    }
                } else {
                    if (data.error === "Campaign name already exists") {
                        ee.view.setErrorCampaignName();
                    }
                    html.modal.error(data, { path: apiPath });
                }
            }, 120000, true);
        },
        //User file API
        loadFiles: function (callback) {
            ee.indiOn();
            var path = "/obsoletefile/list",
                query = {};
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.files = {};
                    data.files.sortByProp("userfileid").reverse();
                    data.images.sortByProp("userfileid").reverse();
                    ee.data.files.filelist = new Collection.Data(data.files);
                    ee.data.files.imagelist = new Collection.Data(data.images);
                    var i = 0
                    ee.data.files.filelist.each(function () {
                        this.url = ee.api.apiurl + "/userfile/" + (ee.data.account.publicaccountid || ee.data.account.affiliatelink) + "/" + this.name;
                        this.index = i++;
                    });
                    i = 0;
                    ee.data.files.imagelist.each(function () {
                        this.url = ee.api.apiurl + "/userfile/" + (ee.data.account.publicaccountid || ee.data.account.affiliatelink) + "/" + this.name;
                        this.index = i++;
                    });
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data, { path: path });
                }
            });
        },
        //Stats API
        loadstats: function (query, callback) {
            if (query.interval === "hourly") {
                //ChanelStatmentDetails
                query.from = ee.tools.time(query.from).toUTC();
                query.to = ee.tools.time(query.to).toUTC();
            } else {
                query.from = ee.tools.time(query.from).toDate();
                query.to = ee.tools.time(query.to).toDate();
                //Summary interval StatmentDetails
            }

            var path = "/log/summary";

            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    data.stats = $.extend({}, data.data);
                    data.stats.logstatussummary.bounced = data.stats.logstatussummary.bounced - data.stats.bouncedcategorysummary.manualcancel;

                    data.stats.delivery = {
                        //  recipients: data.stats.logstatussummary.recipients,
                        delivered: (data.stats.logstatussummary.recipients <= 0 || data.stats.logstatussummary.delivered <= 0) ? "0.00" : ((data.stats.logstatussummary.delivered / data.stats.logstatussummary.recipients) * 100).toFixed(2), // calculate percent off emails and sms total
                        opened: data.stats.logstatussummary.emailtotal <= 0 ? "0.00" : ((data.stats.logstatussummary.opened / data.stats.logstatussummary.emailtotal) * 100).toFixed(2), // calculate percent off emails only (not SMS)
                        clicked: data.stats.logstatussummary.emailtotal <= 0 ? "0.00" : ((data.stats.logstatussummary.clicked / data.stats.logstatussummary.emailtotal) * 100).toFixed(2), // calculate percent off emails only (not SMS)
                        bounced: (data.stats.logstatussummary.recipients <= 0 || data.stats.logstatussummary.bounced <= 0) ? "0.00" : ((data.stats.logstatussummary.bounced / data.stats.logstatussummary.recipients) * 100).toFixed(2), // calculate percent off emails and sms total
                        unsubscribed: data.stats.logstatussummary.emailtotal <= 0 ? "0.00" : ((data.stats.logstatussummary.unsubscribed / data.stats.logstatussummary.emailtotal) * 100).toFixed(2), // calculate percent off emails only (not SMS)
                        complaints: data.stats.logstatussummary.emailtotal <= 0 ? "0.00" : ((data.stats.logstatussummary.complaints / data.stats.logstatussummary.emailtotal) * 100).toFixed(2), // calculate percent off emails only (not SMS)
                        manualcancel: data.stats.bouncedcategorysummary.manualcancel <= 0 ? "0.00" : ((data.stats.bouncedcategorysummary.manualcancel / data.stats.logstatussummary.emailtotal) * 100).toFixed(2)
                    }
                    //count statistic for bounced category
                    var bouncedCategoryName = {
                        spam: { name: ee.t.spam, sn: 2 },
                        blacklisted: { name: ee.t.blacklisted, sn: 3 },
                        nomailbox: { name: ee.t.nomailbox, sn: 4 },
                        greylisted: { name: ee.t.greylisted, sn: 5 },
                        throttled: { name: ee.t.throttledcategory, sn: 6 },
                        timeout: { name: ee.t.timeout, sn: 7 },
                        connectionproblem: { name: ee.t.connectionproblem, sn: 8 },
                        spfproblem: { name: ee.t.spfproblem, sn: 9 },
                        accountproblem: { name: ee.t.accountproblem, sn: 10 },
                        dnsproblem: { name: ee.t.dnsproblem, sn: 11 },
                        codeerror: { name: ee.t.codeerror, sn: 13 },
                        connectionterminated: { name: ee.t.connectionterminated, sn: 15 },
                        notdelivered: { name: ee.t.suppressed, sn: 16 }
                    }
                    var bouncedCount = data.stats.logstatussummary.bounced;
                    var sum = 0, unknown = 0;
                    if (data.stats.delivery.manualcancel > 0) data.stats.bouncedcategorysummary.notdelivered += data.stats.bouncedcategorysummary.manualcancel;
                    var bouncedCategory = data.stats.bouncedcategorysummary;
                    var list = [];
                    for (var category in bouncedCategory) {
                        if (bouncedCategory[category] > 0 && bouncedCategoryName[category]) {
                            var item = bouncedCategoryName[category];
                            sum += bouncedCategory[category];
                            item.count = bouncedCategory[category];
                            if (bouncedCategoryName[category].sn !== 16) {
                                item.percent = (bouncedCategory[category] > 0) ? ((bouncedCategory[category] / bouncedCount) * 100).toFixed(2) : 0;
                            } else {
                                item.sn = '12,14,16';
                            }
                            item.countformatted = bouncedCategory[category].numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
                            list.push(item);
                        }
                    }
                    unknown = (bouncedCount - sum);
                    if (unknown > 0) {
                        list.push({ count: unknown, countformatted: unknown.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator), percent: ((unknown / bouncedCount) * 100).toFixed(2), name: ee.t.unknown, sn: 0 });
                    }
                    /*if (data.stats.delivery.manualcancel > 0) {
                        list.push({ count: data.stats.bouncedcategorysummary.manualcancel, countformatted: data.stats.bouncedcategorysummary.manualcancel.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator), percent: data.stats.delivery.manualcancel, name: ee.t.manualcancel, sn: 14 });
                    }*/
                    list.sortByProp('count');
                    list.reverse();
                    data.stats.bouncedcategorysummary = list;

                    var n = data.stats.dailylogstatussummary.length;
                    for (var i = 0; i < n; i++) {
                        if (query.interval === "hourly") data.stats.dailylogstatussummary[i]['date'] = ee.api.formatDate(data.stats.dailylogstatussummary[i]['date'], "yy-mm-dd hh:ii");
                        data.stats.dailylogstatussummary[i]['suppressed'] = data.stats.dailylogstatussummary[i]['notdelivered'] + data.stats.dailylogstatussummary[i]['manualcancel'];
                    }


                    ee.data["stats" + query.channelid] = data.stats;
                    if (callback)
                        callback(data);
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data, { path: path });
                }

            }, undefined, undefined, undefined, undefined, true);
        },
        loadaccountstats: function (query, callback, silent) {
            query.from = ee.tools.time(query.from).toUTC("00:00:00");
            query.to = ee.tools.time(query.to).toUTC("23:59:59");

            var path = "/log/summary";

            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    data.stats = $.extend({}, data.data);
                    ee.data.account.lastdaysend = data.stats.logstatussummary.emailtotal;
                    ee.data.account.lastdaysendsubaccounts = data.stats.subaccountsummary.emailssenttoday;
                    ee.data.account.thismonthsendsubaccounts = data.stats.subaccountsummary.emailssentthismonth;
                    ee.data.account.thismonthsendsubaccountsFormatted = ee.data.account.thismonthsendsubaccounts.numberFormat();
                    ee.data.account.lastdaysendsubaccountsFormatted = ee.data.account.lastdaysendsubaccounts.numberFormat();
                    ee.data.account.lastdaysendFormatted = ee.data.account.lastdaysend.numberFormat();
                    ee.data.account.lastdaysendMainFormatted = ee.data.account.lastdaysendFormatted - ee.data.account.lastdaysendsubaccountsFormatted;
                    ee.data.account.thismonthsendMainFormatted = ee.data.account.monthlyemailssentformatted - ee.data.account.thismonthsendsubaccountsFormatted;
                    ee.data.account.totalemailssentFormatted = ee.data.account.totalemailssent.numberFormat();
                    ee.data.account.remainingcontacts = ee.data.account.maxcontacts - (ee.data.account.contactscount + ee.data.account.subaccountcontactscount);
                    ee.data.account.allcontactsstoredbillingperiod = ee.data.account.contactscount + ee.data.account.subaccountcontactscount;

                    if (callback)
                        callback();
                }
            }, undefined, undefined, undefined, undefined, silent);
        },
        _formatUsage: function (item, formatCost) {
            item.cost = item.cost.toFixed(2);
            item.jobcount = item.jobcount.numberFormat();
            item.recipientcount = item.recipientcount.numberFormat();
            item.inboundcount = (formatCost || item.inboundcount > 0) ? item.inboundcount.numberFormat() : 0;
            item.attachmentcount = (formatCost || item.attachmentcount > 0) ? item.attachmentcount.numberFormat() : 0;
            item.attachmentssize = (formatCost || item.attachmentssize > 0) ? item.attachmentssize.numberFormat() : 0;
            item.privateipcount = (formatCost || item.privateipcount > 0) ? item.privateipcount.numberFormat() : 0;
            item.privateipcost = (formatCost || item.privateipcost > 0) ? item.privateipcost.toFixed(2) : 0;
            item.smscount = (formatCost || item.smscount > 0) ? item.smscount.numberFormat() : 0;
            item.smscost = (formatCost || item.smscost > 0) ? item.smscost.toFixed(2) : 0;
            item.contactcost = (formatCost || item.contactcost > 0) ? item.contactcost.toFixed(2) : 0;
            item.supportcost = (formatCost || item.supportcost > 0) ? item.supportcost.toFixed(2) : 0;
            item.emailcost = (formatCost || item.emailcost > 0) ? item.emailcost.toFixed(2) : 0;
        },
        loadusage: function (from, to, callback, formatDate) {
            var path = "/account/loadusage",
                query = { from: from, to: to };
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    var tmp = data.data, usage = { list: [], empty: [] }, grandtotal = 0;
                    for (var i = 0, n = tmp.length; i < n; i++) {
                        var list = tmp[i].list;
                        if (list.length == 0) {
                            usage.empty.push({ email: tmp[i].email });
                            delete tmp[i];
                        } else {

                            var total = 0;
                            var totalCredits = 0;
                            var totalCounts = {};

                            for (var j = 0, m = list.length; j < m; j++) {
                                var item = list[j];
                                total += item.cost;
                                totalCredits += item.emailcreditscost;
                                for (var key in item) {
                                    var value = item[key];
                                    if (key === 'date' || $.isNumeric(value) === false) {
                                        continue;
                                    }
                                    if (!totalCounts[key]) {
                                        totalCounts[key] = value;
                                    }
                                    else {
                                        totalCounts[key] += value;
                                    }
                                }
                                //Inbound, SMS, PrivateIP, Advenced Tools, Support
                                list[j].date = ee.api.formatDate(item.date, formatDate, false, true);
                                ee.api._formatUsage(item, true);
                            }
                            list.reverse();
                            ee.api._formatUsage(totalCounts);
                            grandtotal += total;
                            tmp[i].total = total.toFixed(2);
                            tmp[i].currency = ee.data.account.currency;
                            tmp[i].accountname = tmp[i].email.replace(/[^a-zA-Z0-9]/g, "");
                            tmp[i].totalCounts = totalCounts;
                            usage.list.push(tmp[i]);
                        }
                    }
                    var totalAccounts = usage.list.length, mainAccount = usage.list[0];
                    if (totalAccounts > 0) {
                        mainAccount.open = true;
                        if (totalAccounts > 1 && mainAccount.issubaccount === false) {
                            mainAccount.grandtotal = grandtotal.toFixed(2);
                        }
                    }
                    if (totalAccounts === 1 && usage.empty.length === 0) {
                        mainAccount.chevrondown = true;
                    }

                    if (mainAccount && mainAccount.issubaccount === false && totalAccounts > 1) {
                        mainAccount.showgrandtotal = true;
                    }
                    ee.data.usage[from] = usage;
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data, { path: path });
                }
            }, 60000);
        },
        loadLinkContacts: function (query, callback) {

            var path = '/log/loadlinkcontacts';

            query = $.extend({}, query);

            delete query.from;
            delete query.to;

            return ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    var leng = data.data.contacts.length,
                        contact;
                    if (leng > 0) {
                        for (var i = 0; i < leng; i++) {
                            contact = data.data.contacts[i];
                            contact.lastclickedformatted = ee.api.formatDate(contact.lastclicked, 'yy-mm-dd hh:ii');
                        }
                    }
                    if (callback) callback(data);

                } else {
                    html.modal.error(data, { path: path });
                }
            });
        },
        //Logs API
        createFromCampaign: function (options, callback) {
            var path = '/list/CreateFromCampaign';
            return ee.api.request(path, options, function (data) {
                ee.indiOff();
                if (!data.success) return html.modal.error(data, { path: path });
                if (callback) callback();
            })
        },
        generateGenericMsg: function (recipient) {

            recipient.allowResend = true;

            if (recipient.messagecategory === "NotDelivered") {
                recipient.status = "Suppressed";
                recipient.statusname = ee.t.suppressed;
                if (recipient.message.indexOf("'Unsubscribed'") !== -1 || recipient.message.indexOf("'Complaint'") !== -1 || recipient.message.indexOf("transactional") !== -1) {
                    recipient.allowResend = false;
                }
                if (recipient.message.indexOf("'Invalid'") !== -1) {
                    recipient.confirmResend = true;
                }
            }
            if (recipient.status === "WaitingToRetry") {
                recipient.allowResend = false;
            }
            if (recipient.messagecategory === "NoMailbox") {
                recipient.confirmResend = true;
            }
            if (recipient.messagecategory === "ManualCancel" || recipient.messagecategory === "NotDeliveredCancelled") {
                recipient.status = "Suppressed";
                recipient.statusname = ee.t.suppressed;
            };


            if (recipient.message && recipient.message.substr(0, 1) === "[") {

                if (recipient.message.substr(0, 19) === "[Blocked Recipient]") {
                    recipient.genericmessage = ee.t.blockedrecipientdesc;
                } else if (recipient.message.substr(0, 18) === "[Unsubscribe List]") {
                    recipient.genericmessage = ee.t.unsubscribedesc;
                } else if (recipient.message.substr(0, 12) === "[Spam Check]") {
                    recipient.genericmessage = ee.t.spamcheckdesc;
                } else if (recipient.message.substr(0, 16) === "[Content Filter]") {
                    recipient.genericmessage = ee.t.contentfilterdesc;
                } else if (recipient.message.substr(0, 26) === "[Global Blocked Recipient]") {
                    recipient.genericmessage = ee.t.globalblockedrecipientdesc;
                } else if (recipient.message.substr(0, 16) === "[User Cancelled]") {
                    recipient.genericmessage = ee.t.usercancelleddesc;
                }

                recipient.messagecategoryLANG = recipient.messagecategory;


            } else {
                switch (recipient.messagecategory) {
                    case "Unknown":
                        recipient.genericmessage = ee.t.unknowndesc;
                        recipient.messagecategoryLANG = ee.t.unknown;
                        break;
                    case "BlackListed":
                        recipient.genericmessage = ee.t.blacklisteddesc;
                        recipient.messagecategoryLANG = ee.t.blacklisted;
                        break;
                    case "Ignore":
                        recipient.genericmessage = ee.t.ignoredesc;
                        recipient.messagecategoryLANG = ee.t.ignore;
                        break;
                    case "Spam":
                        recipient.genericmessage = ee.t.spamdesc;
                        recipient.messagecategoryLANG = ee.t.spam;
                        break;
                    case "NoMailbox":
                        recipient.genericmessage = ee.t.nomailboxdesc;
                        recipient.messagecategoryLANG = ee.t.nomailbox;
                        break;
                    case "GreyListed":
                        recipient.genericmessage = ee.t.greylisteddesc;
                        recipient.messagecategoryLANG = ee.t.greylisted;
                        break;
                    case "Throttled":
                        recipient.genericmessage = ee.t.throttleddesc;
                        recipient.messagecategoryLANG = ee.t.throttledcategory;
                        break;
                    case "Timeout":
                        recipient.genericmessage = ee.t.timeoutdesc;
                        recipient.messagecategoryLANG = ee.t.timeout;
                        break;
                    case "ConnectionProblem":
                        recipient.genericmessage = ee.t.connectionproblemdesc;
                        recipient.messagecategoryLANG = ee.t.connectionproblem;
                        break;
                    case "SPFProblem":
                        recipient.genericmessage = ee.t.spfproblemdesc;
                        recipient.messagecategoryLANG = ee.t.spfproblem;
                        break;
                    case "AccountProblem":
                        recipient.genericmessage = ee.t.accountproblemdesc;
                        recipient.messagecategoryLANG = ee.t.accountproblem;
                        break;
                    case "DNSProblem":
                        recipient.genericmessage = ee.t.dnsproblemdesc;
                        recipient.messagecategoryLANG = ee.t.dnsproblem;
                        break;
                    case "WhitelistingProblem":
                        recipient.genericmessage = ee.t.whitelistingproblemdesc;
                        recipient.messagecategoryLANG = ee.t.whitelistingproblem;
                        break;
                    case "CodeError":
                        recipient.genericmessage = ee.t.codeerrordesc;
                        recipient.messagecategoryLANG = ee.t.codeerror;
                        break;
                    case "ManualCancel":
                        recipient.genericmessage = ee.t.manualcanceldesc;
                        recipient.messagecategoryLANG = ee.t.manualcancel;
                        break;
                    case "ConnectionTerminated":
                        recipient.genericmessage = ee.t.connectionterminateddesc;
                        recipient.messagecategoryLANG = ee.t.connectionterminated;
                        break;
                    case "NotDeliveredCancelled":
                        recipient.messagecategoryLANG = ee.t.notdeliveredcancelled
                        recipient.genericmessage = ee.t.throttledfor48ho;
                        break;
                    default:
                        recipient.messagecategoryLANG = recipient.messagecategory;
                }

                if (recipient.messagecategory === "NotDelivered") {

                    recipient.messagecategoryLANG = ee.t.notdelivered;

                    switch (recipient.message) {
                        case "Recipient on contact block list.":
                            recipient.genericmessage = ee.t.blockedrecipientdesc;
                            break;
                        case "Not enough credit.":
                            recipient.genericmessage = ee.t.notenoughcreditdesc;
                            break;
                        case "Not enough credit for attachments.":
                            recipient.genericmessage = ee.t.notenoughcreditforattachments;
                            break;
                        default:
                            recipient.genericmessage = ee.t.notdelivereddesc;
                    }
                }
            }

            return recipient;
        },
        loadlinktracking: function (query, callback) {
            var path = "/log/linktracking";

            // lukasz, this limit will make the stats off?
            //query.limit = query.limit != 0 ? query.limit : 100;
            ee.api.request(path, query, function (data) {
                if (data.success) {
                    if (callback) callback(data);
                }
                else {
                    html.modal.error(data, { path: path });
                }
            }, 120000, undefined, undefined, undefined, true);
        },
        cancelinprogress: function (channel, callback) {
            //Note: fixed description for cancelinprogress
            html.modal.confirm(((channel.iscampaign === true) ? ee.t.desccancelcampaign : ee.t.desccancelinprogress), ee.t.cancelinprogress, function () {
                var path = "/log/cancelinprogress",
                    query = { apikey: ee.session.apikey(), channelid: channel.channelid };
                ee.api.request(path, query, function (data) {
                    ee.indiOn();
                    setTimeout(function () {
                        ee.indiOff();
                        if (ee.data.channels) delete ee.data.channels;
                        if (data.success) {
                            if (callback)
                                callback();
                            else {
                                ee.view.show();
                            }
                        }
                        else {
                            html.modal.error(data, { path: path });
                        }
                    }, 2000);
                });
            });
        },
        //Attachment API
        uploadAttachment: function (fileObj, callback) {
            // create a FormData instance, and append the file to it
            var fd = new FormData();
            fd.append('file', fileObj);
            // STEP 3
            // send the FormData instance with the XHR object
            var xhr = new XMLHttpRequest();
            var path = this.path + "/file/upload?&apikey=" + ee.session.apikey() + "&name=" + fileObj.name + "&version=2&expiresAfterDays=0";
            try {
                if (sessionStorage.getItem('subaccountemailtouse')) {
                    const subaccountemailtouse = (sessionStorage.getItem('subaccountemailtouse').startsWith('"') && sessionStorage.getItem('subaccountemailtouse').endsWith('"')) ? _.trim(sessionStorage.getItem('subaccountemailtouse'),'"') : sessionStorage.getItem('subaccountemailtouse')
                    console.log(subaccountemailtouse)
                    path += '&subaccountemailtouse=' + encodeURIComponent(subaccountemailtouse); 
                }
            } catch(err) {
                console.log(err);
                console.log("You need to enable session storage");
            }
            xhr.open('POST', path, true);
            xhr.onload = function (e) {
                var result = eval('(' + e.target.responseText.replace(/\r\n/g, '') + ')');
                if (result.error) {
                    ee.indiOff();
                    html.modal.info(result.error, ee.t.error);
                }
                else {
                    result.data.sizeMB = ee.tools.bytesToSize(result.data.size);
                    if (callback) callback(result.data);
                }
            };
            xhr.send(fd);
        },
        removeAttachment: function (filename, callback) {
            ee.api.request("/attachment/remove", { filename: filename }, function (data) {
                if (data.success) {
                    if (callback) callback();
                } else {
                    console.log(data.error);
                }
            }, undefined, undefined, undefined, undefined, true);
        },
        //Campaign API
        loadcampaigns: function (callback) {
            function colorClass(val, type) {
                switch (type) {
                    case 'delivered':
                        if (val >= 95) return 'egreat';
                        if (val >= 90) return "egood";
                        if (val >= 80) return "enormal";
                        if (val >= 50) return "ebad";
                        return "eterrible";
                        break;
                    case 'opened':
                        if (val >= 20) return "egreat";
                        if (val >= 10) return "egood";
                        if (val >= 5) return "enormal";
                        if (val >= 1) return "ebad";
                        return "eterrible";
                        break;
                    case 'clicked':
                        if (val >= 5) return "egreat";
                        if (val >= 2) return "egood";
                        if (val >= 0.1) return "enormal";
                        if (val >= 0.05) return "ebad";
                        return "eterrible";
                        break;
                    case 'bounced':
                        if (val <= 1) return "egreat";
                        if (val <= 3) return "egood";
                        if (val <= 5) return "enormal";
                        if (val <= 10) return "ebad";
                        return "eterrible";
                }
            };
            if (ee.session.can.ViewCampaigns) {
                var path = "/campaign/list";
            } else if (ee.session.can.ViewChannels) {
                var path = "/channel/list";

            }
            var query = {};

            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.channels = new Collection.Data(data.data);
                    ee.data.openclickcampaigns = [];
                    ee.data.channels.each(function () {
                        ee.views.campaigns.formatSingleChannel(this)
                    });
                    ee.data.channelscreenshotrefresh = null;
                    if (callback) {
                        callback();
                    }
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data, { path: path });
                }

            }, undefined, undefined, undefined, undefined, true);
        },
        deleteCampaign: function (channelid, callback) {
            ee.indiOn();
            var path = "/campaign/delete",
                query = { channelid: channelid };

            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {

                    //Note: Clean data for newCampaugn screen
                    if (ee.data.channels) {
                        ee.data.channels.remove("channelid", channelid);
                    }
                    if (callback) callback();
                    else ee.view.show();
                } else {
                    html.modal.error(data, { path: path });
                }

            });
        },
        formatLists: function (list) {
            list.dateadded = ee.api.formatDate(list.dateadded);
            list.countformatted = list.count.numberFormat();
            list.rule = "List = " + list.listid;
            list.name = list.listname;
            list.rulelocal = "listname = '" + list.listname + "'";

            list.islist = true;

        },
        assignContactParameters: function (query, rule, emails) {
            if (emails)
                query.emails = emails;
            else if (rule)
                query.rule = rule;
            else
                query.allcontacts = true;
        },
        addNewList: function (listname, createemptylist, rule, emails, allowunsubscribe, callback) {
            var query = { listname: listname, allowunsubscribe: allowunsubscribe };
            if (createemptylist)
                query.createemptylist = true;
            else
                ee.api.assignContactParameters(query, rule, emails);

            var path = "/list/add";
            ee.api.request(path, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    if (ee.data.contactgroups) {
                        delete ee.data.contactgroups;
                    }
                    if (callback)
                        callback(data.data);
                    else
                        ee.goTo("#/contacts/list/" + data.data);
                }
                else {
                    html.modal.error(data, { path: path });
                }
            }, 120000, true);
        },
        //Contacts API
        loadContactOverview: function (callback) {
            //TMP FIX
            if (!ee.data.availablefields) {
                var availablefields = dataModel.create("ContactFields");
                $.when(availablefields.require()).then(function (data) {
                    ee.api.loadContactOverview(callback);
                });
                return;
            }
            var path = "/contact/loadcontactoverview",
                query = { includeHistory: 'false' };

            ee.api.request(path, query, function (response) {
                if (response.success) {
                    ee.indiOff();
                    ee.data.contactgroups = new Collection.Data(response.data.segments);
                    ee.data.contactgroups.add(response.data.lists);
                    for (var i = 0, n = response.data.lists.length; i < n; i++) {
                        ee.api.formatLists(response.data.lists[i]);
                    }
                    for (var i = 0, n = response.data.segments.length; i < n; i++) {
                        ee.api.formatSegments(response.data.segments[i]);
                    }
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    ee.indiOff();
                    html.modal.error(response, { path: path });
                }
            });
        },
        exportData: function (query, path, callback) {
            if (!ee.tools.isValidFileName(query.filename)) return html.modal.info(ee.t.exportfilenamechartsmsg);
            ee.api.request(path, query, function (response) {
                delete ee.data.exports;
                if (response.success) {
                    if (callback)
                        callback();
                    else
                        ee.goTo("#/exports");
                }
                else {
                    ee.indiOff();
                    html.modal.info(response.error);
                }
            }, 30000, true);
        },
        exportCampaigns: function (channelids, fileformat, compressionformat, filename, callback) {
            ee.indiOn();
            var query = {
                channelids: channelids,
                fileformat: fileformat,
                compressionformat: compressionformat,
                filename: filename
            };
            ee.api.request("/campaign/export", query, function (response) {
                if (response.success) {
                    ee.indiOff();
                    html.modal.closeAll();
                    if (callback)
                        callback();
                    else
                        ee.goTo("#/exports");
                }
                else {
                    ee.indiOff();
                    html.modal.info(response.error);
                }
            }, undefined, true); //use POST, path can be very long (list of capmaigns)
        },
        formatSegments: function (segment) {
            var ruleObject;
            //Normalize count name for list and segments
            if ($.isNumeric(segment.lastcount) === true) {
                segment.count = segment.lastcount;
                delete segment.lastcount;
            }
            segment.countformatted = segment.count.numberFormat();
            if (segment.segmentid > 0) {
                ruleObject = ee.views.querytool.ruleStringToObj(segment.rule);
                segment.rule = ee.views.querytool.ruleObjToString($.extend({}, ruleObject), false, false, true);
                segment.rulelocal = ee.views.querytool.ruleObjToString(ruleObject, true, false);
            } else {
                segment.rulelocal = segment.rule;
                segment.rule = "";
            }
            if (segment.history) {
                segment.graphdata = [['x'], [segment.name]];
                for (var i = segment.history.length - 1; i >= 0; i--) {
                    segment.graphdata[0].push((segment.history[i].day.toString()).replace(/(.{4})(.{2})(.{2})/, '$1-$2-$3'));
                    segment.graphdata[1].push(segment.history[i].count);
                }
            }
        },
        addSegment: function (segmentname, rule, callback) {
            ee.api.request("/segment/add", { segmentname: segmentname, rule: rule }, function (data) {
                ee.indiOff();
                if (data.success) {
                    data.segment = data.data;
                    delete ee.data.contactgroups;
                    if (callback) {
                        callback(data.segment.segmentid);
                    } else {
                        ee.goTo("#/oldcontact/segments/" + data.segment.segmentid);
                    }
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        updateSegment: function (segmentname, newsegmentname, rule, trackhistory, callback) {
            var query = { segmentname: segmentname, newsegmentname: newsegmentname, rule: rule, trackhistory: trackhistory};
            ee.api.request("/segment/update", query, function (data) {
                ee.indiOff();
                if (data.success) {
                    data.segment = data.data;
                    delete ee.data.contactgroups;
                    if (callback) {
                        callback(data.segment.segmentid);
                    } else {
                        //TODO: change oldcontact to contact and remove callback from query tool
                        ee.goTo("#/oldcontact/segments/" + data.segment.segmentid);
                    }

                }
                else {
                    html.modal.error(data);
                }
            });
        },
        getContactCountsOld: function (set, rule, callback, quiet) {
            var query = {};
            //Refresh Count Contacts without Status rule
            if (rule) {
                if (set && set.activerule) {
                    query.rule = set.activerule.replace(" AND " + set.activestatusrule, "");
                } else {
                    query.rule = rule;
                }
            }
            else
                query.allcontacts = true;
            if (!set) {
                set = {};
            }
            set.counts = {};

            return ee.api.request("/contact/countbystatus", query, function (data) {
                ee.indiOff();
                if (data.success) {
                    data = data.data;
                    set.counts.engaged = data.engaged;
                    set.counts.active = data.active;
                    set.counts.sendable = data.engaged + data.active + data.transactional;
                    set.counts.suppressed = data.unsubscribed + data.bounced + data.complaint + data.inactive + data.stale;
                    set.counts.all = set.counts.sendable + set.counts.suppressed + data.notconfirmed;
                    //   set.counts.splitabdefault = (set.counts.sendable * .1).toFixed(0);//TODO: ????
                    //    set.counts.splitwinnerdefault = (set.counts.sendable * .8).toFixed(0);//TODO: ????
                    set.counts.unsubscribed = data.unsubscribed;
                    set.counts.bounced = data.bounced;
                    set.counts.complaint = data.complaint;
                    set.counts.inactive = (data.inactive) ? data.inactive : 0;
                    set.counts.transactional = data.transactional;
                    set.counts.stale = data.stale;
                    set.counts.notconfirmed = data.notconfirmed;
                    if (callback)
                        callback(set.counts);
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data);
                }
            }, 60000, true, 2, 'json', (quiet) ? true : false);
        },
        /* TO DO: Stop using it in AddContactsUI widget */
        addContact: function (listid, status, email, first, last, title, organization, city, country, state, zip, consentTracking, callback) {
            ee.api.request("/contact/quickadd", { listid: listid, status: status, emails: email.trim(), firstname: first, lastname: last, title: title, organization: organization, city: city, country: country, state: state, zip: zip, consentTracking: consentTracking }, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.view.loaded = false;
                    if (ee.data.contactgroups) {
                        delete ee.data.contactgroups;
                    }
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        /*TODO: Stop using it in AddContactsUI widget */
        contactFindPattern: function (fileObj, stringData, callback, onProgress) {
            if (!callback) {
                console.log("ERROR: callback was not passed to method");
            }
            var target = '/contactshards/findpattern';
            function onSuccess(response) {
                if (response.success === false) {
                    html.modal.info(response.error, ee.t.error);
                    html.widget.AddContactsUI.backToUpload();
                    return;
                }
                if (response.data.contacts <= 0) {
                    html.modal.info(ee.t.nocontactstoupload, ee.t.error);
                    html.widget.AddContactsUI.backToUpload();
                    return;
                }
                //Temporary solution for quick add
                if (stringData.length > 0 && !response.data.headers) {
                    response.data.stringData = stringData;
                    if (!response.data.headers) response.data.headers = ["email"];
                    if (!response.data.pattern) response.data.pattern = ["email"];
                    if (!response.data.example) response.data.example = [(stringData.split(",")[0] || "")];
                }
                //
                if ($.isArray(response.data.headers) === false)
                    response.data.headers = response.data.headers.split(",");
                if ($.isArray(response.data.example) === false)
                    response.data.example = response.data.example.split(",");
                if ($.isArray(response.data.pattern) === false)
                    response.data.pattern = response.data.pattern.split(":");
                response.data.filename = fileObj.name;
                response.data.fieldsfound = response.data.pattern.length;
                $.each(response.data.pattern, function (i, v) {
                    response.data.pattern[i] = { slug: response.data.pattern[i].toLowerCase().replace(/ /g, '_').trim(), name: response.data.pattern[i] };
                });
                $.each(response.data.headers, function (i, v) {
                    response.data.headers[i] = { slug: this.toLowerCase().replace(/ /g, '_').trim(), name: this };
                });
                callback(response.data);
            }
            // create a FormData instance, and append file to it
            if (fileObj) {
                var fd = new FormData();
                fd.append('file', fileObj);

                var uploadRequest = ee.api.uploadFile(fd, target, {
                    silent: true,
                    params: {
                        version: 2,
                        apikey: ee.session.apikey(),
                        encodingName: $('#eEncodingSelect').val()
                    },
                    onProgress: onProgress,
                    onSuccess: onSuccess,
                    onError: function (error) {
                        html.modal.info(error, ee.t.errorupload, false, function () { ee.views.contacts.backToUpload(); });
                    }
                });
                uploadRequest.start();
            } else {
                ee.indiOn();
                //contactsToParse - remove when new code will be deployed
                ee.api.request(target, { data: stringData, contactsToParse: stringData }, function (data) {
                    ee.indiOff();
                    onSuccess(data);
                }, false, true, false, false, true);
            }
        },
        finishSuperAddContacts: function (sessionid, pattern, listname, createlist, status, encodingName, createlog, consentTracking, callback) {
            var target = '/contactshards/addwithpattern',
                pattern = JSON.stringify(pattern),
                query = {
                    sessionid: sessionid,
                    pattern: pattern,
                    listname: listname,
                    createlist: createlist,
                    status: status,
                    encodingName: encodingName,
                    consentTracking: consentTracking
                };

            if (createlog) query.createcontactvalidationlog = true;

            ee.api.request(target, query, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.contactgroups = false;
                    delete ee.data.contactgroups;
                    callback(data.data || { contacts: 0 });
                } else {
                    html.modal.error(data, { title: ee.t.error });
                }
            }, 300000, true);
        },
        activateContactFeatures: function (callback) {
            ee.api.request("/account/activatecontactfeature", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.account.enablecontactfeatures = true;
                    if (ee.data.contactgroups) {
                        delete ee.data.contactgroups;
                    }
                    callback();
                }
                else html.modal.error(data);
            });
        },
        requestPremiumSupport: function (callback) {
            ee.api.request("/account/requestpremiumsupport", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    html.modal.info("We have sent your request to our support team and one of our team members will be emailing you shortly", "Request sent", "emodal-md");
                    callback();
                }
                else html.modal.error(data);
            });
        },
        //Payment API
        loadPaymentHistory: function (limit, fromDate, toDate, callback, reset, formatDate) {
            limit = 500;
            ee.api.request("/account/loadpaymenthistory", { offset: 0, limit, toDate, fromDate },
                function (data) {
                    ee.indiOff();
                    if (data.success) {
                        if (!ee.data.payments || reset) ee.data.payments = { list: [], listfull: false, fromDate, toDate, stopQuery: true };
                        data.payments = data.data;
                        var n = data.payments.length
                        for (var i = 0; i < n; i++) {
                            data.payments[i].amount = ee.data.account.currency + " " + data.payments[i].amount.toFixed(2);
                            data.payments[i].date = moment(data.payments[i].date).format('L');
                            data.payments[i].invoice = (/manual/.test(data.payments[i].source.toLowerCase())) || (/credit expiry/.test(data.payments[i].source.toLowerCase())) ? false : true;
                        }
                        ee.data.payments.list = data.payments;
                        ee.data.payments.count = data.payments.length;
                        callback();
                    } else {
                        html.modal.error(data);
                    }

                }
            );
        },
        sendReceipt: function (paymentid, callback) {
            ee.api.request("/account/sendreceipt", { paymentid: paymentid }, function (data) {
                ee.indiOff();
                if (data.success) {
                    html.modal.info(ee.t.paymentreceiptmessage, ee.t.paymentreceiptmessagetitle, 500);
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        sendInvoice: function (paymentid, callback) {
            ee.api.request("/account/sendinvoice", { paymentid: paymentid }, function (data) {
                ee.indiOff();
                if (data.success) {
                    html.modal.info(ee.t.invoicemessage, ee.t.invoicemessagetitle, "emodal-md");
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        loadProviderData: function (callback) {
            ee.api.request("/payment/loadRegisteredPaymentProvider", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    var payments = data.data;
                    if (payments.brand) {
                        //payments.brand = payments.brand.toLowerCase();
                        payments.details = "•••• •••• •••• " + payments.details;
                    } else payments.brand = "Paypal"
                    if (payments.expirationdate) {
                        var expiration = new Date(payments.expirationdate);
                        payments.exp = expiration.getMonth() + 1 + "/" + expiration.getFullYear().toString().substr(2, 2);
                    }
                    ee.data.account.payments = payments;
                    if (callback)
                        callback(data);
                    else {
                        return data;
                    }
                } else {
                    ee.data.account.payments = {};

                    return data;
                }
            }, false, false, false, false, true);
        },
        //Account API
        reactivateWithPrivateIP: function (privateipkey) {
            ee.api.request("/account/reactivatewithprivateip", { privateipkey: privateipkey }, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.goTo("#/settings/advancedoptions");
                    html.modal.info(ee.t.privateippurchasesuccess, ee.t.success);
                } else {
                    html.modal.error(data, { title: ee.t.error });
                    ee.goTo("#/settings");
                }
            });
        },
        loadAccount: function (callback, toLoad) {
            const accountLoadCallback = (data, hasSSL) => {
                if ($('#ui-datepicker-div').length) {
                    $('#ui-datepicker-div').hide();
                }
                let account = data.data;
                for (const dataaccount in account) {
                    if (account[dataaccount] === "") {
                        account[dataaccount] = undefined; 
                    }
                }
                if (!account.issub) {
                    account.showemailsremaining = true;
                    account.emailsremaining = (account.emailcredits + (account.credit / (account.priceperemail > 0 ? account.priceperemail : 0.00019)));
                    account.emailsremainingformatted = (account.emailcredits + (account.credit / (account.priceperemail > 0 ? account.priceperemail : 0.00019))).numberFormat();
                }
                if (account.issub && account.requiresemailcredits) {
                    account.showemailsremaining = true;
                    account.emailsremaining = account.emailcredits;
                    account.emailsremainingformatted = account.emailcredits.numberFormat();
                }
                account.freeemailsformatted = account.emailcredits + account.freeemails > 0 ? (account.emailcredits + account.freeemails).numberFormat() : "0";

                if (account.autocreditstatus === "on") account.showautocredit = true;
                else account.showautocredit = false;
                if (account.autocreditstatus === "inprogress") account.autocreditinprogress = true;

                account = {
                    ...account,
                    firstname: account.firstname || '',
                    lastname: account.lastname || '',
                    company: account.company || '',
                    creditformatted: (account.credit) ? account.credit.toFixed(2) : "0.00",
                    autocreditformatted: account.autocreditamount.toFixed(2),
                    autocreditlvlformatted: account.autocreditlevel.toFixed(2),
                    emailsizelimitFormatted: ee.tools.bytesToSize(account.emailsizelimit, { unit: 'MB', isNum: true }),
                    reputationformatted: account.reputation.toFixed(2),
                    totalemailssentformatted: (account.totalemailssent) ? account.totalemailssent.numberFormat() : 0,
                    monthlyemailssentformatted: (account.monthlyemailssent) ? account.monthlyemailssent.numberFormat() : 0,
                    countryName: html.countryList(false, account.countryid).find(":selected").html(),
                    datecreated: ee.api.formatDate(account.datecreated, 'yy-mm-dd'),
                    nextbillingdate: account.nextbillingdate ? ee.api.formatDate(account.nextbillingdate) : "",
                    pricePerThousand: (account.priceperemail * 1000).numberFormat(2),
                    scope: (account.publicaccountid === "a18de9fc-4724-42f2-b203-4992ceddc1de" || account.enableprivatebranding === true) ? true : false,
                    repscoreval: 100 - account.reputation,
                    industry: parseInt(account.industry, 10),
                    pricingold: account.pricingplan < 2,
                    pricing2017: account.pricingplan >= 2 && account.pricingplan <= 4,
                    pricing2018: account.pricingplan > 4 && account.pricingplan <= 8,
                    grantedSendingPermission: ee.tools.getSendingPermission(account.sendingpermission),
                };

                //If parent loaded subaccount then he can see IP and can order IP, always!
                if (account.issub && ee.session.get('parentkey')) {
                    account.enableprivateiprequest = true;
                }

                account.isprivatebrandedsub = false; //Default ishould be always false
                if (account.issub && account.privatebrandingurl) {
                    account.isprivatebrandedsub = true;
                }

                //set custom subaccounts paymenturl
                if (account.accountpaymenturl) {
                    account.accountpaymenturl = account.accountpaymenturl.replace('{publicaccountid}', account.publicaccountid).replace("{subaccountemail}", account.email);
                }

                //set payment type
                if (account.paymentprovider) {
                    const paymenttypes = ['none', 'cc', 'pp']
                    account.paymenttype = paymenttypes[account.paymentprovider];
                }

                account.isMac = ee.tools.isMac();


                html.enableuitooltips = account.enableuitooltips;

                // apiurl for resellers
                ee.api.apiurl = (account.enableprivatebranding || (account.issub && !!account.privatebrandingurl)) ? (account.privatebrandingurl ? `http${hasSSL ? 's' : ''}://` + account.privatebrandingurl : ee.api.path) : ee.api.path;

                account.subscriptionlink = ee.api.apiurl + "/contact/subscribe?version=2&pID=" + account.publicaccountid;
                //Defined user name
                if (!account.username) {
                    if (!account.firstname && !account.lastname) {
                        account.username = account.email;
                    } else {
                        account.username = (account.firstname + " " + account.lastname).trim();
                    }
                }

                //tracking of users to visitors
                if (typeof ea !== 'undefined') {
                    ea.contact(account.email, account.datecreated);
                }
                ee.data.account = account;
                if (callback) {
                    callback(account);
                } else {
                    ee.view.show();
                }
            }

            var query = {};
            if (toLoad) query.toLoad = toLoad;



            ee.api.request("/account/load", query, function (resp) {
                ee.indiOff();
                if (resp.success) {
                    if (resp.data.privatebrandingurl && (resp.data.enableprivatebranding || resp.data.isownedbyreseller)) {
                        const sslCheckPromise = new Promise((resolve, reject) => {
                            $.get(`https://${resp.data.privatebrandingurl}`)
                                .success(response => resolve(response))
                                .error(() => reject());
                        });
                        sslCheckPromise.then(() => {
                            accountLoadCallback(resp, true);
                        })
                        .catch(e => {
                            console.log(e)
                            accountLoadCallback(resp);
                        });
                    } else {
                        accountLoadCallback(resp);
                    }
                } else {
                    if (resp.error.toLowerCase().indexOf("incorrect apikey") >= 0) {
                        ee.session.destroy();
                        if (window.location.hash === '') {
                            ee.showView("login");
                        } else {
                            ee.goTo("#");
                        }
                    } else if (resp.error.toLowerCase().indexOf("access denied") >= 0) {
                        //automatic logout for users with no AL
                        html.modal.info(ee.t.accessnonetext, ee.t.accessnonetitle, function () { ee.views['login'].logout(); });

                    }
                }
            }, undefined, true);

        },
        /*Note: In ee.api we should have only methods.
        Maybe eventTimeout should be in different namespace, or we should use closure? by K.G.*/
        eventTimeout: false,
        loadEvents: function () {
            clearTimeout(ee.api.eventTimeout);
            ee.api.request("/event/load", {}, function (data) {
                try {
                    if (!data.success) {
                        ee.data.account.events = data.error;
                    } 
                } catch (e) {
                    console.log(e.message);
                }
                ee.api.eventTimeout = setTimeout(function () { ee.api.loadEvents(); }, 60000)
            }, false, false, false, false, true);
        },
        loadAdvancedoptions: function (callback) {
            ee.api.request("/account/LoadAdvancedOptions", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    data.advancedoptions = data.data;
                    ee.data.account.advancedoptions = data.advancedoptions;
                    if (callback) return callback();
                    ee.view.show();
                } else {
                    html.modal.error(data);
                }
            });
        },
        livestats: function (callback, quick) {
            ee.api.request("/account/livedata2", {}, function (data) {
                //if (!data.success) return html.modal.error(data);
                if (!data.success || quick) return callback(data);
                data.wtr = data.count - data.ready;
                data.domains = new Collection.Data(data.domains);
                data.domains.each(function () {
                    this.rateFixed = this.rate.toFixed(1);
                    this.sentrateFixed = this.sentrate.toFixed(1);
                    this.wtr = this.count - this.ready;
                    this.readyFormated = this.ready.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
                    this.countFormated = this.count.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
                    this.wtrFormated = this.wtr.numberFormat(0, ee.t.decimelseparator, ee.t.thousandsseparator);
                    if (this.ready > 10 && this.sentrate === 0) { this.red = true; }
                    else if (this.ready > 10 && this.sentrate < 3) { this.yellow = true; }
                });

                if (ee.data.reports){
                    ee.data.reports.delivered = data;
                    if (callback) callback(data);
                } else if ((sessionStorage.inspection && sessionStorage.parentemail) || ee.data.admin) { //when there is no data to attach to
                    //if inspection - don't force reload, as it is done somewhere else.
                    return;
                } else {
                    //reload whole screen to populate it with data
                    ee.view.show();
                }
            }, null, null, null, null, true);
        },
        loadDiagnosticsDetails: function (ticketid, callback) {
            ee.api.request("/account/detailsdiagnostics", { ticket: ticketid }, function (data) {
                ee.indiOff();
                ee.data.diagnosticsdetails = {};
                ee.data.diagnosticsdetails.activetab = 0;
                if (data.success) {
                    if (typeof ee.data.diagnosticsdetails == "undefined") ee.data.diagnosticsdetails = {
                        transaction: {}, details: {}, mimemessage: {}, history: {}, dnslog: {}, outboundsmtp: {}
                    };
                    ee.data.diagnosticsdetails = data.diagnosticsdetails;
                }
                else {
                    html.modal.error(data);
                }
                callback();
            });
        },
        getDiagnosticTicket: function (callback) {
            ee.api.request("/account/getdiagnosticsticket", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.diagnosticsticket = data.diagnosticsticket;
                    callback();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        addDiagnosticTicket: function (ticket, ipoverride, send, toemail, template) {
            ee.api.request("/account/adddiagnosticsticket", { email: ticket, ip: ipoverride, sendnow: send, toemail: toemail, template: template }, function (data) {
                ee.indiOff();
                if (data.success) {
                    delete ee.data.diagnostics;
                    ee.view.show();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        listDiagnostics: function (offset, limit, callback, formatDate) {
            if (!limit) limit = 200;
            if (!offset) offset = 0;
            ee.api.request("/account/listdiagnostics", { offset: offset, limit: limit }, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.diagnostics = new Collection.Data(data.diagnostics);
                    ee.data.diagnostics.each(function () {
                        this.havemore = true;
                        if (this.datetested === "") {
                            this.havemore = false;
                        }
                        this.datecreated = ee.tools.time(this.datecreated).fromUTC();// ee.api.formatDate(this.datecreated, formatDate);
                        this.datetested = ee.tools.time(this.datetested).fromUTC(); //ee.api.formatDate(this.datetested, formatDate);
                    });
                    callback();
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        updateAdvancedOptions: function (SendingOptions, BounceOptions, AccountNotifications, callback) {

            /*NOTE: Do we need this whole query object? Mayby we can use $.extend ore something... the optionst property are the same as query obj */
            var query = {
                EnableOpenTracking: SendingOptions.EnableOpenTracking,
                EnableClickTracking: SendingOptions.EnableClickTracking,
                EnableLinkClickTracking: SendingOptions.EnableLinkClickTracking,
                ManageSubscriptions: SendingOptions.ManageSubscriptions,
                ManageSubscribedOnly: SendingOptions.ManageSubscribedOnly,
                TransactionalOnUnsubscribe: SendingOptions.TransactionalOnUnsubscribe,
                consentTrackingOnUnsubscribe: SendingOptions.consentTrackingOnUnsubscribe,
                SkipListUnsubscribe: SendingOptions.SkipListUnsubscribe,
                unsubscribenotificationsemails: SendingOptions.unsubscribenotificationsemails ? SendingOptions.unsubscribenotificationsemails : "",
                AllowCustomHeaders: SendingOptions.AllowCustomHeaders,
                BCCEmail: SendingOptions.BCCEmail,
                AutoTextFromHtml: SendingOptions.AutoTextFromHtml,
                ContentTransferEncoding: SendingOptions.ContentTransferEncoding,
                EmailNotificationForError: BounceOptions.EmailNotificationForError,
                EmailNotificationEmail: BounceOptions.EmailNotificationEmail,
                EnableUIToolTips: AccountNotifications.EnableUIToolTips,
                notificationsemails: AccountNotifications.EmailNotificationBCC,
                logoUrl: SendingOptions.LogoUrl,
                EnableTemplateScripting: SendingOptions.EnableTemplateScripting,
                DisableStoreContact: SendingOptions.DisableStoreContact
            };
            
            if (SendingOptions.deliveryreason || SendingOptions.deliveryreason === "") {
                query.deliveryreason = SendingOptions.deliveryreason;
            }

            if (SendingOptions.EnableStaleContactScore) {
                query.StaleContactScore = SendingOptions.StaleContactScore * -1;
            } else { query.StaleContactScore = 0; }

            if (SendingOptions.EnableStaleContactDay) {
                query.StaleContactInactiveDays = SendingOptions.StaleContactInactiveDays * 1;
            } else { query.StaleContactInactiveDays = 0; }

            if (SendingOptions.DisableStoreContact && SendingOptions.DisableGlobalContacts) {
                EE_API.Account.SetDisableGlobalContacts({ disableGlobalContacts: SendingOptions.DisableGlobalContacts });
            }

            // subaccounts don't have these options available to set, don't pass them
            if (AccountNotifications.LowCreditNotification != undefined)
                query.LowCreditNotification = AccountNotifications.LowCreditNotification;
            if (AccountNotifications.EnableContactFeatures != undefined)
                query.EnableContactFeatures = AccountNotifications.EnableContactFeatures;
            if (ee.data.account.issub || !ee.data.account.enablecontactfeatures)
                query.notificationsemails = "";

            ee.api.request("/account/UpdateAdvancedOptions", query, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.account.advancedoptions = data.data;
                    ee.data.account.deliveryreason = query.deliveryreason;
                    if (callback)
                        callback();
                }
                else {
                    html.modal.error(data);
                }
            }, false, true);
        },
        setGlobalContacts: function (DisableGlobalContacts, callback) {
            var path = "/account/updateprofile";

            ee.api.request(path, profile, function (data) {
                //Reload account data after 1 second
                setTimeout(function () {
                    ee.indiOff();
                    if (data.success) {
                        html.modal.closeAll();
                        ee.api.loadAccount(function () {
                            if (callback) { callback(); }
                            else { ee.view.show(); }
                        });
                    } else {
                        html.modal.error(data, { path: path });
                    }
                }, 500);
            });
        },
        registerCard: function (purchasedata) {
            ee.api.request("/account/registercard", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.goTo("#/payments/autorechargesuccessful")
                } else {
                    html.modal.error(data, {
                        title: ee.t.registrationerror,
                        size: "emodal-md",
                    });
                }
            });
        },
        makePurchase: function (purchasedata) {
            ee.api.request("/account/makepurchase", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.goTo("#/payments/paymentsuccessful")
                }
                else {
                    html.modal.error(data, {
                        title: ee.t.paymenterror,
                        size: "emodal-md",
                    });
                }
            });
        },
        makePayPalPurchase: function (purchasedata) {
            ee.api.request("/account/makepaypalpurchase", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    window.location = data.data;
                }
                else {
                    html.modal.error(data, {
                        title: ee.t.paymenterror,
                        size: "emodal-md",
                    });

                }
            });
        },
        registerPayPal: function (purchasedata) {
            ee.api.request("/account/registerpaypal", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    window.location = data.data;
                }
                else {
                    html.modal.error(data, {
                        title: ee.t.paymenterror,
                        size: "emodal-md",
                    });
                }
            });
        },
        makeAutoPurchase: function (purchasedata) {
            ee.api.request("/account/makeautopurchase", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.goTo("#/payments/paymentsuccessful")
                }
                else {
                    html.modal.error(data, {
                        title: ee.t.paymenterror,
                        size: "emodal-md",
                    });
                }
            });

        },
        makeAutoPaypalPurchase: function (purchasedata) {
            ee.api.request("/account/makeautopaypalpurchase", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.goTo("#/payments/paymentsuccessful")
                }
                else {
                    html.modal.error(data, {
                        title: ee.t.paymenterror,
                        size: "emodal-md",
                    });

                }
            });

        },
        disableautocredit: function (callback) {
            ee.api.request("/account/disableautocredit", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.account.autocreditstatus = data.autocreditstatus;
                    ee.data.account.showautocredit = data.autocreditstatus === "on";
                    ee.data.account.paymentprovider = 0;
                    ee.data.account.paymenttype = "none";
                    if (callback)
                        callback();
                    else
                        ee.view.show();
                }
                else {
                    html.modal.error(data);
                }

            });
        },
        updateautocredit: function (purchasedata) {
            ee.api.request("/account/updateautocredit", purchasedata, function (data) {
                ee.indiOff();
                if (data.success) {
                    delete ee.data.account;
                    ee.goTo('#/account')
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        // Survey API
        //TODO: move this to surveyData
        surveyLivePreview: function (survey, callback) {
            ee.api.request("/survey/livepreview", { surveydata: JSON.stringify(survey) }, function (response) {
                ee.indiOn();
                if (response.success) {
                    if (callback) callback(response.data); else ee.view.show();
                    return;
                }
                html.modal.info(response.error, ee.t.error);
                return;
            }, false, true);
        },
        //Users API
        activateUser: function (verification, user, pass, callback) {
            ee.api.request("/user/activate", { username: user, password: pass, verificationcode: verification, returnUrl: ee.baseurl }, function (response) {
                ee.indiOff();

                if (response.success) {
                    window.location = response.data;
                    location.reload(true);
                }
                else {
                    html.modal.info(response.error);
                }
            })
        },
        // api consent for apikey
        apiConsent: function (callback) {
            ee.api.request("/account/apiconsent", {}, function (data) {
                ee.indiOff();
                if (data.success) {
                    ee.data.account.apiconsented = true;
                    if (callback)
                        callback();
                    else {
                        ee.view.show();
                    }
                }
                else {
                    html.modal.error(data);
                }
            });
        },
        /*
            NOTE: formatDate is utility not apicom method
        */
        // Converts the date to a specific date format.
        // If the date format is not specified then a  date is converted with default format. (ee.t.dateformat - default format)
        // Date - string/Date Obj
        // format - string - "dd-mm-YY HH:ii:s"
        // Return - string/false
        // toServerTime - true/false - return server date time
        // skiptime - skip time parser
        formatDate: function (date, format, toServerTime, skiptime) {
            var parsedate, time;

            if (!date || date.length == 0) return "";
            if (typeof (date) === "string") {
                let convertedDate, newDate;
                convertedDate = date;

                if (moment(convertedDate, 'L').isValid()) {
                    newDate = moment(convertedDate, 'DD-MM-YYYY').format('MM/DD/YYYY')
                } else {
                    newDate = moment(convertedDate).format('YYYY/MM/DD LT');
                }
                date = new Date(newDate);
            }
            
            if (!(/Date/g.test(date.constructor.toString()))) {
                html.modal.info(ee.t.errordate, ee.t.error);
                return false;
            }

            var convert24to12 = function (h) {
                if (h > 12) { h = h - 12; }
                if (h == 0) { h = 12; }
                return h;
            }

            var parseTime = function (dateString) {
                var finds = dateString.match(/(H{1,2}|h{1,2}|i{1,2}|s{1,2}|'.*?')/g);
                if (finds) {
                    for (var i = 0, n = finds.length; i < n; i++) {
                        finds[i] = { reg: finds[i], fun: undefined, zerofill: false }
                        finds[i].zerofill = (finds[i].reg.length == 2) ? true : false;
                        var fun = finds[i].reg;
                        if (finds[i].zerofill) var fun = fun.substring(1);
                        switch (fun) {
                            case "h": finds[i].fun = "getHours"; break;
                            case "H": finds[i].fun = undefined; finds[i].convertTo12 = true; break;
                            case "i": finds[i].fun = "getMinutes"; break;
                            case "s": finds[i].fun = "getSeconds"; break;
                        }
                        finds[i].reg = new RegExp(finds[i].reg);
                    }
                }             
                return finds;
            }

            if (typeof (format) !== "string") { format = "dd/mm/yy"; }

            if (ee.api.prevDateFormat && ee.api.prevDateFormat.format === format) {
                time = ee.api.prevDateFormat.time;              
            } else {
                time = (parseTime(format));
                ee.api.prevDateFormat = { format: format, time: time };
            }

            if (!skiptime) {
                if (toServerTime === true) {
                    return this.zerofill(date.getUTCFullYear()) + "-" + this.zerofill(date.getUTCMonth() + 1) + "-" + this.zerofill(date.getUTCDate()) + " " + this.zerofill(date.getUTCHours()) + ":" + this.zerofill(date.getUTCMinutes()) + ":" + this.zerofill(date.getUTCSeconds());
                } else {
                    date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                }
            }

            parsedate = $.datepicker.formatDate(format, date);
            if (time) {
                for (var i = 0, n = time.length; i < n; i++) {
                    var v;
                    if (!time[i].fun && time[i].convertTo12) {
                        v = date.getHours();
                        parsedate += (v >= 12 && v < 24) ? " PM" : " AM";
                        v = convert24to12(v);
                    } else {
                        v = date[time[i].fun]();
                    }
                    if (time[i].zerofill) v = (v < 10) ? "0" + v : v;
                    parsedate = parsedate.replace(time[i].reg, v);
                }
            }
            return parsedate;
        },
        zerofill: function (v) {
            return (v < 10) ? "0" + v : v;
        },
        dutarionTime: function (fromDate, toDate) {
            try {
                var difference_ms = (new Date(toDate).getTime()) - (new Date(fromDate).getTime());
                var h = 0, m = 0, s = 0;
                if (difference_ms > 0) {
                    h = Math.floor(difference_ms / (1000 * 60 * 60));
                    difference_ms -= h * (1000 * 60 * 60);
                }
                if (difference_ms > 0) {
                    m = Math.floor(difference_ms / (1000 * 60));
                    difference_ms -= m * (1000 * 60);
                }
                if (difference_ms > 0) {
                    s = Math.floor(difference_ms / 1000);
                }
                return ((h < 10) ? "0" + h : h) + ":" + ((m < 10) ? "0" + m : m) + ":" + ((s < 10) ? "0" + s : s);
            } catch (exeption) {
                return 0;
            }
        }
    }
    //call construct dor API
    ee.api = new APICOM();
}());
