(function (root) {
    root.Accounts = {
        GetDisposableToken: function (name, query) {
            return root.request('/account/disposabletoken ', name, query, 'GET');
        },
        ExportOnboardingSurveys: function (name, query) {
            return root.request('/account/exportSurveys', name, query, 'GET');
        },
        LoadSingleSurvey: function (name, query) {
            return root.request('/account/survey', name, query, 'GET');
        },
        GetAllSurveysID: function (name, query) {
            return root.request('/account/survey/formID', name, query, 'GET');
        },
    };
})(API_V3);
