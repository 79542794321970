(function (root) {

    root.Segment = {
        List: function (query) { return root.request('/segment/list', query) },
        Copy: function (query) { return root.request('/segment/copy', query) },
        "Delete": function (query) { return root.request('/segment/delete', query) },
        LoadByName: function (query) { return root.request('/segment/loadbyname', query) },
        LoadTrackedHistory: function (query) { return root.request('/segment/loadtrackedhistory', query) }
    };

}(EE_API));