(function (root) {

    root.List = {
        Add: function (query) { return root.request('/list/add', query) },
        AddContacts: function (query) { return root.request('/list/addcontacts', query) },
        List: function (query) { return root.request('/list/list', query) },
        Copy: function (query) { return root.request('/list/copy', query) },
        Update: function (query) { return root.request('/list/update', query) },
        "Delete": function (query) { return root.request('/list/delete', query) },
        CreateRandomList: function (query) { return root.request('/list/createrandomlist', query) },
        CreateNthSelectionLists: function (query) { return root.request('/list/createnthselectionlists', query) },
        MoveContacts: function (query) { return root.request('/list/movecontacts', query) },
        RemoveContacts: function (query) { return root.request('/list/removecontacts', query) }
    };


}(EE_API));