(function () {
    var _format = function () {
        this.StatusName = this.Status ;

        if (this.StartInfo && this.StartInfo.Target)  {
            if (this.StartInfo.Type === 'ClicksLink') {
                this.Target = JSON.parse(this.StartInfo.Target);
            } else if (this.StartInfo.Type === 'JoinsList'){
                this.Target = this.StartInfo.Target.Lists; 
            } else {
                this.Target = this.StartInfo.Target;
            }
        }
        if (this.StartInfo && this.StartInfo.Type) {
            this.Trigger = _getTrigger(this.StartInfo.Type, (this.Target?.Lists ? this.Target.Lists : this.Target));

            if ((this.StartInfo.Type !== 'ClicksLink' && this.StartInfo.Type !== 'StatusChanged') && this.StartInfo.Target) {
                let TargetParsed;
                try {
                    TargetParsed = JSON.parse(this.StartInfo.Target)
                } catch {
                }
                this.Trigger = this.Trigger + ' "' + (TargetParsed?.Lists ? TargetParsed.Lists: this.StartInfo.Target) + '"';
            }
        }
        this.IsInvalid = this.Status === 'Invalid';
        this.IsDraft = this.Status === 'Inactive';
        this.IsPaused = this.Status === 'Paused';
        this.IsPublished = this.Status === 'Active';
        //this.DateCreated = moment(this.DateCreated).format("DD/MM/YYYY");
    };

    var _getStatus = function (status) {

        switch (status) {
            case -1:
                return "Invalid";
            case 0:
                return "Draft";
            case 1:
                return "Paused";
            case 2:
                return "Published";
            case 3:
                return "ShuttingDown";
        }
    };

    var _getTrigger = function (trigger, target) {
        switch (trigger) {
            case 'OpensEmail':
                return ee.t.contactopenemail;
            case 'ClicksLink':
                if (_.isNil(target.Link)) {
                    return ee.t.contactclickanylink;
                } else {
                    return ee.t.contactclickspecificlink;
                }
            case 'JoinsList':
                return ee.t.contactjoinslist;
            case 'StatusChanged':
                if (target === "-2") {
                    return "Contact status is changed to transactional";
                } else if (target === "-1") {
                    return "Contact status is changed to engaged";
                } else if (target === "0") {
                    return "Contact status is changed to active";
                }
            case 'AnotherJourney':
                return ee.t.triggeredbyanotherautomation;
            case 'Manually':
                return ee.t.whenapiissent;
        }
    };

    var AutomationsList = function AutomationsList(dataArr) {

        if (!(this instanceof AutomationsList)) {
            throw new Error('AutomationsList is a constructor and it`s need "new" operator');
        };

        //if (dataModel.isModel("AutomationsList", ee.data.journeys)) return ee.data.journeys;
        Collection.Data.call(this, dataArr);

        this.isLoaded = false;
    };

    var options = {
        collection: true,
        storage: {
            name: "journeys",
            load: API_V3.Journeys.GetJourneysList
        }
    };

    var proto = {
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        },
        reload: function () {
            this.data = null;
            this.isLoaded = false;
            return this.require();
        },
        delete: function (name) {
            var that = this;
            return API_V3.Journeys.DeleteJourney(name).then(function () {
                that.isLoaded = false;
                return $.when(that.require());
            }).catch(function (err) {
                console.log(err);
            })
        }
    };

    dataModel.inherit(AutomationsList, proto, options).register("AutomationsList", AutomationsList);

})();
