(function(root) {

    root.Export = {
        List: function(query) {
            return root.request('/export/list', {manualerrorhandle: true})
        },
        Delete: function(query) {
            return root.request('/export/delete', query)
        },
        downloadBulk: function(query) {
            return root.request('/export/downloadbulk', query, {type: 'GET'})
        }
    };

}(EE_API));
