ee.views["start"] = (function () {

    var $screen,
        that = {
            show: function () {
                ee.goTo('#/dashboard')
            }
        }
    return that;
}());
