ee.views['querytool'] = (function ViewQueryTool() {
    /*-- Returned object of view class--*/
    var that = {};
    /*-- Private property --*/
    var $screen = false;
    const tableDef = {
            general: new Collection.Data([
                { label: 'Firstname', column: 'firstname', type: 'text' },
                { label: 'Lastname', column: 'lastname', type: 'text' },
                { label: 'List Name', column: 'list', type: 'list' },
                { label: 'Status', column: 'status', type: 'enum', values: [{ val: 'Transactional', name: 'Transactional' }, { val: 'Engaged', name: 'Engaged' }, { val: 'Active', name: 'Active' }, { val: 'Inactive', name: 'Inactive' }, { val: 'Bounced', name: 'Invalid' }, { val: 'Unsubscribed', name: 'Unsubscribed' }, { val: 'Abuse', name: 'Complaint' }, { val: 'Stale', name: 'Stale' }, { val: 'NotConfirmed', name: "Not Confirmed"}] },
                { label: 'Source', column: 'source', type: 'enum', values: [{ val: 'DeliveryApi', name: 'DeliveryApi' }, { val: 'ManualInput', name: 'ManualInput' }, { val: 'FileUpload', name: 'FileUpload' }, { val: 'WebForm', name: 'WebForm' }, { val: 'ContactApi', name: 'ContactApi' }] },
                { label: 'Unsubscribe Reason', column: 'unsubscribereason', type: 'enum', values: [{ val: 0, name: 'Unknown' }, { val: 1, name: 'I no longer wish to receive your emails' }, { val: 2, name: 'Content is not relevant or interesting' }, { val: 3, name: 'You are sending too frequently' }, { val: 4, name: 'I never asked to receive this email' }, { val: 5, name: 'Email contains deceptive content' }, { val: 6, name: 'Contact marked the email as junk or spam with their email provider' }, { val: 7, name: 'Contact unsubscribed via a third-party unsubscribe system' }, { val: 8, name: 'Contact unsubscribed via list-unsubscribe header' }] },
                { label: "Email", column: "email", type: "text" },
                { label: 'Date Added', column: 'dateadded', type: 'datetime' },
                { label: 'Date Updated', column: 'dateupdated', type: 'datetime' },
                { label: 'Status Change Date', column: 'statuschangedate', type: 'datetime' },
                { label: 'Consent Date', column: 'consentdate', type: 'datetime' },
                { label: 'Consent IP', column: 'consentip', type: 'text' },
                { label: 'Consent Tracking', column: 'consenttracking', type: 'enum', values: [{ val: 'Unknown', name: 'Unknown' }, { val: 'Allow', name: 'Allow' }, { val: 'Deny', name: 'Deny' }] },
                { label: 'Now - Date Added (days)', column: 'daysfromdateadded', type: 'number' },
                { label: 'Now - Date Updated (days)', column: 'daysfromstatuschangedate', type: 'number' },
                { label: 'Now - Consent Date (days)', column: 'daysfromconsentdate', type: 'number' },
                { label: 'Created From IP', column: 'createdfromip', type: 'text' },
                { label: 'Last Error', column: 'friendlyerrormessage', type: 'text' }
            ]),
            statistic: new Collection.Data([
                { label: 'Total Sent', column: 'totalsent', type: 'number' },
                { label: 'Total Opens', column: 'totalopened', type: 'number' },
                { label: 'Total Clicks', column: 'totalclicked', type: 'number' },
                { label: 'Total Bounces', column: 'totalfailed', type: 'number' },
                { label: 'Last Sent', column: 'lastsent', type: 'datetime' },
                { label: 'Last Opened', column: 'lastopened', type: 'datetime' },
                { label: 'Last Clicked', column: 'lastclicked', type: 'datetime' },
                { label: 'Last Bounced', column: 'firstfaileddate', type: 'datetime' },
                { label: 'Now - Last Sent (days)', column: 'daysfromlastsent', type: 'number' },
                { label: 'Now - Last Opened (days)', column: 'daysfromlastopened', type: 'number' },
                { label: 'Now - Last Clicked (days)', column: 'daysfromlastclicked', type: 'number' },
                { label: 'Now - Last Bounced (days)', column: 'daysfromlastfailed', type: 'number' },
            ]),
            custom: false
        };
        let conditionDef = false;
    /*-- Private methods --*/
    var _reset = function () {
        if ($screen) {
            $screen.remove();
            $screen = false;
        }
        ee.frame.content.empty();
        //TODO: Lang faile should load first...
        //Init conditionDef
        if (conditionDef === false) {
            conditionDef = {
                enume: [
                    { label: 'is', operator: '=' },
                    { label: ee.t.isnot, operator: '!=' }
                ],
                string: [
                    { label: ee.t.is, operator: '=' },
                    { label: ee.t.isnot, operator: '!=' },
                    { label: ee.t.startswith, operator: 'starts-with' },
                    { label: ee.t.endswith, operator: 'ends-with' },
                    { label: ee.t.contains, operator: 'contains' },
                    { label: ee.t.notcontains, operator: 'not-contains' }
                ],
                date: [
                    { label: ee.t.is, operator: '=' },
                    { label: ee.t.monthday, operator: 'month-day' },
                    { label: ee.t.isnot, operator: '!=' },
                    { label: '>', operator: '>' },
                    { label: '<', operator: '<' },
                    { label: '>=', operator: '>=' },
                    { label: '<=', operator: '<=' }
                ],
                defaults: [
                    { label: 'is', operator: '=' },
                    { label: ee.t.isnot, operator: '!=' },
                    { label: '>', operator: '>' },
                    { label: '<', operator: '<' },
                    { label: '>=', operator: '>=' },
                    { label: '<=', operator: '<=' }
                ]
            }
        }
    };
    var _resetTableDef = function () {
        tableDef.custom = false;
    };
    var _setupTableDef = function (ContactFields) {
        if (!ContactFields) ContactFields = dataModel.create('ContactFields').reload();
        if (tableDef.custom === false) {
            tableDef.custom = new Collection.Data([]);
            $.when(ContactFields).then(function (model) {
                model.each(function () {
                    if (this.name === "firstname" || this.name === "lastname" || this.name === "consenttracking") {
                        return true;
                    };
                    tableDef.custom.add({ label: this.name, column: this.name, type: this.fielddata.typeinput });
                    if (this.fielddata.typeinput === 'datetime') {
                        tableDef.custom.add({ label: 'Now - ' + this.name + ' (days)', column: "daysfrom" + this.name, type: 'number' });
                    }
                });
            }).catch(function (err) { html.modal.error(err); });
        }
        return true;
    };
    var _initCommonEvent = function () {
        $('.save').attr('disabled', 'true');
        const $cancelBtn = $(".js-contacts__btn--cancel-segment");
        const initialSettings = {
            name: $("#esegmentname").val(),
            rule: _getRuleFromEditor($screen.queryparts)
        }

        $cancelBtn.off().on("click", e => ee.goTo("#/contacts/segmentslist"));

        $(document).on('click', 'input, .eremovecondition, .evalue, .econdition, .ecolumn', function() {
            const $saveBtn = $('.save');
            $saveBtn.attr('disabled', false);

            $cancelBtn.removeAttr('disabled').removeClass('hidden');
        });
        $(document).ready(function() {
            $(document).on('click', '.equeryli, .eremovecondition', function() {
                $screen.setrule.removeClass('hidden');
            })
        })

        $screen.on('click', '.save', function () {
            const id = parseInt($screen.segmentid.val(), 10);
            const segment = ee.data.contactgroups.get('segmentid', id);
            const name = $screen.segmentname.val().trim();
            const method = $(this).data("method");
            const trackhistory = $(document).find("#etracksegmenthistory").is(':checked');

            if (name === '') {
                html.modal.info('Please enter Segment name and try again.', ee.t.error);
                return;
            } else if (name.indexOf("'") !== -1) {
                html.modal.info(ee.t.segmentnamerestriction, ee.t.error);
                return;
            }
            const rule = _getStringRuleFromEditor(false, true, false);
            const callback = (segmentid) => {
                    delete ee.data.segments;
                    ee.goTo("#/contacts/" + method + "/" + segmentid);
            };

            if (rule === "") {
                html.modal.info('Query can not be empty', ee.t.error);
                return;
            }
            if (method === "list") {
                ee.api.addNewList(name, false, rule, false, true);
                return;
            }

            if (method === "segment") {
                if (!segment) {
                    ee.api.addSegment(name, rule, callback);
                } else {
                    ee.api.updateSegment(segment.name, name, rule, trackhistory, callback);
                }
            }
        })


        $screen.setrule.click(function () {
            var rule = _getStringRuleFromEditor(false, true);
            ee.api.getContactCountsOld(false, rule, function (counts) {
                var hasOwn = Object.prototype.hasOwnProperty;
                for (var prop in counts) {
                    if (hasOwn.call(counts, prop) && counts[prop] !== undefined) {
                        counts[prop + "Formated"] = counts[prop].numberFormat();
                    }
                }
                $screen.ruleresults.removeClass("hidden");
                $screen.setrule.text(ee.t.evaluate)
                $screen.ruleresultscontainer.html(html.get('QueryResults', counts));
            });
        });
    };

    var _initQueryTool = function () {
        /*--- START QUERY PARTS EVENTS---*/
        //Query Parts drop down events
        $screen.queryparts.on('click', '.equerygrouptitle', function (e) {
            e.stopPropagation();
            var $title = $(this);
            var $ul = $title.closest('li').find('.equerygroupitemlist');
            $title.find('.fa').toggleClass('rotate180');
            $ul.slideToggle();
        });
        $screen.queryparts.on('click', '.equerygroupitemlist li', function (e, skipUpdateQueryString) {
            var $li = $(this),
                $input = $(this).closest('.dropdown-select').find('input:first'),
                $row = $(this).closest('.equeryli'),
                column = $li.attr('data-column'),
                group = $li.attr('data-group'),
                def;

            //------------START
            if (group === '()') {
                _addSubQuery($row);
                if (!skipUpdateQueryString) {
                    _updateQueryString();
                }
                return;
            }
            //------------END ADD SUBQUERY
            if (!tableDef[group]) {
                console.log('Error: group not found')
                return;
            }
            def = tableDef[group].get('column', column);
            if (!def) {
                console.log('Error: Definition not found')
                return;
            }
            //First run if $input don't have attribute 'data-column'
            if (!$input.attr('data-column')) {
                var $parentUl = $li.closest('.equeryul'), $prev = $row.prev();
                _addQueryPart($parentUl);
                if ($prev.length === 1) {
                    $prev.find('> .econdition-btn:first').show();
                }
            }
            $input.val($li.text());
            $input.attr('data-column', column);
            $input.attr('data-group', group);
            $row.find('div.hidden, .btn.hidden').removeClass('hidden');
            _setSelectCondition(def, $row.find('.econdition:first'));
            _setConditionValue(def, $row.find('.evalue:first'));
            if (!skipUpdateQueryString) {
                _updateQueryString();
            }

        });
        //REMOVE QUERY ROW
        $screen.queryparts.on('click', '.eremovecondition', function (e) {
            var $thisLi = $(this).closest('.equeryli'), $thisUl = $thisLi.closest('.equeryul'),
                $nextLi = $thisLi.next('.equeryli'),
                $prevLi = $thisLi.prev('.equeryli');
            if ($nextLi.find('.dropdown-select input:first').val() === '+') {
                $prevLi.find('.row.econdition-btn').hide();
            }
            $thisLi.remove();
            //Check for sub query
            if ($thisUl.hasClass('subul')) {
                if ($thisUl.find('> .equeryli').length === 0) {
                    var $parentLi = $thisUl.closest('.equeryli'), $parentUL = $parentLi.closest('.equeryul'),
                        $parentNextLi = $parentLi.next('.equeryli'), $parentPrevLi = $parentLi.prev('.equeryli');
                    if ($parentNextLi.find('.dropdown-select input:first').val() === '+') {
                        $parentPrevLi.find('.row.econdition-btn').hide();
                    }
                    $parentLi.remove();
                    if ($parentUL.hasClass('subul') && $parentUL.find('.equeryli').length === 1) {
                        $parentUL.find('.equeryli:last .eremovecondition').removeClass('hidden');
                    }
                } else if ($thisUl.find('> .equeryli').length === 1) {
                    $thisUl.find('.equeryli:last .eremovecondition').removeClass('hidden');
                }
            }
            _updateQueryString();
        });
        //SELECT CONDITION AND/OR
        $screen.queryparts.on('click', '.econdition-btn label', function (e, skipUpdateQueryString) {
            var $this = $(this),
                selectedCondition = $this.hasClass('econdition-and') ? 'and' : 'or',
                $parentUL = $this.closest('.equeryul'),
                $all = $parentUL.find('> .equeryli > .econdition-btn label');
            $all.removeClass('active');
            $all.filter('.econdition-' + selectedCondition).addClass('active');
            $parentUL.attr('data-condition', selectedCondition);
            if (!skipUpdateQueryString) {
                _updateQueryString();
            }
        });
        //UPDATE QUERY STRING
        $screen.queryparts.on('change', '.econdition', function () {
            //!!!Exception!!! change format input and type then choose mont-date
            var $this = $(this), $input = $this.closest('.equery').find('.evalue:first');
            if ($this.val() === 'month-day' && $input.val() !== "<nodata>") {
                $input.datepicker("destroy").attr('data-type', 'monthday').val(ee.tools.time($input.val()).toDate("MM-DD"));
            } else {
                $input.datepicker("destroy").attr('data-type', $this.attr('data-type'));
            }
            // --- END EXEPTION --- //
            _updateQueryString();
        });
        $screen.queryparts.on('change', 'select.evalue', _updateQueryString);
        $screen.queryparts.on('blur', 'input.evalue:not(.hasDatepicker)', _updateQueryString);
        //INIT DATETIME PICKER
        $screen.queryparts.on("focusin", "input[data-type=monthday]", function (e) {
            e.stopPropagation();
            var year = (new Date).getFullYear();
            $(this).datepicker({
                dateFormat: "mm-dd",
                changeMonth: true,
                changeYear: false,
                showButtonPanel: true,
                minDate: new Date(year, 0, 1),
                maxDate: new Date(year, 11, 31),

                onClose: function (input, inst) {
                    setTimeout(function () {
                        inst.dpDiv.removeClass('monthdayDatePicker'); _updateQueryString();
                    }, 200);
                    return false;
                },
                beforeShow: function (input, inst) {
                    inst.dpDiv.addClass('monthdayDatePicker');
                    setTimeout(function () {
                        var $buttonPane = $(input).datepicker("widget").find(".ui-datepicker-buttonpane");
                        $buttonPane.find(".ui-datepicker-current").off().click(function () {
                            input.value = "{today}";
                            $(input).datepicker("hide");
                        })
                        $("<button>", { text: "No data", click: function () { input.value = "<nodata>"; $(input).datepicker("hide"); } }).appendTo($buttonPane).addClass("ui-datepicker-clear ui-state-default ui-priority-primary ui-corner-all");

                    }, 1);
                }
            });
        });
        $screen.queryparts.on("focusin", "input[data-type=datetime]", function (e) {
            e.stopPropagation();
            $(this).datepicker({
                dateFormat: ee.tools.localDateFormatDatepicker(),
                changeMonth: true,
                changeYear: true,
                onClose: function (input, inst) {
                    if (input === "<nodata>") {
                        inst.input.val("<nodata>");
                    }
                    _updateQueryString();

                },
                beforeShow: function (input, inst) {
                    setTimeout(function () {
                        var $buttonPane = $(input).datepicker("widget").find(".ui-datepicker-buttonpane");
                        $("<button>", {
                            text: "No data",
                            click: function () {
                                inst.lastVal = input.value = "<nodata>";
                                $(input).datepicker("hide");
                            }
                        }).appendTo($buttonPane).addClass("ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all");
                    }, 200);
                },
            });
        });

        $(document).on('click', 'body', function(e){
            if ($(e.target).hasClass('evalue')){
                if (e.target.dataset.type != "datetime") {
                    $(e.target).removeClass('hasDatepicker');
                }
                if ($(e.target).is('select')){
                    $(e.target).removeClass('hasDatepicker');
                }
            }
        })
        /*--- END QUERY PARTS EVENTS---*/
    };
    var _addSubQuery = function ($row) {
        //remove selects and inputs .row but .conditon row stay
        $row.find('.row:first').remove();
        var $subUl = html.get('SubQueryWrap');
        $row.prepend($subUl)

        _addQueryPart($subUl);

        if ($row.next().hasClass('equeryli') === false) {
            _addQueryPart($row.closest('.equeryul'));
        }
        $subUl.prepend(html.getraw('QueryBracket', { bracket: '(' }));
        $subUl.append(html.getraw('QueryBracket', { bracket: ')' }));
        const $prev = $row.prev();
        if ($prev.length === 1) {
            $prev.find('> .econdition-btn:first').show();
        }

    }
    var _addQueryPart = function ($parentUL) {
        if (!$parentUL) {
            $parentUL = $screen.queryparts;
        }
        var selectedCondition = $parentUL.attr('data-condition'),
            $lastLi = $parentUL.find('> .equeryli:last'),
            $row = html.get('QueryPartRow', { tableDef: tableDef, isAND: ((selectedCondition === 'and') ? true : false) });
        if ($lastLi.length === 0) {
            $parentUL.append($row);
        } else {
            $row.insertAfter($lastLi);
        }
        if ($parentUL.hasClass('subul') === true && $parentUL.find('.subul').length === 0 && $parentUL.find('.equeryli').length === 1) {
            $row.find('.eremovecondition').removeClass('hidden');
        }
    }
    var _setSelectCondition = function (def, $select) {
        var conditionDefCategory = 'defaults';
        if (def.type === 'list' || def.type === 'enum') {
            conditionDefCategory = 'enume';
        }
        if (def.type === 'text') {
            conditionDefCategory = 'string';
        }
        if (def.type === 'date' || def.type === 'datetime') {
            conditionDefCategory = 'date';
        }
        var conditions = conditionDef[conditionDefCategory], options = '';
        for (var i = 0, n = conditions.length; i < n; i++) {
            options += '<option value=\'' + conditions[i]['operator'] + '\'>' + conditions[i]['label'] + '</option>'
        }
        $select.attr("data-type", def.type).html(options);
    };
    var _setConditionValue = function (def, $input) {
        if (def.type === 'list' || def.type === 'enum') {
            var $select = $($input.changeElementType('select')[0]);
            var options = '';
            if (def.values) {
                for (var i = 0, n = def.values.length; i < n; i++) {
                    options += '<option value=\'' + def.values[i]['val'] + '\'>' + def.values[i]['name'] + '</option>';
                }
            } else {
                var values = ee.data.contactgroups.filter(['islist'], 'true');
                options += '<option value="">Please select the list</option>';
                values.each(function () {
                    options += '<option>' + this.name + '</option>';
                });
            }
            $select.html(options);
            return;
        }

        if (def.type === 'boolean') {
            var $select = $($input.changeElementType('select')[0]);
            $select.html('<option value=\'False\'>False</option><option value=\'True\'>True</option>');
            return;
        }
        if ($input.is('select') === true) {
            var $input = $($input.changeElementType('input')[0]);
        }
        if (def.type === 'datetime') {
            $input.attr('data-type', 'datetime').val(ee.tools.time(new Date()).toDate("L LT"));
        } else if (def.type === 'date') {
            $input.attr('data-type', 'date').val(ee.tools.time(new Date()).toDate("L"));
        } else if (def.type == 'number') {
            //Unquote number value
            $input.attr('data-type', 'number').val(0);
        } else {
            //Common string quote text
            $input.attr('data-type', 'text').attr('placeholder', '<blank>');
        }
    };
    var _getRuleFromEditor = function ($ul) {
        var queryArray = { logic: "", filters: [] };
        $ul.find('> .equeryli').each(function () {
            let $li = $(this);
            let $rowInput = $li.find('> .equery:first');
            let $value = $rowInput.find('.evalue:first');
            let $conditionBtn = $li.find('> .econdition-btn:first');
            let query = {};
            let $children = $li.children();
            if ($children.is('ul') === true) {
                //Subquery
                queryArray.filters.push(_getRuleFromEditor($children));
            } else if ($rowInput.length === 1) {
                query.column = $rowInput.find('.ecolumn:first').attr('data-column');
                query.operator = $rowInput.find('.econdition:first').val();
                query.value = $value.val().replace(/[\\']/g, '');

                if (query.column === "list") {
                    query.column = "listname";
                    query.value = $value.find(":selected").text();
                }

                if (query.column === 'unsubscribereason') {
                    query.value = parseInt(query.value);
                }

                if (query.column) {
                    queryArray.filters.push(query);
                }
            }
            if (queryArray.logic === '') {
                queryArray.logic = $conditionBtn.find('.btn.active:first').text().trim();
            }

        });

        return queryArray;
    };
    var _getStringRuleFromEditor = function (toLocalTime, toServerTime, parseEnums) {
        return _ruleObjToString(_getRuleFromEditor($screen.queryparts), toLocalTime, toServerTime, null, parseEnums);
    };
    var _ruleObjToEditor = function (ruleObj, $ul) {
        if (ruleObj.filters) {
            $.each(ruleObj.filters, function () {
                var $li = $ul.find('> .equeryli:last');
                if (this.filters) {
                    _addSubQuery($li);
                    _ruleObjToEditor(this, $li.find("ul.equeryul:first"));
                    return;
                } else {
                    var $queryContainer = $li.find('> .equery:first');
                    $queryContainer.find('.dropdown-menu.multi-list li[data-column=' + ((this.column === 'listname') ? 'list' : this.column) + ']').trigger('click', [true]);
                    $queryContainer.find('.econdition').val(this.operator);
                    if (this.operator === "month-day") {
                        $queryContainer.find('.evalue').attr('data-type', 'monthday')
                    }
                    $queryContainer.find('.evalue').val(this.value);
                }
            });
        }
        $ul.find('> li.equeryli > .econdition-btn:first .econdition-' + ruleObj.logic.toLowerCase()).trigger('click', [true]);
    }
    var _updateQueryString = function () {
        $screen.ruleresultscontainer.html('...');
        $screen.query.text(_getStringRuleFromEditor());
    }
    var _monthDateParse = function (val, toLocal, toServer) {
        if (val === "{today}") return val;
        if (toLocal) {
            return ee.tools.time(val).toUTC();
        }
        if (toServer) {
            return new Date().getFullYear() + '-' + val;
        }
        return val;
    }
    /*-- Public property and methods --*/
    var Add = function () {
        $screen = html.get('QueryTool', { segment: { name: ee.t.newsegment } });
        $screen.appendTo(ee.frame.content);
        _initCommonEvent();
        _initQueryTool();
        _addQueryPart();

        if (ee.session.can.AdminModify && ee.views.querytool.adminShortcut) {
            ee.views.querytool.adminShortcut.init();
        }

        ee.tools.validateEntityName(
            {
                screen: $screen,
                inputname: '#esegmentname',
                submit: "#esavesegment"
            }
        )

    };
    var Edit = function (id, data) {
        //segment Search
        let propname, identifier;
        if (parseInt(id,10)) {
            propname = "segmentid";
            identifier = parseInt(id, 10);
        } else {
            propname = "name";
            identifier = decodeURIComponent(id);
        }
        const segment = ee.data.contactgroups.get(propname, identifier);
        let ruleObj = false;
        const trackingCount = (data === "No data") ? "No data" : data.length;

        if (!segment) {
            console.log("Error: Segment do not exist");
            ee.goTo("#/contacts");
            return;
        }

        $screen = html.get('QueryTool', { segment: segment, trackingCount: trackingCount, showTrackingCheckbox: true });
        $screen.appendTo(ee.frame.content);

        if (data.length === 10 && !$screen.tracksegmenthistory.is(':checked')) {
            $screen.tracksegmenthistory.attr("disabled", true);
            $screen.find(".trackingHistoryBadge").addClass("disabled");
        }

        if (data !== "No data") {
            $screen.tracksegmenthistory.on("change", (e) => {
                let trackingSegmentCountElem = $screen.find("#trackingSegmentCount");
                let trackingSegmentCount = trackingSegmentCountElem.text();
                if ($screen.tracksegmenthistory.is(':checked')) {
                    trackingSegmentCount++;
                    trackingSegmentCountElem.text(trackingSegmentCount);
                } else {
                    trackingSegmentCount--;
                    trackingSegmentCountElem.text(trackingSegmentCount);
                }
            })
        }

        _initCommonEvent();
        //Init Query Tools
        _initQueryTool();
        _addQueryPart();
        //Setup Editor
        ruleObj = _ruleStringToObj(segment.rulelocal);

        _ruleObjToEditor(ruleObj, $screen.queryparts);
        //Insert query to header
        _updateQueryString();

        ee.tools.validateEntityName(
            {
                screen: $screen,
                inputname: '#esegmentname',
                submit: "#esavesegment"
            }
        )
    };
    var _ruleStringToObj = function (where) {
        where += " ";

        var obj = { logic: "AND", filters: [] },
            group = obj,
            sequence = "",
            rulepos = 0,
            rule = null,
            inval = false,
            quotedSequence = false;
            lastWasBracket = false;
        for (var i = 0; i < where.length; i++) {
            var character = where[i];
            //if inside a string value just take the chars
            if (inval) {
                if (character == "'")
                    inval = false;
                else
                    sequence += character;
                continue;
            }
            if (character == "'") {
                inval = true;
                quotedSequence = true;
            }
            else if (character == "(") {
                //create a new group
                var newgroup = { logic: "AND", filters: [], parent: group };
                group.filters.push(newgroup);
                group = newgroup;
                lastWasBracket = true;
            }
            else if (character == " " || character == ")") {
                if (lastWasBracket) {
                    lastWasBracket = false
                } else if (sequence.length >= 0) {
                    if (character == ")") {
                        lastWasBracket = true;
                    }
                    if (quotedSequence === false && (sequence == "AND" || sequence == "OR"))
                        group.logic = sequence;
                    else if (rulepos == 0 && character != ')') {
                        rule = { column: sequence.toLowerCase() };
                        rulepos++;
                    }
                    else if (rulepos == 1) {
                        rule.operator = sequence;
                        rulepos++;
                    }
                    else if (rulepos == 2) {
                        rule.value = sequence;
                        rulepos = 0;
                        quotedSequence = false;
                        //Note: Re-change of assumptions ....
                        if (rule.column === "label" || rule.column === "labelname") {
                            rule.column = "listname";
                        }
                        group.filters.push(rule);
                    }
                    sequence = "";
                }
                if (character == ")")
                    group = group.parent;
            }
            else
                sequence += character;
        }


        return obj;
    };
    var _ruleObjToString = function (ruleObject, toLocalTime, toServerTime, fixColumns, parseEnums = true) {
        var where = [];
        $.each(ruleObject.filters, function (index, filter) {
            if (filter.column === undefined) {
                var subquery = _ruleObjToString(filter, toLocalTime, toServerTime).trim();
                if (subquery !== '') {
                    where.push('( ' + subquery + ' )');
                }
            } else {
                //Masked List Column change to LabelName
                var list = false, item = false, value;

                if (filter.column === "listname" || filter.column === "list") {
                    item = tableDef.general.head()
                    /*NOTE It is synchronus to much!! */
                    if (filter.column === "listname") {
                        list = (!ee.data.contactgroups) ? ee.data.lists.get('listname', filter.value) : ee.data.contactgroups.get('listname', filter.value);
                    } else {
                        list = (!ee.data.contactgroups) ? ee.data.lists.get('listid', Number(filter.value)) : ee.data.contactgroups.get('listid', parseInt(filter.value, 10));
                    }
                    if (list) {
                        if (fixColumns === true || toServerTime === true) {
                            where.push("List " + filter.operator + " " + list.listid);
                            return true;
                        }
                        if (toLocalTime) {
                            filter.value = list.listname.replace(/[\\']/g, "");
                        }
                    } else {
                        filter.value = "<error: list removed>";
                    }

                } else {
                    item = tableDef.general.get('column', filter.column);
                    if (!item) {
                        item = tableDef.custom.get('column', filter.column);
                    }
                    if (!item) {
                        item = tableDef.statistic.get('column', filter.column);
                    }
                }
                if (item) {
                    switch (item.type) {
                        case "datetime":
                            if (filter.operator === 'month-day') {
                                value = "'" + ((filter.value === "<nodata>") ? filter.value : _monthDateParse(filter.value, toLocalTime, toServerTime)) + "'";
                            } else if (toLocalTime) {
                                value = "'" + ((filter.value === "<nodata>") ? filter.value : ee.tools.time(filter.value).toDate()) + "'";
                            } else if (toServerTime) {
                                value = "'" + ((filter.value === "<nodata>") ? filter.value : ee.tools.time(filter.value).toDate()) + "'";
                            } else {
                                value = "'" + filter.value + "'";
                            }
                            break;
                        case "number":
                            value = (!isNaN(parseInt(filter.value))) ? filter.value : 0;
                            break;
                        case "boolean":
                            value = (filter.value === "True") ? "True" : "False";
                            break;
                        default:
                            if (item.column === 'unsubscribereason') {

                                const stringedValue = _.find(item.values, (val) => { return val.name === filter.value});
                                if (stringedValue && !parseEnums) {
                                    value = "'" +  stringedValue.val + "'";
                                    break;
                                }

                                const parsedValue = (parseEnums && _.isNumber(filter.value)) ? item.values[parseInt(filter.value)].name : filter.value;
                                value = "'" + parsedValue + "'";
                                break;
                            }

                            value = "'" + filter.value + "'";
                            break;
                    }
                }
                where.push(filter.column + " " + filter.operator + " " + value);
            }
        });
        return where.join(" " + ruleObject.logic + " ");
    };
    /*-- Return --*/
    that.show = function () {
        //load contacts
        var contactFieldsData;

        if (!ee.data.contactgroups) {
            ee.api.loadContactOverview();
            return;
        }
        contactFieldsData = dataModel.create("ContactFields");
        _reset();
        $.when(contactFieldsData.require()).then(function (data) {
            switch (ee.Router.action()) {
                case 'edit':
                    return EE_API.Segment.LoadTrackedHistory({})
                    .then((data) => {
                        Edit(ee.Router.parameter('node0'), data);
                    })
                    .catch((err) => {
                        console.log(err);
                        Edit(ee.Router.parameter('node0'), "No data");
                    });
                default:
                    Add();
            }
        });
        return;
    };
    that.ruleStringToObj = _ruleStringToObj;
    that.ruleObjToString = _ruleObjToString;
    that.resetTableDef = _resetTableDef;
    that.setupTableDef = _setupTableDef;
    return that;
})();
