(function (root) {
    'use strict';
    var Sidebar = function() {
        return {
            init: function (title, content) {
                // Create & Save sidebar markup inside this.$sidebar
                this.createMarkup(title, content);
                // Close on overlay or x click
                this.$sidebar.on("click", function(e) {
                    if($(e.target).hasClass('sidebar__frameoverlay') ||
                    $(e.target).hasClass('sidebar__close')) {
                        this.destroy();
                    }
                }.bind(this));
                // Show sidebar
                $("body").append(this.$sidebar);
                return this;
            },
            createMarkup: function(title, content) {
                var wrapper = html.get("SidebarFrame", {title: title});

                if(Array.isArray(content)) {
                    for(var i = 0; i < content.length; i++) {
                        wrapper.find('.sidebar__content').append(content[i]);
                    }
                } else {
                    wrapper.find('.sidebar__content').append(content);
                }

                this.$sidebar = wrapper;
            },
            destroy: function() {
                var sidebar = this.$sidebar.find('.sidebar');
                sidebar.removeClass('slide-in');
                setTimeout(function() {
                    this.$sidebar.remove();
                }.bind(this), 500)
            }
        };
    };

    /*
    *
    * @param {String} title - Sidebar title
    * @param {jQuery/[jQuery]} content - jQuery element instance or array of insances
    * @param {function} afterInit - callback, it's first arg is a Sidebar instance
    *
    * */
    root.Sidebar = function(title, content, afterInitCb) {
        var sidebar = Sidebar().init(title, content);
        if (!ee.data.sidebars) {
            ee.data.sidebars = [];
        }
        ee.data.sidebars.push(sidebar);
        if(afterInitCb) {
            afterInitCb(sidebar);
        }
        // Slide in sidebar from right
        setTimeout(function() {
            sidebar.$sidebar.find('.sidebar').addClass('slide-in');
        }, 0);

        return sidebar;
    };
})(html);
