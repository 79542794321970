(function (namespace) {

    var _format =  function () {
        var ruleObject,
            segment = this;
        //Normalize count name for list and segments
        if ($.isNumeric(segment.lastcount) === true) {
            segment.count = segment.lastcount;
            delete segment.lastcount;
        }
        segment.countformatted = segment.count.numberFormat();
        /*NOTE: bottleneck of segments. */
        if (segment.segmentid > 0) {
            ruleObject = ee.views.querytool.ruleStringToObj(segment.rule);
            segment.rule = ee.views.querytool.ruleObjToString($.extend({}, ruleObject), false, false, true);
            segment.rulelocal = ee.views.querytool.ruleObjToString(ruleObject, true, false);
        } else {
            segment.rulelocal = segment.rule;
            segment.rule = "";
        }
        if (segment.history) {
            segment.graphdata = [['x'], [segment.name]];
            for (var i = segment.history.length - 1; i >= 0; i--) {
                segment.graphdata[0].push((segment.history[i].day.toString()).replace(/(.{4})(.{2})(.{2})/, '$1-$2-$3'));
                segment.graphdata[1].push(segment.history[i].count);
            }
        }
    },

    /* Lists constructor */
    /**
     * Create Lists collection model
     * @class
     * @extends Collection.Data
     * @memberof dataModel
     */
    Segments = function Segments(dataArr) {

        if (!(this instanceof Segments)) {
            throw new Error('Segments is a constructor and it`s need "new" operator');
            return;
        };

        if (dataModel.isModel("Segments", ee.data.segments)) return ee.data.segments;

        Collection.Data.call(this, dataArr);

        this.isLoaded = false;
    };
    var options = {
        collection: true,
        storage: {
            name: "segments",
            load: EE_API.Segment.List
        }
    };
    var proto = {
        require: function () {
            const that = this;
            const lists = dataModel.create("Lists").require();
            const custom = dataModel.create("ContactFields");

            return $.when(lists, custom.require()).then(function () {
                return that._sup.require.call(that);
            }).catch((err) => {console.error(err)});

        },
        reload: function () {
            this.data = null;
            this.isLoaded = false;
            return this.require();
        },
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        }
    };

    dataModel.inherit(Segments, proto, options).register("Segments", Segments);

}(window.dataModel));
