(function (root) {

    root.Automation = {
        Add: function (query) { return root.request('/journey/add', query) },
        "Delete": function (query) { return root.request('/journey/delete', query) },
        DeleteStep: function (query) { return root.request('/journey/deletestep', query)},
        Load: function (query) { return root.request('/journey/load', query) },
        List: function (query) { return root.request('/journey/list', query) },
        Update: function (query) { return root.request('/journey/update', query) },
        UpdateStepCustom: function (query) { return root.request('/journey/updatestepcustom', query) },
        AddStepCustom: function (query) { return root.request('/journey/addstepcustom', query) },
        UpdateJson: function (query) { return root.request('/journey/updatejson', query) }
    };


}(EE_API));