(function (namespace) {

    //Format raw data from backend
    function __format() {
        var recipient = this;
        recipient.statusname = __getStatusName(recipient.status);

        if (!recipient.date && recipient.datesent) recipient.date = recipient.datesent;

        recipient.date = ee.tools.time(recipient.date).fromUTC();
        if (recipient.datesent) {
            recipient.datesent = ee.tools.time(recipient.datesent).fromUTC();
        }
        if (recipient.dateclicked) {
            recipient.dateclicked = ee.tools.time(recipient.dateclicked).fromUTC();
        }
        if (recipient.dateopened) {
            recipient.dateopened = ee.tools.time(recipient.dateopened).fromUTC();
        }

        recipient.schedule = (recipient.nexttryon && (recipient.status === "ReadyToSend" || recipient.status === "WaitingToRetry")) ? ee.tools.time(recipient.nexttryon).fromUTC() : false;
        if (recipient.status === "Bounced" || recipient.status === "WaitingToRetry") {
            __generateGenericMsg(recipient);
        }

        if (moment && moment.tz) {
            recipient.timezone = moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset());
        }


    };
    function __getStatusName(name) {
        var statusnames = {
            "Submitted": ee.t.submitted,
            "ReadyToSend": ee.t.readytosend,
            "WaitingToRetry": ee.t.waitingtoretry,
            "Sending": ee.t.sending,
            "Bounced": ee.t.bounced,
            "Sent": ee.t.sent,
            "Opened": ee.t.opened,
            "Clicked": ee.t.clicked,
            "Unsubscribed": ee.t.unsubscribed,
            "AbuseReport": ee.t.complaint
        }
        return statusnames[name];
    };
    function __generateGenericMsg(recipient) {

        recipient.allowResend = true;

        if (recipient.messagecategory === "NotDelivered") {
            recipient.status = 'Bounced';
            recipient.statusname = ee.t.bounced;
            if ( recipient.message.indexOf("'Unsubscribed'") !== -1 || recipient.message.indexOf("'Complaint'") !== -1 || recipient.message.indexOf("transactional") !== -1) {
                recipient.allowResend = false;
            }
            if (recipient.message.indexOf("'Invalid'") !== -1) {
                recipient.confirmResend = true;
            }
        }
        if (recipient.status === "WaitingToRetry") {
            recipient.allowResend = false;
        }
        if (recipient.messagecategory === "NoMailbox") {
            recipient.confirmResend = true;
        }
        if (recipient.messagecategory === "ManualCancel" || recipient.messagecategory === "NotDeliveredCancelled") {
            recipient.status = "Bounced";
            recipient.statusname = ee.t.suppressed;
        };


        if (recipient.message && recipient.message.substr(0, 1) === "[") {

            if (recipient.message.substr(0, 19) === "[Blocked Recipient]") {
                recipient.genericmessage = ee.t.blockedrecipientdesc;
            } else if (recipient.message.substr(0, 18) === "[Unsubscribe List]") {
                recipient.genericmessage = ee.t.unsubscribedesc;
            } else if (recipient.message.substr(0, 12) === "[Spam Check]") {
                recipient.genericmessage = ee.t.spamcheckdesc;
            } else if (recipient.message.substr(0, 16) === "[Content Filter]") {
                recipient.genericmessage = ee.t.contentfilterdesc;
            } else if (recipient.message.substr(0, 26) === "[Global Blocked Recipient]") {
                recipient.genericmessage = ee.t.globalblockedrecipientdesc;
            } else if (recipient.message.substr(0, 16) === "[User Cancelled]") {
                recipient.genericmessage = ee.t.usercancelleddesc;
            }

            recipient.messagecategoryLANG = recipient.messagecategory;


        } else {
            switch (recipient.messagecategory) {
                case "Unknown":
                    recipient.genericmessage = ee.t.unknowndesc;
                    recipient.messagecategoryLANG = ee.t.unknown;
                    break;
                case "BlackListed":
                    recipient.genericmessage = ee.t.blacklisteddesc;
                    recipient.messagecategoryLANG = ee.t.blacklisted;
                    break;
                case "Ignore":
                    recipient.genericmessage = ee.t.ignoredesc;
                    recipient.messagecategoryLANG = ee.t.ignore;
                    break;
                case "Spam":
                    recipient.genericmessage = ee.t.spamdesc;
                    recipient.messagecategoryLANG = ee.t.spam;
                    break;
                case "NoMailbox":
                    recipient.genericmessage = ee.t.nomailboxdesc;
                    recipient.messagecategoryLANG = ee.t.nomailbox;
                    break;
                case "GreyListed":
                    recipient.genericmessage = ee.t.greylisteddesc;
                    recipient.messagecategoryLANG = ee.t.greylisted;
                    break;
                case "Throttled":
                    recipient.genericmessage = ee.t.throttleddesc;
                    recipient.messagecategoryLANG = ee.t.throttledcategory;
                    break;
                case "Timeout":
                    recipient.genericmessage = ee.t.timeoutdesc;
                    recipient.messagecategoryLANG = ee.t.timeout;
                    break;
                case "ConnectionProblem":
                    recipient.genericmessage = ee.t.connectionproblemdesc;
                    recipient.messagecategoryLANG = ee.t.connectionproblem;
                    break;
                case "SPFProblem":
                    recipient.genericmessage = ee.t.spfproblemdesc;
                    recipient.messagecategoryLANG = ee.t.spfproblem;
                    break;
                case "AccountProblem":
                    recipient.genericmessage = ee.t.accountproblemdesc;
                    recipient.messagecategoryLANG = ee.t.accountproblem;
                    break;
                case "DNSProblem":
                    recipient.genericmessage = ee.t.dnsproblemdesc;
                    recipient.messagecategoryLANG = ee.t.dnsproblem;
                    break;
                case "WhitelistingProblem":
                    recipient.genericmessage = ee.t.whitelistingproblemdesc;
                    recipient.messagecategoryLANG = ee.t.whitelistingproblem;
                    break;
                case "CodeError":
                    recipient.genericmessage = ee.t.codeerrordesc;
                    recipient.messagecategoryLANG = ee.t.codeerror;
                    break;
                case "ManualCancel":
                    recipient.genericmessage = ee.t.manualcanceldesc;
                    recipient.messagecategoryLANG = ee.t.manualcancel;
                    break;
                case "ConnectionTerminated":
                    recipient.genericmessage = ee.t.connectionterminateddesc;
                    recipient.messagecategoryLANG = ee.t.connectionterminated;
                    break;
                case "NotDeliveredCancelled":
                    recipient.messagecategoryLANG = ee.t.notdeliveredcancelled
                    recipient.genericmessage = ee.t.throttledfor48ho;
                    break;
                default:
                    recipient.messagecategoryLANG = recipient.messagecategory;
            }

            if (recipient.messagecategory === "NotDelivered") {

                recipient.messagecategoryLANG = ee.t.notdelivered;

                switch (recipient.message) {
                    case "Recipient on contact block list.":
                        recipient.genericmessage = ee.t.blockedrecipientdesc;
                        break;
                    case "Not enough credit.":
                        recipient.genericmessage = ee.t.notenoughcreditdesc;
                        break;
                    case "Not enough credit for attachments.":
                        recipient.genericmessage = ee.t.notenoughcreditforattachments;
                        break;
                    default:
                        recipient.genericmessage = ee.t.notdelivereddesc;
                }
            }
        }
    };

    var Logs = function Logs(data) {

        if (!data) data = [];
        Collection.Data.call(this, data);

        this.query = {
            to: moment().toISOString(),
            from: moment().subtract(35, "days").toISOString(),
            channelid: 0,
            messagecategory: 0
        }; //query state
    };

    var options = {
        collection: true
    };

    var proto = {
        FormatData: function (data) {
            this.each(__format);
        },
        Load: function (opt, callback) {
            let self = this;
            let query = self.query;
            if (!opt || !opt.query) {
                throw new Error("Require query parameter in option object!");
            };

            // Change state or set default

            if (opt.query.messagecategory !== undefined) query.messagecategory = opt.query.messagecategory;
            if (opt.query.to) {
                opt.query.to = moment(opt.query.to).toISOString();
                self.query.to = opt.query.to;
            }
            if (opt.query.from) {
                opt.query.from = moment(opt.query.from).toISOString();
                self.query.from = opt.query.from;
            }
            (opt.query.statuses) ? query.statuses = opt.query.statuses : opt.query.statuses = query.statuses;

            opt.query.statuses = String(opt.query.statuses);

            if (!opt.query.limit) opt.query.limit = 500;
            if (!opt.query.channelid) opt.query.channelid = query.channelid;

            if (String(opt.query.statuses) !== '4' || opt.query.messagecategory === 0) delete opt.query.messagecategory;

            if (opt.query.messagecategory) opt.query.includeSMS = false;

            if (opt.query.includeSMS) {
                opt.query.includeSMS = true;
                opt.query.includeEmail = false;
            } else {
                opt.query.includeSMS = true;
                opt.query.includeEmail = true;
            }

            if (query.email) {
                opt.query.email = query.email;
                opt.query.includeSMS = false;
            };
            ee.api.request("/log/load", opt.query,
            function (data) {
                if (!data.success) return html.modal.error(data);
                if (!opt.add) {
                    self.update(data.data.recipients).each(__format);
                } else {
                    var dataToAdd = new Collection.Data(data.data.recipients);
                    dataToAdd.each(__format);
                    self.add(dataToAdd.getAll());
                }
                if (callback) callback(self, data);
            }, 60000, undefined, undefined, undefined, true)

        },

        LoadAll: function (callback, customLimit, offset) {
            var self = this,
                callquery = {...self.query};

            self.Load({
                query: {
                    statuses: ["1,2,3", "5", "6", "7", "8", "4,10", "9"],
                    limit: customLimit ? customLimit : 500,
                    offset: offset ? offset : 0,
                    from: callquery.from,
                    to: callquery.to
                }
            }, function (obj, data) {
                if (callback) callback(obj, data);
            })
        },
        LoadInProgress: function (opt, callback) {
            var self = this,
                query = self.query;

            if (!opt || !opt.statuses) { throw new Error("Statuses are required!") };

            /* time on last 2 days */
            query.to = moment().toISOString();
            query.from = moment().subtract(2, "days").toISOString();

            self.Load({
                query: {
                    statuses: opt.statuses,
                    limit: 500
                }
            }, function (obj, data) {
                if (callback) callback(obj, data);
            })
        },
        LoadBounced: function (callback) {
            var self = this,
                query = self.query;

            /* set time on last 35days */
            query.to = moment().toISOString();
            query.from = moment().subtract(35, "days").toISOString();

            self.Load({
                query: {
                    from: self.query.from,
                    to: self.query.to,
                    statuses: ["4,10"],
                    limit: 500
                }
            }, function (obj, data) {
                if (callback) callback(obj, data);
            })
        },
        LoadBouncedCategory: function (messagecategory, callback) {
            var self = this,
                query = self.query;
            if (messagecategory === undefined) {
                throw new Error("Require messagecategory parameter in option object!");
                return;
            };
            self.Load({
                query: {
                    statuses: ['4'],
                    from: self.query.from,
                    to: self.query.to,
                    messagecategory: messagecategory
                }
            },
            function (self, data) {
                if (callback) callback(self, data)
            })
        },
        LoadMore: function (callback) {
            var self = this,
                query = self.query;


            self.Load({
                add: true,
                query: {
                    limit: 500,
                    from: self.query.from,
                    to: self.query.to,
                    offset: self.getLength(),
                    messagecategory: query.messagecategory
                }
            }, function (obj, data) {
                if (callback) callback(obj, data);
            })
        },
        setEmail: function (email) {
            this.query.email = email;
        },
        removeEmail: function () {
            delete this.query.email;
        },
        setChannelID: function (channelId) {
            this.query.channelid = channelId;
        },
        removeChannelID: function () {
            this.query.channelid = 0;
        },
        getChannelID: function () {
            return Number(this.query.channelid);
        },
        Export: function (opt, callback) {
            var self = this,
                query = self.query,
                exportQuery;


            if (!opt.messagecategory && query.messagecategory) opt.messagecategory = query.messagecategory;

            if (String(query.statuses) !== '4' || opt.messagecategory === 0) delete opt.messagecategory;

            exportQuery = {
                filename: opt.filename || "Logs",
                fileFormat: opt.fileFormat || 'csv',
                compressionFormat: opt.compressionFormat || 0,
                to: ee.tools.time(query.to).toUTC(),
                from: ee.tools.time(query.from).toUTC(),
                statuses: opt.statuses ? opt.statuses : String(query.statuses),
                channelid: query.channelid,
            };

            if (query.email) {
                exportQuery.email = query.email;
                exportQuery.includeSms = false;
            }

            if (opt.messagecategory) {
                exportQuery.messageCategory = query.messagecategory;
            }
            if (opt.includeSMS) {
                exportQuery.includeSMS = true;
                exportQuery.includeEmail = false;
            }

            ee.api.exportData(exportQuery, '/log/export', callback);
        },
        getLog: function (key, val, callback) {
            var self = this,
                logObj = self.get(key, val);
            return dataModel.create("Log", {
                data: logObj,
                callback: callback
            });
        },
        getStatuses: function () {
            return this.query.statuses;
        },
        getDateTime: function (type) {
            var self = this;
            switch (type) {
                case "from":
                    return self.query.from;
                case "to":
                    return self.query.to;
                default:
                    return [self.query.from, self.query.to]
            }
        }
    };

    dataModel.inherit(Logs, proto, options).register("Logs", Logs);

}(window.dataModel));
