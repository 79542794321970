(function (namespace) {

    var Links = function Links(data) {

        if (!(this instanceof Links)) {
            throw new Error('Links is a constructor and it`s need "new" operator');
            return;
        };

        if (!data) data = [];

        Collection.Data.call(this, data);

        this.query = {
            to: new Date(),
            from: new Date(),
            channelid: 0
        }; //query state
    };

    var options = {
        collection: true
    };

    var proto = {

        load: function (opt) {
            var self = this,
                query = self.query;

            if (typeof opt === 'function') {
                callback = opt;
                opt = { query: {} };
            };

            if (!opt) opt = {};
            if (!opt.query) opt.query = {};

            if (!opt.query.from) opt.query.from = query.from;
            if (!opt.query.to) opt.query.to = query.to;

            opt.query.from = ee.tools.time(opt.query.from).toUTC();
            opt.query.to = ee.tools.time(opt.query.to).toUTC();

            if (opt.query.channelid === undefined && query.channelid !== undefined) opt.query.channelid = query.channelid;
            return EE_API.Log.LinkTracking(opt.query)
                .then(function (data) {
                    self.update(data.trackedlink);
                });
        },

        export: function (opt, callback) {
            var self = this,
                query = self.query,
                exportQuery;

            if (callback === undefined && typeof opt === "function") {
                callback = opt;
                opt = {};
            };

            exportQuery = {
                filename: opt.filename || "Links",
                fileFormat: opt.fileFormat || 'csv',
                compressionFormat: opt.compressionFormat || 0,
                to: ee.tools.time(query.to).toUTC(),
                from: ee.tools.time(query.from).toUTC(),
                channelid: query.channelid
            };
            return EE_API.Log.ExportLinkTracking(exportQuery);
        },

        setDateFrom: function (dateFrom) {
            this.query.from = dateFrom;
            return this;
        },

        setDateTo: function (dateTo) {
            this.query.to = dateTo;
            return this;
        },

        setChannelID: function (channelid) {
            this.query.channelid = channelid;
            return this;
        },
        exportContact: function (opt, callback) {
            var self = this,
                query = self.query;

            if (!opt.filename) opt.filename = opt.link.replace(/[^a-zA-Z0-9]/g, '_');

            opt.from = query.from;
            opt.to = query.to;
            opt.from = ee.tools.time(opt.from).toUTC();//ee.api.formatDate(opt.from, '', true);
            opt.to = ee.tools.time(opt.to).toUTC();//ee.api.formatDate(opt.to, '', true);

            return EE_API.Log.ExportLinkContacts(opt);
        },
        removeChannelID: function () {
            var self = this;
            delete self.query.channelid;
            return self;
        },
        getDateFrom: function () {
            return this.query.from;
        },
        getDateTo: function () {
            return this.query.to;
        }
    }

    dataModel.inherit(Links, proto, options).register("Links", Links);

}(window.dataModel));
