(function (namespace) {
    "use strict";

    //Format raw data from backend
    var __format = function () {
        this.lastactivity = ee.tools.time(this.lastactivity).fromUTC("L LT");//ee.api.formatDate(this.lastactivity);
        this.repstarhtml = html.widget.Stars.get(this.reputation).html;

        if (this.monthlyrefillcredits === 0) {
            this.monthlyrefillcreditsSTR = ee.t.none
        } else {
            this.monthlyrefillcreditsSTR = String(this.monthlyrefillcredits);
        };
        if (this.dailysendlimit === 0) {
            this.dailysendlimitSTR = ee.t.unlimited;
        } else {
            this.dailysendlimitSTR = String(this.dailysendlimit);
        }
        //Remove MailerId if user is not admin
        if (ee.session.can.AdminView === false) {
            delete this.mailerid
        }
        this.totalemailssentSTR = this.totalemailssent.numberFormat();
    },
        Subaccount = function (data) {
            if (typeof data === 'string') {
                this.email = data;
                return this;
            }
            if (data) _.assign(this, data);
            return this;
        },
        proto = {
            Create: function (query) {
                if (query.poolname === 'Default') delete query.poolname;
                return EE_API.Account.AddSubAccount(query);
            },
            Edit: function (query) {
                query.subaccountemail = this.email;
                return EE_API.Account.UpdateSubAccount(query);
            },
            Delete: function () {
                return EE_API.Account.DeleteSubAccount({ subaccountemail: this.email });
            },
            AddCredits: function (query) {
                var self = this;
                query.subaccountemail = self.email;
                return EE_API.Account.AddSubAccountCredits(query).then(function () {
                    self.emailcredits = Number(self.emailcredits) + Number(query.credits);
                });
            },
            RemoveCredits: function (query) {
                var self = this;
                query.subaccountemail = self.email;
                query.creditType = "Email";
                return EE_API.Account.RemoveSubAccountCredits(query).then(function () {
                    self.emailcredits = Number(self.emailcredits) - Number(query.credits);
                });
            },
            LoadCreditsHistory: function () {
                return EE_API.Account.LoadSubAccountsEmailCreditsHistory({ subaccountemail: this.email }).then(function (data) {
                    var history = new Collection.Data(data);
                    history.each(function () {
                        this.date = ee.api.formatDate(this.date, "dd/mm/yy");
                        if (this.amount !== undefined && this.amount < 0) this.className = 'eredtext';
                    });
                    return history
                });
            },
            Format: __format
        };
    $.extend(Subaccount.prototype, proto);
    dataModel.register("Subaccount", Subaccount);
}(window.dataModel));
