(function (namespace) {
    var _contactSource = ['Delivery API', 'Manual Input', 'File Upload', 'Web Form', 'Contact API'],
        _contactStatus = ['Transactional', 'Engaged', 'Active', 'Invalid', 'Unsubscribed', 'Complaint', 'Inactive', 'Stale', 'NotConfirmed'],
        _parseContact = function () {
            var contact = this;
            contact.sourcename = _contactSource[contact.source];
            if (contact.birthdate && contact.birthdate.trim() !== '') {
                contact.birthdate = ee.api.formatDate(contact.birthdate, 'yy-mm-dd', false, true);
            }
            contact.statusname = _contactStatus[(contact.status) + 2];
        },
        _getCountQuery = function (rule) {
            var countQuery = {};
            if (rule) {
                countQuery.rule = rule;
            } else {
                countQuery.allContacts = true;
            }
            return countQuery;
        },
        /* Lists constructor */
        /**
         * Create Lists collection model
         * @class
         * @extends Collection.Data
         * @memberof dataModel
         */
        ContactsList = function ContactsList(dataArr) {

            Collection.Data.call(this, dataArr);
            this.statusCount = {};
        };

    var options = {
        collection: true
    };

    var proto = {
        load: function (opt) {
            var that = this,
                countactsList = EE_API.Contact.List(opt.query),
                countQuery = _getCountQuery(opt.query.rule),
                countByStatus = EE_API.Contact.CountByStatus(countQuery);
            return $.when(countByStatus, countactsList).then(function (count, contacts) {
                
                count.all = count.engaged + count.active + count.complaint + count.unsubscribed + count.bounced + count.inactive + count.transactional + count.stale + count.notconfirmed;

                that.updateCountByStatus(count);
                that.update(contacts);
                _.forEach(that.data, function(el){
                    el.dateadded = ee.tools.time(el.dateadded).fromUTC("L LT");
                    el.dateupdated = ee.tools.time(el.dateupdated).fromUTC("L LT");
                })
                return that;
            })
        },
        update: function (data) {
            return Collection.Data.prototype.update.call(this, data).each(_parseContact);
        },
        updateCountByStatus: function (countObj) {
            var that = this;
            $.extend( that.statusCount, countObj );
        },
        getCountByStatus: function () {
            return this.statusCount;
        }
    };

    dataModel.inherit(ContactsList, proto, options).register("ContactsList", ContactsList);

}(window.dataModel));
