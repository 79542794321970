(function (root) {
    'use strict';

    /**
     * Iterates over the standard `event, callback` (as well as the fancy multiple maps `{event: callback}` ).
     * @param {any} $obj
     * @param {any} events
     * @param {any} handler
     */
    var _internalOn = function ($obj, events, handler) {
        if (events && typeof events === 'object') {
            for (var eventName in events) {
                if (events.hasOwnProperty(eventName)) {
                    _eventRegister($obj, eventName, events[eventName]);
                }
            }
        } else if (typeof events === 'string') {
            _eventRegister($obj, events, handler);
        }
    };
    /**
     * Register an event on the object if handler exists
     * @param {any} $obj
     * @param {any} eventName
     * @param {any} handler
     */
    var _eventRegister = function ($obj, eventName, handler) {
        if (!handler) return;
        $obj.on(eventName, handler);
    };
    /**
     * Event object is used to extends the prototype of plugin(complex views) that are not themselves a jquery object.
     * Allows to create new custom events which are trigger in the plugin logic.
     * @namespace html.event
     * @memberof html
     */
    var Event = {

        /**
         * Bind custom event on this.$container
         * @param {string} eventName - Custom event name
         * @param {function} handler - A function to execute when the event is triggered.
         * @return {obj} this - return itself
         */
        on: function on(eventName, handler) {
            if (!this.$container) throw new Error("Object $container does not exist in scope");
            _internalOn(this.$container, eventName, handler)
            return this;
        },
        /**
         * Remove an event handler from this.$container
         * @param {string} eventName - One or more space-separated event types
         * @param {function} handler - A function to execute when the event is triggered.
         * @return {obj} this - return itself
         */
        off: function (eventName) {
            if (!this.$container) return;
            this.$container.off(eventName);
            return this;
        },
        /**
         * Execute all handlers and behaviors attached to the this.$container for the given event type.
         * @param {string} eventName - A string containing a custom event type
         * @param {array} params - Additional parameters to pass along to the event handler.
         * @return {obj} this - return itself
         */
        trigger: function (eventName, params) {
            if (!this.$container) return;
            this.$container.trigger(eventName, params);
            return this;
        },

        /**
         * Execute all handlers and behaviors attached to the this.$container for the given event type.
         * @param {string} eventName - A string containing a custom event type
         * @param {array} params - Additional parameters to pass along to the event handler.
         * @return {obj} this - return 
         */
        triggerHandler: function (eventName, params) {
            if (!this.$container) return;
            return this.$container.triggerHandler(eventName, params);
        }
    };
    root.event = Event;
})(html);