(function () {
    "use strict";

    var surveyPattern = {
        publicsurveyid: "00000000-0000-0000-0000-000000000000",
        name: "",
        status: 1,
        expirydate: "",
        surveysteps: false
    },
        _enumStatus = ['Expired', 'Active'],
        _format = function () {
            this.datecreatedUTC = this.datecreated;
            this.datecreated = ee.tools.time(this.datecreated).fromUTC("L")
            this.dateupdated = this.dateupdated ? ee.tools.time(this.dateupdated).fromUTC("L"): ""
            this.expirydate = (this.expirydate) ? ee.tools.time(this.expirydate).fromUTC("L") : "Never";
            if (this.expirydate !== 'Never' && this.status === 1) {
                this.showexpirydate = true;
            }
            this.statusclass = (this.status === 1) ? 'text-success' : 'text-danger';
            this.firenldystatus = _enumStatus[this.status];
            this.summaryloaded = false;
            this.responsesloaded = false;
            this.contentloaded = false;
        },
        _formatSurveyResponse = function (response) {
            response.duration = ee.api.dutarionTime(response.datestart, response.datecompleted);
            response.duration = ee.api.dutarionTime(response.datestart, response.datecompleted);
            response.datestartformatted = ee.tools.time(response.datestart).fromUTC("L LT");// ee.api.formatDate(response.datestart, "dd/mm/yy, hh:ii:ss");//ee.tools.time(this.datestart).toDate("L");//ee.api.formatDate(response.datestart, "dd/mm/yy, hh:ii:ss");
            response.datecompletedformatted = ee.tools.time(response.datecompleted).fromUTC("L LT");//ee.api.formatDate(response.datecompleted, "dd/mm/yy, hh:ii:ss");//ee.tools.time(this.datecompleted).toDate("L");//ee.api.formatDate(response.datecompleted, "dd/mm/yy, hh:ii:ss");
        },
        _formatsurveySummary = function (survey, summary) {
            var j = 1;
            survey.surveysteps.each(function () {
                if (this.questiontype == 0) { return; }
                this.hasresults = true;
                this.questionnumber = j++;
                //Pobieramy wynik dla stepa, jezeli nie ma to idziemy dalej
                if (summary[this.surveystepid]) {
                    var answers = summary[this.surveystepid];
                } else {
                    return;
                }
                if (this.questiontype <= 3) {
                    //Multi select answers
                    this.multianswersmode = true;
                    answers = new Collection.Data(answers);
                    for (var i = 0, n = this.surveystepanswers.length; i < n; i++) {
                        var stepanswer = this.surveystepanswers[i], answer = answers.get('surveystepanswerid', stepanswer.surveystepanswerid);
                        if (answer) {
                            stepanswer.count = answer.count;
                            stepanswer.percent = Math.round((answer.count / survey.resultcount) * 100);
                            if (stepanswer.percent > 100) stepanswer.percent = 100;
                        } else {
                            stepanswer.count = 0;
                            stepanswer.percent = 0;
                        }
                    }
                } else if (this.questiontype === 7) {
                    this.npsmode = true;
                    answers = new Collection.Data(answers);
                    var parsedAnswers = [], npsStats = { promoters: 0, detractors: 0, passives: 0, totalcount: 0 };
                    for (var i = 0; i <= 10; i++) {
                        var answer = answers.get('content', i.toString());
                        if (!answer) { answer = { count: 0 }; }
                        answer.content = i;
                        if (i <= 6) {
                            npsStats.detractors += answer.count;
                        } else if (i <= 8) {
                            npsStats.passives += answer.count;
                        } else if (i <= 10) {
                            npsStats.promoters += answer.count;
                        }
                        npsStats.totalcount += answer.count;
                        parsedAnswers.push(answer);
                    }
                    npsStats.detractorsPercent = Math.round((npsStats.detractors / npsStats.totalcount) * 100);
                    npsStats.passivesPercent = Math.round((npsStats.passives / npsStats.totalcount) * 100);
                    npsStats.promotersPercent = Math.round((npsStats.promoters / npsStats.totalcount) * 100);
                    npsStats.nps = npsStats.promotersPercent - npsStats.detractorsPercent;
                    this.surveystepanswers = parsedAnswers;
                    this.surveystepanswersnps = npsStats;
                } else {
                    this.defaultmode = true;
                    this.surveystepanswers = answers;
                }
            });
        },
        Survey = function (data) {
            if (typeof data === 'string') {
                _.assign(this, surveyPattern);
                this.publicsurveyid = data;
                return this;
            }
            if (!data) { data = surveyPattern };
            _.assign(this, data);
            return this;
        },
        proto = {
            Format: _format,
            Save: function () {
                var self = this;
                self.expirydate = (self.expirydate.toLowerCase() === 'never') ? '' : ee.tools.time(self.expirydate).toDate("L")/*ee.api.formatDate(self.expirydate, "yy-mm-dd", false, true)*/;
                return EE_API.Survey.Add({
                    survey: ee.tools.XSSProtectHtml(JSON.stringify({
                        //Note: this rewrite parts variable we can remove when date will be parsed only in template
                        publicsurveyid: self.publicsurveyid,
                        name: self.name,
                        status: self.status,
                        expirydate: self.expirydate,
                        surveysteps: self.surveysteps
                    }))
                }).then(function (data) {
                    _format.call(data)
                    _.assign(self, data);
                    self.contentloaded = true;
                    self.surveysteps = new Collection.Data(self.surveysteps);
                    var surveysList = dataModel.create("SurveysList");
                    if (surveysList.isLoaded === true) {
                        surveysList.add('unshift', self);
                    }
                });
            },
            Delete: function () {
                return EE_API.Survey.Delete({ publicsurveyid: this.publicsurveyid });
            },
            Update: function () {
                var self = this;
                self.expirydate = (self.expirydate.toLowerCase() === 'never') ? '' : ee.tools.time(self.expirydate).toDate("L");//ee.api.formatDate(self.expirydate, "yy-mm-dd", false, true);
                return EE_API.Survey.Update({
                    survey: ee.tools.XSSProtectHtml(JSON.stringify({
                        //Note: this rewrite parts variable we can remove when date will be parsed only in template
                        publicsurveyid: self.publicsurveyid,
                        name: self.name,
                        status: self.status,
                        expirydate: self.expirydate,
                        surveysteps: self.surveysteps
                    }))
                }).then(function (data) {
                    self.surveysteps = new Collection.Data(self.surveysteps);
                    var surveysList = dataModel.create("SurveysList");

                    if (surveysList.isLoaded === true) {
                        surveysList.replace('publicsurveyid', self.publicsurveyid, self);
                    }
                    return self;
                });

            },
            Load: function () {
                var self = this;
                if (self.contentloaded === true) {
                    return self;
                } else {
                    return EE_API.Survey.Load({ publicsurveyid: self.publicsurveyid }).then(function (data) {
                        _format.call(data)
                        _.assign(self, data);
                        self.surveysteps = new Collection.Data(data.surveysteps);
                        self.contentloaded = true;
                        return self;
                    }).catch(function (e) { console.log(e); });
                }
            },
            LoadResponses: function () {
                var self = this, def = $.Deferred();
                $.when(self.Load()).then(function () {
                    EE_API.Survey.LoadResponseList({ publicsurveyid: self.publicsurveyid }).then(function (data) {
                        self.resultslist = new Collection.Data(data.reverse())
                        self.resultcount = data.length;
                        var n = data.length;
                        self.resultslist.each(function (i) {
                            _formatSurveyResponse(this);
                            this.no = n--;
                            this.index = i;
                        });
                        def.resolve(self);
                    });
                })
                .catch(function (e) { 
                    def.reject(e); 
                });
                return def;
            },
            LoadSummary: function () {
                var self = this, def = $.Deferred();
                $.when(self.Load()).then(function () {
                    EE_API.Survey.LoadResults({ publicsurveyid: self.publicsurveyid }).then(function (data) {
                        self.resultcount = parseInt(data.count, 10);
                        self.summaryloaded = true;
                        _formatsurveySummary(self, data.summary);
                        def.resolve(self);
                    });
                }).catch(function (e) { def.reject(e); });
                return def;
            },
            RemoveResponses: function (resultsIdToDelete) {
                var self = this;
                return EE_API.Survey.RemoveResponses({ publicsurveyid: self.publicsurveyid, resultsids: resultsIdToDelete }).then(function () {
                    for (var i = 0, n = resultsIdToDelete.length; i < n; i++) {
                        self.resultslist.remove("surveyresultid", resultsIdToDelete[i]);
                    }
                    n = self.resultslist.data.length, i = 0;
                    self.resultslist.each(function () {
                        this.no = n--;
                        this.index = i++;
                    });
                    self.resultcount = n;
                });
            },
            GetShareLink: function (domain) {
                if (!domain) {
                    domain = (ee.data.account.issub && ee.data.account.privatebrandingurl) ? 'http://' + ee.data.account.privatebrandingurl : ee.api.path;
                } else {
                    domain = "http://tracking." + domain;
                }
                return domain + "/public/survey?sid=" + this.surveylink;
            },
        }

    dataModel.inherit(Survey, proto).register("Survey", Survey);

}(window.dataModel));
