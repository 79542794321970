/**
 * Controller from the login screen when user is not auth
 * @namespace
 * @memberof ee.views
 */
ee.views.register = (function ViewRegister() {
    let that
    let $frame
    let $screen
    let $logo
    const _validMarks = {success: 'has-success', error: 'has-error'}
    const _errorHandle = function(obj) {
        const data = {content: ee.t.unknowndesc, type: 'alert-danger'}
        if (obj.error) {
            data.content = obj.error
        } else if (obj.message) {
            data.content = ee.t[obj.message]
        }
        const $alert = html.get('AlertBody', data)
        $screen.$alert ? $screen.$alert.remove() : undefined
        $screen.find('.authframe-form:last').prepend($alert)
        $screen.$alert = $alert
        grecaptcha.reset()
        ee.indiOff()
    }
    const _createAccount = function(token) {
        ee.indiOn()
        const query = $screen.elasticCollectForm()
        const sourceid = html.widget.Marketing.getSourceId()
        query.response = token
        query.marketingconsent = $screen.find('#emarketingconsent').is(':checked')
        //clientsource
        query.clientsource = html.widget.Marketing.getClientSource()
        //The plan
        try {
            if (window.sessionStorage.plan) {
                if ($.isNumeric(window.sessionStorage.plan) && window.sessionStorage.plan > 0) {
                    query.plan = parseInt(window.sessionStorage.plan, 10)
                    if (query.plan === 7) {
                        query.maxcontacts = parseInt(window.sessionStorage.planvalue, 10) || 2500
                    } else if (query.plan === 8) {
                        query.maxcontacts = parseInt(window.sessionStorage.planvaluepro, 10) || 2500
                    }
                } else {
                    delete window.sessionStorage.plan
                }
            }
        } catch (err) {
            console.log(err)
            console.log('You need to enable session storage')
        }
        if (sourceid) {
            query.sourceid = sourceid
        }
        query.dashboardurl = ee.baseurl

        delete query['g-recaptcha-response']

        EE_API.Account.Create(query)
            .then(function(data) {
                if (ee.base) html.widget.Marketing.doConversions()

                const apiKey = typeof data === 'string' ? data : data.apikey

                ee.session.setAccount(apiKey, null, '#/dashboard', query.username)

                _.debounce(function() {
                    ee.logEvent('Account created.')
                }, 100)()

                try {
                    if (window.sessionStorage.plan) delete window.sessionStorage.plan
                } catch (err) {
                    console.log(err)
                    console.log('You need to enable session storage')
                }
                //We need this?
                if (typeof _gaq !== 'undefined') _gaq.push(['_trackPageview', '#/account-created'])
            })
            .catch(_errorHandle)
    }
    const _initRecaptcha = function() {
        grecaptcha.ready(function() {
            grecaptcha.render('eregisterend', {
                sitekey: '6Lf9BCEUAAAAAHyXRzgZZHsB74VeM2sCsZZKK9HB',
                callback: _createAccount,
                size: 'invisible',
                badge: 'inline',
            })
        })
    }
    const _googleOauthCallback = function(resp, $screen) {
        const params = ee.session.getOauthParams(resp)

        return ee.session.login(params, $screen)
    }
    const _setReferal = function() {
        switch (ee.Router.action()) {
            case 'joomla-acymailing':
                return {referralid: '2f0447bb-173a-459d-ab1a-ab8cbebb9aab', promocode: 'REF_PLUS'}
            case 'wordpress-mailpoet':
                return {referralid: 'cfb09bc8-558d-496b-83e6-b05e901a945c', promocode: 'REF_PLUS'}
            case 'xtreeme':
            case 'followupstarter':
                return {referralid: '3ae8ed6c-a1b3-11e0-a69b-12313b0611f6', promocode: 'REF_PLUS'}
            case 'open-classifieds':
                return {referralid: '3af0458a-a1b3-11e0-a69b-12313b0611f6', promocode: 'REF_PLUS'}
        }
        let referralid = ee.Router.parameter('r')
        if (referralid) {
            return {referralid, promocode: 'REF_PLUS'}
        }

        if (Cookies.get('referral')) {
            referralid = Cookies.get('referral')
            Cookies.remove('referral')

            return {referralid, promocode: 'REF_PLUS'}
        }

        return {}
    }
    /*-- PUBLIC --*/
    that = {
        show() {
            //Change this to promise
            if (ee.languages.isListedLanguages() === false) {
                ee.languages.list(that.show)
                return
            }
            html.setViewport('mobile')
            const languages = _.filter(ee.languages.data, function(o) {
                return o.locale !== 'cr' && o.locale !== 'vi'
            })
            ///
            if (!$logo) {
                $logo = html.getraw('authFrameLogo', {
                    logourl: ee.parameters.logourl,
                    base: !ee.isReseller(),
                })
            }

            if (!$frame) {
                $frame = html.get('authFrame', {
                    languages,
                    base: !ee.isReseller(),
                    year: new Date().getFullYear(),
                    isRegister: true,
                    logo: $logo,
                })
            }

            ee.container.html($frame)
            $.when(html.loadLibrary('https://www.google.com/recaptcha/api.js')).then(function() {
                switch (ee.Router.action()) {
                    case 'user':
                        that.showRegisterUser(ee.Router.parameter('verification'), ee.Router.parameter('userName'))
                        break
                    default:
                        that.showRegisterStart(_setReferal(ee.Router.action()))
                        break
                }
            })

            let language
            $frame.language.find('input').on('change', function() {
                language = $(this)
                ee.languages.load(language.data('locale'), function() {
                    ee.view.show()
                })
                $frame.languagename.text(language.data('langname'))
            })
            $frame.languagename.text(ee.tools.getLanguageName(ee.session.get('locale')))
            html.showPasswordTip($frame)
        },
        getReferal() {
            return _setReferal()
        },
        showRegisterStart(query) {
            $screen = html.get('RegisterStart', {
                base: !ee.isReseller(),
                logo: $logo,
                data: query,
                countries: ee.tools.CountryList(),
                gauth: ee.isOAuthEnabled(),
            })
            $screen.appendTo($frame.authframecontent.empty())

            if (navigator.cookieEnabled) {
                ee.attachGoogleSignin('gSignInWrapper', $screen, _googleOauthCallback)
            } else {
                console.warn("Cookies disabled.")
            }

            $screen.firstname.focus()
            $screen.find('[data-toggle="tooltip"]')?.tooltip()
            $screen.on('click', '.action-registerend', function(e) {
                e.preventDefault()
                e.stopPropagation()
                if ($screen.$alert) $screen.$alert.remove()
            })

            new html.Validator($screen.register_step1, {
                submit: '.action-registerend',
                inputs: [
                    {
                        name: 'username',
                        css: _validMarks,
                        msg: ee.t.emailinvalid,
                        messageSelector: '.help-block',
                        validFunc() {
                            return ee.tools.isValidEmail(this.val().trim())
                        },
                    },
                    {
                        name: 'password',
                        css: _validMarks,
                        msg: ee.t.errorpassword,
                        messageSelector: '.help-block',
                        validFunc() {
                            return this.val().length >= 6
                        },
                    },
                    {
                        name: 'terms',
                        css: _validMarks,
                        eventType: 'change',
                        validFunc(inputs) {
                            return this.prop('checked')
                        },
                    },
                ],
            })
            _initRecaptcha()
            $screen.on('click', '.action-back', function(e) {
                e.preventDefault()
            })
            //Google Conversion only for base interface
            //??
            if (ee.base) {
                const google_conversion_id = 878227499
                const google_conversion_language = 'en'
                const google_conversion_format = '3'
                const google_conversion_color = 'ffffff'
                const google_conversion_label = 'myyWCJemo2oQq-DiogM'
                const google_remarketing_only = false
                html.loadLibrary('https://www.googleadservices.com/pagead/conversion.js')
            }

        },
        showRegisterConfirm(username) {
            ee.indiOff()
            $screen = html.get('RegisterSuccess', {base: ee.isLive()})
            $screen.appendTo($frame.authframecontent.empty())
            $screen.on('click', '.action-resend', function() {
                ee.indiOn()
                const $btn = $(this)
                $btn.attr('disabled', true)
                EE_API.Account.ResendActivation({username})
                    .then(function() {
                        $btn.text('Activation email was resent')
                        setTimeout(function() {
                            $btn.attr('disabled', false).text(ee.t.resend)
                        }, 30000)
                    })
                    .catch(_errorHandle)
                    .catch(function() {
                        $btn.attr('disabled', false)
                    })
                    .always(function() {
                        $btn.blur()
                        ee.indiOff()
                    })
            })
        },
        showRegisterUser(verifyKey, userName) {
            $screen = html.get('NewRegisterUser', {base: ee.isLive(), logo: $logo, username: userName})
            $screen.appendTo($frame.authframecontent.empty())

            $screen.on('click', '.action-registeruser', function() {
                ee.indiOn()
                const query = $screen.elasticCollectForm()
                query.returnUrl = ee.baseurl
                query.verificationcode = verifyKey
                EE_API.User.Activate(query)
                    .then(function() {
                        $screen.userformregister.remove()
                        $screen.userformsuccess.removeClass('hidden')
                        ee.goTo('#/')
                    })
                    .catch(_errorHandle)
                    .always(ee.indiOff)
            })
            $screen.on('click', '.action-gotosigin', function() {
                ee.goTo('#/')
            })
            $screen.password.focus()
            new html.Validator($screen, {
                submit: '.action-registeruser',
                inputs: [
                    {
                        name: 'username',
                        css: _validMarks,
                        msg: ee.t.emailinvalid,
                        messageSelector: '.help-block',
                        validFunc() {
                            return ee.tools.isValidEmail(this.val().trim())
                        },
                    },
                    {
                        name: 'password',
                        css: _validMarks,
                        msg: ee.t.errorpassword,
                        messageSelector: '.help-block',
                        validFunc() {
                            return this.val().length >= 6
                        },
                    },
                ],
            })
        },
    }
    return that
})()
