html.addTemplate("AccountSecurity","<div id=\"eaccsecurity\" class=\"eaccsecurity\"><div class=\"row\"><div class=\"col-xs-12\"><a class=\"pull-left breadcrumb\" href=\"#/account/\">{{t.myaccount}}</a></div><div class=\"col-md-6\"><h2>{{t.security}}</h2></div><div class=\"col-md-6 text-right\"><button type=\"button\" id=\"addusersbutton\" class=\"btn btn_md btn_primary\" >{{t.users}}</button></div></div><div id=\"eemail_section\" class=\"board\"></div><div id=\"epassword_section\" class=\"board\"></div><div id=\"epublicaccountid_section\" class=\"board\"><div class=\"eeform eeform_panel eeform_panel__marge-top\"><hr /><h3>{{t.publicaccountid}}</h3><p class=\"eeform_panel_desc\" {{^readonly}} style=\"margin-bottom:2.4rem;\" {{/readonly}}>{{t.publicaccountid_desc}}</p><div class=\"row\"><div class=\"col-xs-12 col-sm-6 col-md-4\"><input type=\"text\"  readonly value=\"{{publicaccountid}}\"/></div></div></div></div>{{#modify2fa}}<div class=\"board\"><div class=\"board-body\"><h3 id=\"emaintitle\">{{t.twofactorauthentication}}</h3><div style=\"position: relative\" id=\"esectioncontainer\"></div></div></div>{{/modify2fa}}{{#viewSettings}}<div class=\"board\"><div class=\"board-body\"><div style=\"position: relative\" id=\"etrustedbrowser\"><h3>{{t.recentlyuseddevices}}</h3></div></div></div>{{/viewSettings}}</div>");
html.addTemplate("AccountSecurityEnable","<section style=\"display: none\" class=\"step\"><div class=\"row\"><div class=\"col-xs-7\"><p class=\"enomargin\">{{t.twofactorauthentication_desc}}</p><div class=\"btn-group\"><button class=\"btn btn_md btn_primary action-enable\">{{t.enableauthentication}}</button></div></div></div></section>");
html.addTemplate("AccountSecurityStatus","<section style=\"display: none\" class=\"step\"><div class=\"row\"><div class=\"col-xs-7\"><p><b>{{title}}<label class=\"label label-info on-right\">{{t.active}}</label></b>{{desc}}</p><div class=\"btn-group\"><button class=\"btn btn_md_basic btn_basic action-disabled\">{{t.disableauthentication}}</button></div></div></div></section>");
html.addTemplate("AccountSecuritySelect","<section style=\"display: none\" class=\"step row\"><div class=\"col-xs-7\"><ul class=\"list-unstyled\" id=\"eformselector\"><li class='radio radio-svg'><input type=\"radio\" id=\"esms\" name=\"twofamethod\" value=\"sms\" /><label for=\"esms\">\<b class=\"text-uppercase\">{{t.twofamethodsms}}</b><span>{{t.twofactorauthentication_sms_desc}}</span></label></li><li class='radio radio-svg'><input type=\"radio\" id=\"eemail\" name=\"twofamethod\" value=\"email\" /><label for=\"eemail\"><b class=\"text-uppercase\">{{t.twofamethodemails}}</b><span>{{t.twofactorauthentication_email_desc}}</span></label></li><li class='radio radio-svg'><input type=\"radio\" id=\"eboth\" name=\"twofamethod\" value=\"both\" /><label for=\"eboth\"><b class=\"text-uppercase\">{{t.twofamethodboth}}</b><span>{{t.twofactorauthentication_both_desc}}</span></label></li></ul><div style=\"margin-top: 1rem\"><button type=\"button\" class=\"btn btn_md_basic btn_basic action-cancel\" style=\"margin-right:8px;\">{{t.cancel}}</button><button type=\"button\" class=\"btn btn_md btn_primary action-next\" disabled data-callbak=\"matchMethod\">{{t.next}}</button></div></div></section>");
html.addTemplate("2faDisableWithPass","<p>{{t.twofadisabled_desc}}</p>");
html.addTemplate("AccountSecuritySetup","<section style=\"display: none\" class=\"step\"><div class=\"row\"><div class=\"col-xs-6\" id=\"emethod\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn_md_basic btn_basic action-cancel{{^isSecondStep}} hide{{/isSecondStep}}\">{{t.cancel}}</button><button type=\"button\" class=\"btn btn_md_basic btn_basic action-prev{{#isSecondStep}} hide{{/isSecondStep}}\">{{t.prev}}</button><button type=\"button\" class=\"btn btn_md_basic btn_basic action-next\" disabled>{{t.next}}</button><button type=\"button\" class=\"btn btn_md_basic btn_basic action-verify btn_primary\" style=\"display: none;\" id=\"everify\" disabled>{{t.verify}}</button></div></div><div class=\"col-xs-6\"><div id=\"epasscodeform\" style=\"display:none;\"><b>{{t.twofasecuritycode}}</b><p>{{t.twofasecuritycode_desc}}</p><div class=\"form-group has-feedback enomargin\"><label class=\"control-label\" for=\"eemailaddress\">{{t.twofaauthenticationpasscode}}</label><div class=\"control-feedback\"><input type=\"email\" class=\"form-control\" id=\"epasscodeinput\"><div class=\"error hide pull-left\" id=\"epasscodeinputerror\"></div><a class=\"help-block resendpasscode pull-right\">{{t.twofaresendpasscode}}</a><span class=\"fa fa-check form-control-feedback hide\" aria-hidden=\"true\"></span></div></div></div></div></div></section>");
html.addTemplate("AccountSecurityBackupCode","<section style=\"display: none\" class=\"step\"><div class=\"row\"><div class=\"col-xs-7\"><div id=\"e2faphone\"><b>{{t.twofabackupcode}}</b><p>{{t.twofabackupcode_desc}}</p><div class=\"form-group\"><input type=\"text\" class=\"form-control text-center\" id=\"ebackupcode\" value=\"{{backupCode}}\" readonly style=\"max-width:320px\"></div></div></div></div><div class=\"btn-group\"><button type=\"button\" class=\"btn btn_md_basic btn_basic action-exit\">{{t.close}}</button></div></section>");
html.addTemplate("AccountSecurityEmailVerify","<div id=\"e2faemail\"><b>{{t.twofayouremailaddress}}</b><p style=\"height:7.2rem\">{{t.twofayouremailaddress_des}}</p><div class=\"form-group has-feedback enomargin\"><label class=\"control-label\" for=\"eemailaddress\">{{t.emailaddress}}</label><div class=\"control-feedback\"><input type=\"email\" class=\"form-control\" id=\"eemailaddress\"><span class=\"fa fa-check form-control-feedback hide\" aria-hidden=\"true\"></span></div></div></div>");
html.addTemplate("AccountSecuritySMSVerify","<div id=\"e2faphone\"><b>{{t.twofayourphonenumber}}</b><p style=\"height:7.2rem\">{{t.twofayourphonenumber_desc}}</p><div class=\"form-group\"><label for=\"countrycode\">{{t.countrycode}}</label><select class=\"form-control\" id=\"ecountrycode\" required><option value=\"\" selected disabled>{{t.selectcountry}}</option><option value=\"1\" data-id=\"230\" class=\"countrycode-marked\">United States (+1)</option><option value=\"44\" data-id=\"229\" class=\"countrycode-marked\">United Kingdom (+44)</option><option value=\"33\" data-id=\"73\" class=\"countrycode-marked\">France (+33)</option><option value=\"91\" data-id=\"100\" class=\"countrycode-marked\">India (+91)</option><option value=\"55\" data-id=\"30\" class=\"countrycode-marked\">Brazil (+55)</option><option value=\"1\" data-id=\"38\" class=\"countrycode-marked\">Canada (+1)</option>{{#country}} {{#ID}}<option value=\"{{callingcode}}\" data-id=\"{{ID}}\">{{name}} (+{{callingcode}})</option>{{/ID}}{{/country}}</select></div><div class=\"form-group enomargin\"><label for=\"ephonenumber\">{{t.mobilephonenumber}}</label><div class=\"control-feedback \"><input type=\"text\" class=\"form-control\" id=\"ephonenumber\" placeholder=\"\"><span class=\"fa fa-check form-control-feedback hide\" aria-hidden=\"true\"></span></div><a class=\"help-block\" id=\"edontmobilephone\">{{t.donthavemobilephonenumber}}</a></div></div>");
html.addTemplate("AccountSecuritySteps","<div class=\"twofasteps\">{{#stepsCount}}<span class=\"twofasteps-item\"></span>{{/stepsCount}}</div>");
html.addTemplate("RecentlyUsedDevices","<section>   <div class=\"row\"><div class=\"col-xs-12\"><p class=\"enomargin\">{{t.trustedbrowser_desc}}</p><div id=\"trustedlistcontainer\" class=\"trustedlistcontainer\">{{#list}}<div class=\"singledevice\"><div class=\"mainInfo\"><div class=\"{{^trusted}}newDeviceIcon{{/trusted}} {{#trusted}}oldDeviceIcon{{/trusted}}\"></div><div class=\"textContener\"><div class=\"deviceinfo\">{{t.log_in}} {{t.with}} {{browser}} {{t.on}} {{os}}</div><div class=\"datedeviceuse\">{{lastloginShort}}</div></div></div><div class=\"advancedInfo\" style=\"display:none;\"><ul><li><span>{{t.browser}}:</span> {{browser}}</li><li><span>OS:</span> {{os}}</li><li><span>{{t.device}}:</span> {{device}}</li><li><span>{{t.time}}:</span> {{lastlogin}}</li><li><span>{{t.location}}:</span> {{country}}</li><li><span>{{t.ip}}:</span> {{ip}}</li><li><span>{{t.isp}}:</span> {{isp}}</li></ul>{{#modifySettings}}<button class=\"settrustedstate {{#trusted}}endSession{{/trusted}} {{^trusted}}startSession{{/trusted}} btn_sm btn_primary\" data-browserid=\"{{browsertokenid}}\">{{#trusted}}{{t.endsession}}{{/trusted}}{{^trusted}}{{t.startsessions}}{{/trusted}}</button>{{/modifySettings}}</div></div>{{/list}}</div><div class=\"btn-group\" id=\"reviewallsessionsContainer\" {{^showReviewAllBtn}} style=\"display:none;\" {{/showReviewAllBtn}}><button class=\"btn btn_md btn_primary text-uppercase action-enable\" id=\"reviewallsessions\">{{t.reviewallsessions}}</button></div><div class=\"toggleSection\"><span id=\"sendNotificationAlert\" class=\"slider {{^deviceAlertState}}sliderOn{{/deviceAlertState}} {{#deviceAlertState}}sliderOff{{/deviceAlertState}}\"></span><p>{{t.senddevicesnotificationalert}}</p></div></div></div></section> ");
html.addTemplate("AllRecentlyUsedDevicesContainer","<div class=\"eaccsecurity\"><h2>{{t.recentlyuseddevices}}</h2><div class=\"board\"><div class=\"board-body\"><div style=\"position: relative\" id=\"etrustedbrowser\"><div class=\"form-group select-wrapper\"><label for=\"filterByTrusted\">{{t.sortby}} </label><select class=\"select_caret\" id=\"filterByTrusted\"><option data-order=\"date\">{{t.logindate}}</option><option data-order=\"desc\">{{t.trusted}}</option><option data-order=\"asc\">{{t.untrusted}}</option></select></div></div></div></div></div>");
html.addTemplate("AllRecentlyUsedDevices","<section><div class=\"row\"><div class=\"col-xs-12\"><div id=\"trustedlistcontainer\" class=\"trustedlistcontainer\">{{#list}}<div class=\"singledevice\"><div class=\"mainInfo\"><div class=\"{{^trusted}}newDeviceIcon{{/trusted}} {{#trusted}}oldDeviceIcon{{/trusted}}\"></div><div class=\"textContener\"><div class=\"deviceinfo\">{{t.log_in}} {{t.with}} {{browser}} {{t.on}} {{os}}</div><div class=\"datedeviceuse\">{{lastloginShort}}</div></div></div><div class=\"advancedInfo\" style=\"display:none;\"><ul><li><span>{{t.browser}}:</span> {{browser}}</li><li><span>OS:</span> {{os}}</li><li><span>{{t.device}}:</span> {{device}}</li><li><span>{{t.time}}:</span> {{lastlogin}}</li><li><span>{{t.location}}:</span> {{country}}</li><li><span>{{t.ip}}:</span> {{ip}}</li><li><span>{{t.isp}}:</span> {{isp}}</li></ul>{{#modifySettings}}<button class=\"settrustedstate {{#trusted}}endSession{{/trusted}} {{^trusted}}startSession{{/trusted}}\" data-browserid=\"{{browsertokenid}}\">{{#trusted}}{{t.endsession}}{{/trusted}}{{^trusted}}{{t.startsessions}}{{/trusted}}</button>{{/modifySettings}}</div></div>{{/list}}</div></div></div></section>");
html.addTemplate("AccountSecurityProfile","<div id=\"eaccsecurityprofile\" class=\"eaccsecurity\"><div id=\"eemail_section\" class=\"board\"></div><div id=\"epassword_section\" class=\"board\"></div></div>");
