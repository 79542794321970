(function (root) {

    root.User = {
        Activate: function (query) { return root.request('/user/activate', query, { manualerrorhandle: true }) },
        List: function (query) { return root.request('/user/list', query) },
        Invite: function (query) { return root.request('/user/invite', query) },
        Edit: function (query) { return root.request('/user/edit', query) },
        Delete: function (query) { return root.request('/user/delete', query) },
        ResetEmail: function (query) { return root.request('/user/resetemail', query) },
        ResetPassword: function (query) { return root.request('/user/ResetPassword', query) },
        ChangePassword: function (query) { return root.request('/user/changepassword', query, { manualerrorhandle: true }) },
        ChangeEmail: function (query) { return root.request('/user/changeemail', query) },
        setTwoFactorAuthEmail: function (query) { return root.request('/user/setTwoFactorAuthEmail', query); },
        setTwoFactorAuthSms: function (query) { return root.request('/user/setTwoFactorAuthSms', query); },
        VerifyTwoFactorAuthEmail: function (query, options) { return root.request('/user/VerifyTwoFactorAuthEmail', query, options); },
        VerifyTwoFactorAuthSms: function (query, options) { return root.request('/user/VerifyTwoFactorAuthSms', query, options); },
        ResendTwoFactorAuthCode: function (query) { return root.request('/user/ResendTwoFactorAuthCode', query) },
        ResendTwoFactorAuthVerificationEmail: function () { return root.request('/user/ResendTwoFactorAuthVerificationEmail', {}); },
        ResendTwoFactorAuthVerificationSms: function () { return root.request('/user/ResendTwoFactorAuthVerificationSms', {}); },
        GenerateTwoFactorAuthDisableCode: function () { return root.request('/user/GenerateTwoFactorAuthDisableCode', {}); },
        DisableTwoFactorAuth: (query) => { return root.request('/user/disabletwofactorauth', query); },
    };

}(EE_API));
