(function (namespace) {

    var Referrals = function () {
        var self = this;

        self.details = undefined;
        self.payouthistory = undefined;
        return self;
    };
    var proto = {
        getDetailsProp: function (key) {
            return this.details[key];
        },
        setDetailsProp: function (key, value) {
            this.details[key] = value;
            return this;
        },
        Load: function (callback) {
            var self = this;
            ee.api.request('/account/loadreferraldetails', {}, function (data) {
                if (!data.success) return html.modal.error(data);
                self.details = data.data;
                self.setDetailsProp('credits', self.getDetailsProp('currentreferralcredit').toFixed(2))
                if (callback) callback(self, data);
            }, undefined, undefined, undefined, undefined, true)
        },
        LoadHistory: function (callback) {
            var self = this;
            ee.api.request('/account/loadpayouthistory', {}, function (data) {
                if (!data.success) return html.modal.error(data);
                self.payouthistory = new Collection.Data(data.data);
                self.payouthistory.each(function () {
                    this.dateFRM = ee.api.formatDate(this.date, 'dd/mm/yy', false, true);
                    this.amountFRM = (this.amount * -1).numberFormat();
                });
                if (callback) callback(self, data);
            }, undefined, undefined, undefined, undefined, true)
        }
    };

    $.extend(Referrals.prototype, proto);
    dataModel.register("Referrals", Referrals);
}(window.dataModel));
