(function (global) {
    var _hasInterface = function (name, model) {
        if (typeof model !== "function") {
            throw new Error('Model REGISTER failed! Model ' + name + ' should have type of "function"');
        }
        if (model.length > 1) {
            throw new Error("Model REGISTER failed! Model " + name + " should have max 1 argument in declaration");
        }
        if (model.prototype.constructor !== model) {
            throw new Error("Model REGISTER failed! Model " + name + " constructor should be " + name + " function!");
        }
        //if (typeof model.prototype.options === "function" && typeof model.prototype.api_load !== "function" && typeof model.prototype.storage !== "string") {
        //    throw new Error("Model REGISTER failed! Model " + name + " constructor has require methods but it hasn't corect storage or/and api_load properties in prototype.");
        //}

        return true;
    },
        _models = {},
        _modelExtend = {
            require: function (query) {
                var that = this;
                var storageName = that.options.storage.name;
                var loadMethod = that.options.storage.load;
                var storage = that.isLoaded ? ee.data[storageName] : loadMethod(query);

                return $.when(storage).then(function (data) {
                    if (!that.isLoaded) {
                        that.update(data);
                        ee.data[storageName] = that;
                        that.isLoaded = true;
                    }
                    return that;
                });
            }
        },
        dataModel = {
            create: function (name, opt) {

                if (!_models[name]) {
                    throw new Error("Model CREATE failed! Model " + name + " dosen't exist!");
                }
                return new _models[name](opt);
            },
            register: function (name, model) {

                if (_models[name]) {
                    throw new Error("Model REGISTER failed! Model " + name + " alredy exist!");
                }
                if (_hasInterface(name, model)) {
                    _models[name] = model;
                }
            },
            extend: function (name, obj) {
                if (!_models[name]) {
                    throw new Error("Model EXTEND failed! Model " + name + " dosen't exist!");
                }

                $.extend(_models[name].prototype, obj);

            },
            inherit: function (constructor, proto, options) {

                options = options || {};

                if (options.collection) {
                    constructor.prototype = Object.create(Collection.Data.prototype);
                }
                if (options.storage && typeof options.storage === "object") {

                    constructor.prototype.require = _modelExtend.require;
                    constructor.prototype = Object.create(constructor.prototype);
                    Object.defineProperty(constructor.prototype, "_sup", {
                        value: Object.getPrototypeOf(constructor.prototype)
                    });

                }

                Object.defineProperty(constructor.prototype, "constructor", {
                    value: constructor,
                    writable: true,
                    configurable: true
                });

                Object.defineProperty(constructor.prototype, "options", {
                    value: options
                });

                $.extend(constructor.prototype, proto);

                return this;
            },
            isModel: function (name, obj) {

                var model = _models[name];

                if (!model) {
                    console.warn("isModel CHECK failed! Model " + name + " dosen't exist!");
                    return false;
                }
                if (obj && obj instanceof model && obj.constructor === model) return true;
                return false;
            },
            dataStorage: function (model) {
                var storage = model.isLoaded ? ee.data[model.storage] : model.api_load();

                return $.when(storage).then(function (data) {
                    if (!model.isLoaded) {
                        model.update(data);
                        ee.data[model.storage] = model;
                        model.isLoaded = true;
                    }
                    return model;
                });
            }
        };

    window.dataModel = dataModel;
}(this));
