(function (root) {
    root.Survey = {
        Add: function (query) { return root.request('/survey/add', query) },
        Update: function (query) { return root.request('/survey/update', query) },
        Delete: function (query) { return root.request('/survey/delete', query) },
        List: function (query) { return root.request('/survey/list', query) },
        Load: function (query) { return root.request('/survey/loadcontent', query) },
        LoadResponseList: function (query) { return root.request('/survey/loadresponselist', query) },
        LoadResults: function (query) { return root.request('/survey/loadresults', query) },
        RemoveResponses: function (query) { return root.request('/survey/removeresults', query) },
        Export: function (query) { return root.request('/survey/export', query) }
    };
}(EE_API));