(function LanguagesTextData(namespace) {
    //Private constructors
    var LanguagesTextData = function (textType) {
        var self = this;
        if (!(self instanceof LanguagesTextData)) {
            throw new Error('LanguagesTextData is a constructor and it`s need "new" operator');
            return;
        };
        Collection.Data.call(this, []);
        _textTypeSelected_ = textType;
    },
    _typeName_ = ['frontend', 'backend'], _languageBackendDefault_,
    _textTypeSelected_ = false,
    _loadtextDefaultBackend = function (callback) {
        ee.api.request("/language/defaultbackendtext", { inheritedAdmin: true }, function (response) {
            ee.indiOff();
            if (!response.success) return html.modal.error(response);
            _languageBackendDefault_ = response.data;
            if (callback) callback();
        });
    },
    _loadTexts = function (self, locale, callback) {
        ee.api.request("/language/loadtexts", { locale: locale, textType: _textTypeSelected_, inheritedAdmin: true }, function (response) {
            ee.indiOff();
            if (!response.success) return html.modal.error(response);
            var translations = response.data;
            var textPattern = (_textTypeSelected_ === 'backend') ? _languageBackendDefault_ : ee.languages.get("locale", "en").data;
            self.statistics = { totalcount: 0, counttranslated: 0, countnotparent: 0, percentagetranslation: 0 };
            $.each(textPattern, function (keyname, value) {
                var text = translations[keyname];
                if (typeof text === "undefined") {
                    translations[keyname] = {
                        data: value,
                        keyname: keyname,
                        languageid: 0,
                        languagetextid: 0,
                        version: 0
                    };
                } else {
                    self.statistics.counttranslated++;
                    text.original = value;
                    text.hasParent = true;
                }

            });
            self.update($.map(translations, function (val, i) { return val; }));
            self.statistics.totalcount = self.getLength();
            self.each(function () {
                if (this.languagetextid > 0 && !this.hasParent) { self.statistics.countnotparent++ }
            })
            self.statistics.percentagetranslation = ((self.statistics.counttranslated / self.statistics.totalcount) * 100).toFixed(2);
            if (callback) callback();
        });
    },
    proto = {
        statistics: { total: 0, counttranslated: 0, countnotparent: 0, percentagetranslation: 0 },
        //Public method for trusted users
        saveText: function (locale, textType, texts, callback) {
            ee.api.request("/language/savebulktexts", { locale: locale, textType: textType, texts: JSON.stringify(texts), inheritedAdmin: true }, function (response) {
                ee.indiOff();
                if (!response.success) return html.modal.error(response);
                if (callback) callback();
            }, false, true);
        },
        loadtextsfrontend: function (locale, callback) {
            if (_textTypeSelected_ !== 'frontend') console.log('You can\'t load texts, wrong textTypeSelected in object.');
            _loadTexts(this, locale, callback);
        },
        loadtextsbackend: function (locale, callback) {
            if (_textTypeSelected_ !== 'backend') console.log('You can\'t load texts, wrong textTypeSelected in object.');
            var self = this;
            if (!_languageBackendDefault_) {
                _loadtextDefaultBackend(function () { self.loadtextsbackend(locale, callback) });
                return;
            }
            _loadTexts(this, locale, callback);
        },
        getRevisions: function (languageid, keyname, callback) {
            ee.api.request("/language/gettextrevision", { languageid: languageid, textType: _textTypeSelected_, keyname: keyname, inheritedAdmin: true }, function (response) {
                ee.indiOff();
                if (!response.success) return html.modal.error(response);
                if (callback) callback(response.data);
            });
        },
        getType: function () {
            return _textTypeSelected_;
        }
    };

    LanguagesTextData.prototype = Object.create(Collection.Data.prototype);
    $.extend(LanguagesTextData.prototype, proto);
    namespace["LanguagesTextData"] = LanguagesTextData;
}(window.dataModel));
