(function (namespace) {
    "use strict";
    var _regexpDefaultSender = /[\(\)]/g,

        /* AccessLevel constructor */
        /**
         * Create AccessLevel collection model
         * @class
         * @extends Collection.Data
         * @memberof dataModel
         */
        AccessLevel = function AccessLevel(data) {
            if (!(this instanceof AccessLevel)) {
                throw new Error('AccessLevel is a constructor and it needs \'new\' operator');
            }
            if (!data) data = ee.data.accesslevel;
            if (dataModel.isModel('AccessLevel', data)) return ee.data.accesslevel;

            Collection.Data.call(this, null);
            this.isLoaded = false;
        },

        options = {
            collection: true,
            storage: {
                name: "accesslevel",
                load: EE_API.Access.ListApikeys
            }
        },


        proto = {
            update: function (data) {
                Collection.Data.prototype.update.call(this, data).each(_format);
                return this;
            },
            add: function (domain, trackingtype) {
                //TODO: Add should always return added object
                return EE_API.Domain.Add({ domain: domain, trackingtype: trackingtype });
            },

            reload: function () {
                this.data = null;
                this.isLoaded = false;
                return this.require();
            },
            View: function (element) {
                return this.data.indexOf("View" + element) !== "undefined";
            },
            Modify: function (element) {
                return this.data.indexOf("Modify" + element) !== "undefined";
            },
            Send: function (element) {
                return this.data.indexOf("Send" + element) !== "undefined";
            },
            HasAccess: function (element) {
                return this.View(element) || this.Modify(element) || this.Send(element);
            },
            Login: function () {
                return this.data.indexOf("Login") !== "undefined";
            },
            /*
            * Preset for generating views. Returns an object that can be used everywhere in template.
            *
            */
            Access: function (element) {
                var access = {};
                access.View = this.View(element);
                access.Modify = this.Modify(element);
                access.Send = this.Send(element);
                access.HasAccess = this.HasAccess(element);
                return access;
            },
            hasPreset: function () {
                var presets = {};
            }
        };

    dataModel.inherit(AccessLevel, proto, options).register("AccessLevel", AccessLevel);

}(window.dataModel));
