(function (namespace) {
    "use strict";

    var SubaccountsList = function SubAccounts(dataArr) {
        if (dataModel.isModel("SubaccountsList", ee.data.subaccounts)) return ee.data.subaccounts;
        Collection.Data.call(this, dataArr);
        this.isLoaded = false;
    };

    var options = {
        collection: true,
        storage: {
            name: "subaccounts",
            load: EE_API.Account.GetSubAccountList
        }
    };

    var proto = {
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(dataModel.create('Subaccount').Format);
            return this;
        },
        get: function (neddle, key) {
            if (!key) key = 'email';
            var index = this.getIndex(key, neddle), item;
            if (index === -1) return false;
            item = this.data[index];
            if (dataModel.isModel("Subaccount", item) === false) {
                item = this.data[index] = dataModel.create('Subaccount', item);
            }
            return item;
        }
    };

    dataModel.inherit(SubaccountsList, proto, options).register("SubaccountsList", SubaccountsList);

}(window.dataModel));
