(function (root) {

    root.UserFiles = {
        DeleteFile: function (query) { return root.request('/file/delete', query) },
        SaveScreenshot: function (query) { return root.request('/file/savescreenshot', query, {timeout: 120000}) },
        UploadList: function (query) { return root.request('/file/listall', query) },
        UploadFiles: function (query) { return root.request('/file/upload', query) }
    };

}(EE_API));
