(function () {
    'use strict';
    /**
    * Extension of the class HTML, adds methods associated with the display full width overlay layer bettwen content and modals.
    * @namespace
    * @memberof html
    */
    var _active = new Collection.Data([]),
        _display = function ($container) {
            $('body').addClass("hidde-overflow"); //note: check if it is needed
            $container.addClass("open");
        },
        _defaultFrameOptions = {
            templateName: 'FrameOverlay',
            css: '',
            animation: true,
            showInstant: true,
            ignoreEscBtn: false
        },
        FrameOverlay = function ($content, opt, closeCallback) {
            if (!(this instanceof FrameOverlay)) {
                throw new Error('FrameOverlay is a constructor and it`s need "new" operator');
            }
            var self = this, options;
            this.options = options = $.extend({}, _defaultFrameOptions, opt);
            this.index = html.generateUniqueId(5);
            this.$container = html.get(options.templateName, { css: options.css, animation: options.animation ? ' eframeoverlay-animation' : '' });
            this.$content = $content;
            this.$container.find('.eframeoverlay-content:first').append($content);

            $(window).on('resize.' + this.index, function () {
                self.triggerHandler('afterResize');
            });
            $('body').append(this.$container);

            //Bind default close action
            $(document).on('keyup.' + this.index, function (e) {
                //If hit esc and all modal is close then trigger close overlay event
                if (!options.ignoreEscBtn && e.keyCode === 27 && (html.modal || html.modal.getactiveCount() === 0)) {
                    self.close(closeCallback);
                }
            });
            this.$container.on('click', 'button[data-action="close"]', function () { self.close(closeCallback); });
            _active.add(this);
            if (options.showInstant) {
                if (options.animation === false) _display(this.$container);
                self.show();
            }
            //EE-91 ticket - that's why it's for campaigncreator
            if (ee.Router.currentPath()===('campaigncreator')) {
                $(window).on('popstate', function(ev) {
                var $frameOverlay = $('.eframeoverlay');

                if ($frameOverlay && $frameOverlay.hasClass('open')) {
                    var rollbackPath = '#/' + ee.Router.currentPath();
                    setTimeout(function() {
                        ee.goTo(rollbackPath, true);
                        $(window).off('popstate');
                    }, 100);
                }
            });
            }
            
            return this;
        };

    FrameOverlay.prototype = {
        show: function () {
            var self = this, options = self.options;
            setTimeout(function () { self.trigger('beforeShow'); }, 0);
            setTimeout(function () {
                _display(self.$container);
                self.trigger('afterShow');
            }, options.animation ? 200 : 0);
        },
        close: function (callback) {
            var self = this;
            if (self.triggerHandler('beforeClose') === false) return;
            $(window).off('resize.' + this.index);
            $(document).off('keyup.' + this.index);
            $('body').removeClass("hidde-overflow");
            this.$container.removeClass("open");
            setTimeout(function () {
                self.trigger('afterClose');
                _active.remove('index', this);
                self.$container.remove();
                if (callback) {
                    callback();
                }
            }, this.$container.hasClass('eframeoverlay-animation') ? 110 : 0);

        },
        changeContent: function ($content) {
            this.trigger('beforeUpdate');
            this.$container.find('.eframeoverlay-content:first').empty().append($content);
            this.trigger('afterUpdate');
        },

        /**
         * Note: This metod is used only in ee.navigate, probably will be removed
         */
        forcedCloseAll: function () {
            _active.each(function () {
                this.off().close();
            });
        }
    };

    Object.defineProperty(FrameOverlay.prototype, "constructor", {
        value: FrameOverlay,
        writable: true,
        configurable: true
    });

    $.extend(FrameOverlay.prototype, html.event);
    html.Frameoverlay = FrameOverlay;
}());
