(function () {
    /**
    * Extension of the class HTML, adds methods associated with the display and behavior of searchboxes.
    * @namespace
    * @memberof html
    */

    /** 
*   New Searchbox behavior 
*   @param {jQuery.Object} $search - a container in which there are html elements for search plugin
*   @param {function} searchMethod - passed a search string to 'searchMethod', this function should filter data and refresh the view.
*   @param {Object} options - Options object
*   @param {String} options.activeClass - a active class name to open search input, default is 'search__active'
*   @param {Boolean} options.autoSearch - turn on autoSearch, default: true
*   @param {Boolean} options.searchOnClose - trigger search with empty string on close, default: true
*   @param {Boolean} options.inFrame - check if search is made inside original context, or inside other screen (in such case don't update urls)
*   @memberof html
*/
    html.bindSearch = function($search, searchMethod, options) {
    var searchTriggerDelay, searchDone = false; 
    var adminSearch = location.hash.indexOf("#/admin") != -1,

        opt = $.extend({
            activeClass: 'search__active',
            autoSearch: true,
            searchOnClose: true,
            staticSearch: false,
            inFrame: false
        }, options);
    $search.off('click')
    $search.input = $search.find('.search__input');
    $search.btnclear = $search.find('i.fa-times');
    $search.btnsearch = $search.find('i.fa-search');
    if (opt.staticSearch === true) {
        $search.btnsearch.addClass('active');
    }
    $search.on('click', 'i.fa-search', function (e) {
        e.stopPropagation();
        if ($search.hasClass(opt.activeClass) === true) {
            //is open, do search
            searchMethod($search.input.val());
            searchDone = true;
            if (!adminSearch && !opt.inFrame)
                html.updateRouter('search', $search.input.val());
            $search.input.focus();
        } else {
            //open it
            $search.addClass(opt.activeClass);
            $search.input.focus();
        }
    });
    $search.on('click', 'i.fa-times', function (e) {
        e.stopPropagation();
        if (opt.staticSearch === true) {
            $search.input.val('');
            searchMethod('');
        } else {
            $search.removeClass(opt.activeClass);
            if ($search.input.val() !== '' || searchDone) {
                $search.input.val('');
                if (opt.searchOnClose) searchMethod('');
            }
            $search.btnsearch.removeClass('active');
        }
        //remove parameter from ee.Router and hash
        if (!adminSearch && !opt.inFrame)
            html.updateRouter('search');
        searchDone = false;
    });
    if (ee.isReseller()) {
        $("div").each(function(){
            if($(this).hasClass('search_icon_pro')){
                $(this).addClass('search_icon');
                $(this).removeClass("search_icon_pro");
            }
        })
        $('.search__btn').each(function(){
            if($(this).hasClass('far')){
                $(this).addClass('fa');
                $(this).removeClass('far');
            }
        })
    }

    $search.input.off('keyup').keyup(function (e) {
        clearTimeout(searchTriggerDelay);
        //TODO: Search should be not do when user click alt, shift, ctrl and other special characters 
        if (e.keyCode === 13) {
            if (!adminSearch && !opt.inFrame)
                html.updateRouter('search', $search.input.val());
            searchMethod($search.input.val(), true);
            searchDone = true;
        } else {
            if (opt.autoSearch) {
                searchTriggerDelay = setTimeout(function () {
                    searchMethod($search.input.val(), false);
                    searchDone = true;
                    //@search mechanism
                    if (!adminSearch && !opt.inFrame)
                        html.updateRouter('search', $search.input.val());
                }, 200);
            } else {
                ($search.input.val().length > 0) ? $search.btnsearch.addClass('active') : $search.btnsearch.removeClass('active');
            }
        }
    });

        var searchstring = ee.Router.parameter('search');
        if (searchstring) {
            setTimeout(function () {
                // contast lags
                $search.input.val(decodeURI(searchstring)).trigger('keyup');
                $search.addClass('search__active');
                if (ee.Router.parameter('page') && $('#epagination')) {
                    $('#epagination').find('[data-page="' + ee.Router.parameter('page') + '"]').click();
                }
                searchMethod($search.input.val(), true);
            }, 1000);
        } else {
            if (!adminSearch && !opt.inFrame)
                html.updateRouter("search");
        }


    }

/** 
*   Adds/updates/removes search parameter. One-way binding Search > Router
*   @param {string} searchstring - string that will ba added as parameter to hash. If empty - removes parameter.
*   @memberof html
*/
    html.updateRouter = function (key, value) {
        //@search mechanism
        //return;
        if (value) {
            ee.Router.setParameter(key, value);
        } else
            ee.Router.removeParameter(key);
    },

/** 
*   Removes and disables Search
*   @memberof html
*/
    html.clearSearch = function () {
        var $search = $(ee.frame.find('.search'));
        if ($search) $search.removeClass('search__active').find('input').val('').trigger('keyup');
    };

}());