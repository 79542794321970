(function (namespace) {
    "use strict";
    var _templateType = ["rawhtml", "designer"],
        _format = function () {
            var template = this;
            template.typename = _templateType[template.templatetype] || 0; //Default is raw
            template.issnapshot = template.templatescope === 3;
            template.isdraft = template.templatescope === 2;
            template.ispublic = template.templatescope === 1;
            //templatebody text null then change to empty string!
        },

        Template = function Template(data) {
            //Set Default Property
            this.data = { templateid: 0, templatetype: undefined, name: "New Template", subject: "", fromemail: "", fromname: "", tags: "", templatescope: 0, ispublic: false, contentLoaded: true, originaltemplateid: 0 };
            if (!data) return;
            this.data.templateid = parseInt(data.templateid, 10) || 0;
            this.data.issnapshot = data.issnapshot || false;
            this.data.ispublic = data.ispublic || false;
            this.data.isdraft = data.isdraft || false;
            this.data.templatetype = parseInt(data.templatetype, 10) || 0;
            if (this.data.templateid > 0) {
                this.data.contentLoaded = false;
            }
            if (this.data.issnapshot === true) { this.data.templatescope = 3; }
            else if (this.data.isdraft === true) { this.data.templatescope = 2; }
            else if (this.data.ispublic === true) { this.data.templatescope = 1; }
            else { this.data.templatescope = 0; }
        },
        proto = {
            load: function () {
                if (this.data.contentLoaded === true) return this;
                var that = this;
                return EE_API.Template.LoadTemplate({ templateID: this.data.templateid, ispublic: this.data.ispublic }).then(function (data) {
                    that.data.contentLoaded = true;
                    that.data.bodyhtml = '';
                    that.data.bodytext = '';
                    that.data.bodyamp = '';
                    that.data.css = '';
                    that.update(data);
                    return this;
                });
            },
            save: function () {
                var request, data = this.data;
                if (data.templateid > 0) {
                    data.removescreenshot = false;
                    data.thumbnailpath = ee.api.path + "/userfile/" + ee.data.account.publicaccountid + "/templates" + data.thumbnail + "?timestamp=" + (new Date().getTime());
                    data.mediumscreenshotpath = ee.api.path + "/userfile/" + ee.data.account.publicaccountid + "/templates" + data.mediumscreenshot + "?timestamp=" + (new Date().getTime());
                    data.screenshotpath = ee.api.path + "/userfile/" + ee.data.account.publicaccountid + "/templates" + data.screenshot + "?timestamp=" + (new Date().getTime());
                }
                request = (data.templateid > 0) ? EE_API.Template.Update(data) : EE_API.Template.Save(data);

                return request.then(function (templateid) {
                    if (data.templateid === 0) data.templateid = templateid;
                    return templateid;
                });
            },
            saveSnapshot: function (data) {
                var query = {
                    templatetype: data.templatetype,
                    name: "Snapshot_" + data.templateid + "_" + ee.api.formatDate(new Date(), "yymmddhhiiss"),
                    bodyhtml: data.bodyhtml || "",
                    bodytext: data.bodytext || "",
                    bodyamp: data.bodyamp || "",
                    css: data.css || "",
                    originaltemplateid: data.templateid
                };
                return EE_API.Template.SaveSnapshot(query).then(function (templateid) {
                    if (data.templateid === 0) data.templateid = templateid;
                    return templateid;
                });
            },
            // Return Promis with request for API
            loadEmbededHtml: function () {
                return EE_API.Template.GetEmbeddedHtml({ templateID: this.data.templateid });
            },
            // Return Promis with request for API
            saveThumbnail: function (imageSrc) {
                return EE_API.Template.SaveScreenshot({ base64image: imageSrc.split(',')[1], templateid: this.data.templateid, templateName: this.data.name});
            },
            update: function (newData) {
                for (var property in this.data) {
                    if (this.data.hasOwnProperty(property) && newData.hasOwnProperty(property)) {
                        this.data[property] = newData[property];
                    }
                }
                _format.call(this.data);
                return this;
            },

            getTypeByName: function (typename) {
                return _templateType.indexOf(typename) || 0;
            }
        };
    $.extend(Template.prototype, proto);
    dataModel.register("Template", Template);
}(window.dataModel));
